/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { isSafari } from '../../utils/browser'

const CustomOTPInput = ({
  otpLength = 6,
  otp,
  setOtp,
  isValidCode,
  setErr,
}) => {
  const inputRefs = Array(otpLength)
    .fill(null)
    .map(() => useRef())

  const handleInputChange = (e, index) => {
    const { value } = e.target
    if (value.length <= 1) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp]
        newOtp[index] = value
        return newOtp
      })

      if (value && index < otpLength - 1) {
        inputRefs[index + 1].current.focus()
      }
    }
  }

  const handleInputPaste = (e) => {
    e.preventDefault()
    const pasteData = e.clipboardData.getData('text')
    if (pasteData.length === otpLength) {
      const newOtp = pasteData.split('').map((char) => parseInt(char, 10))
      setOtp(newOtp)
    }
  }

  const handleInputKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs[index - 1].current.focus()
    }
  }

  const baseClasses =
    'text-center border border-borderGrey rounded-2xl py-3 px-4  text-brightPurple  font-maisonBold bg-LightGrey focus:border-brightPurple focus:outline-none transition duration-150 dark:bg-dark-darkPurple dark:text-dark-darkTextGrey dark:border-dark-darkBorderGrey dark:focus:border-dark-darkTeal placeholder:text-TextGrey'

  const errorClasses = !isValidCode
    ? ''
    : 'border-textNegative bg-textNegativeTint'


  const clearOtp = () => {
    if (otp.join('')?.length === 6) {
      setOtp(new Array(6).fill(''))
    } else if (otp.join('')?.length === 0 && setErr) {
      setErr(false)
    }
  }

  return (
    <div
      className="flex items-center justify-center"
      onClick={() => clearOtp()}
    >
      {otp.map((value, index) => (
        <React.Fragment key={index}>
          <input
            placeholder="-"
            style={{
              border: isValidCode && '1px solid #FF619A',
              ...(isSafari
                ? { fontFamily: 'MaisonDemiBold', fontWeight: '500' }
                : {}),
            }}
            className={`${baseClasses} ${errorClasses} m-auto w-[51.18px] h-[${
              value ? '64px' : '52px'
            }] md:w-[56.67px] md:h-[64px]`}
            ref={inputRefs[index]}
            value={value}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleInputKeyDown(e, index)}
            onPaste={handleInputPaste}
            maxLength={1}
            type={'number'}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

export default CustomOTPInput
