/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  BriefcaseIcon,
  Close,
  HomeIcon,
  InfoIcon,
} from '../../../assets/AppIcons'
import { useTheme } from '../../../utils/ThemeColorContext'
import FormInput from '../../../components/inputs/FormInput'
import PageHeader from '../../../components/PageHeader'
import Modal from '../../../components/Modal'
import FormSelect from '../../../components/inputs/FormSelect'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import SubPageHeader from '../../../components/SubPageHeader'
import ModalHeader from '../../../components/ModalHeader'
import RadioGroupInput from '../../../components/inputs/RadioGroupInput'
import OutllineButton from '../../../components/buttons/OutllineButton'
import DefaultPage from '../DefaultPage'
import { useNavigate } from 'react-router'
import { AppStyles } from '../../../assets/styles/AppStyles'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAddresses,
  addPastAddresses,
  getAddress,
  getAddressDetails,
} from '../../../redux/Actions/postMethods'
import Spinner from '../../../components/Spinner'
import { getProfile } from '../../../redux/Actions/getMethods'
import RefferedRoute from '../../../navigation/RefferedRoute'
import OnEnter from '../../../components/navigation/onEnter'
import { isSafari } from '../../../utils/browser'

const initialvalues = {
  department_name: '',
  house: '',
  line_1: '',
  line_2: '',
  line_3: '',
  town: '',
  postcode: '',
  years_resident: '',
  time_at_address: '',
  post_code_manual: '',
  postal_county: '',
  traditional_county: '',
  organisation_name: '',
  udprn: '',
  dps: '',
  error_code: '',
  error_message: '',
  delivery_point_count: 0,
  status: '',
  region: '',
}
const initialvaluesPastAddress = {
  department_name: '',
  house: '',
  line_1: '',
  line_2: '',
  line_3: '',
  town: '',
  postcode: '',
  years_resident: '',
  time_at_address: '',
  // time_at_address: {
  //   label: '',
  //   value: '',
  // },
  post_code_manual: '',
  postal_county: '',
  traditional_county: '',
  organisation_name: '',
  udprn: '',
  dps: '',
  error_code: '',
  error_message: '',
  delivery_point_count: 0,
  status: '',
  region: '',
}

const titleoptions = [
  {
    label: 'Home Owner',
    value: 'Home Owner',
  },
  {
    label: 'Private Tenant',
    value: 'Private Tenant',
  },
  {
    label: 'Living with Parents',
    value: 'Living with Parents',
  },
  {
    label: 'Other Tenant',
    value: 'Other Tenant',
  },
]
const timeoptions = [
  {
    label: 'Less than one year',
    value: 1,
  },
  {
    label: '2 years',
    value: 2,
  },
  {
    label: 'More than 3 years',
    value: 3,
  },
  {
    label: 'More than 5 years',
    value: 5,
  },
]

function AddressDetail() {
  const { loadingAddAddress, loading2, token } = useSelector(
    (state) => state.authReducer,
  )
  const { rapidAddresses, profile, postCodeError, loadingWholeScreen } = useSelector(
    (state) => state.dataReducer,
  )

  const [postcodeTitleToggle1, setPostcodeTitleToggle1] = useState(false)
  const [postcodeTitleToggle2, setPostcodeTitleToggle2] = useState(false)
  const dispatch = useDispatch()
  const { color, isSmallScreen } = useTheme()
  const navigate = useNavigate()
  const [values, setValues] = useState(initialvalues)
  const [pastAddress, setPastAddress] = useState(initialvaluesPastAddress)
  const [changeAddressModal, setchangeAddressModal] = useState(false)
  const [changePastAddressModal, setchangePastAddressModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedPastOption, setSelectedPastOption] = useState('')
  const [additionalAddresses, setAdditionalAddresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [selectedIndex] = useState(null)
  const [activeIndex] = useState(null)
  const [apiPostCodeOptions, setApiPostCodeOptions] = useState(null)
  const [town, settown] = useState('')
  const [postcode, setpostcode] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [openModal, setopenModal] = useState(false)

  useEffect(() => {
    const fetchProfile = async () => {
      await dispatch(getProfile())
    }
    fetchProfile()
  }, [])

  useEffect(() => {
    if (apiPostCodeOptions?.length > 0) {
      settown(rapidAddresses?.town)
      setpostcode(rapidAddresses?.postcode)
    }
  }, [apiPostCodeOptions?.length > 0])

  const [threeYearComplete, setThreeYearComplete] = useState(null)

  const concatenatedAddress = `${values.house ? values.house : ''}${values.department_name ? `${values.department_name}` : ''} ${values.line_1}, ${values.town} ${values.post_code_manual}`
  const concatenatedPastAddress = `${pastAddress.house ? pastAddress.house : ''}${pastAddress.department_name ? `${pastAddress.department_name}` : ''} ${pastAddress.line_1}, ${pastAddress.town} ${pastAddress.post_code_manual}`
  // const concatenatedPastAddress = `${pastAddress.house ? pastAddress.house : ''}${pastAddress.department_name ? `${pastAddress.department_name}` : ''}, ${pastAddress.line_1}, ${pastAddress.town}, ${pastAddress.post_code_manual}`

  const timeAtAddressValues = additionalAddresses.map(
    (item) => item.time_at_address.value,
  )
  const sum = timeAtAddressValues.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  )

  useEffect(() => {
    if (values?.time_at_address?.value) {
      const temp = sum + values?.time_at_address?.value
      setThreeYearComplete(temp)
    } else {
      setThreeYearComplete(sum)
    }
  }, [values?.time_at_address?.value, additionalAddresses])

  const handleAddAddress = () => {
    setPostcodeTitleToggle2(false)
    setAddPast(true)
  }

  const handleRemoveIncome = () => {
    const updatedAddress = additionalAddresses.filter(
      (_, index) => index !== activeIndex,
    )
    setAdditionalAddresses(updatedAddress)
    setopenModal(false)
  }

  function titleCase(input) {
    const words = input?.split(' ')
    const capitalizedWords = words?.map((word) => {
      if (isNaN(parseInt(word))) {
        return word[0]?.toUpperCase() + word?.slice(1)?.toLowerCase()
      } else {
        return word
      }
    })
    return capitalizedWords?.join(' ')
  }

  function upperCase(input) {
    return input?.toUpperCase()
  }

  const [selectAddresFind, setSelectAddressFind] = useState()
  const handleSelectedAddressValues = () => {
    if (selectAddresFind === 'present') {
      setPostcodeTitleToggle1(true)
      setTimeout(() => {
        setValues({
          ...values,
          // postcode isn't actually a postcode, but rather soemthing like 'the selected address'. post_code_manual is the actual postcode
          postcode: selectedOption,
          line_1: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.label,
          ),
          town: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.town,
          ),
          post_code_manual: upperCase(
            JSON.parse(localStorage.getItem('findAddress'))?.postcode,
          ),
          department_name: titleCase(
            localStorage.getItem('findAddress')?.department_name,
          ),
          line_2: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.label2,
          ),
          dps: titleCase(JSON.parse(localStorage.getItem('findAddress'))?.dps),
          udprn: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.udprn,
          ),
          traditional_county: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.traditional_county,
          ),
          postal_county: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.postal_county,
          ),
        })
      }, 500)
    } else {
      setSelectedPastOption(true)
      setPostcodeTitleToggle2(true)
      setTimeout(() => {
        setPastAddress({
          ...pastAddress,
          // postcode isn't actually a postcode, but rather soemthing like 'the selected address'. post_code_manual is the actual postcode
          postcode: selectedOption,
          line_1: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.label,
          ),
          town: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.town,
          ),
          // post_code_manual: upperCase(postcode),
          post_code_manual: upperCase(
            JSON.parse(localStorage.getItem('findAddress'))?.postcode,
          ),
          department_name: titleCase(
            localStorage.getItem('findAddress')?.department_name,
          ),
          line_2: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.label2,
          ),
          dps: titleCase(JSON.parse(localStorage.getItem('findAddress'))?.dps),
          udprn: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.udprn,
          ),
          traditional_county: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.traditional_county,
          ),
          postal_county: titleCase(
            JSON.parse(localStorage.getItem('findAddress'))?.postal_county,
          ),
        })
      }, 500)
    }
  }

  const handleRadioChange = (e) => {
    const addressPartsTesting = e.target.value?.split(',')
    const houseNumberPart = addressPartsTesting[0]
    // Extract the numeric part using regular expression
    const houseNumberMatch = houseNumberPart.match(/\b\d+\b/)
    if (houseNumberMatch) {
      var houseNumber = houseNumberMatch[0]
    }
    setSelectedOption(e.target.value)
    const addressParts = e.target.value?.split(',')
    const label = addressParts[0]?.trim()
    const label2 = addressParts[1]?.trim()

    const stringWithIntegers = label
    const stringWithoutIntegers = stringWithIntegers.replace(/\d+/g, '').trim()
  }

  const handleChange = (e) => {
    let { name, value } = e.target // e.target refers to the input element
    if (name === 'post_code_manual') {
      value = value.toUpperCase()
    } else if (name === 'postcode') {
      if (!postcodeTitleToggle1) {
        value = value.toUpperCase()
      } else {
        return
      }
    }
    setValues({
      ...values, // spread the existing values
      [name]: value, // override the value for the input's name
    })
    localStorage.setItem(`current-postcode-${token?.email}`, value)
  }

  // for past address
  const handleChangePastAddress = (e) => {
    let { name, value } = e.target // e.target refers to the input element
    if (name === 'post_code_manual') {
      value = value.toUpperCase()
    } else if (name === 'postcode') {
      if (!postcodeTitleToggle2) {
        value = value.toUpperCase()
      } else {
        return
      }
    }
    setPastAddress({
      ...pastAddress, // spread the existing values
      [name]: value, // override the value for the input's name
    })
  }

  const [editPresentAddress, setEditPresentAddress] = useState(false)
  const [editPastAddress, setEditPastAddress] = useState(false)
  // ============================= Add Address =======================//
  const handleConfirm = () => {
    setSelectAddressFind('present')
    if (concatenatedAddress) {
      setSelectedOption(concatenatedAddress)
      setPostcodeTitleToggle1(true)
      const line_1 = values.house + ' ' + values.line_1
      setValues({
        ...values,
        line_1: editPresentAddress ? values.line_1 : line_1,
        house: editPresentAddress ? values.house : '',
        postcode: concatenatedAddress,
      })
    }
    setchangeAddressModal(false)
  }
  // ============================= Add Address =======================//
  // ============================= Add Address =======================//
  const handlePastAddressConfirm = () => {
    setSelectAddressFind('past')
    if (concatenatedPastAddress) {
      setSelectedPastOption(concatenatedPastAddress)
      setPostcodeTitleToggle2(true)
      const line_1 = pastAddress.house + ' ' + pastAddress.line_1
      setPastAddress({
        ...pastAddress,
        // line_1: line_1,
        // house: '',
        line_1: editPresentAddress ? pastAddress.line_1 : line_1,
        house: editPresentAddress ? pastAddress.house : '',

        postcode: concatenatedPastAddress,
      })
    }
    setchangePastAddressModal(false)
    // setValues(initialvalues);
  }
  // ============================= Add Address =======================//

  // ============================= Update Address =======================//

  const adjustLabelofYear = (item) => {
    if (item === 2) {
      return '2 years'
    } else if (item === 3) {
      return 'More than 3 years'
    } else if (item === 5) {
      return 'More than 5 years'
    } else if (item === 1) {
      return 'Less than one year'
    }
  }

  function areAllValuesEmpty(obj) {
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] !== '') {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (areAllValuesEmpty(values) && token?.email) {
      localStorage.setItem(`present-${token?.email}`, JSON.stringify(values))
    }
  }, [values])

  useEffect(() => {
    if (isModalOpen === true) {
      setSelectedOption('')
    }
  }, [isModalOpen])

  useEffect(() => {
    if (areAllValuesEmpty(pastAddress) && token?.email) {
      localStorage.setItem(
        `pastaddress-${token?.email}`,
        JSON.stringify(pastAddress),
      )
    }
  }, [pastAddress])

  const loadPresetAddress = () => {
    if (token?.email) {
      const myData = localStorage.getItem(`present-${token?.email}`)
      if (myData && myData.line_1 && myData.town && myData.postcode) {
        setValues(JSON.parse(myData))
        setSelectedOption(concatenatedAddress)
        setPostcodeTitleToggle1(true)
      }
    }
  }

  const loadPastAddress = async () => {
    const myData = localStorage.getItem(`pastaddress-${token?.email}`)
    if (
      myData &&
      token?.email &&
      myData.line_1 &&
      myData.town &&
      myData.postcode
    ) {
      //const temp = await areAllValuesEmpty()
      setPastAddress(JSON.parse(myData))
      setSelectedPastOption(concatenatedPastAddress)
      setPostcodeTitleToggle2(true)
    }
  }

  const onTextChange = (e) => {
    const addressPartsTesting = e?.split(',')
    const houseNumberPart = addressPartsTesting[0]
    // Extract the numeric part using regular expression
    const houseNumberMatch = houseNumberPart.match(/\b\d+\b/)
    if (houseNumberMatch) {
      var houseNumber = houseNumberMatch[0]
    }
    setSelectedOption(e)
    const addressParts = e?.split(',')
    const label = addressParts[0]?.trim()
    const label2 = addressParts[1]?.trim()

    const stringWithIntegers = label
    const stringWithoutIntegers = stringWithIntegers.replace(/\d+/g, '').trim()

    // const addressPartsTesting = option?.split(',')
    // const houseNumberPart = addressPartsTesting[0]
    // // Extract the numeric part using regular expression
    // const houseNumberMatch = houseNumberPart.match(/\b\d+\b/)
    // if (houseNumberMatch) {
    //   var houseNumber = houseNumberMatch[0]
    // }
    // setSelectedOption(option)
    // const addressParts = option?.split(',')
    // const label = addressParts[0]?.trim()
    // const label2 = addressParts[1]?.trim()

    // const stringWithIntegers = label
    // const stringWithoutIntegers = stringWithIntegers.replace(/\d+/g, '').trim()

    // if (selectAddresFind === 'present') {
    //   setPostcodeTitleToggle1(true)
    //   setValues({
    //     ...values,
    //     postcode: option,
    //     line_1:
    //       option?.length > 36
    //         ? titleCase(label2)
    //         : titleCase(stringWithoutIntegers),
    //     town: titleCase(town),
    //     post_code_manual: postcode,
    //     department_name: option?.length > 36 ? titleCase(label) : houseNumber,
    //     line_2: '',
    //     county: titleCase(
    //       JSON.parse(localStorage.getItem('findAddress'))?.traditional_county,
    //     ),
    //   })
    // } else {
    //   setSelectedPastOption(true)

    //   setPostcodeTitleToggle2(true)
    //   setPastAddress({
    //     ...pastAddress,
    //     postcode: option,
    //     line_1:
    //       option?.length > 36
    //         ? titleCase(label2)
    //         : titleCase(stringWithoutIntegers),
    //     town: titleCase(town),
    //     post_code_manual: postcode,
    //     department_name: option?.length > 36 ? titleCase(label) : houseNumber,
    //     line_2: '',
    //     county: titleCase(
    //       JSON.parse(localStorage.getItem('findAddress'))?.traditional_county,
    //     ),
    //   })
    // }
  }

  useEffect(() => {
    // dispatch(getAddress(setMyAddress))
    if (profile?.address) {
      setSelectedOption(concatenatedAddress)
      setPostcodeTitleToggle1(true)
      setValues({
        ...values,
        town: profile?.address?.town,
        line_1: profile?.address?.line_1,
        line_2: profile?.address?.line_2,
        department_name: profile?.address?.department_name,
        postal_county: profile?.address?.postal_county,
        post_code_manual: profile?.address?.postcode,
        time_at_address: {
          label: adjustLabelofYear(profile?.address?.years_resident),
          value: profile?.address?.years_resident,
        },
        status: {
          label: profile?.address?.status,
          value: profile?.address?.status,
        },
        postcode: `${
          profile?.address?.house ? profile?.address?.house + ' ' : ''
        }${profile?.address?.line_1}, ${
          profile?.address?.line_2 && `${profile?.address?.line_2},`
        } ${profile?.address?.town} ${profile?.address?.postcode}`,
      })
      if (values.postcode === '' && profile?.address?.postcode === '') {
        setSelectedOption('')
      }
    } else {
      loadPresetAddress()
    }
    if (profile?.past_address) {
      setAddPast(true)
      setSelectedPastOption(concatenatedPastAddress)
      setPostcodeTitleToggle2(true)
      setPastAddress({
        ...pastAddress,
        town: profile?.past_address?.town,
        line_1: profile?.past_address?.line_1,
        line_2: profile?.past_address?.line_2,
        // line_2: findAddress?.label ? findAddress?.label2 : myAddress?.line_2,
        department_name: profile?.past_address?.department_name,
        postal_county: profile?.past_address?.postal_county,
        post_code_manual: profile?.past_address?.postcode,
        time_at_address: {
          label: adjustLabelofYear(profile?.past_address?.years_resident),
          value: profile?.past_address?.years_resident,
        },
        status: {
          label: profile?.past_address?.status,
          value: profile?.past_address?.status,
        },

        postcode: `${
          profile?.past_address?.house ? profile?.past_address?.house + ' ' : ''
        }${profile?.past_address?.line_1}, ${
          profile?.past_address?.line_2 && `${profile?.past_address?.line_2},`
        } ${profile?.past_address?.town} ${profile?.past_address?.postcode}`,
      })
      if (
        pastAddress.postcode === '' &&
        profile?.past_address?.postcode === ''
      ) {
        setSelectedPastOption('')
      }
    } else {
      loadPastAddress()
    }

    if (pastAddress.postcode === '' && profile?.past_address?.postcode === '') {
      setSelectedPastOption('')
    }
    // ========================= my address available get from redux ==========================
  }, [profile?.address?.id, profile?.past_address?.id, token?.email])

  const handleUpdate = () => {
    const updatedAddresses = [...additionalAddresses]
    updatedAddresses[selectedIndex] = {
      ...updatedAddresses[selectedIndex],
      ...values,
      postcode: concatenatedAddress,
    }
    setAdditionalAddresses(updatedAddresses)
    setValues(initialvalues)
    setchangeAddressModal(false)
  }
  // ============================= Update Address =======================//

  const addAddress = async () => {
    const dataWithPast = { past: pastAddress }
    const dataWithPresent = { present: values }
    if (threeYearComplete < 3) {
      dispatch(addPastAddresses(dataWithPast))
      const res = await dispatch(addAddresses(dataWithPresent))
      if (res) {
        const getSuccess = await dispatch(getAddress(setValues))
        if (getSuccess) {
          const getPro = await dispatch(getProfile())
          if (getPro) {
            localStorage.removeItem(`present-${token?.email}`)
            localStorage.removeItem(`current-timeAddress-${token?.email}`)
            localStorage.removeItem(`current-residency-${token?.email}`)
            localStorage.removeItem(`pastaddress-${token?.email}`)
            localStorage.removeItem(`findAddress`)
            localStorage.removeItem(`current-postcode-${token?.email}`),
              dispatch({ type: ActionTypes.EDIT_PROFILE, payload: values })
            if (window.fbq) {
              window.fbq('init', process.env.REACT_APP_META_ID, {
                em: token?.email?.toLowerCase(),
                ph: token?.phone_number?.slice(1),
                fn: getPro?.first_name?.toLowerCase(),
                ln: getPro?.family_name?.toLowerCase(),
                db: token?.birthdate?.replace('-', ''),
                ct: getPro?.address?.town?.toLowerCase().replace(' ', ''),
                st: getPro?.address?.postal_county ? getPro?.address?.postal_county?.toLowerCase().replace(' ', '') : getPro?.address?.traditional_county?.toLowerCase().replace(' ', ''),
                zp: getPro?.address?.postcode,
                country: 'gb',
                external_id: token?.sub,
              })
            }
            navigate('/payoff')
          }
        }
      }
      // }
      // }
    } else {
      const res = await dispatch(addAddresses(dataWithPresent))
      if (res) {
        localStorage.removeItem(`present-${token?.email}`)
        localStorage.removeItem(`current-timeAddress-${token?.email}`)
        localStorage.removeItem(`pastaddress-${token?.email}`)
        localStorage.removeItem(`current-residency-${token?.email}`)
        localStorage.removeItem(`findAddress`)
        if (window.fbq) {
          window.fbq('init', process.env.REACT_APP_META_ID, {
            em: token?.email?.toLowerCase(),
            ph: token?.phone_number.slice(1),
            fn: token?.given_name?.toLowerCase(),
            ln: token?.family_name?.toLowerCase(),
            db: token?.birthdate?.replace('-', ''),
            ct: res?.data?.address?.town?.toLowerCase().replace(' ', ''),
            st: res?.data?.address?.postal_county ? res?.data?.address?.postal_county?.toLowerCase().replace(' ', '') : res?.data?.address?.traditional_county?.toLowerCase().replace(' ', ''),
            zp: res?.data?.address?.postcode,
            country: 'gb',
            external_id: token?.sub,
          })
        }
        localStorage.removeItem(`current-postcode-${token?.email}`),
          navigate('/payoff')
        dispatch({ type: ActionTypes.EDIT_PROFILE, payload: values })
        dispatch(getAddress(setValues))
        dispatch(getProfile())
      }
    }
  }
  const [addPast, setAddPast] = useState(false)

  useEffect(() => {
    if (threeYearComplete >= 3) {
      setAddPast(false)
    }
  }, [threeYearComplete >= 3])
  return (
    <DefaultPage>
      {/* <JourneyRoute /> */}
      <RefferedRoute />
      <div className="relative container mx-auto md:w-[564px] md:space-y-10 ">
        <PageHeader
          title={'Your address'}
          customDescription={
            <p
              style={
                isSmallScreen
                  ? {
                      ...AppStyles.Body,
                      color: color.Grey,
                    }
                  : {
                      ...AppStyles.Subheading1,
                      color: color.Grey,
                    }
              }
            >
              Hey
              <span style={{ color: color.BlurpleTeal }}>
                {' '}
                {profile?.first_name}
              </span>
              , please add your current address and previous one if required.
            </p>
          }
        />
        <div
          style={{
            backgroundColor: color.White,
          }}
          className=" rounded-3xl p-6 space-y-10"
        >
          <div
            className={`space-y-6 pb-0`}
            style={{
              minHeight: isSmallScreen && 'auto',
              margin: isSmallScreen && additionalAddresses.length > 0 && '0',
            }}
          >
            <div className="flex items-center justify-between ">
              <SubPageHeader Icon={HomeIcon} text={'Your address'} />
            </div>
            <div
              className={`w-full  flex flex-col space-y-6 md:space-y-0 md:flex-row md:space-x-6`}
            >
              <div className="flex flex-col space-y-6 w-full">
                <div className=" flex items-start flex-col md:flex-row md:space-x-6">
                  <div className=" w-full md:space-x-6">
                    <FormSelect
                      fontwBold
                      options={titleoptions}
                      placeholder={'Residency type'}
                      type={'select'}
                      onChange={(val) => {
                        setValues({ ...values, status: val })
                        localStorage.setItem(
                          `current-residency-${token?.email}`,
                          val.value,
                        )
                      }}
                      name={'status'}
                      value={values.status}
                    />
                  </div>
                </div>

                <div className=" flex items-start flex-col space-y-6">
                  <div className="flex flex-col space-y-2 w-full">
                    <div className=" relative">
                      <FormInput
                        placeholder={
                          postcodeTitleToggle1
                            ? 'Your address'
                            : 'Your postcode'
                        }
                        type={'text'}
                        onChange={(e) => {
                          handleChange(e)
                          if (!postcodeTitleToggle1) {
                            const temp = e.target.value?.length
                            if (temp === 0) {
                              setValues(initialvalues)
                            }
                          }
                        }}
                        name={'postcode'}
                        autoComplete={
                          postcodeTitleToggle1 ? 'off' : 'postal-code'
                        }
                        value={values.postcode}
                        rightElement={
                          values.postcode === '' ? (
                            ''
                          ) : (
                            <img
                              src={Close}
                              width={'16px'}
                              height={'16px'}
                              onClick={() => {
                                setEditPresentAddress(false)
                                setPostcodeTitleToggle1(false)
                                setValues({
                                  ...values,
                                  postcode: '',
                                  line_1: '',
                                  line_2: '',
                                  town: '',
                                  house: '',
                                  postal_county: '',
                                  traditional_county: '',
                                  post_code_manual: '',
                                  dps: '',
                                  udprn: '',
                                })
                                setSelectedOption('')
                                // setValues(initialvalues)
                              }}
                              className=" cursor-pointer"
                            />
                          )
                        }
                      />
                      {values.postcode?.length > 5 &&
                        values.postcode?.length < 9 && (
                          <div
                            style={{
                              // marginTop: '0px',
                              position: 'absolute',
                              top: '100%',
                              zIndex: '1',
                              width: '100%',
                              height: '56px',
                              boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.25)',
                            }}
                            onClick={() => {
                              if (loading2) {
                                return
                              }
                              setSelectAddressFind('present')
                              setIsModalOpen(true)
                              dispatch(
                                getAddressDetails(
                                  values.postcode,
                                  setApiPostCodeOptions,
                                  // setIsModalOpen,
                                ),
                              )
                            }}
                            className={`${
                              loading2 && 'flex justify-center'
                            } cursor-pointer rounded-2xl border border-borderGrey p-4 bg-white  `}
                          >
                            <p
                              style={{
                                ...AppStyles.BodyBold,
                                color: color.BlurpleTeal,
                              }}
                            >
                              {loading2 ? <Spinner /> : 'Find address'}
                            </p>
                          </div>
                        )}
                    </div>
                    <div className="space-y-3" id="manually">
                      <p
                        onClick={() => {
                          if (!values.postcode) {
                            localStorage.removeItem('findAddress')
                            setEditPresentAddress(true)
                            setSelectAddressFind('present')
                            setchangeAddressModal(true)
                            setSelectedAddress(null)
                          } else if (editPresentAddress && values.postcode) {
                            setSelectAddressFind('present')
                            setchangeAddressModal(true)
                            setSelectedAddress(null)
                          } else {
                            // setIsModalOpen(true)
                            if (!loading2) {
                              setSelectAddressFind('present')
                              dispatch({
                                type: ActionTypes.POSTCODE_ERROR,
                                payload: '',
                              })
                              setIsModalOpen(true)
                              dispatch(
                                getAddressDetails(
                                  values?.post_code_manual,
                                  setApiPostCodeOptions,
                                  setIsModalOpen,
                                ),
                              )
                            }
                          }
                        }}
                      >
                        <a
                          style={{
                            ...AppStyles.Body,
                            color: color.BlurpleWhite,
                          }}
                          className="w-full underline cursor-pointer "
                        >
                          {editPresentAddress && values.postcode
                            ? 'Edit address manually'
                            : values.postcode
                              ? 'Select a different address'
                              : 'Enter address manually'}
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className=" w-full md:space-x-6">
                    <FormSelect
                      calIcon={isSmallScreen ? true : false}
                      options={timeoptions}
                      placeholder={'Time at address'}
                      type={'select'}
                      onChange={(val) => {
                        setValues({ ...values, time_at_address: val })
                        localStorage.setItem(
                          `current-timeAddress-${token?.email}`,
                          val.value,
                        )
                      }}
                      name={'time_at_address'}
                      value={values.time_at_address}
                    />
                  </div>
                </div>
                <div>
                  {addPast ? (
                    ''
                  ) : (
                    <>
                      {!values.time_at_address ||
                        !values.time_at_address.value ||
                        !values.postcode ||
                        !values.status ||
                        (threeYearComplete < 3 && (
                          <div className=" space-y-6">
                            <div className="p-4 border rounded-2xl space-x-4 flex bg-white border-borderGrey dark:border-dark-darkBorderGrey dark:bg-dark-darkPurple">
                              <div>
                                <InfoIcon fill={color.BlurpleTeal} />
                              </div>
                              <div className="  flex flex-col">
                                <p
                                  style={{
                                    ...AppStyles.BodyBold,
                                    color: color.BlurpleTeal,
                                  }}
                                >
                                  Additional Address
                                </p>
                                <p
                                  style={{
                                    ...AppStyles.Body12Demi,
                                    color: color.Grey,
                                  }}
                                >
                                  As you have not been at your current address
                                  for more than 3 years please provide us with
                                  your previous address.
                                </p>
                              </div>
                            </div>
                            <div>
                              <div className=" space-y-6">
                                <OutllineButton
                                  id={'add-address'}
                                  text={'Add Address'}
                                  onClick={handleAddAddress}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
            </div>
            {addPast && <div className="border-t"></div>}
          </div>
          {threeYearComplete < 3 && addPast && (
            <div
              className={`space-y-6 pb-0`}
              style={{
                minHeight: isSmallScreen && 'auto',
                margin: isSmallScreen && additionalAddresses.length > 0 && '0',
              }}
            >
              <div className="flex items-center justify-between">
                <SubPageHeader Icon={HomeIcon} text={'Your previous address'} />
                <p
                  style={{
                    ...AppStyles.Body16Demi,
                    color: color.SoftPink,
                  }}
                  className="cursor-pointer"
                  onClick={() => {
                    setPastAddress(initialvaluesPastAddress)
                    setAddPast(false)
                    localStorage.removeItem(`pastaddress-${token?.email}`)
                  }}
                >
                  Remove
                </p>
              </div>
              <div className={`w-full  flex flex-col space-y-6`}>
                <div className="flex flex-col space-y-6 w-full">
                  <div className=" flex items-start flex-col md:flex-row md:space-x-6">
                    <div className=" w-full md:space-x-6">
                      <FormSelect
                        fontwBold
                        options={titleoptions}
                        placeholder={'Residency Type'}
                        type={'select'}
                        onChange={(val) =>
                          setPastAddress({
                            ...pastAddress,
                            status: val,
                          })
                        }
                        name={'status'}
                        value={pastAddress.status}
                      />
                    </div>
                  </div>

                  <div className=" flex items-start flex-col space-y-6  ">
                    <div className="flex flex-col gap-2 w-full ">
                      <div className="relative">
                        <FormInput
                          placeholder={
                            postcodeTitleToggle2
                              ? 'Your Address'
                              : 'Your Post Code'
                          }
                          type={'text'}
                          onChange={(e) => {
                            handleChangePastAddress(e)
                            if (!postcodeTitleToggle2) {
                              const temp = e.target.value?.length
                              if (temp === 0) {
                                setPastAddress(initialvaluesPastAddress)
                              }
                            }
                          }}
                          name={'postcode'}
                          autoComplete={
                            postcodeTitleToggle1 ? 'off' : 'postal-code'
                          }
                          value={pastAddress.postcode}
                          rightElement={
                            pastAddress.postcode === '' ? (
                              ''
                            ) : (
                              <img
                                src={Close}
                                width={'16px'}
                                height={'16px'}
                                onClick={() => {
                                  setEditPastAddress(false)
                                  setSelectedPastOption('')
                                  setPostcodeTitleToggle2(false)
                                  setPastAddress({
                                    ...pastAddress,
                                    postcode: '',
                                    line_1: '',
                                    line_2: '',
                                    town: '',
                                    house: '',
                                    postal_county: '',
                                    traditional_county: '',
                                    post_code_manual: '',
                                    udprn: '',
                                    dps: '',
                                  })
                                }}
                                className=" cursor-pointer"
                              />
                            )
                          }
                        />
                        {pastAddress?.postcode?.length > 5 &&
                          pastAddress?.postcode?.length < 9 && (
                            <div
                              style={{
                                // marginTop: '0px',
                                position: 'absolute',
                                top: '68%',
                                zIndex: '1',
                                width: '100%',
                                height: '56px',
                                boxShadow:
                                  '0px 4px 16px 0px rgba(0, 0, 0, 0.25)',
                              }}
                              onClick={() => {
                                if (loading2) {
                                  return
                                }
                                setSelectAddressFind('past')
                                setIsModalOpen(true)
                                dispatch(
                                  getAddressDetails(
                                    pastAddress.postcode,
                                    setApiPostCodeOptions,
                                    setIsModalOpen,
                                  ),
                                )
                              }}
                              className={`${
                                loading2 && 'flex justify-center'
                              } cursor-pointer rounded-2xl border border-borderGrey p-4 bg-white`}
                            >
                              <p
                                style={{
                                  ...AppStyles.BodyBold,
                                  color: color.BlurpleTeal,
                                }}
                              >
                                {loading2 ? <Spinner /> : 'Find Address'}
                              </p>
                            </div>
                          )}
                        <div className=" mt-2 space-y-3" id="manually">
                          <p
                            onClick={() => {
                              if (!selectedPastOption) {
                                localStorage.removeItem('findAddress')
                                setEditPastAddress(true)
                                setSelectAddressFind('past')
                                setchangePastAddressModal(true)
                                setSelectedAddress(null)
                              } else if (
                                editPastAddress &&
                                pastAddress.postcode
                              ) {
                                setSelectAddressFind('past')
                                setchangePastAddressModal(true)
                                setSelectedAddress(null)
                              } else {
                                if (!loading2) {
                                  setSelectAddressFind('past')
                                  setIsModalOpen(true)
                                  dispatch(
                                    getAddressDetails(
                                      pastAddress?.post_code_manual,
                                      setApiPostCodeOptions,
                                      setIsModalOpen,
                                    ),
                                  )
                                }
                              }
                            }}
                          >
                            <a
                              style={{
                                ...AppStyles.Body,
                                color: color.BlurpleWhite,
                              }}
                              className="w-full underline cursor-pointer "
                            >
                              {editPastAddress && pastAddress.postcode
                                ? 'Edit address manually'
                                : selectedPastOption && pastAddress.postcode
                                  ? 'Select a different address'
                                  : 'Enter address manually'}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" w-full md:space-x-6">
                      <FormSelect
                        calIcon={isSmallScreen ? true : false}
                        options={timeoptions}
                        placeholder={'Time at Address'}
                        type={'select'}
                        onChange={(val) => {
                          setPastAddress({
                            ...pastAddress,
                            time_at_address: val,
                          })
                        }}
                        name={'time_at_address'}
                        value={pastAddress.time_at_address}
                      />
                    </div>
                  </div>

                  {(values?.time_at_address?.value === 2 ||
                    values?.time_at_address?.value === 1) &&
                  threeYearComplete < 3 ? (
                    <div className=" space-y-6"></div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <OnEnter
          actionFunction={() => {
            if (changeAddressModal) {
              if (!values.post_code_manual || !values.town || !values.line_1) {
                return
              }
              selectedAddress ? handleUpdate() : handleConfirm()
            } else if (changePastAddressModal) {
              if (!pastAddress.post_code_manual ||
                !pastAddress.town ||
                !pastAddress.line_1
              ) {
                return
              }
              selectedAddress ? handleUpdate() : handlePastAddressConfirm()
            } else {
              if (addPast // threeYearComplete < 3 ||
                ? !values.time_at_address ||
                  !values.time_at_address.value ||
                  !values.postcode ||
                  !values.status ||
                  !pastAddress.time_at_address ||
                  !pastAddress.time_at_address.value ||
                  !pastAddress.postcode ||
                  !pastAddress.line_1 ||
                  !pastAddress.status
                : threeYearComplete < 3 ||
                  !values.time_at_address ||
                  !values.time_at_address.value ||
                  !values.postcode ||
                  !values.line_1 ||
                  // !values.town ||
                  // !values.county ||
                  !values.status
              ) {
                return
              }
              window.heap &&
                window.heap.track('uc_confirm_address', {
                  action_name: 'tap',
                  action_details: 'ucConfirmAddress',
                })
              addAddress()
            }
          }}
        />
        <div
          style={{
            marginTop: isSmallScreen && '24px',
            background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
          }}
          className={
            isSmallScreen ? (
              `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex ${
                profile?.id ? 'justify-center items-center' : ''
              } flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
            ) : (
              `px-6 pb-6 md:p-0 fixed bottom-0 md:relative  w-full `
            )
          }
        >
          {isSmallScreen && (
            <>
              <div
                className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
              ></div>
              <div
                className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
              />
            </>
          )}
          <PrimaryButton
            id={'address-details'}
            text={loadingAddAddress ? <Spinner /> : 'Continue'}
            disabled={
              addPast // threeYearComplete < 3 ||
                ? !values.time_at_address ||
                  !values.time_at_address.value ||
                  !values.postcode ||
                  !values.status ||
                  !pastAddress.time_at_address ||
                  !pastAddress.time_at_address.value ||
                  !pastAddress.postcode ||
                  !pastAddress.line_1 ||
                  !pastAddress.status
                : threeYearComplete < 3 ||
                  !values.time_at_address ||
                  !values.time_at_address.value ||
                  !values.postcode ||
                  !values.line_1 ||
                  // !values.town ||
                  // !values.county ||
                  !values.status
            }
            onClick={() => {
              if (loadingAddAddress) {
                return
              }
              window.heap &&
                window.heap.track('uc_confirm_address', {
                  action_name: 'tap',
                  action_details: 'ucConfirmAddress',
                })
              addAddress()
            }}
            className={' w-full md:w-[260px]'}
          />
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
      >
        <div className=" space-y-6 ">
          <ModalHeader Icon={HomeIcon} title={'Select Your Address'} />
          <div className=" rounded-xl w-full overflow-y-scroll h-[300px]">
            <div
              style={{
                ...AppStyles.Caption1,
                color: color.SoftPink,
                textAlign: 'center',
              }}
            >
              {postCodeError}
            </div>
            {loading2 ? (
              <div className="w-full h-full flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <RadioGroupInput
                options={apiPostCodeOptions}
                name="radioGroup"
                onChange={handleRadioChange}
                selectedValue={selectedOption}
                onTextChange={onTextChange}
                compName={'findAddress'}
              />
            )}
          </div>
          <div className="flex flex-col items-center space-y-2 ">
            <OutllineButton
              id={'cancel-select-address'}
              text={'Cancel'}
              onClick={() => {
                dispatch({ type: ActionTypes.POSTCODE_ERROR, payload: '' })
                if (selectAddresFind === 'present') {
                  setPostcodeTitleToggle1(false)
                  // if (profile?.address?.line_1) {
                  //   setValues({
                  //     ...values,
                  //     town: profile?.address?.town,
                  //     line_1: profile?.address?.line_1,
                  //     line_2: profile?.address?.line_2,
                  //     department_name: profile?.address?.department_name,
                  //     postal_county: profile?.address?.postal_county,
                  //     post_code_manual: profile?.address?.postcode,
                  //     time_at_address: {
                  //       label: adjustLabelofYear(
                  //         profile?.address?.years_resident,
                  //       ),
                  //       value: profile?.address?.years_resident,
                  //     },
                  //     status: {
                  //       label: profile?.address?.status,
                  //       value: profile?.address?.status,
                  //     },
                  //     postcode: `${
                  //       profile?.address?.house
                  //         ? profile?.address?.house + ' '
                  //         : ''
                  //     }${profile?.address?.line_1}, ${
                  //       profile?.address?.line_2 &&
                  //       `${profile?.address?.line_2},`
                  //     } ${profile?.address?.town}, ${profile?.address?.postcode}`,
                  //   })
                  //   if (
                  //     values.postcode === '' &&
                  //     profile?.address?.postcode === ''
                  //   ) {
                  //     setSelectedOption('')
                  //   }
                  //   setSelectedOption('')
                  // }
                  setIsModalOpen(false)
                } else if (selectAddresFind === 'past') {
                  setPostcodeTitleToggle2(false)
                  // if (profile?.past_address?.line_1) {
                  //   setPastAddress({
                  //     ...pastAddress,
                  //     town: profile?.past_address?.town,
                  //     line_1: profile?.past_address?.line_1,
                  //     line_2: profile?.past_address?.line_2,
                  //     // line_2: findAddress?.label ? findAddress?.label2 : myAddress?.line_2,
                  //     department_name: profile?.past_address?.department_name,
                  //     postal_county: profile?.past_address?.postal_county,
                  //     post_code_manual: profile?.past_address?.postcode,
                  //     time_at_address: {
                  //       label: adjustLabelofYear(
                  //         profile?.past_address?.years_resident,
                  //       ),
                  //       value: profile?.past_address?.years_resident,
                  //     },
                  //     status: {
                  //       label: profile?.past_address?.status,
                  //       value: profile?.past_address?.status,
                  //     },

                  //     postcode: `${
                  //       profile?.past_address?.house
                  //         ? profile?.past_address?.house + ' '
                  //         : ''
                  //     }${profile?.past_address?.line_1}, ${
                  //       profile?.past_address?.line_2 &&
                  //       `${profile?.past_address?.line_2},`
                  //     } ${profile?.past_address?.town}, ${profile?.past_address?.postcode}`,
                  //   })
                  // }

                  setIsModalOpen(false)
                }
              }}
              className={' w-full'}
            />
            <PrimaryButton
              text={'Confirm'}
              id={'confirm-select-address'}
              onClick={() => {
                handleSelectedAddressValues()
                setIsModalOpen(false)
              }}
              className={' w-full '}
              disabled={
                (!values.postcode && !pastAddress.postcode) || !selectedOption
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={changeAddressModal}
        onClose={() => {
          if (!values.postcode && !values.line_1 && !values.town) {
            setSelectedOption('')
          }
          setchangeAddressModal(false)
        }}
      >
        <div className="space-y-6" id="enteraddress">
          <ModalHeader
            Icon={HomeIcon}
            title={
              selectedAddress ? 'Change Your Address' : 'Enter Your Address'
            }
          />
          <div className=" rounded-xl w-full space-y-4">
            <FormInput
              placeholder={'House / Apartment / Flat Number'}
              type={'text'}
              onChange={(e) => handleChange(e)}
              name={'house'}
              value={values.house}
            />
            <div>
              <FormInput
                placeholder={'Address Line 1'}
                type={'text'}
                onChange={(e) => handleChange(e)}
                name={'line_1'}
                autoComplete={'address-line1'}
                value={values.line_1}
              />
              <p
                style={{
                  fontSize: isSmallScreen ? '8px' : '8px',
                  fontFamily: 'GilroyBold',
                  lineHeight: '16px',
                  letterSpacing: '2px',
                  fontWeight: '800',
                  color: '#5D1CD3',
                  // width: '376px',
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  ...(isSafari
                    ? {
                        fontFamily: 'MaisonDemiBold',
                        fontWeight: '500',
                      }
                    : {}),
                }}
              >
                required
              </p>
            </div>
            <FormInput
              placeholder={'Address Line 2'}
              type={'text'}
              onChange={(e) => handleChange(e)}
              name={'line_2'}
              autoComplete={'address-line2'}
              value={values.line_2}
            />
            <div>
              <FormInput
                placeholder={'Town'}
                type={'text'}
                onChange={(e) => handleChange(e)}
                name={'town'}
                autoComplete={'address-level1'}
                value={values.town}
              />
              <p
                style={{
                  fontSize: isSmallScreen ? '8px' : '8px',
                  fontFamily: 'GilroyBold',
                  lineHeight: '16px',
                  letterSpacing: '2px',
                  fontWeight: '800',
                  color: '#5D1CD3',
                  // width: '376px',
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  ...(isSafari
                    ? {
                        fontFamily: 'MaisonDemiBold',
                        fontWeight: '500',
                      }
                    : {}),
                }}
              >
                required
              </p>
            </div>
            <FormInput
              placeholder={'County'}
              type={'text'}
              onChange={(e) => handleChange(e)}
              name={'postal_county'}
              value={values.postal_county}
            />
            <div>
              <FormInput
                placeholder={'Post Code'}
                type={'text'}
                onChange={(e) => handleChange(e)}
                name={'post_code_manual'}
                autoComplete={'postal-code'}
                value={values.post_code_manual}
              />
              <p
                style={{
                  fontSize: isSmallScreen ? '8px' : '8px',
                  fontFamily: 'GilroyBold',
                  lineHeight: '16px',
                  letterSpacing: '2px',
                  fontWeight: '800',
                  color: '#5D1CD3',
                  // width: '376px',
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  ...(isSafari
                    ? {
                        fontFamily: 'MaisonDemiBold',
                        fontWeight: '500',
                      }
                    : {}),
                }}
              >
                required
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center space-y-2 ">
            <OutllineButton
              id={'cancel-changeaddress'}
              text={'Cancel'}
              onClick={() => {
                setchangeAddressModal(false)
              }}
              className={' w-full'}
            />
            <PrimaryButton
              text={'Confirm'}
              id={'confirm-changeaddress'}
              onClick={() => {
                selectedAddress ? handleUpdate() : handleConfirm()
              }}
              className={' w-full '}
              disabled={
                !values.post_code_manual || !values.town || !values.line_1
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={changePastAddressModal}
        onClose={() => setchangePastAddressModal(false)}
      >
        <div className="space-y-6">
          <ModalHeader
            Icon={HomeIcon}
            title={
              selectedAddress
                ? 'Change Your Address'
                : 'Enter Your Past Address'
            }
          />
          <div className=" rounded-xl w-full space-y-4">
            <FormInput
              placeholder={'House / Apartment / Flat Number'}
              type={'text'}
              onChange={(e) => handleChangePastAddress(e)}
              name={'house'}
              value={pastAddress.house}
            />
            <div>
              <FormInput
                placeholder={'Address Line 1'}
                type={'text'}
                onChange={(e) => handleChangePastAddress(e)}
                name={'line_1'}
                autoComplete={'address-line1'}
                value={pastAddress.line_1}
              />
              <p
                style={{
                  fontSize: isSmallScreen ? '8px' : '8px',
                  fontFamily: 'GilroyBold',
                  lineHeight: '16px',
                  letterSpacing: '2px',
                  fontWeight: '800',
                  color: '#5D1CD3',
                  // width: '376px',
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  ...(isSafari
                    ? {
                        fontFamily: 'MaisonDemiBold',
                        fontWeight: '500',
                      }
                    : {}),
                }}
              >
                required
              </p>
            </div>
            <FormInput
              placeholder={'Address Line 2'}
              type={'text'}
              onChange={(e) => handleChangePastAddress(e)}
              name={'line_2'}
              autoComplete={'address-line2'}
              value={pastAddress.line_2}
            />
            <div>
              <FormInput
                placeholder={'Town'}
                type={'text'}
                onChange={(e) => handleChangePastAddress(e)}
                name={'town'}
                autoComplete={'address-level1'}
                value={pastAddress.town}
              />{' '}
              <p
                style={{
                  fontSize: isSmallScreen ? '8px' : '8px',
                  fontFamily: 'GilroyBold',
                  lineHeight: '16px',
                  letterSpacing: '2px',
                  fontWeight: '800',
                  color: '#5D1CD3',
                  // width: '376px',
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  ...(isSafari
                    ? {
                        fontFamily: 'MaisonDemiBold',
                        fontWeight: '500',
                      }
                    : {}),
                }}
              >
                required
              </p>
            </div>
            <FormInput
              placeholder={'County'}
              type={'text'}
              onChange={(e) => handleChangePastAddress(e)}
              name={'postal_county'}
              value={pastAddress.postal_county}
            />
            <div>
              <FormInput
                placeholder={'Post Code'}
                type={'text'}
                onChange={(e) => handleChangePastAddress(e)}
                name={'post_code_manual'}
                autoComplete={'postal-code'}
                value={pastAddress.post_code_manual}
              />
              <p
                style={{
                  fontSize: isSmallScreen ? '8px' : '8px',
                  fontFamily: 'GilroyBold',
                  lineHeight: '16px',
                  letterSpacing: '2px',
                  fontWeight: '800',
                  color: '#5D1CD3',
                  // width: '376px',
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  ...(isSafari
                    ? {
                        fontFamily: 'MaisonDemiBold',
                        fontWeight: '500',
                      }
                    : {}),
                }}
              >
                required
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center space-y-2 ">
            <OutllineButton
              id={'cancel-pastaddress'}
              text={'Cancel'}
              onClick={() => setchangePastAddressModal(false)}
              className={' w-full'}
            />
            <PrimaryButton
              text={'Confirm'}
              id={'confirm-pastaddress'}
              onClick={() =>
                selectedAddress ? handleUpdate() : handlePastAddressConfirm()
              }
              className={' w-full '}
              disabled={
                !pastAddress.post_code_manual ||
                !pastAddress.town ||
                !pastAddress.line_1
              }
            />
          </div>
        </div>
      </Modal>

      <Modal isOpen={openModal} onClose={() => setopenModal(false)}>
        <div className=" space-y-6">
          <ModalHeader
            Icon={BriefcaseIcon}
            title={'Remove Address?'}
            description={'Are you sure you want to remove your Address?'}
          />
          <div className=" space-y-2">
            <OutllineButton
              id={'cancel-address'}
              text={'Cancel'}
              onClick={() => setopenModal(false)}
            />
            <PrimaryButton
              id={'confirm-address'}
              text={'Confirm'}
              onClick={() => handleRemoveIncome()}
            />
          </div>
        </div>
      </Modal>
    </DefaultPage>
  )
}

export default AddressDetail
