import { AppStyles } from '../../../assets/styles/AppStyles'
import { useTheme } from '../../../utils/ThemeColorContext'

import React from 'react'
import DefaultPage from '../DefaultPage'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import manageLoanIcon from '../../../assets/icons/manageLoan2.png'
import manageLoanIconMobile from '../../../assets/icons/ManageLoanMobile.svg'
import CheckInformationWrapper from '../CheckInformationWrapper'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AndroidIcon from '../../../assets/images/AndroidIcon.png'
import iosIcon from '../../../assets/images/Ios.png'
import OutllineButton from '../../../components/buttons/OutllineButton'

function ErrorPage() {
  const navigate = useNavigate()
  //   const { token } = useSelector((state) => state.authReducer)
  const { reApply } = useSelector((state) => state.dataReducer)

  const { color, isSmallScreen } = useTheme()

  // Define the provided date
  const providedDate = new Date(reApply)

  // Get the current date
  const currentDate = new Date()

  // Calculate the difference in milliseconds
  const differenceInMilliseconds =
    providedDate.getTime() - currentDate.getTime()

  // Convert milliseconds to days
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24),
  )

  // Print the difference in days
  console.log('Difference in days:', differenceInDays)

  return (
    <DefaultPage>
      <div className="md:mt-0 mt-6 ">
        <CheckInformationWrapper
          img={isSmallScreen ? manageLoanIconMobile : manageLoanIcon}
        >
          <div className="w-full space-y-8 md:space-y-10">
            <div className="flex flex-col items-center space-y-4 md:space-y-6">
              <h1
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Title1,
                        color: color.Plum,
                      }
                    : {
                        ...AppStyles.LargeTitle2,
                        color: color.Plum,
                      }
                }
                className="text-center"
              >
                Whoops!
              </h1>
              <h1
                style={{
                  ...AppStyles.Title1,
                  color: color.Plum,
                  marginTop: '16px',
                }}
                className=" text-center"
              >
                Looks like something has gone wrong.
              </h1>
              <h1
                style={{
                  //   fontSize: isSmallScreen ? '18px' : '20px',
                  fontSize: '18px',
                  fontFamily: 'GilroyBold',
                  lineHeight: '32px',
                  color: '#5D1CD3',
                  width: isSmallScreen ? '279px' : '376px',
                  marginTop: '16px',
                }}
                className=" text-center"
              >
                Please try again or download our app whilst we fix the problem.
              </h1>
              <div
                style={{
                  marginTop: isSmallScreen ? '40px' : '24px',
                  width: '100%',
                }}
              >
                <PrimaryButton
                  text={'Try again'}
                  id={'try-again'}
                  onClick={async () => {
                    navigate('/address-detail')
                  }}
                  className={'w-full mb-[8px]'}
                />
                <OutllineButton
                  text={'Take me home'}
                  id={'take-me-home'}
                  className={`$bg-white`}
                  onClick={() => {
                    navigate('/')
                    localStorage.clear()
                  }}
                />
              </div>
              <div
                className="flex justify-between items-center w-full"
                style={{ marginTop: '24px', marginBottom: '12px' }}
              >
                <span className="border-[1px] w-full"></span>
                <span
                  style={{
                    ...AppStyles.BodyBold,
                    color: color.Plum,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  className="h-[32px] w-[48px] ml-3 mr-3"
                >
                  Or
                </span>
                <span className="border-[1px] w-full"></span>
              </div>
              <p
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Body,
                        color: color.Grey,
                        height: '48px',
                        margin: 0,
                      }
                    : {
                        ...AppStyles.Body16,
                        color: color.Grey,
                        lineHeight: '28px',
                        margin: 0,
                      }
                }
                className="text-center"
              >
                {isSmallScreen
                  ? 'Download Updraft on your phone and check your eligibility from there.'
                  : `
                  Scan the QR code above to install Updraft on your phone and
                  check your eligibility from there.
                  `}
              </p>
              <p
                style={{
                  ...AppStyles.Caption4,
                  color: '#6C819C',
                  marginTop: '40px',
                }}
              >
                Available On
              </p>
              <div
                style={{ marginTop: '12px' }}
                className="flex md:justify-between md:w-[77px]"
              >
                { isSmallScreen ? (
                  <>
                    <a
                      href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                      target="_blank"
                      className="cursor-pointer w-full space-y-2 "
                      rel="noreferrer"
                    >
                      <img
                        src={iosIcon}
                        style={{
                          width: '32px',
                          height: '25px',
                          objectFit: 'contain',
                        }}
                      />
                    </a>
                    <a
                      href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                      target="_blank"
                      className="cursor-pointer w-full space-y-2 "
                      rel="noreferrer"
                    >
                      <img
                        src={AndroidIcon}
                        style={{
                          width: '32px',
                          height: '25px',
                          objectFit: 'contain',
                        }}
                      />
                    </a>
                  </>
                ) : (
                  <>
                    <div className="w-full space-y-2 ">
                      <img
                        src={iosIcon}
                        style={{
                          width: '32px',
                          height: '25px',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                    <div className="w-full space-y-2 ">
                      <img
                        src={AndroidIcon}
                        style={{
                          width: '32px',
                          height: '25px',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </CheckInformationWrapper>
      </div>
    </DefaultPage>
  )
}

export default ErrorPage
