import { ActionTypes } from '../Constants/actionTypes'
import api from '../../api/interceptor'
import apiTransaction from '../../api/interceptorTransaction'
import captureException from '../../utils/SentryHelper'

export const getProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get('prerequisites/')
      dispatch({ type: ActionTypes.GET_PROFILE, payload: res?.data })
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return error?.response?.status
    }
  }
}

export const getPayoutSumarry = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get('payoutsummary/')
      dispatch({ type: ActionTypes.PAYOUT_SUMMARY, payload: res })
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const getMarketingData = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.LOADING_SUCCESS2 })
    try {
      const res = await api.get('marketing/permissions/')
      dispatch({ type: ActionTypes.GET_MAKETING_DATA, payload: res })
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      return res
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      console.log({ error })
    }
  }
}

export const getMonthsLimit = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get('loans/termlimit/')
      // getMonth(res?.data, LoanAmount)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res?.data
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const getAvailableBanks = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await apiTransaction.get('banks/available/')
      dispatch({ type: ActionTypes.GET_BANKS, payload: res.data })
      dispatch({ type: ActionTypes.LOADING_FAIL })
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}
export const getAffordability = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get('prerequisites/v2/affordability/')
      dispatch({ type: ActionTypes.GET_AFFORDIBILITY, payload: res.data })
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log('testing', { error })
    }
  }
}

export const createAffordability = (payload, setErr) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.post('prerequisites/v2/affordability/', payload)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })

      setErr('Something went wrong, please try again in a little while')
      setTimeout(() => {
        setErr('')
      }, 10000)
    }
  }
}

export const getTruelayerTerms = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get('reference/doc/truelayer/')
      dispatch({ type: ActionTypes.GET_TERMS, payload: res.data })
      dispatch({ type: ActionTypes.LOADING_FAIL })
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}
export const getApplications = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get('loans/applications/')
      dispatch({
        type: ActionTypes.ADD_AND_GET_APPLICATOION,
        payload: res.data,
      })
      dispatch({ type: ActionTypes.LOADING_FAIL })
      // localStorage.setItem('AppStatus', res?.data[0]?.status)
      return res
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const getApplicationsErrorHandling = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get('loans/applications/')
      dispatch({ type: ActionTypes.LOADING_FAIL })
      // localStorage.setItem('AppStatus', res?.data[0]?.status)
      return res
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
      window.location.href = '/error'
    }
  }
}

export const Account_Management_List = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get('loan/get_loan_account_management_list/')
      return res?.data[0]
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const getSoftsearchDocs = (setHtml) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get('reference/doc/softsearch/')
      dispatch({ type: ActionTypes.GET_SOFT_SEARCH, payload: res?.data?.html })
      dispatch({ type: ActionTypes.LOADING_FAIL })
      setHtml(res?.data?.html)
      return res?.data?.html
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const upateApplications = (id, data, setError) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.patch(`loans/applications/${id}/`, data)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
      setError(error?.response?.data?.detail)
    }
  }
}

export const addRegularDate = (id, selectedDate) => {
  return async (dispatch) => {
    const data = {
      regular_payment_day: selectedDate,
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.put(`loans/applications/${id}/paymentdate/`, data)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const SubmitApplication = (id, signature, setNetworkErr) => {
  return async (dispatch) => {
    const data = {
      confirmation_signature: signature,
      client_ready_for_pay_to_card: true,
      platform: 'web',
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.put(`loans/applications/${id}/submit/`, data)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
      setNetworkErr(true)
      setTimeout(() => {
        setNetworkErr(false)
      }, 5000)
    }
  }
}

export const ConnectBank = (provider_id, setConnect) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS2 })
      const res = await apiTransaction.get(
        `connect/?provider_id=${provider_id}&platform=web`,
      )
      setConnect(res?.data?.new)
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      console.log({ error })
    }
  }
}

export const getAccounts = (setstep, setAccountLoading, email = null) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      setAccountLoading(true)
      const res = await apiTransaction.get('profile/dd_accounts/')
      if (res?.data?.length > 0) {
        if (!localStorage.getItem(`add-another-bank-account-${email}`) === true) {
          setstep(3)
        }
      }
      if (res) {
        const refresh = await dispatch(refreshAccounts())
        if (refresh) {
          setAccountLoading(false)
          dispatch({ type: ActionTypes.LOADING_FAIL })
        }
      }
      // dispatch({ type: ActionTypes.GET_DD_ACCOUNTS, payload: res.data })
    } catch (error) {
      setAccountLoading(false)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}
export const getAccountsonDD = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      // setAccountLoading(true)
      const res = await apiTransaction.get('profile/dd_accounts/')
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res?.data
    } catch (error) {
      // setAccountLoading(false)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const getDdAccounts = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await apiTransaction.get('profile/dd_accounts/')
      dispatch({ type: ActionTypes.LOADING_FAIL })
      const temp = res?.data?.find((_i) => _i?.days_to_renew > 0)
      return temp ? temp?.days_to_renew : null
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const refreshAccounts = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await apiTransaction.get('profile/accounts/refresh/')

      dispatch({ type: ActionTypes.GET_DD_ACCOUNTS, payload: res.data })
      // dispatch({ type: ActionTypes.LOADING_FAIL })
      return res
    } catch (error) {
      // dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const getDirectDebitGDocs = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get(`reference/doc/ddterms/`)
      dispatch({
        type: ActionTypes.GET_DIRECT_DEBIT_G,
        payload: res?.data?.html,
      })
      dispatch({ type: ActionTypes.LOADING_FAIL })
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const getIntercomHashUser = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get(`intercom/hmac/`)
      return res
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const getAgreementDocs = (id, type, actionType) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get(`loans/applications/${id}/agreement/${type}/`)
      dispatch({
        type: actionType,
        payload: res?.data?.html,
      })
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res?.data?.html
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      // console.log({ error });
    }
  }
}

export const getReApply = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get(`loans/status/`)
      dispatch({
        type: ActionTypes.RE_APPLY,
        payload: res?.data?.reapply,
      })
      dispatch({
        type: ActionTypes.GET_APPLICATION_STATUS,
        payload: res?.data,
      })
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res?.data
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      // console.log({ error });
    }
  }
}
