import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { removeAllGarbage } from '../../../api/dummyData'
import { AppStyles } from '../../../assets/styles/AppStyles'
import PageHeader from '../../../components/PageHeader'
import Spinner from '../../../components/Spinner'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import RadioGroupInput from '../../../components/inputs/RadioGroupInput'
import Textarea from '../../../components/inputs/Textarea'
import OnEnter from '../../../components/navigation/onEnter'
import RefferedRoute from '../../../navigation/RefferedRoute'
import { getApplications, getProfile } from '../../../redux/Actions/getMethods'
import {
  createApplication,
  editMyProfile,
  // editMyProfile,
  // editMyProfileCIC,
} from '../../../redux/Actions/postMethods'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { useTheme } from '../../../utils/ThemeColorContext'
import DefaultPage from '../DefaultPage'
import { isSafari } from '../../../utils/browser'

const initialvalues = {
  expect_change_income_reason: '',
  expect_expenses_increase_reason: '',
  expect_change_income: false,
  expect_expenses_increase: false,
}

function Circumstances() {
  const [goBack, setGoback] = useState(false)
  const { loading, token } = useSelector((state) => state.authReducer)
  const { profile, getApplication } = useSelector((state) => state.dataReducer)
  const [err, setErr] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { color, isSmallScreen } = useTheme()
  const [values, setValues] = useState(initialvalues)
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedYesOption, setSelectedYesOption] = useState('')

  const circumstances = JSON.parse(
    localStorage.getItem(`expect_change_income-${token?.email}`),
  )
  const expenses = JSON.parse(
    localStorage.getItem(`expect_expenses_increase-${token?.email}`),
  )

  useEffect(() => {
    if (profile?.id) {
      setValues({
        expect_change_income_reason:
          profile?.expect_change_income_reason ??
          profile?.expect_change_income_reason,
        // : reason,
        expect_expenses_increase_reason:
          profile?.expect_expenses_increase_reason ??
          profile?.expect_expenses_increase_reason,
        // : increase_reason,
        expect_change_income: profile?.expect_change_income
          ? profile?.expect_change_income
          : circumstances,
        expect_expenses_increase: profile?.expect_expenses_increase
          ? profile?.expect_expenses_increase
          : expenses,
      })
      setSelectedOption(
        profile?.expect_change_income === null
          ? ''
          : profile?.expect_change_income
            ? 'Yes'
            : 'No',
      )
      setSelectedYesOption(
        profile?.expect_expenses_increase === null
          ? ''
          : profile?.expect_expenses_increase
            ? 'Yes2'
            : 'No2',
      )
    }
  }, [profile?.id])

  const [err1, setErr1] = useState('')
  const [err2, setErr2] = useState('')

  const radioOptions = [
    { id: 'No', label: 'No' },
    { id: 'Yes', label: 'Yes' },
  ]
  const yesoptions = [
    { id: 'No2', label: 'No' },
    { id: 'Yes2', label: 'Yes' },
  ]

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value)
    setValues({
      ...values,
      expect_change_income: e.target.value === 'No' ? false : true,
    })
    localStorage.setItem(
      `expect_change_income-${token?.email}`,
      e.target.value === 'No' ? false : true,
    )
  }
  const handleYesRadioChange = (e) => {
    setSelectedYesOption(e.target.value)
    setValues({
      ...values,
      expect_expenses_increase: e.target.value === 'Yes2' ? true : false,
    })
    localStorage.setItem(
      `expect_expenses_increase-${token?.email}`,
      e.target.value === 'Yes2' ? true : false,
    )
  }

  const handleChange = (e) => {
    const { name, value } = e.target // e.target refers to the input element
    setValues({
      ...values, // spread the existing values
      [name]: value, // override the value for the input's name
    })
    localStorage.setItem(`${name}-${token?.email}`, e.target.value)
  }
  const handleUpdateProfile = async () => {
    const updated = await dispatch(
      editMyProfile({ other_income: profile?.other_income, ...values }, setErr),
    )
    const ifNotPolicy = getApplication[0]?.status !== 'POLICY_OK'
    if (updated) {
      if (!ifNotPolicy) {
        const getApp = await dispatch(getApplications())
        if (getApp?.data[0]?.id) {
          localStorage.removeItem(`payoff-${token?.email}`)
          localStorage.removeItem('payoff')
        }

        if (getApplication[0]?.status === 'DECLINED') {
          navigate('/failed-offer')
        } else {
          const getProfileSuccee = await dispatch(getProfile())
          if (getProfileSuccee) {
            navigate('/soft-search')
            dispatch({ type: ActionTypes.EDIT_PROFILE, payload: values })
            localStorage.removeItem('payoff')
            localStorage.removeItem(`payoff-${token?.email}`)
          }
        }
      } else {
        const success = await dispatch(
          createApplication(localStorage.getItem('payoff'), setErr),
        )
        if (success) {
          const getApp = await dispatch(getApplications())
          if (getApp?.data[0]?.id) {
            localStorage.removeItem(`payoff-${token?.email}`)
            localStorage.removeItem('payoff')
          }
        }
        if (success === 'DECLINED') {
          navigate('/failed-offer')
        } else {
          const getProfileSuccee = await dispatch(getProfile())
          if (getProfileSuccee) {
            navigate('/soft-search')
            dispatch({ type: ActionTypes.EDIT_PROFILE, payload: values })
            localStorage.removeItem('payoff')
            localStorage.removeItem(`payoff-${token?.email}`)
          }
        }
      }
    }
  }

  const payoff = localStorage.getItem(`payoff`)
  // ==================== Checking user status  =======================//
  useEffect(() => {
    removeAllGarbage()
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      if (getApplication[0]?.status !== 'POLICY_OK') {
        setGoback(true)
        const myID = await dispatch(getProfile())
        if (!myID?.data?.employer_name) {
          navigate('/address-detail')
        }
        if (payoff === null) {
          navigate('/address-detail')
        }
      }
      dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
    }
    temp()
  }, [getApplication[0]?.status === 'POLICY_OK'])

  const bottomRef = useRef(null)
  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (err || err1 || err2) {
      scrollToBottom()
    }
  }, [err, err1, err2])

  return (
    <DefaultPage>
      {/* <JourneyRoute /> */}
      {getApplication[0]?.status !== 'POLICY_OK' && <RefferedRoute />}
      <div className="relative container mx-auto md:w-[564px] md:space-y-10">
        <PageHeader
          title={'Change in circumstances'}
          backButtonTitle={goBack && 'Your job'}
          // description={
          //   isSmallScreen
          //     ? 'Do you expect any increase in your expenses beyond the cost of living rises that we are already aware of?'
          //     : 'Do you expect any change to your income or expenses in the next 12 months?'
          // }

          customDescription={
            <p
              style={
                isSmallScreen
                  ? {
                      ...AppStyles.Body,
                      color: color.Grey,
                    }
                  : {
                      ...AppStyles.Subheading1,
                      color: color.Grey,
                    }
              }
            >
              <span style={{ color: color.BlurpleTeal }}>
                {' '}
                {profile?.first_name}
              </span>
              , do you expect any change to your income or expenses in the next
              12 months?
            </p>
          }
          prevRoute={'/employment'}
        />
        <div
          style={{
            backgroundColor: color.White,
          }}
          className="rounded-3xl p-6 space-y-6 "
        >
          <div className="w-full ">
            <div className={`space-y-6 pb-10`}>
              <p
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Body16,
                        color: color.Grey,
                        height: '84px',
                        lineHeight: '28px',
                      }
                    : { ...AppStyles.Body16, color: color.Grey }
                }
              >
                Do you expect any increase in your expenses beyond the cost of
                living rises that we are already aware of?
              </p>
              <div className=" rounded-xl w-full">
                <RadioGroupInput
                  options={radioOptions}
                  name="radioGroup"
                  onChange={handleRadioChange}
                  selectedValue={selectedOption}
                  compName={'circumstances'}
                />
              </div>
              {selectedOption === 'Yes' && (
                <div>
                  <Textarea
                    placeholder={
                      'Please tell us a little more about the increases in expenses you are expecting'
                    }
                    type={'text'}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    name={'expect_expenses_increase_reason'}
                    value={values.expect_expenses_increase_reason}
                    customStyle={{
                      padding: '20px 16px',
                      height: isSmallScreen && '96px',
                    }}
                  />
                  <p
                    style={{
                      fontSize: isSmallScreen ? '8px' : '8px',
                      fontFamily: 'GilroyBold',
                      lineHeight: '16px',
                      letterSpacing: '2px',
                      fontWeight: '800',
                      color: '#5D1CD3',
                      // width: '376px',
                      textAlign: 'right',
                      textTransform: 'uppercase',
                      ...(isSafari
                        ? {
                            fontFamily: 'MaisonDemiBold',
                            fontWeight: '500',
                          }
                        : {}),
                    }}
                  >
                    required
                  </p>
                </div>
              )}
            </div>

            <div className={`space-y-6 border-t border-borderGrey pt-10`}>
              <p
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Body16,
                        color: color.Grey,
                        height: '56px',
                        lineHeight: '28px',
                      }
                    : { ...AppStyles.Body16, color: color.Grey }
                }
              >
                Do you expect any changes to your income that may affect your
                ability to repay?
              </p>
              <div className=" rounded-xl w-full">
                <RadioGroupInput
                  options={yesoptions}
                  name="YesRadiogroup"
                  onChange={handleYesRadioChange}
                  selectedValue={selectedYesOption}
                  compName={'circumstances'}
                />
              </div>
              {selectedYesOption === 'Yes2' && (
                <div>
                  <Textarea
                    placeholder={
                      'Please tell us about the changes that may reduce your income'
                    }
                    type={'text'}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    name={'expect_change_income_reason'}
                    value={values.expect_change_income_reason}
                    customStyle={{
                      padding: '20px 16px',
                      height: isSmallScreen && '96px',
                    }}
                  />
                  <p
                    style={{
                      fontSize: isSmallScreen ? '8px' : '8px',
                      fontFamily: 'GilroyBold',
                      lineHeight: '16px',
                      letterSpacing: '2px',
                      fontWeight: '800',
                      color: '#5D1CD3',
                      // width: '376px',
                      textAlign: 'right',
                      textTransform: 'uppercase',
                      ...(isSafari
                        ? {
                            fontFamily: 'MaisonDemiBold',
                            fontWeight: '500',
                          }
                        : {}),
                    }}
                  >
                    required
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ ...AppStyles.Caption1, color: color.SoftPink }}>
          {err}
        </div>
        <OnEnter
          actionFunction={() => {
            if (!selectedOption ||
              !selectedYesOption ||
              (selectedOption === 'Yes' &&
                !values.expect_change_income_reason &&
                !values.expect_expenses_increase_reason) ||
              (selectedYesOption === 'Yes2' &&
                !values.expect_expenses_increase_reason &&
                !values.expect_change_income_reason)
            ) {
              return
            }
            if (
              selectedOption === 'Yes' &&
              !values.expect_expenses_increase_reason?.trim()
            ) {
              setErr2(
                '** Please tell us a little more about the increases in expenses you are expecting...',
              )
              setTimeout(() => {
                setErr2('')
              }, 5000)
              setErr1('')
            } else if (
              selectedYesOption === 'Yes2' &&
              !values.expect_change_income_reason?.trim()
            ) {
              setErr1(
                '** Please tell us about the changes that may affect your income...',
              )
              setTimeout(() => {
                setErr1('')
              }, 5000)
              setErr2('')
            } else {
              handleUpdateProfile()
            }
          }}
        />
        <p style={{ color: '#FF619A' }}>{err1}</p>
        <p style={{ color: '#FF619A' }}>{err2}</p>
        <div ref={bottomRef} />
        <div
          style={{
            marginTop: isSmallScreen && '24px',
            background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
          }}
          className={
            isSmallScreen ? (
              `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex ${
                profile?.id ? 'justify-center items-center' : ''
              } flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
            ) : (
              `px-6 pb-6 md:p-0 fixed bottom-0 md:relative  w-full `
            )
          }
        >
          {isSmallScreen && (
            <>
              <div
                className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
              ></div>
              <div
                className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
              />
            </>
          )}
          <PrimaryButton
            id="circumstances-btn"
            text={loading ? <Spinner /> : 'Continue'}
            disabled={
              !selectedOption ||
              !selectedYesOption ||
              (selectedOption === 'Yes' &&
                !values.expect_change_income_reason &&
                !values.expect_expenses_increase_reason) ||
              (selectedYesOption === 'Yes2' &&
                !values.expect_expenses_increase_reason &&
                !values.expect_change_income_reason)
            }
            onClick={() => {
              if (loading) {
                return
              }
              if (
                selectedOption === 'Yes' &&
                !values.expect_expenses_increase_reason?.trim()
              ) {
                setErr2(
                  '** Please tell us a little more about the increases in expenses you are expecting...',
                )
                setTimeout(() => {
                  setErr2('')
                }, 5000)
                setErr1('')
              } else if (
                selectedYesOption === 'Yes2' &&
                !values.expect_change_income_reason?.trim()
              ) {
                setErr1(
                  '** Please tell us about the changes that may affect your income...',
                )
                setTimeout(() => {
                  setErr1('')
                }, 5000)
                setErr2('')
              } else {
                handleUpdateProfile()
              }
            }}
            className={' md:w-[260px]'}
          />
        </div>
      </div>
    </DefaultPage>
  )
}
export default Circumstances
