import React, { useEffect, useState } from 'react'
import { useTheme } from '../../../utils/ThemeColorContext'
import { supportedBanks } from '../../../api/dummyData'

import PageHeader from '../../../components/PageHeader'
import DefaultPage from '../DefaultPage'
import BankCard from '../../../components/cards/BankCard'
import SearchInput from '../../../components/inputs/SearchInput'
import { useNavigate } from 'react-router-dom'

function SupportedBanks() {
  const navigate = useNavigate()
  const { color } = useTheme()
  const [filterValue, setFilterValue] = useState('')

  useEffect(() => {
    if (localStorage.getItem('login')) {
      navigate('/address-detail')
    }
  }, [])

  return (
    <>
      <DefaultPage>
        <div className="relative container mx-auto md:w-[792px]   md:space-y-10  ">
          <PageHeader
            title={'Supported banks'}
            description={
              'If your bank is not listed we will not be able to check your eligibility.'
            }
            backButtonTitle={'Updraft Credit'}
            prevRoute={'/'}
            showTrust={true}
          />
          <div
            style={{
              backgroundColor: color.White,
            }}
            className=" rounded-3xl p-6 space-y-6 "
          >
            <div className=" space-y-6 ">
              <div
                style={{
                  backgroundColor: color.LightGrey,
                  borderColor: color.MidGrey,
                }}
                className="  rounded-2xl px-6 pt-6 border space-y-6"
              >
                <SearchInput onChange={(e) => setFilterValue(e.target.value)} />
                <div 
                 className="grid grid-cols-1 pb-6 gap-y-2 gap-x-4  max-h-[424px] overflow-y-scroll  hide-scrollbar">
                  {supportedBanks
                    ?.filter((value) => {
                      if (filterValue == '') {
                        return value
                      } else if (
                        value?.truelayer_display_name
                          ?.toLowerCase()
                          .includes(filterValue.toLowerCase())
                      ) {
                        return value
                      }
                    })
                    ?.slice()
                    ?.sort((a, b) =>
                      a.truelayer_display_name.localeCompare(
                        b.truelayer_display_name,
                      ),
                    )
                    ?.map((bank) => (
                      <BankCard key={bank.id} bank={bank} isSelected={false} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultPage>
    </>
  )
}

export default SupportedBanks
