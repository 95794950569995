import React from 'react'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import CheckInformationWrapper from '../CheckInformationWrapper'
import DefaultPage from '../DefaultPage'
import Image2 from '../../../assets/images/Image (1).png'
import numeral from 'numeral'

import { conversionAPI } from '../../../redux/Actions/postMethods'
import { AppStyles } from '../../../assets/styles/AppStyles'
import { useTheme } from '../../../utils/ThemeColorContext'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getApplications } from '../../../redux/Actions/getMethods'
import RefferedRoute from '../../../navigation/RefferedRoute'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import SpinnerLarge from '../../../components/SpinnerLarge'
import { getAnalytics, logEvent } from 'firebase/analytics'

const LoanApproved = () => {
  const { token } = useSelector((state) => state.authReducer)
  const { color, isSmallScreen } = useTheme()

  const { loadingWholeScreen, profile, getApplication } = useSelector(
    (state) => state.dataReducer,
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const analytics = getAnalytics()
  useEffect(() => {
    if (profile?.id && getApplication[0]?.id && getApplication[0]?.status === 'APPROVED' && localStorage.getItem(`approvedEventSent_${getApplication[0]?.id}`) !== 'true') {
      const eventReference = 'approved_loan_application_'
      const eventName = 'Approved Loan Application'
      if (window.fbq) {
        window.fbq('trackCustom', eventName, {
          applicationId: getApplication[0]?.id,
          loan_amount: getApplication[0]?.max_amount,
        },
        {eventID: `${eventReference}${getApplication[0]?.id}`}
        )
      }
      dispatch(
        conversionAPI({
          eventName: eventName,
          eventSourceUrl: `${process.env.REACT_APP_FRONTEND_URL}loanapproved`,
          phoneNumber: token?.phone_number,
          email: token?.email,
          fName: token?.given_name,
          lName: token?.family_name,
          cognitoUserId: token?.sub,
          customData: {
            applicationId: getApplication[0]?.id,
            loan_amount: getApplication[0]?.max_amount,
          },
          dateOfBirth: token?.birthdate,
          userAgent: navigator.userAgent,
          eventId: `${eventReference}${getApplication[0]?.id}`,
          eventReference: eventReference,
        }),
      )
      if (window.gtag) {
        let user_data = {}
        if (
          profile?.first_name &&
          profile?.family_name &&
          profile?.address?.postcode
        ) {
          let address = { country: 'UK' }
          address.first_name = profile?.first_name
          address.last_name = profile?.family_name
          address.postal_code = profile?.address.postcode
          if (profile?.address?.line_1)
            address.street = profile?.address?.line_1
          if (profile?.address?.town) address.city = profile?.address.town
          if (
            profile?.address?.postal_county ||
            profile?.address?.traditional_county
          )
            address.region =
              profile?.address?.postal_county ||
              profile?.address?.traditional_county
          user_data = {
            email: token?.email,
            phone_number: token?.phone_number,
            address: address,
          }
        } else {
          user_data = {
            email: token?.email,
            phone_number: token?.phone_number,
          }
        }
        window.gtag('event', 'conversion', {
          send_to: process.env.REACT_APP_LOAN_APPROVED_GTAG_CONVERSION,
          loan_amount: getApplication[0]?.max_amount,
          user_data: user_data,
        })
      }
      logEvent(analytics, 'approved_loan_application_client_side', {
        loan_amount: getApplication[0]?.max_amount,
        platform: 'web',
      })
      localStorage.setItem(`approvedEventSent_${getApplication[0]?.id}`, 'true')
    }
  }, [profile?.id, getApplication[0]?.id])

  useEffect(() => {
    if (JSON.parse(localStorage.getItem(`added_account`)) === true) {
      navigate('/direct-debit')
    }
  }, [])
  // ==================== Checking user status  =======================//
  useEffect(() => {
    if (!getApplication[0]?.id) {
      const temp = async () => {
        dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
        const myID = await dispatch(getApplications())
        if (JSON.parse(localStorage.getItem(`added_account`))) {
          // dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/direct-debit')
        } else if (
          myID?.data[0]?.status === 'APPROVED' &&
          myID?.data[0]?.offer_type === 'UPDRAFT_CREDIT_INTEREST_ONLY'
        ) {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/loanapproved')
        } else {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/address-detail')
        }
      }
      temp()
    }
  }, [])

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <>
          <RefferedRoute />
          <div className="md:flex md:w-[792px] m-[auto] pt-8 md:pt-0">
          <CheckInformationWrapper img={Image2}>
            <div className=" w-full space-y-8 md:space-y-10  ">
              <div className="space-y-4 md:space-y-6 ">
                <h1
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Title1,
                          color: color.Plum,
                          lineHeight: '32px',
                          margin: 0,
                          padding: 0,
                        }
                      : {
                          ...AppStyles.LargeTitle2,
                          color: color.Plum,
                        }
                  }
                  className="text-center"
                >
                  You’re&nbsp;
                  <span
                    style={
                      isSmallScreen
                        ? { ...AppStyles.Title1, color: color.Teal }
                        : { ...AppStyles.LargeTitle2, color: color.Teal }
                    }
                  >
                    Approved
                  </span>{' '}
                  for <br /> Updraft Credit
                </h1>
                <Fields />
                {isSmallScreen && (
                  <div style={{ marginTop: '32px' }}>
                    <PrimaryButton
                      id={'customise-loan-approved'}
                      text={'Customise My Loan'}
                      onClick={() => navigate('/loan-amount')}
                    />
                  </div>
                )}
              </div>
              {!isSmallScreen && (
                <div className=" space-y-2">
                  <PrimaryButton
                    id={'customise-loan-approved'}
                    text={'Customise My Loan'}
                    onClick={() => navigate('/loan-amount')}
                  />
                </div>
              )}
            </div>
          </CheckInformationWrapper>
          </div>
        </>
      )}
    </DefaultPage>
  )
}

const Fields = () => {
  const { color, isSmallScreen } = useTheme()
  const { getApplication } = useSelector((state) => state.dataReducer)

  return (
    <div
      style={{
        backgroundColor: color.LightGrey,
        margin: isSmallScreen && 0,
        marginTop: isSmallScreen && '16px',
      }}
      className="w-full rounded-2xl "
    >
      <div
        style={{ borderColor: color.MidGrey, height: '56px' }}
        className="cardBody  border-b"
      >
        <p
          style={{
            ...AppStyles.BodyBold,
            color: color.Plum,
            lineHeight: '24px',
          }}
        >
          Your offer
        </p>

        <p style={{ ...AppStyles.BodyBold, color: color.BlurpleTeal }}>
          Up to £
          {getApplication[0]?.max_amount
            ? numeral(getApplication[0]?.max_amount).format('0,0')
            : 0}{' '}
        </p>
      </div>
      <div style={{ borderColor: color.MidGrey }} className="cardBody   ">
        <p
          style={{
            ...AppStyles.BodyBold,
            color: color.Plum,
            lineHeight: '24px',
          }}
        >
          Annual Interest Rate
        </p>
        <p style={{ ...AppStyles.BodyBold, color: color.BlurpleTeal }}>
          {!getApplication[0]?.interestRate
            ? 0
            : (getApplication[0]?.interestRate * 100).toFixed(2)}
          %
        </p>
      </div>
    </div>
  )
}

export default LoanApproved
