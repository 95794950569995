/* eslint-disable react/prop-types */
import React from 'react'
import { useTheme } from '../../utils/ThemeColorContext'

function CheckInformationWrapper({ img, children }) {
  const { color, isSmallScreen } = useTheme()
  return (
    <div className="relative container mx-auto space-y-10 md:px-6">
      <div
        className={`w-full  flex flex-col items-center ${
          isSmallScreen ? 'pt-2 ' : 'pt-24'
        } px-0`}
      >
        <div
          // color.White
          style={{
            backgroundColor: color.White,
          }}
          className=" p-6 md:p-10 rounded-2xl  md:w-[456px] flex flex-col items-center w-full  "
        >
          {img && (
            <img
              src={img}
              alt=""
              className={` ${isSmallScreen ? '-mt-[48px]' : '-mt-[132px]'}`}
              style={{
                width: isSmallScreen ? '168px' : '235.29px',
                height: isSmallScreen ? '200px' : '280px',
                objectFit: 'contain',
                marginBottom: isSmallScreen ? '16px' : '40px',
              }}
            />
          )}
          <div className=" w-full">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default CheckInformationWrapper
