/* eslint-disable react/prop-types */
import React from 'react'
import { AppStyles } from '../../assets/styles/AppStyles'
import { useTheme } from '../../utils/ThemeColorContext'

function DocumentsCol({ docTitle, description = '', Icon }) {
  const { color } = useTheme()
  return (
    <div
      style={{
        backgroundColor: color.White,
        borderColor: color.MidGrey,
      }}
      className="flex flex-col items-center justify-center py-5 px-4 border rounded-2xl space-y-4  w-full min-h-[128px]"
    >
      <Icon fill={color.BlurpleTeal} className={' w-6 h-6'} />
      <div className=" flex flex-col justify-center items-center ">
        <p
          style={{ ...AppStyles.BodyBold, color: color.Plum }}
          className="text-center"
        >
          {' '}
          {docTitle}
        </p>

        <p
          style={{ ...AppStyles.Caption1, color: color.Grey }}
          className="text-center"
        >
          {' '}
          {description}
        </p>
      </div>
    </div>
  )
}

export default DocumentsCol
