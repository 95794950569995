/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import '../../assets/AppTheme.css'
import { AppStyles } from '../../assets/styles/AppStyles'
import { ActionTypes } from '../../redux/Constants/actionTypes'
import { useTheme } from '../../utils/ThemeColorContext'

function PrimaryButton({ text, disabled = false, onClick, className, id }) {
  const dispatch = useDispatch()

  const [hovered, setHovered] = useState(false)
  const [active, setActive] = useState(false)
  const { color, isSmallScreen } = useTheme()

  useEffect(() => {
    if (!disabled) {
      dispatch({ type: ActionTypes.PRESS_ENTER_ON })
    } else {
      dispatch({ type: ActionTypes.PRESS_ENTER_OFF })
      setHovered(false)
    }
  }, [disabled])
  return (
    <>
      <button
        id={id}
        className={`${className} w-full py-3 px-4 h-[48px] flex items-center justify-center z-30`}
        style={{
          ...{
            ...AppStyles.ButtonMedium,
          },
          color: disabled
            ? color.Tint1
            : active
              ? color.WhiteBlack
              : hovered
                ? color.WhiteBlack
                : color.WhiteBlurple,
          backgroundColor: disabled
            ? color.MidGrey
            : active
              ? color.BlurpleTeal
              : hovered
                ? isSmallScreen
                  ? color.BlurpleTeal
                  : color.Plum
                : color.BlurpleTeal,
        }}
        type="button"
        onClick={onClick}
        disabled={disabled}
        onMouseEnter={() => !disabled && setHovered(true)}
        onMouseLeave={() => !disabled && setHovered(false)}
        onMouseDown={() => !disabled && setActive(true)}
        onMouseUp={() => !disabled && setActive(false)}
      >
        {text}
      </button>
    </>
  )
}

export default PrimaryButton
