/* eslint-disable react/no-unescaped-entities */
import { getAnalytics, logEvent } from 'firebase/analytics'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { InfoIcon } from '../../../assets/AppIcons'
import { AppStyles } from '../../../assets/styles/AppStyles'
import PageHeader from '../../../components/PageHeader'
import Spinner from '../../../components/Spinner'
import SpinnerLarge from '../../../components/SpinnerLarge'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import AgreeToTerms from '../../../components/cards/AgreeToTerms'
import RefferedRoute from '../../../navigation/RefferedRoute'
import {
  getApplications,
  getProfile,
  getSoftsearchDocs,
} from '../../../redux/Actions/getMethods'
import {
  Creditpolicydecision,
  conversionAPI,
  editMyProfile,
  // profileSoftSearch,
} from '../../../redux/Actions/postMethods'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { useTheme } from '../../../utils/ThemeColorContext'
import DefaultPage from '../DefaultPage'

function SoftSearch() {
  const { loading, token } = useSelector((state) => state.authReducer)
  const [softErr, setSoftErr] = useState('')

  const { getApplication, loadingWholeScreen, profile } = useSelector(
    (state) => state.dataReducer,
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { color, isSmallScreen } = useTheme()
  const [allow_soft_search, setIsOn] = useState(true)
  // const data = { allow_soft_search }

  // const alreadyChecked = JSON.parse(
  //   localStorage.getItem(`softsearch-${token?.email}`),
  // )

  const data = {
    allow_soft_search,
    expect_expenses_increase: profile?.expect_expenses_increase,
    expect_expenses_increase_reason: profile?.expect_expenses_increase_reason,
    expect_change_income: profile?.expect_change_income,
    expect_change_income_reason: profile?.expect_change_income_reason,
    other_income: profile?.other_income,
  }

  const analytics = getAnalytics()

  const handleUpdateProfile = async () => {
    const updated = await dispatch(editMyProfile(data))
    if (updated) {
      const getPro = await dispatch(getProfile())
      if (getPro) {
        if (getApplication[0]?.status === 'DECLINED') {
          navigate('/failed-offer')
        } else {
          if (!getApplication[0]?.credit_decision) {
            const application = await dispatch(Creditpolicydecision(setSoftErr))
            if (application?.status) {
              const eventReference = 'checked_eligibility_'
              const eventName = 'Checked Eligibility'
              if (window.fbq) {
                window.fbq('trackCustom', eventName, {
                  applicationId: application.id
                },
                {eventID: `${eventReference}${application?.id}`}
                )
              }
              dispatch(
                conversionAPI({
                  eventName: eventName,
                  eventSourceUrl: `${process.env.REACT_APP_FRONTEND_URL}soft-search`,
                  phoneNumber: token?.phone_number,
                  email: token?.email,
                  fName: token?.given_name,
                  lName: token?.family_name,
                  cognitoUserId: token?.sub,
                  customData: { applicationId: application.id },
                  dateOfBirth: token?.birthdate,
                  userAgent: navigator.userAgent,
                  eventId: `${eventReference}${application.id}`,
                  eventReference: eventReference,
                }),
              )
              if (window.gtag) {
                let user_data = {}
                if (
                  getPro?.first_name &&
                  getPro?.family_name &&
                  getPro?.address?.postcode
                ) {
                  let address = { country: 'UK' }
                  address.first_name = getPro?.first_name
                  address.last_name = getPro?.family_name
                  address.postal_code = getPro?.address.postcode
                  if (getPro?.address?.line_1)
                    address.street = getPro?.address?.line_1
                  if (getPro?.address?.town) address.city = getPro?.address.town
                  if (
                    getPro?.address?.postal_county ||
                    getPro?.address?.traditional_county
                  )
                    address.region =
                      getPro?.address?.postal_county ||
                      getPro?.address?.traditional_county
                  user_data = {
                    email: token?.email,
                    phone_number: token?.phone_number,
                    address: address,
                  }
                } else {
                  user_data = {
                    email: token?.email,
                    phone_number: token?.phone_number,
                  }
                }
                window.gtag('event', 'conversion', {
                  send_to:
                    process.env.REACT_APP_CHECKED_ELIGIBILITY_GTAG_CONVERSION,
                  user_id: token?.sub,
                  user_data: user_data,
                })
              }
              logEvent(analytics, 'checked_eligibility_client_side', {
                user_id: token?.sub,
                platform: 'web',
              })
              const getApplication = await dispatch(getApplications())
              if (getApplication) {
                navigate('/monthly-spend')
              }
            }
          } else {
            const getApplication = await dispatch(getApplications())
            if (getApplication) {
              navigate('/monthly-spend')
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    dispatch(getSoftsearchDocs())
  }, [])

  // ==================== Checking user status  =======================//
  useEffect(() => {
    if (!getApplication[0]?.id) {
      const temp = async () => {
        dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
        const myID = await dispatch(getApplications())
        if (myID?.data[0]?.status === 'POLICY_OK') {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/soft-search')
        } else {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/address-detail')
        }
      }
      temp()
    }
  }, [!getApplication[0]?.id])

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <>
          <RefferedRoute />
          <div className="relative container mx-auto  md:w-[564px]  md:space-y-10  ">
            <PageHeader
              title={'Soft search'}
              customDescription={
                <p
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Body,
                          color: color.Grey,
                        }
                      : {
                          ...AppStyles.Subheading1,
                          color: color.Grey,
                        }
                  }
                >
                  <span style={{ color: color.BlurpleTeal }}>
                    {' '}
                    {profile?.first_name}
                  </span>
                  , in order to apply for Updraft Credit, we must check your
                  details with the credit bureau.
                  <br /> <br />
                  This allows us to assess your continued affordability and
                  changes to your financial record. Doing so will not impact
                  your credit score but will be visible to you on your credit
                  report.
                </p>
              }
              backButtonTitle={'Change in circumstances'}
              prevRoute={'/circumstances'}
            />

            <div
              style={{ backgroundColor: color.White }}
              className="rounded-3xl p-6 space-y-6 "
            >
              {/* <SubPageHeader
                Icon={BankNoteIcon}
                text={'Credit profile check'}
              /> */}
              <div className="w-full ">
                <div
                  style={{
                    backgroundColor: color.White,
                    borderColor: color.MidGrey,
                    minHeight: isSmallScreen && '74px',
                    alignItems: !isSmallScreen && 'center',
                    // justifyContent: 'center',
                  }}
                  className="p-4 border rounded-2xl flex space-x-4"
                >
                  <div>
                    <InfoIcon fill={color.BlurpleTeal} />
                  </div>
                  <p style={{ ...AppStyles.Body12Demi, color: color.Grey }}>
                    Soft searches won't impact your credit score but will be
                    visible to you on your credit report.
                  </p>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: color.LightGrey,
                  borderColor: color.MidGrey,
                }}
                className="p-5 border rounded-2xl space-y-6 overflow-y-scroll h-[500px] "
              >
                <div className=" space-y-4">
                  <p style={{ ...AppStyles.BodyBold, color: color.Plum }}>
                    Please read these T&C carefully.
                  </p>
                  <p style={{ ...AppStyles.Body, color: color.Grey }}>
                    By completing and submitting the Updraft soft search
                    application form you agree to these T&C.
                    <br />
                    <br />
                    In these T&C, ‘we/our/us’ means Fairscore Limited (Company
                    number 10868865) whose registered office is at 20-22 Wenlock
                    Road, London, N1 7GU.
                  </p>
                </div>
                <div className=" space-y-4">
                  <p style={{ ...AppStyles.BodyBold, color: color.Plum }}>
                    1. What will we do?
                  </p>
                  <p style={{ ...AppStyles.Body, color: color.Grey }}>
                    1.1 In order to decide whether we can provide you with an
                    Updraft product we may use the information held at credit
                    reference agencies (CRA). A CRA is a company that collects
                    personal information from various sources and provides that
                    personal information for a variety of uses (including to
                    prospective lenders for the purposes of making credit
                    decisions).
                    <br />
                    <br />
                    1.2 We will perform a ‘soft search’ on you at the CRA which
                    will provide us with publicly held data, including the
                    electoral roll and shared credit performance data. If you
                    have a financial associate their data may also be provided.
                    <br />
                    <br />
                    1.3 A ‘soft search’ allows lenders to check your eligibility
                    for credit without leaving a footprint on your credit file
                    that can be seen by other lenders. When the CRA receives a
                    search request from us they will place a quotation search
                    footprint on your credit report, whether or not you decide
                    to apply for the product. This search will not affect your
                    ability to gain credit.
                    <br />
                    <br />
                    1.4 We use a ‘soft search’ along with its credit scoring
                    methods to check your eligibility and assess whether you
                    would be successful if you decided to apply for an Updraft
                    product.
                    <br />
                    <br />
                    1.5 A ‘hard search’ can affect your credit score as it may
                    be seen and used by other lenders to determine your
                    eligibility for credit. Please note that an Updraft ‘soft
                    search’ will result in an additional ‘hard search’ on your
                    credit file if you decide to apply for an Updraft product.
                    If you choose to apply for the Updraft product, we will
                    undertake a credit check and provide you with the product
                    terms and conditions.
                    <br />
                    <br />
                    1.6 The information which we provide to the CRA may be
                    supplied by them to other organisations such as Fraud
                    Prevention Agencies and used by those organisations for the
                    purposes of checking identity, preventing fraud, tracing and
                    collection of debt. The CRA may also use the data to
                    undertake statistical analysis. 1.7 The identities of the
                    CRA we use are below. The links contain information on their
                    role also as fraud prevention agencies, the data they hold,
                    the ways in which they use and share personal information,
                    data retention periods and your data protection rights.
                    <br />
                    <br />
                    TransUnion (formerly CallCredit):
                    <br />
                    <br />
                    www.transunion.co.uk/crain
                    <br />
                    <br />
                    Additionally, you can contact any of the CRA below, if you
                    wish to obtain a copy of your credit report. Transunion,
                    Consumer Services Team, PO Box 491, Leeds, LS3 1WZ or call
                    0870 06014
                  </p>
                </div>
                <div className=" space-y-4">
                  <p style={{ ...AppStyles.BodyBold, color: color.Plum }}>
                    2. How often will we search your file
                  </p>
                  <p style={{ ...AppStyles.Body, color: color.Grey }}>
                    As part of the Updraft ‘soft search’ service, if you do not
                    become an Updraft loan customer straightaway, we will keep
                    and may use the information you’ve submitted to review your
                    credit file in future. We will use it to carry out regular
                    ‘soft searches’ over the next 12 months. These searches will
                    be done no more than once a month and will not affect your
                    credit score. Based on this, we may then contact you (by
                    mobile app or email, and/or SMS) about the Updraft loan,
                    similar products and services offered by us. You do not have
                    to agree to our use of your personal data in this way and
                    you have the option to decline this as part of the process.
                  </p>
                </div>
                <div className=" space-y-4">
                  <p style={{ ...AppStyles.BodyBold, color: color.Plum }}>
                    3. When does the soft credit search service start and
                    wfacebhen does it end?
                  </p>
                  <p style={{ ...AppStyles.Body, color: color.Grey }}>
                    3.1. The ‘soft search’ service will start when you submit a
                    soft search application form.
                    <br />
                    <br />
                    3.2. The ‘soft search’ service ends when either:
                    <br />
                    <br />
                    3.2.1. you become an Updraft customer; or
                    <br />
                    <br />
                    3.2.2. one year after you submit your ‘soft search’ form to
                    us; or
                    <br />
                    <br />
                    3.2.3 if you do not agree to regular soft searches, when the
                    initial soft search has been completed; whichever is the
                    first to happen.
                  </p>
                </div>
                <div className=" space-y-4">
                  <p style={{ ...AppStyles.BodyBold, color: color.Plum }}>
                    4. Things to keep in mind
                  </p>
                  <p style={{ ...AppStyles.Body, color: color.Grey }}>
                    4.1. Credit is subject to: status; telephone contact;
                    confirmation of name, address and contact details; no
                    reasonable suspicion of fraud; is only available to UK
                    residents who are at least 18 years of age and are not
                    legally restricted from obtaining credit e.g. because of
                    bankruptcy.
                    <br />
                    <br />
                    4.2. The result of your ‘soft search’ is a good indicator of
                    whether you would be approved for an Updraft loan. However,
                    we may be unable to give you an Updraft loan if:
                    <br />
                    <br />
                    4.2.1. we are unable to successfully complete affordability,
                    fraud and money laundering checks; or
                    <br />
                    <br />
                    4.2.2. you change any of the information you provided to us
                    in the ‘soft search’ form or otherwise; or
                    <br />
                    <br />
                    4.3. in all cases we may need you to set up a Direct Debit
                    before we can open your Updraft loan.
                    <br />
                    <br />
                    4.4 If false or inaccurate information is provided and fraud
                    is identified, details will be passed to fraud prevention
                    agency.
                  </p>
                </div>
                {/* <div className=" space-y-4">
              <p style={{ ...AppStyles.BodyBold, color: color.Plum }}>
                
              </p>
              <p style={{ ...AppStyles.Body, color: color.Grey }}>

              </p>
            </div> */}
              </div>
              <AgreeToTerms
                setIsOn={setIsOn}
                isOn={allow_soft_search}
                title={'Agree to ongoing soft searches'}
                // name={`softsearch-${token?.email}`}
                // alreadyChecked={alreadyChecked}
              />
            </div>
            <div style={{ ...AppStyles.Caption1, color: color.SoftPink }}>
              {softErr}
            </div>
            <div
              style={{
                marginTop: isSmallScreen && '24px',
                background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
              }}
              className={
                isSmallScreen ? (
                  `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex ${
                    profile?.id ? 'justify-center items-center' : ''
                  } flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
                ) : (
                  `px-6 pb-6 md:p-0 fixed bottom-0 md:relative  w-full `
                )
              }
            >
              {isSmallScreen && (
                <>
                  <div
                    className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
                  ></div>
                  <div
                    className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
                  />
                </>
              )}
              <PrimaryButton
                id={'soft-search-btn'}
                text={loading ? <Spinner /> : 'Next'}
                // disabled={!allow_soft_search && !alreadyChecked}
                onClick={() => {
                  if (loading) {
                    return
                  }
                  window.heap &&
                    window.heap.track('uc_soft_search_tap', {
                      action_name: 'tap',
                      action_details: 'ucSoftSearch',
                    })
                  handleUpdateProfile()
                }}
                className={' md:w-[260px]'}
              />
            </div>
          </div>
        </>
      )}
    </DefaultPage>
  )
}

export default SoftSearch
