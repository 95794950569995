/* eslint-disable react/no-unescaped-entities */
import { AppStyles } from '../../../assets/styles/AppStyles'
import { useTheme } from '../../../utils/ThemeColorContext'

import React, { useEffect } from 'react'
import DefaultPage from '../DefaultPage'
import manageLoanIcon from '../../../assets/icons/manageLoan2.png'
import manageLoanIconMobile from '../../../assets/icons/ManageLoanMobile.svg'
import CheckInformationWrapper from '../CheckInformationWrapper'
import AndroidIcon from '../../../assets/images/AndroidIcon.png'
import iosIcon from '../../../assets/images/Ios.png'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import {
  Account_Management_List,
  // getApplications,
} from '../../../redux/Actions/getMethods'
import SpinnerLarge from '../../../components/SpinnerLarge'
import { removeAllGarbage } from '../../../api/dummyData'

function ActiveLoan() {
  const dispatch = useDispatch()
  const { color, isSmallScreen } = useTheme()
  const navigate = useNavigate()
  const { applicationStatus, loadingWholeScreen } = useSelector(
    (state) => state.dataReducer,
  )

  useEffect(() => {
    if (applicationStatus?.status === 'APPLY') {
      navigate('/reapply')
    }
  }, [applicationStatus?.status === 'APPLY'])

  // ==================== Checking user status  =======================//
  useEffect(() => {
    removeAllGarbage()
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      const get_loan_account_management = await dispatch(
        Account_Management_List(),
      )
      if (
        (get_loan_account_management?.status === 'CLOSED' &&
          get_loan_account_management?.settled_date === null) ||
        (get_loan_account_management?.status === 'CLOSED' &&
          get_loan_account_management?.settled_date !== null)
      ) {
        // window.location.href = `${window.location.origin}/no-active-loan`
        navigate('/no-active-loan')
      } else if (
        get_loan_account_management?.status === 'GOOD' ||
        get_loan_account_management?.status === 'OVERDUE' ||
        get_loan_account_management?.status === 'HOLIDAY' ||
        get_loan_account_management?.status === 'ARRANGEMENT' ||
        get_loan_account_management?.status === 'ARRANGEMENT_OVERDUE' ||
        get_loan_account_management?.status === null
      ) {
        navigate('/active-loan')
      } else {
        navigate('/address-detail')
      }
      dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
    }
    temp()
  }, [])

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <div className="md:mt-0 mt-8 ">
          <CheckInformationWrapper
            img={isSmallScreen ? manageLoanIconMobile : manageLoanIcon}
          >
            <div className="w-full space-y-8 md:space-y-10">
              <div className="flex flex-col items-center">
                <h1
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Title1,
                          color: color.Plum,
                          marginBottom: '8px',
                        }
                      : {
                          ...AppStyles.LargeTitle2,
                          color: color.Plum,
                          marginBottom: '24px',
                        }
                  }
                  className="text-center"
                >
                  Welcome back
                </h1>
                { isSmallScreen ? (
                  <a
                    href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h1
                      style={{
                        fontSize: isSmallScreen ? '18px' : '20px',
                        fontFamily: 'GilroyBold',
                        lineHeight: '32px',
                        color: '#5D1CD3',
                        width: '376px',
                        marginBottom: isSmallScreen ? '16px' : '24px',
                      }}
                      className=" text-center"
                    >
                      {isSmallScreen
                        ? 'Download the Updraft app'
                        : '  Scan the QR code above to install Updraft on your phone.'}
                    </h1>
                  </a>
                ) : (
                  <h1
                    style={{
                      fontSize: isSmallScreen ? '18px' : '20px',
                      fontFamily: 'GilroyBold',
                      lineHeight: '32px',
                      color: '#5D1CD3',
                      width: '376px',
                      marginBottom: isSmallScreen ? '16px' : '24px',
                    }}
                    className=" text-center"
                  >
                    {isSmallScreen
                      ? 'Download the Updraft app'
                      : '  Scan the QR code above to install Updraft on your phone.'}
                  </h1>
                )}
                <p
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Body,
                          color: color.Grey,

                          marginBottom: '40px',
                        }
                      : {
                          ...AppStyles.Body16,
                          color: color.Grey,
                          lineHeight: '28px',
                          marginBottom: '40px',
                        }
                  }
                  className="text-center"
                >
                  If you're looking to manage your loan or anything else, you'll
                  need to download the Updraft app.
                </p>
                <p
                  className="mb-[16px]"
                  style={{
                    ...AppStyles.Caption4,
                    color: '#6C819C',
                  }}
                >
                  Available On
                </p>
                <div className="flex md:justify-between md:w-[77px] m-[auto]">
                  { isSmallScreen ? (
                    <>
                      <a
                        href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                        target="_blank"
                        className="cursor-pointer w-full space-y-2 "
                        rel="noreferrer"
                      >
                        <img
                          src={iosIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </a>
                      <a
                        href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                        target="_blank"
                        className="cursor-pointer w-full space-y-2 "
                        rel="noreferrer"
                      >
                        <img
                          src={AndroidIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className="w-full space-y-2 ">
                        <img
                          src={iosIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                      <div className="w-full space-y-2 ">
                        <img
                          src={AndroidIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                    </>
                  )}
              </div>
                <div className="flex justify-between items-center w-full mt-[40px] mb-[12px]">
                  <span className="border-[1px] md:w-[102.5px] w-[61.5px]"></span>
                  <span
                    style={{
                      ...AppStyles.BodyBold,
                      color: color.Plum,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    className="h-[24px] w-[132px] md:w-[147px] ml-3 mr-3"
                  >
                    After a new loan?
                  </span>
                  <span className="border-[1px] md:w-[102.5px] w-[61.5px]"></span>
                </div>
                <p
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Body,
                          color: color.Grey,
                          height: '72px',
                        }
                      : {
                          ...AppStyles.Body16,
                          color: color.Grey,
                          lineHeight: '28px',
                        }
                  }
                  className="text-center"
                >
                  Unfortunately we can’t offer you the opportunity to check your
                  eligibility right now.
                </p>
              </div>
            </div>
          </CheckInformationWrapper>
        </div>
      )}
    </DefaultPage>
  )
}

export default ActiveLoan
