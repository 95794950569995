/* eslint-disable react/prop-types */
import React from 'react'
import { AppStyles } from '../../assets/styles/AppStyles'
import { useTheme } from '../../utils/ThemeColorContext'
import {
  ArrowHeadRightIcon,
  InfoIcon,
  UpdraftLogoIcon,
} from '../../assets/AppIcons'

function LoanDetailsCard({ amountPaid, amountUnPaid, referenceID, onClick }) {
  const { color, isSmallScreen } = useTheme()
  function currencyToNumber(currencyString) {
    return parseFloat(currencyString.replace(/[^0-9.-]+/g, ''))
  }
  const paid = currencyToNumber(amountPaid)
  const unpaid = currencyToNumber(amountUnPaid)
  const taskProgress = (paid / (paid + unpaid)) * 100

  return (
    <div
      onClick={onClick}
      style={{ backgroundColor: color.White, borderColor: color.MidGrey }}
      className=" border rounded-2xl cursor-pointer"
    >
      <div className="p-4 rounded-t-2xl flex justify-between items-center">
        <div className="flex space-x-4 items-center">
          <div
            style={{ backgroundColor: color.Blurple }}
            className="w-14 h-14 rounded-full flex items-center justify-center"
          >
            <UpdraftLogoIcon fill={color.White} />
          </div>
          <div>
            <p style={{ ...AppStyles.BodyBold, color: color.GreyBlue }}>
              Updraft Credit
            </p>
            {isSmallScreen && (
              <p
                style={{
                  fontFamily: 'MaisonMedium","sans-serif',
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: '#6C819C',
                }}
              >
                UD00001
              </p>
            )}
          </div>
        </div>
        <div className="flex space-x-2">
          {!isSmallScreen && (
            <p style={{ ...AppStyles.BodyBold, color: color.Grey }}>
              Loan reference{' '}
            </p>
          )}
          {isSmallScreen ? (
            <ArrowHeadRightIcon
              fill={color.BlurpleTeal}
              className={'w-4 h-4'}
            />
          ) : (
            <p style={{ ...AppStyles.BodyBold, color: color.BlurpleTeal }}>
              {referenceID}
            </p>
          )}
        </div>
      </div>

      <div
        style={{ backgroundColor: color.LightGrey }}
        className="rounded-b-2xl p-4 space-y-2"
      >
        <div className="rounded-b-2xl flex justify-between items-center">
          <div className="">
            <p style={{ ...AppStyles.BodyBold, color: color.GreyBlue }}>
              Amount Repaid
            </p>
            <p style={{ ...AppStyles.BodyBold, color: color.BlurpleTeal }}>
              {amountPaid}
            </p>
          </div>
          <div className=" space-y-1">
            <div className="flex items-center space-x-1 ">
              <p style={{ ...AppStyles.BodyBold, color: color.GreyBlue }}>
                Left to Pay{' '}
              </p>
              <InfoIcon fill={color.BlurpleTeal} className={' w-4 h-4 '} />
            </div>
            <p
              className=" text-end"
              style={{ ...AppStyles.BodyBold, color: color.BlurpleTeal }}
            >
              {amountUnPaid}
            </p>
          </div>
        </div>
        <div className="relative">
          <div
            style={{ backgroundColor: color.MidGrey }}
            className="overflow-hidden h-2 text-xs flex rounded"
          >
            <div
              style={{ width: `${taskProgress}%`, backgroundColor: color.Teal }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoanDetailsCard
