import React from 'react'
import updraftLogoWhite from '../../assets/images/updraftlogowhite.svg'
import { Facebook, Instagram, Linkedin, Twitter } from '../../assets/AppIcons'
import openup from '../../assets/images/openup.png'
import finder from '../../assets/images/Award.png'
import industryawards from '../../assets/images/industryawards.png'
import googleplay from '../../assets/images/googleplay.png'
import appleplay from '../../assets/images/App Store.png'
import { AppStyles } from '../../assets/styles/AppStyles'
import { useTheme } from '../../utils/ThemeColorContext'

function Footer() {
  const { color, isSmallScreen } = useTheme()
  return (
    <div
      style={{ backgroundColor: color.WebFooter, zIndex: 1000 }}
      className=" hidden  bottom-0 w-full p-6 lg:py-[80px] lg:px-[120px]  rounded-t-3xl md:block"
    >
      <div>
        <div className="flex flex-col space-y-16 md:space-y-0 md:flex-row md:space-x-16">
          <div className=" min-w-[40px] min-h-[40px] ">
            <img src={updraftLogoWhite} alt="Logo" className="w-10" />
          </div>
          <div className=" space-y-14">
            <div className="flex flex-col space-y-10   md:flex-row md:space-y-0  md:space-x-[58px]  lg:space-x-[116px]  w-full">
              {/* column 1 */}
              <div className="space-y-6">
                <p style={{ ...AppStyles.Body16Demi, color: color.White }}>
                  Company
                </p>
                <div className=" space-y-4">
                  <p
                    style={{
                      ...AppStyles.BodyLink,
                      color: color.White,
                      opacity: 0.7,
                    }}
                  >
                    <a
                      href="https://www.updraft.com/join-the-team/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Join the team{' '}
                    </a>
                  </p>
                  <p
                    style={{
                      ...AppStyles.BodyLink,
                      color: color.White,
                      opacity: 0.7,
                    }}
                  >
                    <a
                      href="https://www.updraft.com/refer/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Refer a friend{' '}
                    </a>
                  </p>
                </div>
              </div>
              {/* column 2 */}
              <div className="space-y-6">
                <p style={{ ...AppStyles.Body16Demi, color: color.White }}>
                  The legal stuff
                </p>
                <div className=" space-y-4">
                  <p
                    style={{
                      ...AppStyles.BodyLink,
                      color: color.White,
                      opacity: 0.7,
                    }}
                  >
                    <a
                      href="https://www.updraft.com/terms-conditions/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms
                    </a>
                  </p>
                  <p
                    style={{
                      ...AppStyles.BodyLink,
                      color: color.White,
                      opacity: 0.7,
                    }}
                  >
                    <a
                      href="https://www.updraft.com/privacy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy
                    </a>
                  </p>
                  <p
                    style={{
                      ...AppStyles.BodyLink,
                      color: color.White,
                      opacity: 0.7,
                    }}
                  >
                    <a
                      href="https://www.updraft.com/cookies/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cookies
                    </a>
                  </p>
                  <p
                    style={{
                      ...AppStyles.BodyLink,
                      color: color.White,
                      opacity: 0.7,
                    }}
                  >
                    <a
                      href="https://www.updraft.com/regulatory/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Regulatory
                    </a>
                  </p>
                </div>
              </div>

              {/* column  3 */}
              <div className="space-y-6">
                <p style={{ ...AppStyles.Body16Demi, color: color.White }}>
                  Need some help?
                </p>
                <div className=" space-y-4">
                  <p
                    style={{
                      ...AppStyles.BodyLink,
                      color: color.White,
                      opacity: 0.7,
                    }}
                  >
                    <a
                      className="cursor-pointer"
                      onClick={() => {
                        window.Intercom('showNewMessage')
                      }}
                    >
                      Contact us
                    </a>
                  </p>
                  <p
                    style={{
                      ...AppStyles.BodyLink,
                      color: color.White,
                      opacity: 0.7,
                    }}
                  >
                    <a
                      href="https://www.updraft.com/support/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Support
                    </a>
                  </p>
                  <p
                    style={{
                      ...AppStyles.BodyLink,
                      color: color.White,
                      opacity: 0.7,
                    }}
                  >
                    <a
                      href="http://help.updraft.com/en/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAQs
                    </a>
                  </p>
                </div>
              </div>

              {/* column 4 */}

              <div className="space-y-6">
                <p style={{ ...AppStyles.Body16Demi, color: color.White }}>
                  Connect with us
                </p>
                <div className="md: flex space-x-6 ">
                  <a
                    className="cursor-pointer"
                    href="https://www.facebook.com/heyupdraft"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Facebook} alt="Socials" />
                  </a>
                  <a
                    className="cursor-pointer"
                    href="https://www.instagram.com/heyupdraft/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Instagram} alt="Socials" />
                  </a>
                  <a
                    className="cursor-pointer"
                    href="https://linkedin.com/company/helloupdraft/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Socials" />
                  </a>
                  <a
                    className="cursor-pointer"
                    href="https://twitter.com/HeyUpdraft"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="Socials" />
                  </a>
                </div>
              </div>
            </div>

            {/* company logos */}

            <div className=" space-y-8 xl:pr-[104px]">
              <div className="flex flex-col space-y-8 lg:flex-row justify-between  lg:space-y-0">
                <div className="flex space-x-6 items-center">
                  <a
                    className="cursor-pointer"
                    href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appleplay} alt="App Store" />
                  </a>
                  <a
                    className="cursor-pointer"
                    href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={googleplay} alt="Google Play Store" />
                  </a>
                </div>
                <div className="flex space-x-6 items-center ">
                  <img src={openup} alt="App Store" />
                  <img src={industryawards} alt="Google Play Store" />
                  <img src={finder} alt="Google Play Store" />
                </div>
              </div>

              {/* about updraft */}
              <p
                style={{
                  ...AppStyles.TabbarInActive,
                  color: color.White,
                  opacity: 0.7,
                }}
              >
                Fairscore Ltd (trading as “Updraft”) is registered in England.
                Our registered company number is 10868865 and our registered
                office is at 20-22 Wenlock Road, London, N1 7GU. We are
                authorised and regulated by the Financial Conduct Authority
                under firm reference number 810923. <br />
                <br />
                Updraft is acting as an agent of TrueLayer, who is providing the
                regulated Account Information Service and is authorised and
                regulated by the Financial Conduct Authority under the Payment
                Services Regulations 2017 and Electronic Money Regulations 2011
                (firm reference number 901096).
              </p>
              <p
                style={{
                  ...AppStyles.BodyLink,
                  color: color.White,
                }}
              >
                © 2023 Fairscore Ltd
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
