import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import {
  ArrowHeadRightIcon,
  Bank,
  BankNoteIcon,
  CalenderIcon,
  CheckIcon,
  ClockIcon,
  CreditReportIcon,
  Intro,
  Simple,
  Tip,
  User,
  phoneIcon,
} from '../../../assets/AppIcons'
import { AppStyles } from '../../../assets/styles/AppStyles'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { useTheme } from '../../../utils/ThemeColorContext'

// import { storeBankPayOffData } from '../../../api/dummyData'
import updriftTealLogo from '../../../assets/images/tealupdraftlogo.svg'
import updriftPurpleLogo from '../../../assets/images/updriftpurpleLogo.svg'
import OutllineButton from '../../../components/buttons/OutllineButton'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import Footer from '../../../components/navigation/Footer'
import TopNavBar from '../../../components/navigation/TopNavBar'

const applicationRequirements = [
  {
    icon: User,
    title: 'Minimum Age Required ',
    description:
      'You’ll need to be at least 18 years old with a history of using credit.',
    height: '108px',
  },
  {
    icon: Tip,
    title: 'Minimum Income ',
    description:
      'You’ll need to receive an annual income of at least £10,000 from employment or a pension.',
    height: '132px',
  },
]

function Introduction() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { profile } = useSelector((state) => state.dataReducer)
  const { token } = useSelector((state) => state.authReducer)
  const { darkMode, color, isSmallScreen } = useTheme()

  // useEffect(() => {
  //   storeBankPayOffData()
  // }, [])

  useEffect(() => {
    if (profile?.id) {
      window.heap &&
        window.heap.track('home_card_tapped', {
          action_name: 'getApproved',
          action_details: 'SignUpUserCreated',
          order: '<INDEX> (0-based)',
        })
      setTimeout(() => {
        window.heap &&
          window.heap.track('application_start_home', {
            action_name: 'tap',
            action_details: 'applicationStartHome',
            credit_option: 'uc',
          })
      }, 5000)
    }
  }, [profile?.id])

  useEffect(() => {
    if (localStorage.getItem('login')) {
      navigate('/address-detail')
    }
  }, [token?.email])

  return (
    <>
      <div style={{ backgroundColor: color.LightGrey }}>
        <TopNavBar />
        <div className="mobilecta bottom-0 w-full min-h-[800px] pb-8 pt-[72px] md:pt-0 mt-12 md:mt-16  md:block md:cta md:pb-16">
          <div className="relative container mx-auto  md:w-[792px] ">
            <div className=" px-6 pb-[177px] md:pb-0">
              {/* header */}
              <div className="flex flex-col justify-center items-center w-full space-y-4 pb-8 md:pb-16  ">
                <img
                  src={darkMode ? updriftTealLogo : updriftPurpleLogo}
                  alt=""
                  className="w-[40px] md:w-[60px] h-[40px] md:h-[60px]"
                />
                <h1
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.LargeTitle3,
                          color: color.Plum,
                        }
                      : { ...AppStyles.BigTitle, color: color.Plum }
                  }
                  className="text-center "
                >
                  Say hello to Updraft Credit
                </h1>
                <h3
                  style={
                    isSmallScreen
                      ? { ...AppStyles.Body, color: color.Grey }
                      : { ...AppStyles.Subtitle, color: color.Grey }
                  }
                  className="max-w-[327px] md:max-w-[792px] px-0 md:px-4 md:px-6 leading-[24px] md:leading-[32px]  text-center "
                >
                  {isSmallScreen ? (
                    `A personal loan to pay off credit cards, overdrafts and
                  everything in between. Our aim is to lower the cost of your existing credit and
                  help you get it paid off faster.`
                  ) : (
                    <>
                      A personal loan to pay off credit cards, overdrafts and
                      everything in between.
                      <br />
                      <span>
                        {' '}
                        Our aim is to lower the cost of your existing credit and
                        help you get it paid off faster.
                      </span>
                    </>
                  )}
                </h3>
              </div>
              <div className="md:max-w-[588px] m-[auto] md:p-0">
                <div className="pl-4 md:pl-0 flex flex-col  items-start md:justify-between space-y-3 md:flex-row md:items-start md:space-y-0 md:space-x-6 ">
                  <div className="md:flex flex-col space-y-3 ">
                    <div className="flex items-center space-x-4 h-[24px] md:h-[28px]">
                      <div className="">
                        <CalenderIcon
                          fill={color.BlurpleTeal}
                          className={'w-6 h-6'}
                        />
                      </div>
                      <p
                        className="h-[24px] md:h-[28px] flex items-center pt-[2px]"
                        style={
                          isSmallScreen
                            ? { ...AppStyles.Body, color: color.Grey }
                            : { ...AppStyles.Body16, color: color.Grey }
                        }
                      >
                        Pay off over a term that suits you
                      </p>
                    </div>
                    <div
                      style={{ margin: '16px 0' }}
                      className="flex items-center space-x-4  h-[24px] md:h-[28px]"
                    >
                      <CheckIcon
                        fill={color.BlurpleTeal}
                        className={'w-6 h-6'}
                      />
                      <p
                        className="h-[24px] md:h-[28px] flex items-center pt-[2px]"
                        style={
                          isSmallScreen
                            ? { ...AppStyles.Body, color: color.Grey }
                            : { ...AppStyles.Body16, color: color.Grey }
                        }
                      >
                        A clear path to being debt free
                      </p>
                    </div>

                    <div
                      style={{ margin: ' 0' }}
                      className="flex items-center space-x-4 h-[24px] md:h-[28px]"
                    >
                      <BankNoteIcon
                        fill={color.BlurpleTeal}
                        className={'w-6 h-6'}
                      />
                      <p
                        className="h-[24px] md:h-[28px] flex items-center justify-center pt-[2px]"
                        style={
                          isSmallScreen
                            ? { ...AppStyles.Body, color: color.Grey }
                            : { ...AppStyles.Body16, color: color.Grey }
                        }
                      >
                        No early settlement fees
                      </p>
                    </div>
                  </div>
                  <div className="md:flex flex-col space-y-4 ">
                    <div className="flex items-center space-x-4 h-[24px] md:h-[28px]">
                      <ClockIcon fill={color.BlurpleTeal} />
                      <p
                        className="h-[24px] md:h-[28px] flex items-center pt-[2px]"
                        style={
                          isSmallScreen
                            ? { ...AppStyles.Body, color: color.Grey }
                            : { ...AppStyles.Body16, color: color.Grey }
                        }
                      >
                        Check eligibility in minutes
                      </p>
                    </div>
                    <div
                      style={{ margin: '16px 0' }}
                      className="flex items-center h-[24px] md:h-[28px] space-x-4"
                    >
                      <CreditReportIcon
                        fill={color.BlurpleTeal}
                        className={'w-6 h-6'}
                      />
                      <p
                        className="h-[24px] md:h-[28px] flex items-center pt-[2px]"
                        style={
                          isSmallScreen
                            ? { ...AppStyles.Body, color: color.Grey }
                            : { ...AppStyles.Body16, color: color.Grey }
                        }
                      >
                        No impact on your credit score{' '}
                      </p>
                    </div>
                    <div
                      style={{ margin: ' 0' }}
                      className="flex items-center h-[24px] md:h-[28px] space-x-4"
                    >
                      <Simple fill={color.BlurpleTeal} className={'w-6 h-6'} />
                      <p
                        className="h-[24px] md:h-[28px] flex items-center pt-[2px]"
                        style={
                          isSmallScreen
                            ? { ...AppStyles.Body, color: color.Grey }
                            : { ...AppStyles.Body16, color: color.Grey }
                        }
                      >
                        1 simple monthly payment
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pt-8 md:pt-16">
                  <h2 style={{ ...AppStyles.Title2, color: color.BlurpleTeal }}>
                    {' '}
                    Here’s what you’ll need to apply
                  </h2>
                  <div className="space-y-3 pt-3">
                    {applicationRequirements.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            backgroundColor: color.White,
                            width: isSmallScreen && '100%',
                            height: isSmallScreen && '100%',
                            padding: isSmallScreen && '16px',
                            marginBottom: isSmallScreen && '12px',
                          }}
                          className="rounded-2xl p-6 flex items-start space-x-4"
                        >
                          <img src={item.icon} alt="" />
                          <div className="space-y-1">
                            <p
                              style={{
                                ...AppStyles.Body16Demi,
                                color: color.Plum,
                              }}
                            >
                              {item.title}
                            </p>
                            <p
                              style={{ ...AppStyles.Body16, color: color.Grey }}
                            >
                              {item.description}
                            </p>
                          </div>
                        </div>
                      )
                    })}
                    <div
                      onClick={() => navigate('/supported-banks')}
                      style={{
                        backgroundColor: color.White,
                        width: isSmallScreen && '100%',
                        height: isSmallScreen && 'auto',
                      }}
                      className="cursor-pointer rounded-2xl p-6 flex items-start space-x-4 "
                    >
                      <img src={Bank} alt="" />
                      <div className="flex space-x-4 items-center justify-between w-full ">
                        <div className=" space-y-1">
                          <p
                            style={{
                              ...AppStyles.Body16Demi,
                              color: color.Plum,
                            }}
                          >
                            Have a Supported UK Bank
                          </p>
                          <p style={{ ...AppStyles.Body16, color: color.Grey }}>
                            Your bank must be registered within the UK. Click
                            here for a list of the accepted banks.
                          </p>
                        </div>
                        <div>
                          <ArrowHeadRightIcon
                            className={'w-4 h-4'}
                            fill={color.BlurpleTeal}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: color.White,
                        width: isSmallScreen && '100%',
                        height: isSmallScreen && '100%',
                        padding: isSmallScreen && '16px',
                        marginBottom: isSmallScreen && '12px',
                      }}
                      className="rounded-2xl p-6 flex items-start space-x-4"
                    >
                      <img src={phoneIcon} alt="" />
                      <div className="space-y-1">
                        <p
                          style={{ ...AppStyles.Body16Demi, color: color.Plum }}
                        >
                          Have an iOS or Android device
                        </p>
                        <p style={{ ...AppStyles.Body16, color: color.Grey }}>
                          Should you accept a loan offer, you will need a
                          supported mobile device to download our app and manage
                          your loan.
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        borderColor: color.MidGrey,
                        backgroundColor: color.White,
                      }}
                      className="rounded-2xl border py-4 px-6 flex items-start space-x-4 "
                    >
                      <img src={Intro} alt="" />
                      <div className="flex space-x-4 items-center justify-between w-full ">
                        <div className=" space-y-1">
                          <p
                            style={
                              isSmallScreen
                                ? {
                                    ...AppStyles.Body12Demi,
                                    color: color.Grey,
                                  }
                                : {
                                    ...AppStyles.Body12Demi,
                                    color: color.Grey,
                                  }
                            }
                          >
                            By proceeding with checking eligibility, you are
                            agreeing to receive documentation (including any
                            loan documentation) digitally through the Updraft
                            app and to your email address. <br />
                            <br />
                            You also acknowledge, should you accept a loan
                            offer, you will only be able to manage this via the
                            Updraft app.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: '12px',
                        marginBottom: isSmallScreen && '12px',
                      }}
                      className="rounded-2xl flex items-start space-x-4"
                    >
                      <div className="space-y-1">
                        <p
                          style={{
                            ...AppStyles.representativeExample,
                            color: color.BlurpleWhite,
                          }}
                        >
                          Representative Example
                        </p>
                        <p
                          style={{
                            ...AppStyles.Body12Demi,
                            color: color.Grey,
                          }}
                        >
                          To borrow £3,000 over 36 months with a representative
                          APR of 26.5% (fixed) will cost £121.67 per month, with
                          a total cost of credit of £1,380.20 and a total amount
                          payable of £4,380.20.
                          <br />
                          <br />
                          From 14.8% to 41.39% APR. £1,000-20,000 over 1-5 years
                          available.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* get started btn */}
            <div
              style={{
                marginTop: isSmallScreen && '24px',
                background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
              }}
              className={
                isSmallScreen ? (
                  `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex ${
                    profile?.id ? 'justify-center items-center' : ''
                  } flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
                ) : (
                  `mt-10 md:max-w-[588px] m-[auto] fixed md:relative w-full bottom-0  px-6 pb-6 md:p-0 flex ${
                    profile?.id && 'justify-center items-center'
                  } flex-col-reverse gap-5 md:gap-10 md:flex-row `
                )
              }
            >
              {isSmallScreen && (
                <>
                  <div
                    className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
                  ></div>
                  <div
                    className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
                  />
                </>
              )}
              {!profile?.id && (
                <div className=" flex flex-col-reverse  md:flex-row gap-5 ">
                  <OutllineButton
                    id={'login-bottom'}
                    text={'Login'}
                    onClick={() => {
                      dispatch({ type: ActionTypes.OPEN_LOGIN_MODAL })
                    }}
                    className={'h-[56px]  w-full md:w-[226px] bg-white'}
                  />

                  <div className="md:hidden bg-[#6C819C33] h-px w-full md:h-[56px] md:w-px  "></div>
                </div>
              )}

              <PrimaryButton
                id={'eligibility-check-bottom'}
                text={'Start Eligibility Check'}
                onClick={() => navigate('/about-you')}
                className={' h-[56px] w-full md:w-[320px]'}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Introduction