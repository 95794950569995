/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import CheckInformationWrapper from '../CheckInformationWrapper'
import DefaultPage from '../DefaultPage'
import Image2 from '../../../assets/images/Image (1).png'
import Shape from '../../../assets/images/Shape.svg'
import numeral from 'numeral'

import { conversionAPI } from '../../../redux/Actions/postMethods'
import { AppStyles } from '../../../assets/styles/AppStyles'
import { useTheme } from '../../../utils/ThemeColorContext'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowHeadDownIcon, ArrowHeadUpIcon } from '../../../assets/AppIcons'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getApplications } from '../../../redux/Actions/getMethods'
import RefferedRoute from '../../../navigation/RefferedRoute'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import SpinnerLarge from '../../../components/SpinnerLarge'
import { getAnalytics, logEvent } from 'firebase/analytics'

const LoanApprovedFee = () => {
  const { token } = useSelector((state) => state.authReducer)
  const dispatch = useDispatch()
  const { profile, getApplication, loadingWholeScreen } = useSelector(
    (state) => state.dataReducer,
  )

  const { color, isSmallScreen } = useTheme()
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(true)

  const toggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  const analytics = getAnalytics()
  useEffect(() => {
    if (profile?.id && getApplication[0]?.id && getApplication[0]?.status === 'APPROVED' && localStorage.getItem(`approvedEventSent_${getApplication[0]?.id}`) !== 'true') {
      const eventReference = 'approved_loan_application_'
      const eventName = 'Approved Loan Application'
      if (window.fbq) {
        window.fbq('trackCustom', eventName, {
          applicationId: getApplication[0]?.id,
          loan_amount: getApplication[0]?.max_amount,
          loan_fee_percent: getApplication[0]?.fee_percentage,
        },
        {eventID: `${eventReference}${getApplication[0]?.id}`}
        )
      }
      dispatch(
        conversionAPI({
          eventName: eventName,
          eventSourceUrl: `${process.env.REACT_APP_FRONTEND_URL}loanapprovedfee`,
          phoneNumber: token?.phone_number,
          email: token?.email,
          fName: token?.given_name,
          lName: token?.family_name,
          cognitoUserId: token?.sub,
          customData: {
            applicationId: getApplication[0]?.id,
            loan_amount: getApplication[0]?.max_amount,
            loan_fee_percent: getApplication[0]?.fee_percentage,
          },
          dateOfBirth: token?.birthdate,
          userAgent: navigator.userAgent,
          eventId: `${eventReference}${getApplication[0]?.id}`,
          eventReference: eventReference,
        }),
      )
      if (window.gtag) {
        let user_data = {}
        if (
          profile?.first_name &&
          profile?.family_name &&
          profile?.address?.postcode
        ) {
          let address = { country: 'UK' }
          address.first_name = profile?.first_name
          address.last_name = profile?.family_name
          address.postal_code = profile?.address.postcode
          if (profile?.address?.line_1)
            address.street = profile?.address?.line_1
          if (profile?.address?.town) address.city = profile?.address.town
          if (
            profile?.address?.postal_county ||
            profile?.address?.traditional_county
          )
            address.region =
              profile?.address?.postal_county ||
              profile?.address?.traditional_county
          user_data = {
            email: token?.email,
            phone_number: token?.phone_number,
            address: address,
          }
        } else {
          user_data = {
            email: token?.email,
            phone_number: token?.phone_number,
          }
        }
        window.gtag('event', 'conversion', {
          send_to: process.env.REACT_APP_LOAN_APPROVED_GTAG_CONVERSION,
          loan_amount: getApplication[0]?.max_amount,
          user_data: user_data,
        })
      }
      logEvent(analytics, 'approved_loan_application_client_side', {
        loan_amount: getApplication[0]?.max_amount,
        platform: 'web',
      })
      localStorage.setItem(`approvedEventSent_${getApplication[0]?.id}`, 'true')
    }
  }, [profile?.id, getApplication[0]?.id])

  useEffect(() => {
    if (JSON.parse(localStorage.getItem(`added_account`)) === true) {
      navigate('/direct-debit')
    }
  }, [])

  // ==================== Checking user status  =======================//
  useEffect(() => {
    if (!getApplication[0]?.id) {
      const temp = async () => {
        dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
        const myID = await dispatch(getApplications())
        if (JSON.parse(localStorage.getItem(`added_account`))) {
          // dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/direct-debit')
        } else if (
          myID?.data[0]?.status === 'APPROVED' &&
          myID?.data[0]?.offer_type !== 'UPDRAFT_CREDIT_INTEREST_ONLY'
        ) {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/loanapprovedfee')
        } else {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/address-detail')
        }
      }
      temp()
    }
  }, [!getApplication[0]?.id])

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <>
          <RefferedRoute />
          <div className="md:flex md:w-[792px] m-[auto] pt-8 md:pt-0">
            <CheckInformationWrapper img={Image2}>
              <div className="w-full  space-y-8 md:space-y-10 ">
                <div className=" space-y-4 md:space-y-6 ">
                  <h1
                    style={
                      isSmallScreen
                        ? {
                            ...AppStyles.Title1,
                            color: color.Plum,
                          }
                        : {
                            ...AppStyles.LargeTitle2,
                            color: color.Plum,
                          }
                    }
                    className="text-center"
                  >
                    You’re&nbsp;
                    <span
                      style={
                        isSmallScreen
                          ? { ...AppStyles.Title1, color: color.Teal }
                          : { ...AppStyles.LargeTitle2, color: color.Teal }
                      }
                    >
                      Approved
                    </span>{' '}
                    for <br /> Updraft Credit
                  </h1>
                  <div
                    style={{
                      borderColor: color.MidGrey,
                      margin: isSmallScreen && '0',
                      marginTop: isSmallScreen && '16px',
                      minHeight: '86px',
                    }}
                    className={`border w-full rounded-[16px] flex space-x-4 p-4`}
                  >
                    <div>
                      <img src={Shape} alt="shape icon" />
                    </div>
                    <div className="flec flex-col w-full space-y-2">
                      {collapsed ? (
                        <p
                          style={{ ...AppStyles.Body12Demi, color: color.Grey }}
                        >
                          Based on your personal circumstances we can only offer
                          you Updraft Credit with a small fee.
                        </p>
                      ) : (
                        <p
                          style={{
                            ...AppStyles.Body12Demi,
                            color: color.Grey,
                          }}
                        >
                          Based on your personal circumstances we can only offer
                          you Updraft Credit with a small fee.
                          <br />
                          <br />
                          It’s included in your monthly repayments and is spread
                          evenly over the term of your loan.
                          <br />
                          <br />
                          You'll still benefit from no fees on over payments or
                          early settlement of your account. We don't charge fees
                          for late payments either.
                          <br />
                          <br />
                          Your loan fee is { getApplication[0]?.fee_percentage * 100 }% of the amount you choose to
                          borrow. We'll show you how much yours is once you've
                          selected your loan amount.
                        </p>
                      )}
                      <div
                        className="flex justify-between items-center w-full "
                        onClick={toggleCollapse}
                      >
                        <div className="flex justify-start">
                          <div
                            style={{
                              ...AppStyles.Body12Demi,
                              color: color.BlurpleTeal,
                              lineHeight: '24px',
                            }}
                            className="text-12 "
                          >
                            How this fee works
                          </div>
                        </div>
                        {collapsed ? (
                          <div className="cursor-pointer">
                            <ArrowHeadDownIcon
                              fill={color.BlurpleTeal}
                              onClick={toggleCollapse}
                            />
                          </div>
                        ) : (
                          <div className="cursor-pointer">
                            <ArrowHeadUpIcon
                              fill={color.BlurpleTeal}
                              onClick={toggleCollapse}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ backgroundColor: color.LightGrey }}
                    className="w-full rounded-2xl "
                  >
                    <div
                      style={{ borderColor: color.MidGrey }}
                      className="cardBody  border-b h-[56px]"
                    >
                      <p
                        style={{
                          ...AppStyles.BodyBold,
                          color: color.Plum,
                          lineHeight: '24px',
                        }}
                      >
                        Your Offer
                      </p>
                      <p
                        style={{
                          ...AppStyles.BodyBold,
                          color: color.BlurpleTeal,
                        }}
                      >
                        Up to £
                        {getApplication[0]?.max_amount
                          ? numeral(getApplication[0]?.max_amount).format('0,0')
                          : 0}{' '}
                      </p>
                    </div>
                    <div
                      style={{ borderColor: color.MidGrey }}
                      className="cardBody border-b  h-[56px]"
                    >
                      <p
                        style={{
                          ...AppStyles.BodyBold,
                          color: color.Plum,
                          lineHeight: '24px',
                        }}
                      >
                        Annual Interest Rate
                      </p>
                      <p
                        style={{
                          ...AppStyles.BodyBold,
                          color: color.BlurpleTeal,
                        }}
                      >
                        {!getApplication[0]?.interestRate
                          ? 0
                          : (getApplication[0]?.interestRate * 100).toFixed(2)}
                        %
                      </p>
                    </div>
                    <div className="cardBody ">
                      <div>
                        <p
                          style={{
                            ...AppStyles.BodyBold,
                            color: color.Plum,
                            lineHeight: '24px',
                          }}
                        >
                          Loan Fee
                        </p>
                        <p
                          style={{
                            ...AppStyles.Caption1,
                            color: color.Grey,
                            lineHeight: '24px',
                          }}
                        >
                          Split over the term of your loan
                        </p>
                      </div>

                      <p
                        style={{
                          ...AppStyles.BodyBold,
                          color: color.BlurpleTeal,
                        }}
                      >
                        From £
                        {!getApplication[0]?.fee_percentage
                          ? 0
                          : getApplication[0]?.fee_percentage *
                            getApplication[0]?.min_amount}
                      </p>
                    </div>
                  </div>
                  {isSmallScreen && (
                    <div style={{ marginTop: '32px' }}>
                      <PrimaryButton
                        id={'customise-loan'}
                        text={'Customise My Loan'}
                        onClick={() => {
                          navigate('/loan-amount')
                        }}
                      />
                    </div>
                  )}
                </div>
                {!isSmallScreen && (
                  <PrimaryButton
                    id={'customise-loan'}
                    text={'Customise My Loan'}
                    onClick={() => {
                      navigate('/loan-amount')
                    }}
                  />
                )}
              </div>
            </CheckInformationWrapper>
          </div>
        </>
      )}
    </DefaultPage>
  )
}

export default LoanApprovedFee
