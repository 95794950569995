import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import FailedOffer from '../screens/app/loanapplication/FailedOffer'
import MonthlySpend from '../screens/app/loanapplication/MonthlySpend'
import VerifyIncome from '../screens/app/loanapplication/VerifyIncome'
import CustomizeYourLoan from '../screens/app/customizeLoan/CustomizeYourLoan'
import DirectDebut from '../screens/app/directDebut/DirectDebut'
import ConfirmEmail from '../screens/app/loanapplication/ConfirmEmail'
import EmailVerified from '../screens/app/loanapplication/EmailVerified'
import DirectDebit from '../screens/app/loanapplication/DirectDebit'
import AboutYou from '../screens/app/loanapplication/AboutYou'
import Introduction from '../screens/app/loanapplication/Introduction'
import AddressDetail from '../screens/app/loanapplication/AddressDetail'
import PayoffOptions from '../screens/app/loanapplication/PayoffOptions'
import Employment from '../screens/app/loanapplication/Employment'
import Circumstances from '../screens/app/loanapplication/Circumstances'
import SoftSearch from '../screens/app/loanapplication/SoftSearch'
import ManageLoans from '../screens/app/manageloans/ManageLoans'
import LoanDetails from '../screens/app/manageloans/LoanDetails'
import SignAgreement from '../screens/app/information/SignAgreement'
import LoanReferred from '../screens/app/information/LoanReferred'
import LoanApproved from '../screens/app/information/LoanApproved'
import LoanApprovedFee from '../screens/app/information/LoanApprovedFee'
import Privacy from '../screens/app/Privacy/Privacy'
import Marketing from '../screens/app/Marketing/Marketing'
import LoanAgreement from '../screens/app/LoanAgreement/LoanAgreement'
import ProtectedRoutes from './ProtectedRoutes'
import SupportedBanks from '../screens/app/loanapplication/SupportedBanks'
import ManageLoan from '../screens/app/loanapplication/ManageLoan'
import ReApply from '../screens/app/loanapplication/ReApply'
import ExsistingLoan from '../screens/app/loanapplication/ExsistingLoan'
import ActiveLoan from '../screens/app/loanapplication/ActiveLoan'
import NoActiveLoan from '../screens/app/loanapplication/NoActiveLoan'
import LoanAmount from '../screens/app/customizeLoan/LoanAmount'
import ErrorPage from '../screens/app/loanapplication/Error'
import DirectDebutAddAccounts from '../screens/app/loanapplication/Direct-Debut-add-accounts'

function AppStack() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Introduction />} />
        <Route path="/about-you" element={<AboutYou />} />
        <Route path="/supported-banks" element={<SupportedBanks />} />
        <Route path="/address-detail" element={<Privacy />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/marketing-permissions" element={<Marketing />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/failed-offer" element={<FailedOffer />} />
          <Route path="/active-loan" element={<ActiveLoan />} />
          <Route path="/no-active-loan" element={<NoActiveLoan />} />
          <Route path="/reapply" element={<ExsistingLoan />} />
          <Route path="/exsisting-loan" element={<ReApply />} />
          <Route path="/monthly-spend" element={<MonthlySpend />} />
          <Route path="/verify-income" element={<VerifyIncome />} />
          <Route path="/customise-loan" element={<CustomizeYourLoan />} />
          <Route path="/loan-amount" element={<LoanAmount />} />
          <Route path="/direct-debit" element={<DirectDebut />} />
          <Route path="/direct-debit-account" element={<DirectDebutAddAccounts />} />
          <Route path="/direct-debit-guarantee" element={<DirectDebit />} />

          <Route path="/confirm-email" element={<ConfirmEmail />} />
          <Route path="/email-verified" element={<EmailVerified />} />

          {/* intro screens */}
          <Route path="/introduction" element={<Introduction />} />
          <Route path="/address-details" element={<AddressDetail />} />
          <Route path="/payoff" element={<PayoffOptions />} />
          <Route path="/employment" element={<Employment />} />
          <Route path="/circumstances" element={<Circumstances />} />
          <Route path="/soft-search" element={<SoftSearch />} />
          <Route path="/manage-loans" element={<ManageLoans />} />
          <Route path="/loan-details" element={<LoanDetails />} />
          <Route path="/manageloan" element={<ManageLoan />} />
          <Route path="/signagreement" element={<SignAgreement />} />
          <Route path="/loanreferred" element={<LoanReferred />} />
          <Route path="/loanapproved" element={<LoanApproved />} />
          <Route path="/loanapprovedfee" element={<LoanApprovedFee />} />
          <Route path="/loan-agreement" element={<LoanAgreement />} />
          <Route path="/loan-amount" element={<LoanAmount />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppStack
