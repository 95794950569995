/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  CreditCardIcon,
  FoodIcon,
  HomeIcon,
  // InfoIcon,
  LightIcon,
} from '../../../assets/AppIcons'
import { AppStyles } from '../../../assets/styles/AppStyles'
import {
  createAffordability,
  getAffordability,
  getApplications,
} from '../../../redux/Actions/getMethods'
import { conversionAPI, createOffer } from '../../../redux/Actions/postMethods'
import { useTheme } from '../../../utils/ThemeColorContext'

import { getAnalytics, logEvent } from 'firebase/analytics'
import { numberWithCommonAndPannie } from '../../../api/dummyData'
import PageHeader from '../../../components/PageHeader'
import Spinner from '../../../components/Spinner'
import SpinnerLarge from '../../../components/SpinnerLarge'
import SubPageHeader from '../../../components/SubPageHeader'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import FormInput from '../../../components/inputs/FormInput'
import OnEnter from '../../../components/navigation/onEnter'
import RefferedRoute from '../../../navigation/RefferedRoute'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import DefaultPage from '../DefaultPage'

const initialValues = {
  user_creditcard: '',
  user_carfinance: '',
  user_installmentloan: '',
  user_mortgage: '',
  user_utilities: '',
  user_subscriptions: '',
  user_insurance: '',
  user_essential: '',
}

function MonthlySpend() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, token } = useSelector((state) => state.authReducer)
  const { affordibility, profile, getApplication, loadingWholeScreen } =
    useSelector((state) => state.dataReducer)

  const [values, setvalues] = useState(initialValues)
  const [err, setErr] = useState('')
  const { color, isSmallScreen } = useTheme()
  const [result, setResult] = useState(null)
  const [utilitiesErr, setUtilitiesErr] = useState(false)
  const [essentialsErr, setEssentialsErr] = useState(false)
  const [subscriptionsErr, setSubscriptionsErr] = useState(false)
  const [loansErr, setLoansErr] = useState(false)
  const [carFinanceErr, setCarFinanceErr] = useState(false)
  const [creditCardsErr, setCreditCardsErr] = useState(false)
  const [housingErr, setHousingErr] = useState(false)
  const [insuranceErr, setInsuranceErr] = useState(false)

  const analytics = getAnalytics()

  const addition =
    (parseFloat(values?.user_creditcard) || 0) +
    (parseFloat(values?.user_carfinance) || 0) +
    (parseFloat(values?.user_installmentloan) || 0) +
    (parseFloat(values?.user_mortgage) || 0) +
    (parseFloat(values?.user_utilities) || 0) +
    (parseFloat(values?.user_subscriptions) || 0) +
    (parseFloat(values?.user_insurance) || 0) +
    (parseFloat(values?.user_essential) || 0)

  const handleChange = (e) => {
    const { name, value } = e.target // e.target refers to the input element
    setvalues({
      ...values, // spread the existing values
      [name]: value, // override the value for the input's name
    })
  }

  useEffect(() => {
    if (values.user_essential) {
      setEssentialsErr(false)
    }
    if (values.user_utilities) {
      setUtilitiesErr(false)
    }
    if (values.user_creditcard) {
      setCreditCardsErr(false)
    }
    if (values.user_carfinance) {
      setCarFinanceErr(false)
    }
    if (values.user_installmentloan) {
      setLoansErr(false)
    }
    if (values.user_mortgage) {
      setHousingErr(false)
    }
    if (values.user_subscriptions) {
      setSubscriptionsErr(false)
    }
    if (values.user_insurance) {
      setInsuranceErr(false)
    }
  }, [values])

  function areAllValuesEmpty(obj) {
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] !== '') {
        return true
      }
    }
    return false
  }

  const loggedIn = localStorage.getItem('login')
  useEffect(() => {
    if (areAllValuesEmpty(values) && loggedIn) {
      sessionStorage.setItem(`monthly-${token?.email}`, JSON.stringify(values))
    }
  }, [values, loggedIn])

  const loadMonthlyData = () => {
    if (loggedIn) {
      const myData = sessionStorage.getItem(`monthly-${token?.email}`)
      if (myData) {
        setvalues(JSON.parse(myData))
      }
    }
  }
  const myData = JSON.parse(sessionStorage.getItem(`monthly-${token?.email}`))

  const twoTimeGetAfordability = async () => {
    const temp = await dispatch(getAffordability())
    if (temp) {
      dispatch(getAffordability())
    }
  }

  useEffect(() => {
    twoTimeGetAfordability()
  }, [])

  useEffect(() => {
    if (affordibility?.id) {
      setvalues({
        user_creditcard:
          myData?.user_creditcard ||
          affordibility?.display_creditcard?.toFixed(0),
        user_carfinance:
          myData?.user_carfinance ||
          affordibility?.display_carfinance?.toFixed(0),
        user_installmentloan:
          myData?.user_installmentloan ||
          affordibility?.display_installmentloan?.toFixed(0),
        user_mortgage:
          myData?.user_mortgage || affordibility?.display_mortgage?.toFixed(0),
        user_utilities:
          myData?.user_utilities || affordibility?.user_utilities?.toFixed(0),
        user_subscriptions:
          myData?.user_subscriptions ||
          affordibility?.user_subscriptions?.toFixed(0),
        user_insurance:
          myData?.user_insurance || affordibility?.user_insurance?.toFixed(0),
        user_essential:
          myData?.user_essential || affordibility?.user_essential?.toFixed(0),
      })
    } else {
      loadMonthlyData()
    }
  }, [affordibility?.id])

  useEffect(() => {
    if (result) {
      const application = result
      // when createOffer() has succeeded, even if after a reload of applications
      if (['REFERRED', 'APPROVED', 'DECLINED'].includes(application?.status)) {
        const eventReference = 'submit_loan_application_'
        const eventName = 'Submit Loan Application'
        if (window.fbq) {
          window.fbq('trackCustom', eventName, {
            applicationId: application?.id,
          },
          {eventID: `${eventReference}${application?.id}`}
          )
        }
        dispatch(
          conversionAPI({
            eventName: eventName,
            eventSourceUrl: `${process.env.REACT_APP_FRONTEND_URL}monthly-spend`,
            phoneNumber: token?.phone_number,
            email: token?.email,
            fName: token?.given_name,
            lName: token?.family_name,
            cognitoUserId: token?.sub,
            customData: {
              applicationId: application?.id,
              loan_amount: application?.amount,
            },
            dateOfBirth: token?.birthdate,
            userAgent: navigator.userAgent,
            eventId: `${eventReference}${application?.id}`,
            eventReference: eventReference,
          }),
        )
        if (window.gtag) {
          let user_data = {}
          if (
            profile?.first_name &&
            profile?.family_name &&
            profile?.address?.postcode
          ) {
            let address = { country: 'UK' }
            address.first_name = profile?.first_name
            address.last_name = profile?.family_name
            address.postal_code = profile?.address.postcode
            if (profile?.address?.line_1)
              address.street = profile?.address?.line_1
            if (profile?.address?.town) address.city = profile?.address.town
            if (
              profile?.address?.postal_county ||
              profile?.address?.traditional_county
            )
              address.region =
                profile?.address?.postal_county ||
                profile?.address?.traditional_county
            user_data = {
              email: token?.email,
              phone_number: token?.phone_number,
              address: address,
            }
          } else {
            user_data = {
              email: token?.email,
              phone_number: token?.phone_number,
            }
          }
          window.gtag('event', 'conversion', {
            send_to: process.env.REACT_APP_SUBMIT_LOAN_GTAG_CONVERSION,
            loan_amount: application?.amount,
            user_data: user_data,
          })
        }
        logEvent(analytics, 'submit_loan_application_client_side', {
          loan_amount: application?.amount,
          platform: 'web',
        })
      }
      const getAffo = dispatch(getAffordability())
      if (getAffo) {
        const getApp = dispatch(getApplications())
        if (getApp) {
          navigate('/verify-income')
          localStorage.removeItem(`present-${token?.email}`)
          localStorage.removeItem('payoff')
          localStorage.removeItem('findAddress')
          localStorage.removeItem(`employmentFilledForm-${token?.email}`),
            localStorage.removeItem(`current-postcode-${token?.email}`),
            localStorage.removeItem(`current-timeAddress-${token?.email}`),
            localStorage.removeItem(`expect_expenses_increase-${token?.email}`),
            localStorage.removeItem(`change_in_circumstance-${token?.email}`),
            localStorage.removeItem(`current-residency-${token?.email}`),
            sessionStorage.removeItem(`monthly-${token?.email}`)
        }
      }
    }
  }, [result])

  const handleAffordibilty = async () => {
    let toReturn = false
    if (!(values.user_essential ? values.user_essential : '').replace(' ', '').trim()) {
      setEssentialsErr(true)
      toReturn = true
    }
    if (!(values.user_utilities ? values.user_utilities : '').replace(' ', '').trim()) {
      setUtilitiesErr(true)
      toReturn = true
    }
    if (!(values.user_creditcard ? values.user_creditcard : '').replace(' ', '').trim()) {
      setCreditCardsErr(true)
      toReturn = true
    }
    if (!(values.user_carfinance ? values.user_carfinance : '').replace(' ', '').trim()) {
      setCarFinanceErr(true)
      toReturn = true
    }
    if (!(values.user_installmentloan ? values.user_installmentloan : '').replace(' ', '').trim()) {
      setLoansErr(true)
      toReturn = true
    }
    if (!(values.user_mortgage ? values.user_mortgage : '').replace(' ', '').trim()) {
      setHousingErr(true)
      toReturn = true
    }
    if (!(values.user_subscriptions ? values.user_subscriptions : '').replace(' ', '').trim()) {
      setSubscriptionsErr(true)
      toReturn = true
    }
    if (!(values.user_insurance ? values.user_insurance : '').replace(' ', '').trim()) {
      setInsuranceErr(true)
      toReturn = true
    }
    if (toReturn) {
      return
    }
    let valuesPayload = {
      ...values,
    }
    if (profile?.address?.status !== "Home Owner") {
      valuesPayload = {
        ...values,
        user_rent: values.user_mortgage,
        user_mortgage: null,
      }
    }
    const res = await dispatch(createAffordability(valuesPayload, setErr))
    if (res) {
      if (!getApplication[0]?.offer_review) {
        dispatch(createOffer(setResult, setErr))
      } else {
        const getAffo = await dispatch(getAffordability())
        if (getAffo) {
          const getApp = await dispatch(getApplications())
          if (getApp) {
            navigate('/verify-income')
            localStorage.removeItem(`present-${token?.email}`)
            localStorage.removeItem('payoff')
            localStorage.removeItem('findAddress')
            localStorage.removeItem(`employmentFilledForm-${token?.email}`),
              localStorage.removeItem(`current-postcode-${token?.email}`),
              localStorage.removeItem(`current-timeAddress-${token?.email}`),
              localStorage.removeItem(
                `expect_expenses_increase-${token?.email}`,
              ),
              localStorage.removeItem(`change_in_circumstance-${token?.email}`),
              localStorage.removeItem(`current-residency-${token?.email}`),
              sessionStorage.removeItem(`monthly-${token?.email}`)
          }
        }
      }
    }
  }

  // ==================== Checking user status  =======================//
  useEffect(() => {
    if (!getApplication[0]?.id) {
      const temp = async () => {
        dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
        const myID = await dispatch(getApplications())
        if (myID?.data[0]?.status === 'CREDIT_OK') {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })

          navigate('/monthly-spend')
        } else {
          navigate('/address-detail')
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
        }
      }
      temp()
    }
  }, [!getApplication[0]?.id])

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <>
          <RefferedRoute />
          <div
            style={{ backgroundColor: color.LightGrey }}
            className="relative container mx-auto md:w-[564px] md:space-y-10 "
          >
            <PageHeader
              title={'Your outgoings'}
              description={`This is your personal spend not your partners, only record your outgoings. Please ensure you complete all fields, if you don't pay any amount, add £0.`}
            />
            <div
              style={{ backgroundColor: color.White }}
              className=" rounded-3xl p-6 space-y-10 "
            >
              <div className={'space-y-6'}>
                <SubPageHeader
                  Icon={CreditCardIcon}
                  text={'Monthly credit card commitments'}
                />
                <div>
                  <FormInput
                    leftSign={true}
                    placeholder={'Credit cards'}
                    onChange={(e) => numberWithCommonAndPannie(e, handleChange)}
                    type={'number'}
                    value={
                      !values.user_creditcard
                        ? ''
                        : values.user_creditcard.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ',',
                          ) // Add commas
                    }
                    name={'user_creditcard'}
                  />
                  <p
                    style={{
                      ...AppStyles.Caption5,
                      color: '#5D1CD3',
                      textAlign: 'right',
                    }}
                  >
                    required
                  </p>
                  { creditCardsErr && (
                    <p style={{ color: '#FF619A' }}>
                      Please include your monthly credit card costs, or £0 if
                      none
                    </p>
                  )}
                </div>
                <div>
                  <FormInput
                    leftSign={true}
                    placeholder={'Car financing'}
                    onChange={(e) => numberWithCommonAndPannie(e, handleChange)}
                    type={'number'}
                    value={
                      !values.user_carfinance
                        ? ''
                        : values.user_carfinance.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ',',
                          ) // Add commas
                    }
                    name={'user_carfinance'}
                  />
                  <p
                    style={{
                      ...AppStyles.Caption5,
                      color: '#5D1CD3',
                      // width: '376px',
                      textAlign: 'right',
                    }}
                  >
                    required
                  </p>
                  { carFinanceErr && (
                    <p style={{ color: '#FF619A' }}>
                      Please include your monthly car finance costs, or £0 if
                      none
                    </p>
                  )}
                </div>
                <div>
                  <FormInput
                    leftSign={true}
                    placeholder={'Personal loans'}
                    onChange={(e) => numberWithCommonAndPannie(e, handleChange)}
                    type={'number'}
                    value={
                      !values.user_installmentloan
                        ? ''
                        : values.user_installmentloan.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ',',
                          ) // Add commas
                    }
                    name={'user_installmentloan'}
                  />
                  <p
                    style={{
                      ...AppStyles.Caption5,
                      color: '#5D1CD3',
                      textAlign: 'right',
                    }}
                  >
                    required
                  </p>
                  { loansErr && (
                    <p style={{ color: '#FF619A' }}>
                      Please include your monthly personal loans costs, or £0
                      if none
                    </p>
                  )}
                </div>
              </div>
              <div
                style={{ backgroundColor: color.MidGrey }}
                className=" h-px "
              ></div>

              <div className=" space-y-6">
                <div style={{ marginBottom: isSmallScreen && '24px' }}>
                  <SubPageHeader Icon={HomeIcon} text={'Monthly housing'} />
                </div>
                <div
                  style={{ borderColor: color.MidGrey }}
                  className="grid  gap-4 border-b-borderGrey"
                >
                  <div>
                    <FormInput
                      leftSign={true}
                      placeholder={'Rent or mortgage'}
                      onChange={(e) =>
                        numberWithCommonAndPannie(e, handleChange)
                      }
                      type={'number'}
                      value={
                        !values.user_mortgage
                          ? ''
                          : values.user_mortgage.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            ) // Add commas
                      }
                      name={'user_mortgage'}
                    />
                    <p
                    style={{
                      ...AppStyles.Caption5,
                      color: '#5D1CD3',
                      textAlign: 'right',
                    }}
                    >
                      required
                    </p>
                    { housingErr && (
                      <p style={{ color: '#FF619A' }}>
                        Please include your rent or mortgage costs, or £0 if
                        none
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{ backgroundColor: color.MidGrey }}
                className=" h-px "
              ></div>

              <div className=" space-y-6">
                <div style={{ marginBottom: isSmallScreen && '24px' }}>
                  <SubPageHeader Icon={FoodIcon} text={'Monthly essentials'} />
                </div>
                <div className="grid border-b-borderGrey">
                  <FormInput
                    leftSign={true}
                    placeholder={'Day to day (food, travel, petrol)'}
                    onChange={(e) => numberWithCommonAndPannie(e, handleChange)}
                    type={'number'}
                    value={
                      !values.user_essential
                        ? ''
                        : values.user_essential.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ',',
                          ) // Add commas
                    }
                    name={'user_essential'}
                  />
                  <p
                    style={{
                      ...AppStyles.Caption5,
                      color: '#5D1CD3',
                      textAlign: 'right',
                    }}
                  >
                    required
                  </p>
                  { essentialsErr && (
                    <p style={{ color: '#FF619A' }}>
                      Please include your monthly day to day (food, travel,
                      petrol, etc) essentials costs, or £0 if none
                    </p>
                  )}
                </div>
              </div>
              <div
                style={{ backgroundColor: color.MidGrey }}
                className=" h-px "
              ></div>
              <div className="space-y-6">
                <div style={{ marginBottom: isSmallScreen && '24px' }}>
                  <SubPageHeader Icon={LightIcon} text={'Monthly bills'} />
                </div>
                <div className={'space-y-6'}>
                  <div>
                    <FormInput
                      leftSign={true}
                      placeholder={'Utilities (electric, water, council tax)'}
                      onChange={(e) =>
                        numberWithCommonAndPannie(e, handleChange)
                      }
                      type={'number'}
                      value={
                        !values.user_utilities
                          ? ''
                          : values.user_utilities.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            ) // Add commas
                      }
                      name={'user_utilities'}
                    />
                    <p
                      style={{
                        ...AppStyles.Caption5,
                        color: '#5D1CD3',
                        textAlign: 'right',
                      }}
                    >
                      required
                    </p>
                    { utilitiesErr && (
                      <p style={{ color: '#FF619A' }}>
                        Please include your monthly utilities (electric, water,
                        council tax, etc) costs, or £0 if none
                      </p>
                    )}
                  </div>
                  <div>
                    <FormInput
                      leftSign={true}
                      placeholder={'Insurance (home, life, car)'}
                      onChange={(e) =>
                        numberWithCommonAndPannie(e, handleChange)
                      }
                      type={'number'}
                      value={
                        !values.user_insurance
                          ? ''
                          : values.user_insurance.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            ) // Add commas
                      }
                      name={'user_insurance'}
                    />
                    <p
                      style={{
                        ...AppStyles.Caption5,
                        color: '#5D1CD3',
                        textAlign: 'right',
                      }}
                    >
                      required
                    </p>
                    { insuranceErr && (
                      <p style={{ color: '#FF619A' }}>
                        Please include your monthly insurance (home, life,
                        car, etc) costs, or £0 if none
                      </p>
                    )}
                  </div>
                  <div>
                    <FormInput
                      leftSign={true}
                      placeholder={'Subscriptions (netflix, spotify)'}
                      onChange={(e) =>
                        numberWithCommonAndPannie(e, handleChange)
                      }
                      type={'number'}
                      value={
                        !values.user_subscriptions
                          ? ''
                          : values.user_subscriptions.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            ) // Add commas
                      }
                      name={'user_subscriptions'}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                        }
                      }}
                    />
                    <p
                      style={{
                        ...AppStyles.Caption5,
                        color: '#5D1CD3',
                        textAlign: 'right',
                      }}
                    >
                      required
                    </p>
                    { subscriptionsErr && (
                      <p style={{ color: '#FF619A' }}>
                        Please include your monthly subscriptions (Netflix,
                        Spotify, etc) costs, or £0 if none
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="flex justify-center items-center space-x-px w-full rounded-[12px]"
                style={{
                  background: 'rgba(93, 28, 211, 0.05)',
                  padding: '20px 16px 16px 16px',
                }}
              >
                <span
                  style={{
                    ...AppStyles.MonthlySpendText,
                  }}
                >
                  Your monthly spend
                </span>
                <span
                  style={{
                    ...AppStyles.Title3,
                    color: color.BlurpleTeal,
                  }}
                >
                  £
                  {addition
                    ? addition
                        .toFixed(2)
                        ?.toLocaleString('en-US', {
                          maximumFractionDigits: 2, // No decimal places
                        })
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : '0,000.00'}{' '}
                </span>
              </div>
            </div>
            <div style={{ ...AppStyles.Caption1, color: color.SoftPink }}>
              {err}
            </div>
            <OnEnter
              actionFunction={() => {
                if (!values.user_creditcard ||
                  !values.user_carfinance ||
                  !values.user_installmentloan ||
                  !values.user_mortgage
                ) {
                  return
                }
                handleAffordibilty()
                window.heap &&
                  window.heap.track('uc_confirm_affordability_details', {
                    action_name: 'tap',
                    action_details: 'ucConfirmAffordability_details',
                  })
              }}
            />
            <div
              style={{
                marginTop: isSmallScreen && '24px',
                background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
              }}
              className={
                isSmallScreen ? (
                  `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex ${
                    profile?.id ? 'justify-center items-center' : ''
                  } flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
                ) : (
                  `px-6 pb-6 md:p-0 fixed bottom-0 md:relative  w-full `
                )
              }
            >
              {isSmallScreen && (
                <>
                  <div
                    className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
                  ></div>
                  <div
                    className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
                  />
                </>
              )}
              <PrimaryButton
                id={'monthlyspend-btn'}
                disabled={
                  !values.user_creditcard ||
                  !values.user_carfinance ||
                  !values.user_installmentloan ||
                  !values.user_mortgage
                  // !values.user_rent,
                  // !values.user_essential
                }
                text={loading ? <Spinner /> : 'Continue'}
                onClick={() => {
                  if (loading) {
                    return
                  }
                  handleAffordibilty()
                  window.heap &&
                    window.heap.track('uc_confirm_affordability_details', {
                      action_name: 'tap',
                      action_details: 'ucConfirmAffordability_details',
                    })
                }}
                className={' md:w-[260px]'}
              />
            </div>
          </div>
        </>
      )}
    </DefaultPage>
  )
}

export default MonthlySpend
