export const ActionTypes = {
  LOADING_SUCCESS: 'LOADING_SUCCESS',
  LOADING_SUCCESS_ADD_ADDRESS: 'LOADING_SUCCESS_ADD_ADDRESS',
  LOADING_FAIL_ADD_ADDRESS: 'LOADING_FAIL_ADD_ADDRESS',
  LOADING_SUCCESS_WHOLE_SCREEN: 'LOADING_SUCCESS_WHOLE_SCREEN',
  LOADING_FAIL_WHOLE_SCREEN: 'LOADING_FAIL_WHOLE_SCREEN',
  ADD_OTHER_ACCOUNT: 'ADD_OTHER_ACCOUNT',
  LOADING_SUCCESS2: 'LOADING_SUCCESS2',
  LOADING_FAIL: 'LOADING_FAIL',
  LOADING_FAIL2: 'LOADING_FAIL2',
  OPEN_LOGIN_MODAL: 'OPEN_LOGIN_MODAL',
  OPEN_LOGIN_CLOSE: 'OPEN_LOGIN_CLOSE',
  LOGIN_WITH_VALUES: 'LOGIN_WITH_VALUES',

  ABOUT_YOU: 'ABOUT_YOU',
  USER_TOKEN_DATA: 'USER_TOKEN_DATA',
  GET_SOFT_SEARCH: 'GET_SOFT_SEARCH',
  ADD_AND_GET_APPLICATOION: 'ADD_AND_GET_APPLICATOION',
  GET_PROFILE: 'GET_PROFILE',
  EDIT_PROFILE: 'EDIT_PROFILE',
  GET_AFFORDIBILITY: 'GET_AFFORDIBILITY',
  GET_BANKS: 'GET_BANKS',
  FILTERS_BANKS: 'FILTERS_BANKS',
  GET_DD_ACCOUNTS: 'GET_DD_ACCOUNTS',
  GET_LIMITS: 'GET_LIMITS',
  GET_TERMS: 'GET_TERMS',
  GET_DIRECT_DEBIT_G: 'GET_DIRECTDEBITG',
  GET_AGREMENT_DOCS: 'GET_AGREMENT_DOCS',
  GET_FACTS_DOCS: 'GET_FACTS_DOCS',
  GET_PCCI_DOCS: 'GET_PCCI_DOCS',
  GET_RAPID_ADDRESSES: 'GET_RAPID_ADDRESSES',
  FINDADDRESS: 'FINDADDRESS',
  AGREEMENT: 'AGREEMENT',
  SIGNATURE: 'SIGNATURE',
  PAYOUT_SUMMARY: 'PAYOUT_SUMMARY',
  RE_APPLY: 'RE_APPLY',
  GET_APPLICATION_STATUS: 'GET_APPLICATION_STATUS',
  GET_MAKETING_DATA: 'GET_MAKETING_DATA',
  PRESS_ENTER_ON: 'PRESS_ENTER_ON',
  PRESS_ENTER_OFF: 'PRESS_ENTE_OFFR',
  POSTCODE_ERROR: 'POSTCODE_ERROR',
}
