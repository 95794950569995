import { ActionTypes } from '../Constants/actionTypes'
const initState = {
  user: [],
  token: [],
  loading: false,
  loading2: false,
  loadingAddAddress: false,
  isLoginModalOpen: false,
  signUpValues: {},
}

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_SUCCESS:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.LOADING_FAIL:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.LOADING_SUCCESS2:
      return {
        ...state,
        loading2: true,
      }
    case ActionTypes.LOADING_FAIL2:
      return {
        ...state,
        loading2: false,
      }
    case ActionTypes.LOADING_SUCCESS_ADD_ADDRESS:
      return {
        ...state,
        loadingAddAddress: true,
      }
    case ActionTypes.LOADING_FAIL_ADD_ADDRESS:
      return {
        ...state,
        loadingAddAddress: false,
      }
    case ActionTypes.USER_TOKEN_DATA:
      return {
        ...state,
        token: action.payload,
      }

    case ActionTypes.OPEN_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalOpen: true,
      }
    case ActionTypes.OPEN_LOGIN_CLOSE:
      return {
        ...state,
        isLoginModalOpen: false,
      }
    case ActionTypes.LOGIN_WITH_VALUES:
      return {
        ...state,
        signUpValues: action.payload,
      }
    default:
      return state
  }
}
