const removeExcess = /^mozilla\/\d\.\d\W/
const userAgentData = window.navigator.userAgentData
const userAgent = window.navigator.userAgent

const iosOptions = {
  iphone: /iphone/,
  ipad: /ipad|macintosh/,
}

const androidOptions = {
  android: /android/,
}

const desktopOptions = {
  windows: /win/,
  mac: /macintosh/,
  linux: /linux/
}


const detectPlatform = (isSmallScreen) => {
  let result = "unknown"
  if (userAgent) {
    const ua = userAgent.toLowerCase().replace(removeExcess, '')
    const ios = Object.keys(iosOptions).find(os => iosOptions[os]?.test(ua) && window.navigator.maxTouchPoints >= 1)
    const android = Object.keys(androidOptions).find(os => androidOptions[os]?.test(ua) && window.navigator.maxTouchPoints >= 1)
    const desktop = Object.keys(desktopOptions).find(os => desktopOptions[os]?.test(ua))
    result = ios || android || desktop || "unknown"
  } else if (userAgentData) {
    result = userAgentData.platform.toLowerCase()
  } else {
    result = navigator.platform || "unknown"
  }
  if (result === "unknown") {
    if (isSmallScreen) {
      result += "_mobile"
    } else {
      result += "_desktop"
    }
  }
  return result
}

export default detectPlatform