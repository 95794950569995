/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState, useEffect } from 'react'
import checkIsUserLoggedIn from '../navigation/checkIsUserLoggedIn'
import ProtectedRoutes from '../navigation/ProtectedRoutes'

const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const initialValues = {
    step: 1 || localStorage.getItem('authStep'),
    isLoggedin: false,
    token: null,
    // ... You can declare more initial values here if needed
  }

  const [step, setStep] = useState(initialValues.step)

  const [isLoggedin, setisLoggedin] = useState(initialValues.isLoggedin)
  const [token, setToken] = useState(initialValues.token)

  useEffect(() => {
    // Store step in local storage whenever it changes
    // localStorage.setItem('authStep', step)
    const fetchLoginStatus = async () => {
      const user = await checkIsUserLoggedIn()
      if (user) {
        setToken(user?.signInUserSession?.idToken?.jwtToken)
      }
      ProtectedRoutes(user?.signInUserSession?.idToken?.jwtToken)
    }
    fetchLoginStatus()
  }, [step])

  const contextValue = {
    step,
    setStep,
    setisLoggedin,
    isLoggedin,
    setToken,
    token,
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}
