// DateInput.js
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { AppStyles } from '../../assets/styles/AppStyles'
import { useTheme } from '../../utils/ThemeColorContext'
// import moment from 'moment'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { ArrowHeadLeftIcon, ArrowHeadRightIcon } from '../../assets/AppIcons'

// range function
const range = (start, end, step = 1) => {
  let array = []
  for (let i = start; i <= end; i += step) {
    array.push(i)
  }
  return array
}

// getYear function
const getYear = (date) => {
  return date.getFullYear()
}

// getMonth function
const getMonth = (date) => {
  return date.getMonth()
}

const MyDatePicker = ({ date, onDateChange, error, placeholder, onCalendarOpen = null, openCalendar }) => {
  const { color, isSmallScreen } = useTheme()
  const onHandleClick = (onClick) => {
    setTimeout(() => {
      onClick()
    }, 120)
  }
  const years = range(1900, getYear(new Date()) - 18, 1)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  useEffect(() => {
    const dayHeaders = document.querySelectorAll('.react-datepicker__day-name')
    dayHeaders.forEach((header) => {
      header.setAttribute('data-abbreviation', header.innerText.charAt(0))
    })
  }, [])

  const inputStyle = isSmallScreen ? {
    ...AppStyles.BodyBoldForZoomIssue,
    color: color.BlurpleTeal,
    backgroundColor: color.LightGrey,
    borderColor:  color.BlurpleTeal,
    width: '100%',
    border: 'none',
    padding: '0px',
  } : {
    ...AppStyles.BodyBold,
    color: color.BlurpleTeal,
    backgroundColor: color.LightGrey,
    borderColor: color.BlurpleTeal,
    width: '100%',
    border: 'none',
    padding: '0px',
  }

  const [calendarOpen, setCalendarOpen] = useState(false)

  const ref = useRef(null)
  const openTheCalendar = () => {
    ref.current.setOpen(true)
  }
  useEffect(() => {
    if (openCalendar != null) {
      setTimeout(() => {
        openTheCalendar()
      }, 120)
    }
  }, [openCalendar])

  const CustomInput = forwardRef(({ value, onClick }, innerRef) => {
    return (
    <div
      ref={innerRef}
      style={{
        position: 'relative !important',
        backgroundColor: color.LightGrey,
        borderColor: error
          ? color.SoftPink
          : calendarOpen ? color.Blurple : color.MidGrey,
          }}
      onClick={() => {
        onHandleClick(onClick)
      }}
      className="py-4 px-3 border rounded-2xl h-16 flex flex-col justify-center relative overflow-visible items-start space-y-0 disable-dbl-tap-zoom"
    >
      { date === null ? (
        <p
          style={{
            color: color.Grey,
          }}
        >
          {placeholder}
        </p>
      ) : (
        <div>
          <p
            style={{
              ...AppStyles.Caption3,
              color: color.Grey,
            }}
          >
            {placeholder}
          </p>
          <p
            style={{
              ...inputStyle,
            }}
            className={`px-0 focus:ring-transparent bg-transparent hover:bg-transparent focus:bg-transparent
              placeholder:font-MaisonMedium placeholder:text-14 placeholder:font-medium placeholder:text-TextGrey`}
          >
            {value}
          </p>
        </div>
      )}
    </div>
    )
  })

  return (
    <>
      <DatePicker
        ref={ref}
        onCalendarOpen={() => { 
          setCalendarOpen(true)
          if (onCalendarOpen) {
            onCalendarOpen()
          }
        }}
        onCalendarClose={() => { 
          setCalendarOpen(false)
        }}
        customInput={<CustomInput />}
        placeholderText= { placeholder ? placeholder : "DD / MM / YYYY" }
        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
        <div
          className=' gap-2 flex justify-between items-center px-3 pb-2 space-x-0 rounded-t-2xl'
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className={' w-3 h-3 m-0'}  >
            <ArrowHeadLeftIcon fill={color.Blurple} className={' w-3 h-3'} />
          </button>
          <div className="flex gap-2 w-full">
            <select
              style={{
                ...AppStyles.ButtonMedium,
                color: color.Blurple,
                width: '100%',
              }}
              className="text-center hover:border-borderGrey border-2 bg-white border-borderGrey  px-2 py-3 h-[48px] rounded-xl btn-medium  transition md:block"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              style={{
                ...AppStyles.ButtonMedium,
                color: color.Blurple,
                width: '100%',
              }}
              className=" text-center hover:border-borderGrey border-2 bg-white border-borderGrey px-2 py-3 h-[48px] rounded-xl btn-medium  transition md:block"
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <ArrowHeadRightIcon fill={color.Blurple} className={' w-3 h-3'} />
          </button>
        </div>
        )}
        selected={date ? new Date(date) : ''}
        onChange={(newDate) => {
          onDateChange(newDate)
        }}
        onYearChange={(date) => {
          onDateChange(date)
        }}
        onMonthChange={(date) => {
          onDateChange(date)
        }}
        dateFormat="dd/MM/yyyy"
        showPopperArrow={false}
      ></DatePicker>
    </>
  )
}
export default MyDatePicker
