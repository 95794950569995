import { AppStyles } from '../../../assets/styles/AppStyles'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '../../../utils/ThemeColorContext'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { conversionAPI } from '../../../redux/Actions/postMethods'
import React from 'react'
import DefaultPage from '../DefaultPage'
import manageLoanIcon from '../../../assets/icons/manageLoan2.png'
import manageLoanIconMobile from '../../../assets/icons/ManageLoanMobile.svg'
import CheckInformationWrapper from '../CheckInformationWrapper'
import { getReApply } from '../../../redux/Actions/getMethods'
import iosIcon from '../../../assets/images/Ios.png'
import AndroidIcon from '../../../assets/images/AndroidIcon.png'
import { getAnalytics, logEvent } from 'firebase/analytics'

function FailedOffer() {
  const dispatch = useDispatch()
  const { getApplication, reApply, profile } = useSelector(
    (state) => state.dataReducer,
  )
  const { token } = useSelector((state) => state.authReducer)

  const navigate = useNavigate()
  const { color, isSmallScreen } = useTheme()

  useEffect(() => {
    dispatch(getReApply())
    if (getApplication[0]?.status === 'DECLINED') {
      navigate('/failed-offer')
    } else if (getApplication[0]?.status === 'REFERRED') {
      navigate('/loanreferred')
    } else if (
      getApplication[0]?.status === 'APPROVED' &&
      getApplication[0]?.offer_type === 'UPDRAFT_CREDIT_INTEREST_ONLY'
    ) {
      navigate('/loanapproved')
    } else if (
      getApplication[0]?.status === 'APPROVED' &&
      getApplication[0]?.offer_type !== 'UPDRAFT_CREDIT_INTEREST_ONLY'
    ) {
      navigate('/loanapprovedfee')
    } else {
      navigate('/address-detail')
    }
    // else if (!bank) {
    //   navigate('/verify-income')
    // }
  }, [getApplication[0]?.id])

  // Define the provided date
  const providedDate = new Date(reApply)

  // Get the current date
  const currentDate = new Date()

  // Calculate the difference in milliseconds
  const differenceInMilliseconds =
    providedDate.getTime() - currentDate.getTime()

  // Convert milliseconds to days
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24),
  )

  const analytics = getAnalytics()
  useEffect(() => {
    if (getApplication[0]?.id && getApplication[0]?.status === 'DECLINED' && localStorage.getItem(`declinedEventSent_${getApplication[0]?.id}`) !== 'true') {
      const eventReference = 'decline_loan_application_'
      const eventName = 'Decline Loan Application'
      if (window.fbq) {
        window.fbq('trackCustom', eventName, {
          applicationId: getApplication[0]?.id,
        },
        {eventID: `${eventReference}${getApplication[0]?.id}`}
        )
      }
      dispatch(
        conversionAPI({
          eventName: eventName,
          eventSourceUrl: `${process.env.REACT_APP_FRONTEND_URL}failed-offer`,
          phoneNumber: token?.phone_number,
          email: token?.email,
          fName: token?.given_name,
          lName: token?.family_name,
          cognitoUserId: token?.sub,
          customData: {
            applicationId: getApplication[0]?.id,
          },
          dateOfBirth: token?.birthdate,
          userAgent: navigator.userAgent,
          eventId: `${eventReference}${getApplication[0]?.id}`,
          eventReference: eventReference,
        }),
      )
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          send_to: process.env.REACT_APP_LOAN_DECLINED_GTAG_CONVERSION,
          user_data: {
            email: token?.email,
            phone_number: token?.phone_number,
          },
        })
      }
      logEvent(analytics, 'decline_loan_application_client_side', {
        platform: 'web',
      })
      localStorage.setItem(`declinedEventSent_${getApplication[0]?.id}`, 'true')
    }
  }, [getApplication[0]?.id])

  return (
    <DefaultPage>
      <div className="h-full flex flex-col items-center justify-center space-y-10 ">
        <CheckInformationWrapper img={isSmallScreen ? manageLoanIconMobile : manageLoanIcon}>
          <div className="w-full space-y-10">
            <div className="flex flex-col items-center space-y-6">
              <h1
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Title1,
                        color: color.Plum,
                        height: !isSmallScreen && '32px',
                        width: '279px',
                      }
                    : { ...AppStyles.LargeTitle2, color: color.Plum }
                }
                className=" text-center"
              >
                Sorry, {profile?.first_name}
              </h1>
              <h1
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Title1,
                        color: color.BlurpleTeal,
                        height: !isSmallScreen && '32px',
                        width: '279px',
                      }
                    : { ...AppStyles.LargeTitle2, color: color.BlurpleTeal }
                }
                className=" text-center"
              >
                Unfortunately we can’t offer you Updraft Credit at this time.
                But...
              </h1>
              <h1
                style={{
                  fontSize: isSmallScreen ? '18px' : '20px',
                  fontFamily: 'GilroyBold',
                  lineHeight: '32px',
                  color: '#5D1CD3',
                  width: '376px',
                }}
                className=" text-center"
              >
                {isSmallScreen
                  ? 'You can get the Updraft app'
                  : 'Scan the QR code above to install Updraft on your phone.'}
              </h1>
              <p
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Body,
                        color: color.Grey,
                        margin: 0,
                        marginTop: '16px',
                      }
                    : {
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: '28px',
                        color: '#6C819C',
                        fontFamily: 'MaisonMedium","sans-serif',
                      }
                }
                className="leading-[24px] text-center"
              >
                {!isSmallScreen
                  ? 'You can get your free credit score and report, alongside the ability to manage your spending in one place on our free app.'
                  : 'Enjoy your free credit score and report, alongside the ability to manage your spending in one place.'}
              </p>
              <div
                className="flex justify-center w-full rounded-[12px]"
                style={{
                  background: 'rgba(93, 28, 211, 0.05)',
                  padding: '20px 16px 16px 16px',
                }}
              >
                <span className="reapplyin"> You can reapply in</span>
                <span className="days_90">
                  {reApply ? differenceInDays || '-' : '-'} days
                </span>
              </div>

              <p
                style={{
                  ...AppStyles.Caption4,
                  color: '#6C819C',
                }}
              >
                Available On
              </p>
              <div className="flex md:justify-between md:w-[77px] m-[auto]">
                { isSmallScreen ? (
                  <>
                    <a
                      href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                      target="_blank"
                      className="cursor-pointer w-full space-y-2 "
                      rel="noreferrer"
                    >
                      <img
                        src={iosIcon}
                        style={{
                          width: '32px',
                          height: '25px',
                          objectFit: 'contain',
                        }}
                      />
                    </a>
                    <a
                      href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                      target="_blank"
                      className="cursor-pointer w-full space-y-2 "
                      rel="noreferrer"
                    >
                      <img
                        src={AndroidIcon}
                        style={{
                          width: '32px',
                          height: '25px',
                          objectFit: 'contain',
                        }}
                      />
                    </a>
                  </>
                ) : (
                  <>
                    <div className="w-full space-y-2 ">
                      <img
                        src={iosIcon}
                        style={{
                          width: '32px',
                          height: '25px',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                    <div className="w-full space-y-2 ">
                      <img
                        src={AndroidIcon}
                        style={{
                          width: '32px',
                          height: '25px',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </CheckInformationWrapper>
      </div>
    </DefaultPage>
  )
}

export default FailedOffer
