/* eslint-disable react/prop-types */
import React from 'react'
import { CheckIcon } from '../../assets/AppIcons'
import { useTheme } from '../../utils/ThemeColorContext'
import { AppStyles } from '../../assets/styles/AppStyles'
// import { useSelector } from 'react-redux'

function ContentBankCard({ selectedBank, onClick, isSelected, DirectDebut }) {
  // const { token } = useSelector((state) => state.authReducer)
  const { color } = useTheme()
  // const bank = JSON.parse(localStorage.getItem(`bank-${token?.email}`))
  // const bank = JSON.parse(localStorage.getItem(`bankConfirm-${token?.email}`))

  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: color.LightGrey,
        borderColor: color.MidGrey,
      }}
      className=" border rounded-2xl p-5 space-y-5 cursor-pointer min-h-[152px]"
    >
      <div className="flex justify-between items-center">
        <img
          src={selectedBank?.provider?.logo_uri}
          // src={selectedBank.truelayer_logo_url}
          alt=""
          className=" w-[40px] h-[40px] rounded-full "
        />
        {selectedBank?.id === isSelected?.id && (
          <div
            style={{ background: color.Teal }}
            className=" w-5 h-5 flex items-center justify-center rounded-full"
          >
            <CheckIcon fill={color.White} className={' w-3 h-3'} />
          </div>
        )}
        {DirectDebut && (
          <div
            style={{ background: color.Teal }}
            className=" w-5 h-5 flex items-center justify-center rounded-full"
          >
            <CheckIcon fill={color.White} className={' w-3 h-3'} />
          </div>
        )}
      </div>
      <div style={{ height: '52px' }}>
        <h2
          style={{
            ...AppStyles.Title2,
            color: color.Plum,
            marginBottom: '4px',
          }}
        >
          {selectedBank?.display_name}{' '}
          {/* {selectedBank?.truelayer_display_name} Current Account{" "} */}
        </h2>
        <div className="flex space-x-2">
          <p
            style={{
              ...AppStyles.Body,
              color: color.Grey,
              borderRightColor: color.Tint1,
              // height: '15px',
            }}
            className=" pr-2 border-r "
          >
            {/* {selectedBank?.account_number?.number} */}
            {selectedBank?.number}
          </p>
          <p style={{ ...AppStyles.Body, color: color.Grey }}>
            {selectedBank?.sort_code}
            {/* {selectedBank?.sort} */}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ContentBankCard
