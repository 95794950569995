/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import DefaultPage from '../DefaultPage'
import OutllineButton from '../../../components/buttons/OutllineButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppStyles } from '../../../assets/styles/AppStyles'
import { useTheme } from '../../../utils/ThemeColorContext'
import Modal from '../../../components/Modal'
import { useState } from 'react'
import { InfoIcon } from '../../../assets/AppIcons'
import CustomOTPInput from '../../../components/CustomOtp'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { getApplications, getProfile } from '../../../redux/Actions/getMethods'
import InboxIcon from '../../../assets/icons/Inbox.svg'
import EmailIcon from '../../../assets/icons/Email.svg'
import Thumb from '../../../assets/images/Like.png'
import { isSafari } from '../../../utils/browser'
import SpinnerLarge from '../../../components/SpinnerLarge'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { removeAllGarbage } from '../../../api/dummyData'
import OnEnter from '../../../components/navigation/onEnter'
import captureException from '../../../utils/SentryHelper'

function ConfirmEmail() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.authReducer)
  const { loadingWholeScreen, getApplication } = useSelector(
    (state) => state.dataReducer,
  )
  const [err, setErr] = useState('')
  const [limitErr, setLimitErr] = useState('')
  const [searchParams] = useSearchParams()

  const { color, isSmallScreen } = useTheme()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenNewEmail, setIsModalOpenNewEmail] = useState(false)
  const [otp, setOtp] = useState(new Array(6).fill(''))
  const isValidCode =
    otp.join('').length > 5 && otp.join('') === '000000' ? true : false

  useEffect(() => {
    // if (token?.email) {
    //   localStorage.setItem(`journeyStep-${token?.email}`, '/confirm-email')
    // }
    if (otp.join('').length > 0) {
      setIsModalOpenNewEmail(false)
    }
  }, [token?.email, otp.join('').length > 0])

  const sendVerificationCode = async () => {
    // Assuming you already have the user object and the attribute to verify
    const user = await Auth.currentAuthenticatedUser()
    const attribute = 'email' // Change this to the attribute you want to verify
    try {
      // Send a verification code to the user's email
      const result = await Auth.verifyUserAttribute(user, attribute, {
        platform: 'web',
      })
      return result
      // Handle success, e.g., display a message to the user
    } catch (error) {
      captureException(error)
      console.error('Error sending verification code:', error)
      if (
        error?.message === 'Attempt limit exceeded, please try after some time.'
      ) {
        setLimitErr(error?.message)
        setTimeout(() => {
          setLimitErr('')
        }, 5000)
      } /* else if (error?.message === "") {
        here;
      } */
      // Handle the error, e.g., display an error message to the user
    }
  }

  useEffect(() => {
    if (setOtp(new Array(6).fill(''))) {
      setErr(false)
    }
    if (searchParams.get('code')) {
      confirmAttributeVerificationss(searchParams.get('code'))
    } else {
      sendVerificationCode()
    }
  }, [searchParams.get('code')])

  const confirmAttributeVerificationss = async (otp) => {
    try {
      const confirmationResult = await Auth.verifyCurrentUserAttributeSubmit(
        'email',
        otp,
        {
          platform: 'web',
        },
      )
      if (confirmationResult) {
        const profile = await dispatch(getProfile())
        if (profile) {
          setIsModalOpen(false)
          navigate('/email-verified')
        }
      }
      return confirmationResult
      // Handle success, e.g., display a message to the user
    } catch (error) {
      captureException(error)
      if (
        error?.message ===
        'Invalid verification code provided, please try again.'
      ) {
        setErr(true)
        setOtp(new Array(6).fill(''))
        setTimeout(() => {}, 5000)
      }
      // Handle the error, e.g., display an error message to the user
    }
  }

  // ==================== Checking user status  =======================//
  useEffect(() => {
    if (!getApplication[0]?.id) {
      const temp = async () => {
        dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
        const myID = await dispatch(getApplications())
        if (searchParams.get('code')?.length > 0) {
          console.log('testing')
        } else if (
          myID?.data[0]?.status === 'APPROVED' &&
          myID?.data[0]?.dd_account_number
        ) {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/confirm-email')
          removeAllGarbage()
        } else {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/address-detail')
        }
      }
      temp()
    }
  }, [!getApplication[0]?.id])

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <>
          {/* isModalOpenNewEmail */}
          <div className=" relative container mx-auto md:w-[564px]   space-y-10 h-800 py-8 md:py-0 ">
            <div
              className={`bg-white px-6 py-10 md:p-10  flex flex-col items-center justify-center space-y-6 md:w-[564px] ${
                isModalOpenNewEmail ? 'h-[594px] md:h-[608px]' : ''
              }rounded-[24px]`}
            >
              <img src={InboxIcon} />
              <div className=" flex flex-col items-center space-y-4">
                <h1
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.LargeTitle5,
                          color: color.Plum,
                        }
                      : {
                          ...AppStyles.LargeTitle2,
                          color: color.Plum,
                        }
                  }
                  className=" text-center"
                >
                  Check Your Email
                </h1>
                <p
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Body14,
                          color: color.Grey,
                        }
                      : {
                          ...AppStyles.Body14,
                          color: color.Grey,
                        }
                  }
                  className=" text-center"
                >
                  To verify your identity we've sent an email to
                </p>
                <h3
                  style={{
                    ...AppStyles.Title2,
                    color: color.BlurpleTeal,
                  }}
                  className=" text-center"
                >
                  {token?.email}
                </h3>
              </div>

              <div
                style={{
                  backgroundColor: color.White,
                  borderColor: color.MidGrey,
                  margin: isModalOpenNewEmail
                    ? isSmallScreen && '24px 0 0 0'
                    : '24px 0',
                }}
                className="p-4 border w-full flex rounded-2xl space-x-4  items-start justify-start"
              >
                <div>
                  <InfoIcon fill={color.BlurpleTeal} />
                </div>
                <p
                  style={{
                    ...AppStyles.Body12Demi2_1,
                    color: color.Grey,
                    lineHeight: '20px',
                    ...(isSafari
                      ? {
                          fontFamily: 'MaisonDemiBold',
                          fontWeight: '500',
                        }
                      : {}),
                  }}
                >
                  Once opened, please look for the code provided and enter it
                  below. Check your spam or junk inboxes as it may have landed
                  there.

                </p>
              </div>

              {isModalOpenNewEmail && (
                <div
                  style={{
                    ...AppStyles.SentNewCode,
                    margin: '24px auto',
                    cursor: 'pointer',
                    width: !isSmallScreen ? '360px' : '279px',
                  }}
                >
                  <img src={Thumb} className="w-[16px] h-[16px]" />
                  <p
                    style={{
                      ...AppStyles.SentNewCodeText,
                      ...(isSafari
                        ? { fontFamily: 'MaisonDemiBold', fontWeight: '500' }
                        : {}),
                    }}
                    className={` text-brightPurple font-maisonMedium rounded-xl btn-medium transition md:block dark:text-dark-darkTeal dark:border-dark-darkBorderGrey`}
                  >
                    We’ve sent you a new email
                  </p>
                </div>
              )}

              <div
                style={{ margin: 0 }}
                className="flex flex-col items-center space-y-2 w-full"
              >
                <div className={'w-full space-y-4'}>
                  <div
                    className=" flex flex-col space-y-6"
                    style={{}}
                  >
                    <div className="w-full max-w-[440px] mx-auto">
                      <CustomOTPInput
                        otp={otp}
                        setOtp={setOtp}
                        isValidCode={err}
                        setErr={setErr}
                      />
                    </div>
                  </div>
                  <div className="space-y-6">
                    <p></p>
                  </div>
                {err && (
                  <p
                    style={{ ...AppStyles.Caption1, color: color.SoftPink }}
                    className=" text-center "
                  >
                    We couldn’t validate your code.
                  </p>
                )}
                  {/* <PrimaryButton
                    disabled={token?.email_verified === true}
                    id={'enter-code-manually-btn'}
                    text={'Enter Code'}
                    onClick={() => {
                      
                      setIsModalOpen(true)
                    }}
                  /> */}
                </div>
                <div className={'w-full space-y-2'}>
                  <PrimaryButton
                    id={'confirm-verification-btn'}
                    disabled={!otp?.every((char) => char !== '') || isValidCode}
                    className={'w-full'}
                    text={'Submit'}
                    onClick={async () => {
                      await confirmAttributeVerificationss(otp.join(''))
                    }}
                  />
                  <OutllineButton
                    id={'send-new-email'}
                    isDisabled={token?.email_verified === true}
                    text={'Send me a new email'}
                    className={`$bg-white w-full`}
                    onClick={async () => {
                      // navigate("/email-verified");
                      const sent = await sendVerificationCode()
                      sent && setIsModalOpenNewEmail(true)
                    }}
                  />
                </div>
                {limitErr && (
                  <p
                    style={{ ...AppStyles.Caption1, color: color.SoftPink }}
                    className=" text-center "
                  >
                    {limitErr}
                  </p>
                )}
                {/* <p onClick={() => setIsModalOpen(true)}>
              <a
                style={{ ...AppStyles.BodyLink, color: color.BlurpleWhite }}
                className="underline cursor-pointer"
              >
                Enter Code
              </a>
            </p> */}
              </div>
            </div>
          </div>
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <div className=" space-y-6">
              <div
                className=" w-full flex flex-col items-center justify-center space-y-6 "
                style={{}}
              >
                <div className="flex w-fit relative items-center justify-center">
                  {/* <Iphone fill={color.BlurpleTeal} /> */}
                  <img src={EmailIcon} />
                  <div
                    style={{ backgroundColor: color.Teal }}
                    className=" absolute top-[0px] right-[5px] rounded-md transform rotate-45 flex items-center justify-center  w-[24px] h-[24px] "
                  >
                    <p className="transform -rotate-45  text-white text-12 font-bold font-maisonBold w-full text-center">
                      1
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <p style={{ ...AppStyles.Title1, color: color.Plum }}>
                    Please enter your code
                  </p>
                  <p style={{ ...AppStyles.Subheading1, color: color.Grey }}>
                    You'll find it in the email we sent
                  </p>
                </div>
              </div>
              <CustomOTPInput
                otp={otp}
                setOtp={setOtp}
                isValidCode={err}
                setErr={setErr}
              />
              {err && (
                <p
                  style={{ ...AppStyles.Caption1, color: color.SoftPink }}
                  className=" text-center "
                >
                  We couldn’t validate your code
                </p>
              )}

              <div className=" space-y-2">
                <OutllineButton
                  id={'cancel-email'}
                  text={'Cancel'}
                  onClick={() => {
                    setOtp(new Array(6).fill(''))
                    setIsModalOpen(false)
                    setIsModalOpenNewEmail(false)
                  }}
                />
                <OnEnter
                  actionFunction={async () => {
                    if (!otp?.every((char) => char !== '') || isValidCode) {
                      return
                    }
                    await confirmAttributeVerificationss(otp.join(''))
                  }}
                />
                <PrimaryButton
                  id={'confirm-verification-btn'}
                  disabled={!otp?.every((char) => char !== '') || isValidCode}
                  className={'w-full'}
                  text={'Submit'}
                  onClick={async () => {
                    await confirmAttributeVerificationss(otp.join(''))
                  }}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </DefaultPage>
  )
}

export default ConfirmEmail
