import { Auth } from 'aws-amplify'

const checkIsUserLoggedIn = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return user
  } catch (error) {
    return null // User is not logged in
  }
}

export default checkIsUserLoggedIn
