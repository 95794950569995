import React, { useEffect, useState } from 'react'
import connectaccout from '../../../assets/images/connectaccout.png'
import TrueLayer from '../../../assets/images/truelayer.svg'
import PageHeader from '../../../components/PageHeader'
import SpinnerLarge from '../../../components/SpinnerLarge'
import BankCard from '../../../components/cards/BankCard'
import SearchInput from '../../../components/inputs/SearchInput'
import DefaultPage from '../DefaultPage'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppStyles } from '../../../assets/styles/AppStyles'
import Spinner from '../../../components/Spinner'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import AddAccount from '../../../components/cards/AddAccount'
import ContentBankCard from '../../../components/cards/ContentBankCard'
import {
  ConnectBank,
  getAccounts,
  getApplications,
  getAvailableBanks,
  getTruelayerTerms,
} from '../../../redux/Actions/getMethods'
import { useTheme } from '../../../utils/ThemeColorContext'
// import { directDebutMehod } from '../../../redux/Actions/postMethods'
import { supportedBanks } from '../../../api/dummyData'

function DirectDebutAddAccounts() {
  // ========================= Redux ===================
  const { loading } = useSelector((state) => state.authReducer)
  const dispatch = useDispatch()
  // ========================= Redux ===================

  const navigate = useNavigate()
  const { terms, dd_accounts } = useSelector((state) => state.dataReducer)
  const { loading2, token } = useSelector((state) => state.authReducer)

  const { color } = useTheme()
  const [step, setstep] = useState(0)
  const [search, setSeach] = useState('')
  const [selectedBanks, setSelectedBanks] = useState([])
  const [selectedBankAccount, setSelectedBankAccount] = useState(null)
  const [connect, setConnect] = useState('')
  const [anOther, setAnOther] = useState(false)

  const addOther =
    localStorage.getItem(`add_accounts-${token?.email}`) === 'true'
  const addedOther = localStorage.getItem('added_account') === 'true'
  useEffect(() => {
    if (addOther) {
      setstep(0)
      setAnOther(true)
    }
  }, [addOther])

  const [accountLoading, setAccountLoading] = useState(false)
  useEffect(() => {
    dispatch(getApplications())
    dispatch(getAvailableBanks())
    if (!addOther) {
      dispatch(getAccounts(setstep, setAccountLoading, addOther, navigate))
      // if (dd_accounts?.length > 0) {
      //   setstep(3)
      //   dispatch(refreshAccounts())
      // }
    } else if (addedOther) {
      navigate('/direct-debit')
      localStorage.removeItem('add_account')
      localStorage.removeItem('added_account')
    }
  }, [dd_accounts?.length > 0])

  useEffect(() => {
    dispatch(getTruelayerTerms())
    if (selectedBanks?.truelayer_id) {
      dispatch(ConnectBank(selectedBanks?.truelayer_id, setConnect))
      localStorage.setItem('added_account', 'true')
    }
  }, [selectedBanks?.truelayer_id])

  const separator =
    'You will connect via a secure service provided by TrueLayer, an account information service provider regulated by the Financial Conduct Authority under the Payment Services Regulations 2017 and Electronic Money Regulations 2011 (firm reference number 901096)'
  let text = terms?.body2

  if (text) {
    const parts = text.split(separator)

    text = parts.map((part, index) => (
      <div key={index}>
        {part}
        {index !== parts.length - 1 && <br />}
      </div>
    ))
  }

  const handleAddAccount = async () => {
    localStorage.setItem(
      `bankConfirm-${token?.email}`,
      JSON.stringify(selectedBankAccount),
    )
    const getApp = await dispatch(getApplications())
    if (getApp) {
      if (getApp?.data[0]?.status === 'DECLINED') {
        navigate('/failed-offer')
      } else {
        navigate('/loanreferred')
        localStorage.setItem(`reffered-${token?.email}`, '/loanreferred')
      }
      // }
    }
  }

  return (
    <DefaultPage>
      {accountLoading || loading ? (
        <SpinnerLarge />
      ) : (
        <>
          <div className="relative container mx-auto md:w-[564px]   md:space-y-10  ">
            <PageHeader
              title={
                anOther
                  ? addOther && step === 1
                    ? 'Select your bank'
                    : 'Link your bank account'
                  : 'Let’s get you a faster  decision by verifying your income'
              }
              description={
                anOther
                  ? addOther && step === 1
                    ? ''
                    : 'Please link the account into which you are paid. You’ll connect it via Open Banking, which means all of your info is safe and secure. It’s trusted by all the major banks, from HSBC to Monzo and plenty of others in between.'
                  : 'Please link the account into which you are paid. You’ll connect it via Open Banking, which means all of your info is safe and secure. It’s trusted by all the major banks, from HSBC to Monzo and plenty of others in between.'
              }
              backButtonTitle={
                addOther
                  ? step === 0
                    ? 'Direct Debit setup'
                    : 'Direct Debit setup'
                  : anOther
                    ? 'Income Verification'
                    : ''
              }
              anOther={anOther}
              setAnOther={setAnOther}
              prevRoute={addOther ? '/direct-debit' : '/monthly-spend'}
              setstep={setstep}
              marginTop={'24px'}
            />
            <div
              style={{
                backgroundColor: color.White,
              }}
              className=" rounded-3xl p-6 space-y-6 "
            >
              {/* {step !== 2 && (
                <SubPageHeader Icon={Links} text={'Link an account'} />
              )} */}
              {step === 0 && (
                <div
                  style={{
                    backgroundColor: color.LightGrey,
                    borderColor: color.MidGrey,
                  }}
                  className="p-5 border overflow-scroll min:h-[400px] rounded-2xl space-y-6  items-center"
                >
                  <img src={TrueLayer} alt="" />
                  <div className=" space-y-4">
                    <p style={{ ...AppStyles.BodyBold, color: color.Plum }}>
                      {terms?.title1}
                    </p>
                    <p style={{ ...AppStyles.Body, color: color.Grey }}>
                      {terms?.body1}
                    </p>
                  </div>
                  <div className=" space-y-4">
                    <p style={{ ...AppStyles.BodyBold, color: color.Plum }}>
                      {terms?.title2}
                    </p>
                    <div
                      style={{
                        ...AppStyles.Body,
                        color: color.Grey,
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {terms?.body2}
                    </div>
                  </div>
                </div>
              )}
              {step === 1 && (
       
                  <div
                    style={{
                      backgroundColor: color.LightGrey,
                      borderColor: color.MidGrey,
                    }}
                    className="  rounded-2xl px-6 pt-6 border space-y-6"
                  >
                    <SearchInput onChange={(e) => setSeach(e.target.value)} />
                    <div style={{ marginRight: '0px'}} className=" space-y-2 pb-6 w-full max-h-[500px] overflow-y-auto hide-scrollbar ">
                      {supportedBanks
                        ?.filter((value) => {
                          if (search == '') {
                            return value
                          } else if (
                            value?.truelayer_display_name
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return value
                          }
                        })
                        ?.slice()
                        ?.sort((a, b) =>
                          a.truelayer_display_name.localeCompare(
                            b.truelayer_display_name,
                          ),
                        )
                        ?.map((bank) => (
                          <BankCard
                            key={bank.id}
                            bank={bank}
                            isSelected={selectedBanks}
                            onClick={() => setSelectedBanks(bank)}
                          />
                        ))}
                    </div>
                  </div>
                
              )}
              {step === 2 && (
                <div className=" space-y-3 w-full flex items-center justify-center">
                  <img src={connectaccout} alt="" />
                </div>
              )}
              {step === 3 && (
                <div className=" space-y-6">
                  {/* <p style={{ ...AppStyles.Body16, color: color.Grey }}>
                    Your Selected Bank(s)
                  </p> */}
                  <div className=" grid grid-cols-1 gap-6  ">
                    {dd_accounts?.map((bank, index) => (
                      <div key={index}>
                        <ContentBankCard
                          selectedBank={bank}
                          isSelected={selectedBankAccount}
                          onClick={() => {
                            setSelectedBankAccount(bank)
                            localStorage.setItem(
                              `bank-${token?.email}`,
                              JSON.stringify(bank),
                            )
                          }}
                        />
                      </div>
                    ))}
                    <AddAccount
                      onClick={() => {
                        setstep(0)
                        setAnOther(true)
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <a
              href="#"
              style={{
                background:
                  'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
              }}
              className=" px-6 pb-6 md:p-0 fixed bottom-0 md:relative  w-full "
            >
              <PrimaryButton
                id={'dd-accounts'}
                disabled={step === 0 ? false : !connect && !selectedBankAccount}
                text={
                  step === 3 ? (
                    loading2 ? (
                      <Spinner />
                    ) : (
                      'Verify My Income'
                    )
                  ) : loading ? (
                    <Spinner />
                  ) : step === 1 ? (
                    loading2 ? (
                      <Spinner />
                    ) : (
                      'Continue'
                    )
                  ) : (
                    'Connect My Account'
                  )
                }
                onClick={() => {
                  step === 0
                    ? setstep(1)
                    : step === 1
                      ? (window.location.href = connect)
                      : step === 2
                        ? setstep(3)
                        : handleAddAccount()
                }}
                className={' md:w-[260px]'}
              />
            </a>
          </div>
        </>
      )}
    </DefaultPage>
  )
}

export default DirectDebutAddAccounts
