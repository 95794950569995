import { ActionTypes } from '../Constants/actionTypes'
import api from '../../api/interceptor'
import apiAddress from '../../api/interceptorAddress'
import { getProfile } from './getMethods'
import { Auth } from 'aws-amplify'
import captureException from '../../utils/SentryHelper'

export const editMyProfile = (data, setErr) => {
  return async (dispatch) => {
    const profileData = {
      customer_income: data[0]?.yearly_salary,
      other_income: data[0]?.other_income
        ? data[0]?.other_income
        : data?.other_income
          ? data?.other_income
          : 0,
      employment_status: data[0]?.status,
      occupation: data[0]?.job_title,
      employer_name: data[0]?.employer_name,
      employment_duration_min_months: data[0]?.time_with_employer,
      // employment_duration_years: data[0]?.time_with_employer / 12,
      // allow_soft_search: true,
      allow_soft_search: data?.allow_soft_search,
      title: data?.title?.value,
      change_in_circumstance: null,
      change_in_circumstance_reason: null,
      expect_expenses_increase: data?.expect_expenses_increase,
      expect_expenses_increase_reason: !data?.expect_expenses_increase
        ? ''
        : data?.expect_expenses_increase_reason,
      expect_change_income: data?.expect_change_income,
      expect_change_income_reason: !data?.expect_change_income
        ? ''
        : data?.expect_change_income_reason,
      first_name: data?.first_name,
      family_name: data?.surname,
      birthday: data?.date || data?.birthday,
      phone: data?.phone,
      address: null,
      past_address: null,
      additional_incomes: {
        pension: {
          amount: null,
          from_source: null,
        },
        something_else: {
          amount: data[0]?.other_income
            ? data[0]?.other_income
            : data?.other_income
              ? data?.other_income
              : 0,
          nature: 'Other income',
          from_source: 'Info not supplied by old app version',
          explanation: 'Info not supplied by old app version',
        },
        investments: {
          amount: null,
          from_source: null,
        },
        another_job: {
          amount: null,
          from_source: null,
        },
        benefits: {
          amount: null,
          from_source: null,
        },
      },
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.post('prerequisites/', profileData)
      return res
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
      // const err = error?.response?.status
      if (error) {
        setErr('Something went wrong, please try again in a little while')
        setTimeout(() => {
          setErr('')
        }, 10000)
        dispatch({ type: ActionTypes.LOADING_FAIL })
      }
    }
  }
}

export const editMyProfileCIC = (data, setErr) => {
  return async (dispatch) => {
    const profileData = {
      expect_expenses_increase: data?.expect_expenses_increase,
      expect_expenses_increase_reason: data?.expect_expenses_increase_reason,
      expect_change_income: data?.expect_change_income,
      expect_change_income_reason: data?.expect_change_income_reason,
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.post('prerequisites/', profileData)
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
      // const err = error?.response?.status
      if (error) {
        setErr('Something went wrong, please try again in a little while')
        setTimeout(() => {
          setErr('')
        }, 10000)
        dispatch({ type: ActionTypes.LOADING_FAIL })
      }
    }
  }
}

export const profileSoftSearch = (data, setErr) => {
  return async (dispatch) => {
    const profileData = {
      allow_soft_search: data?.allow_soft_search,
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.post('prerequisites/', profileData)
      return res
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
      // const err = error?.response?.status
      if (error) {
        setErr('Something went wrong, please try again in a little while')
        setTimeout(() => {
          setErr('')
        }, 10000)
        dispatch({ type: ActionTypes.LOADING_FAIL })
      }
    }
  }
}
export const editMyProfileAfterSignUp = (data) => {
  return async (dispatch) => {
    const profileData = {
      first_name: data?.first_name,
      family_name: data?.surname,
      birthday: data?.birthday,
      title: data?.title?.value,
      phone: data?.phone,
      address: null,
      past_address: null,
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.post('prerequisites/', profileData)
      getProfile()
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const createApplication = (data, setErr) => {
  return async (dispatch) => {
    const payload = {
      reason: data,
      platform: 'web',
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.post('loans/v2/policydecision/', payload)
      dispatch({
        type: ActionTypes.ADD_AND_GET_APPLICATOION,
        payload: res?.data,
      })
      dispatch({ type: ActionTypes.LOADING_FAIL })

      return res?.data?.status
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
      // const err = error?.response?.status
      if (error) {
        setErr('Something went wrong, please try again in a little while')
        setTimeout(() => {
          setErr('')
        }, 10000)
      }
    }
  }
}

export const Creditpolicydecision = (setSoftErr) => {
  return async (dispatch) => {
    const payload = {
      platform: 'web',
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.post('loans/creditpolicydecision/', payload)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res?.data
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      setSoftErr('Something went wrong, please try again in a little while')
      setTimeout(() => {
        setSoftErr('')
      }, 10000)
    }
  }
}

export const createOffer = (setResult, setErr) => {
  return async (dispatch) => {
    const data = {
      acceptable_offer_types: [
        'UPDRAFT_CREDIT_INTEREST_ONLY',
        'UPDRAFT_CREDIT_INTEREST_AND_FEE',
      ],
      platform: 'web',
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.post('loans/offerreview/', data)
      setResult(res?.data)
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
      // ============= Status 504 ==============
      if (error?.response?.status === 504) {
        setTimeout(async () => {
          try {
            dispatch({ type: ActionTypes.LOADING_SUCCESS })

            const res = await api.get('loans/applications/')
            const application = res?.data[0]
            dispatch({ type: ActionTypes.LOADING_FAIL })
            setResult(application)
          } catch (error) {
            dispatch({ type: ActionTypes.LOADING_FAIL })
            console.log({ error })
            setErr(error)
          }
        }, 3000)
      } else {
        captureException(error)
        setErr('Something went wrong, please try again in a little while')
        setTimeout(() => {
          setErr('')
        }, 10000)
      }
    }
  }
}

export const getOffer = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get('loans/offerreview/')
      dispatch({ type: ActionTypes.LOADING_FAIL })
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const getAddress = (setValues) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.get('prerequisites/addresses/')
      dispatch({ type: ActionTypes.LOADING_FAIL })
      setValues(res.data?.address)
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const addAddresses = (data) => {
  return async (dispatch) => {
    const payload = {
      address: {
        postal_county: data?.present?.postal_county,
        postcode: data?.present?.post_code_manual,
        town: data?.present?.town,
        traditional_county: data?.present?.traditional_county,
        department_name:
          data?.present?.department_name === 'undefined'
            ? ''
            : data?.present?.department_name,
        organisation_name: data?.present?.organisation_name,
        udprn: data?.present?.udprn,
        dps: data?.present?.dps,
        line_1: data?.present?.house
          ? `${data?.present?.house} ${data?.present?.line_1}`
          : data?.present?.line_1,
        line_2:
          data?.present?.line_2 === 'undefined' ? '' : data?.present?.line_2,
        line_3: data?.present?.line_3,
        years_resident: data?.present?.time_at_address?.value,
        status: data?.present?.status?.value,
        region: data?.present?.region,
      },
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_ADD_ADDRESS })
      const res = await api.post('prerequisites/addresses/', payload)
      dispatch({ type: ActionTypes.LOADING_FAIL_ADD_ADDRESS })
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL_ADD_ADDRESS })
      console.log({ error })
    }
  }
}
export const addPastAddresses = (data) => {
  return async (dispatch) => {
    const payload = {
      past_address: {
        postal_county: data?.past?.postal_county,
        postcode: data?.past?.post_code_manual,
        town: data?.past?.town,
        traditional_county: data?.past?.traditional_county,
        department_name:
          data?.past?.department_name === 'undefined'
            ? ''
            : data?.past?.department_name,
        organisation_name: data?.past?.organisation_name,
        udprn: data?.past?.udprn,
        dps: data?.past?.dps,
        // line_1: data?.past?.line_1,
        line_1: data?.past?.house
          ? `${data?.past?.house} ${data?.past?.line_1}`
          : data?.past?.line_1,
        line_2: data?.past?.line_2 === 'undefined' ? '' : data?.past?.line_2,
        line_3: data?.past?.line_3,
        years_resident: data?.past?.time_at_address?.value,
        status: data?.past?.status?.value,
        region: data?.past?.region,
      },
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_ADD_ADDRESS })
      const res = await api.post('prerequisites/addresses/', payload)
      dispatch({ type: ActionTypes.LOADING_FAIL_ADD_ADDRESS })
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL_ADD_ADDRESS })
      console.log({ error })
    }
  }
}

export const directDebutMehod = (id, data, setErr) => {
  return async (dispatch) => {
    const payload = {
      dd_signature: data?.dd_signature ? data?.dd_signature : 'a',
      dd_account_number: data?.number,
      dd_account_name: data?.provider?.display_name,
      dd_sort_code: data?.sort_code,
    }
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS2 })
      const res = await api.put(
        `loans/applications/${id}/directdebit/`,
        payload,
      )
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      console.log({ error })
      setErr('Please try again later something went wrong')
      setTimeout(() => {
        setErr('')
      }, 5000)
    }
  }
}

export const conversionAPI = (data) => {
  return async (dispatch) => {
    try {
      const res = await api.post('marketing/conversion/', data)
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      console.log({ error })
    }
  }
}

export const getAddressDetails = (
  postCode,
  setApiPostCodeOptions,
  // setIsModalOpen,
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS2 })
      const res = await apiAddress.post('/rapidaddress', {
        key: '525cb-4f0f7-43a6a-02d26',
        postcode: postCode,
        response: 'data_formatted',
        sort: 'asc',
      })
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      dispatch({ type: ActionTypes.POSTCODE_ERROR, payload: '' })
      dispatch({ type: ActionTypes.GET_RAPID_ADDRESSES, payload: res?.data })
      if (res?.data?.delivery_points?.length) {
        let delivery_points_array = []
        res.data.delivery_points.map((item) => {
          return delivery_points_array.push({
            label: item.line_1,
            id: item.line_1,
            label2: item?.line_2,
            town: res?.data?.town,
            postal_county: res.data?.postal_county,
            traditional_county: res.data?.traditional_county,
            postcode: res?.data?.postcode,
            department_name: item?.department_name,
            organisation_name: item?.organisation_name,
            udprn: item?.udprn,
            dps: item?.dps,
            error_code: res?.data?.error_code,
            error_message: res?.data?.error_message,
            line_1: item?.line_1,
            line_2: item?.line_2,
            line_3: item?.line_3,
            delivery_point_count: res?.data?.delivery_point_count,
            region: res?.data?.region,
          })
        })
        delivery_points_array &&
          delivery_points_array.length &&
          setApiPostCodeOptions(delivery_points_array)
        // setIsModalOpen(true)
      } else {
        setApiPostCodeOptions([])
        dispatch({
          type: ActionTypes.POSTCODE_ERROR,
          payload:
            'Unable to find postcode, please enter your postcode and try again. Alternatively please enter your address manually.',
        })
      }
      // setIsModalOpen(true)
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
    }
  }
}

export const SendOtpForgot = (
  data,
  setInvalidEmailForForgot,
  setopenForgetPassModal,
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS2 })
      const res = await Auth.forgotPassword(data)
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      console.log({ error })
      if (error?.message === 'Username/client id combination not found.') {
        setInvalidEmailForForgot('Please try again with valid email')
        setTimeout(() => {
          setInvalidEmailForForgot('')
        }, 5000)
      } else {
        setInvalidEmailForForgot('Please try again later something went wrong ')
        setTimeout(() => {
          setopenForgetPassModal(false)
        }, 5000)
      }
    }
  }
}

export const createNewPass = (
  data,
  setCreateNewPassErr,
  setopenNewPassModal,
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS2 })
      const res = await Auth.forgotPasswordSubmit(
        data?.email.toLowerCase(),
        data?.otp,
        data?.password,
      )
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      return res
    } catch (error) {
      if (
        error?.message ===
        'Password does not conform to policy: Password not long enough'
      ) {
        setCreateNewPassErr(error?.message)
        dispatch({ type: ActionTypes.LOADING_FAIL2 })
        console.log({ error })
        setTimeout(() => {
          setCreateNewPassErr('')
        }, 5000)
      } else {
        captureException(error)
        setCreateNewPassErr('something went wrong try again later.')
        setTimeout(() => {
          setCreateNewPassErr('')
          setopenNewPassModal(false)
        }, 5000)
      }
    }
  }
}

export const updateMarketingData = (data, partnerOffer, promotions) => {
  const payload = [
    {
      id: data[0]?.id,
      granted: promotions,
    },
    {
      id: data[1]?.id,
      granted: partnerOffer,
    },
  ]
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await api.put(`marketing/permissions/`, payload)
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      return res
    } catch (error) {
      captureException(error)
      dispatch({ type: ActionTypes.LOADING_FAIL })

      dispatch({ type: ActionTypes.LOADING_FAIL })
      console.log({ error })
    }
  }
}

export const downloadPdf = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS2 })
      const res = await api.post(`generate-pdf`, data)
      window.open(res?.data?.pdfUrl)
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      console.log({ error })
    }
  }
}
