/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useTheme } from '../../utils/ThemeColorContext'
import { AppStyles } from '../../assets/styles/AppStyles'

function Textarea({ placeholder, type, onChange, name, value, customStyle }) {
  const { color } = useTheme()
  const [isFocused, setIsFocused] = useState(false)
  console.log(isFocused)
  return (
    <div className="relative w-full">
      <textarea
        id={name}
        style={{
          ...AppStyles.BodyBold,
          color: color.BlurpleTeal,
          backgroundColor: color.LightGrey,
          borderColor: color.Tint3,
          ...customStyle,
        }}
        className={`border rounded-2xl w-full px-4 py-7  focus:border-brightPurple focus:outline-none transition duration-150  placeholder:font-maisonMedium`}
        type={type}
        onChange={(e) => onChange(e)}
        name={name}
        value={value}
        rows="2"
        cols="5"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
      />
    </div>
  )
}

export default Textarea
