/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BankIcon } from '../../../assets/AppIcons'
import { useTheme } from '../../../utils/ThemeColorContext'
import { AppStyles } from '../../../assets/styles/AppStyles'
import { useDispatch, useSelector } from 'react-redux'

import PageHeader from '../../../components/PageHeader'
import SubPageHeader from '../../../components/SubPageHeader'
import DefaultPage from '../DefaultPage'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import ContentBankCard from '../../../components/cards/ContentBankCard'
import AddAccount from '../../../components/cards/AddAccount'
import apiTransaction from '../../../api/interceptorTransaction'
import SpinnerLarge from '../../../components/SpinnerLarge'
import { getApplications } from '../../../redux/Actions/getMethods'
import { ActionTypes } from '../../../redux/Constants/actionTypes'

function DirectDebut() {
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.authReducer)
  const [selectedBankAccount, setSelectedBankAccount] = useState(null)
  const [loading, setLoading] = useState(false)
  const { getApplication, loadingWholeScreen, profile } = useSelector(
    (state) => state.dataReducer,
  )
  const { color, isSmallScreen } = useTheme()
  const [accounts, setAccounts] = useState([])
  const navigate = useNavigate()
  const [selectedBanks, setSelectedBanks] = useState([])

  const bank = JSON.parse(localStorage.getItem(`bankConfirm-${token?.email}`))

  useEffect(() => {
    if (bank) {
      setSelectedBankAccount(bank)
    }
  }, [])

  useEffect(() => {
    const bank = JSON.parse(localStorage.getItem(`bank-${token?.email}`))
    if (bank) {
      setSelectedBanks(bank)
    }
    getAccountsonDD()
  }, [])
  const getAccountsonDD = async () => {
    try {
      setLoading(true)
      await apiTransaction.get('profile/dd_accounts/')
      const res = await apiTransaction.get('profile/accounts/refresh/')
      let temp = res?.data?.filter((i) => i?.provider.display_name !== 'mock')
      const mydata = [
        ...temp,
        JSON.parse(localStorage.getItem(`bank-${token?.email}`)),
      ]
      setAccounts(res?.data)
      setLoading(false)
      return res?.data
    } catch (error) {
      setLoading(false)
      console.log({ error })
    }
  }

  // ==================== Checking user status  =======================//
  useEffect(() => {
    if (!getApplication[0]?.id) {
      const temp = async () => {
        dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
        const myID = await dispatch(getApplications())
        if (
          myID?.data[0]?.status === 'APPROVED' &&
          myID?.data[0]?.first_payment_date
        ) {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/direct-debit')
        } else {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })

          navigate('/address-detail')
        }
      }
      temp()
    }
  }, [!getApplication[0]?.id])

  return (
    <DefaultPage>
      {loading || loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <>
          <div className="relative container mx-auto md:w-[564px]   md:space-y-10  ">
            <PageHeader
              title={'Direct Debit setup'}
              customDescription={
                <p
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Body,
                          color: color.Grey,
                        }
                      : {
                          ...AppStyles.Subheading1,
                          color: color.Grey,
                        }
                  }
                >
                  <span style={{ color: color.BlurpleTeal }}>
                    {' '}
                    {profile?.first_name}
                  </span>
                  , please select the account you want to use for your Direct 
                  Debit. As you can see below, we have the account linked used
                  for your income verification. If you don’t want to use this
                  account for your Direct Debit, please add another account.
                </p>
              }
            />

            <div
              style={{ backgroundColor: color.White }}
              className="rounded-3xl p-6 space-y-6  "
            >
              <SubPageHeader Icon={BankIcon} text={'Linked accounts'} />
              {/* <p style={{ ...AppStyles.Body16, color: color.Grey }}>
                Your selected bank(s)
              </p> */}
              <div className=" space-y-3">
                <div className=" grid grid-cols-1  gap-6  ">
                  {accounts?.map((bank, index) => {
                    const temp = JSON.parse(
                      localStorage.getItem(`bankConfirm-${token?.email}`),
                    )
                    return (
                      <div key={index}>
                        <ContentBankCard
                          selectedBank={bank ? bank : temp}
                          isSelected={
                            selectedBankAccount ? selectedBankAccount : temp
                          }
                          onClick={() => {
                            setSelectedBankAccount(bank)
                            localStorage.setItem(
                              `bank-${token?.email}`,
                              JSON.stringify(bank),
                            )
                            localStorage.setItem(
                              `bankConfirm-${token?.email}`,
                              JSON.stringify(bank),
                            )
                          }}
                        />
                      </div>
                    )
                  })}
                  <AddAccount
                    onClick={async () => {
                      await localStorage.setItem(
                        `add_accounts-${token?.email}`,
                        'true',
                      )
                      // navigate('/verify-income')
                      navigate('/direct-debit-account')
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: isSmallScreen && '24px',
                background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
              }}
              className={
                isSmallScreen ? (
                  `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
                ) : (
                  `px-6 pb-6 md:p-0 fixed bottom-0 md:relative  w-full `
                )
              }
            >
              {isSmallScreen && (
                <>
                  <div
                    className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
                  ></div>
                  <div
                    className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
                  />
                </>
              )}
              <PrimaryButton
                id={'direct-debit-btn'}
                text={'Use This Account'}
                disabled={!selectedBankAccount}
                onClick={() => {
                  localStorage.setItem(
                    'SelectedBank',
                    JSON.stringify(selectedBankAccount),
                  )
                  localStorage.removeItem(`added_account`)
                  navigate('/direct-debit-guarantee')
                }}
                className={' md:w-[260px]'}
              />
            </div>
          </div>
        </>
      )}
    </DefaultPage>
  )
}

export default DirectDebut
