import React, { useState } from 'react'
import { BriefcaseIcon } from '../../../assets/AppIcons'
import PageHeader from '../../../components/PageHeader'
import { useTheme } from '../../../utils/ThemeColorContext'
import RadioGroupInput from '../../../components/inputs/RadioGroupInput'
import FormInput from '../../../components/inputs/FormInput'
import SubPageHeader from '../../../components/SubPageHeader'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import OutllineButton from '../../../components/buttons/OutllineButton'
import Modal from '../../../components/Modal'
import ModalHeader from '../../../components/ModalHeader'
import DefaultPage from '../DefaultPage'
import { useNavigate } from 'react-router'
import { AppStyles } from '../../../assets/styles/AppStyles'
import FormSelect from '../../../components/inputs/FormSelect'
import { useDispatch, useSelector } from 'react-redux'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { useEffect } from 'react'
import { editMyProfile } from '../../../redux/Actions/postMethods'
import Spinner from '../../../components/Spinner'
import { getProfile } from '../../../redux/Actions/getMethods'
import numeral from 'numeral'
import RefferedRoute from '../../../navigation/RefferedRoute'
import OnEnter from '../../../components/navigation/onEnter'

function Employment() {
  const { token, loading } = useSelector((state) => state.authReducer)
  const { profile } = useSelector((state) => state.dataReducer)
  const dispatch = useDispatch()
  const { color, isSmallScreen } = useTheme()
  const navigate = useNavigate()
  const [employment_status, setEmploymentStatus] = useState('')
  const [openModal, setopenModal] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [activeIndex, setactiveIndex] = useState(null)
  const [err, setErr] = useState('')

  const [otherIncome, setOtherIncome] = useState(false)
  const [other_income, setOther_income] = useState('')

  const [incomes, setIncomes] = useState([
    {
      employment_status: 'Employed',
      employer_name: '',
      time_with_employer: '',
      job_title: '',
      yearly_salary: '',
    },
  ])

  // useEffect(() => {
  //   if (token?.email) {
  //     localStorage.setItem(`journeyStep-${token?.email}`, '/employment')
  //   }
  // }, [token?.email])

  // ===================== incomes when user add saved in localStorage ==============//
  const filledFromLocalStorage = JSON.parse(
    localStorage.getItem(`employmentFilledForm-${token?.email}`),
  )
  // ===================== Other income when user add saved in localStorage ==============//
  const otherIncomeFromLocal = localStorage.getItem(`other-${token?.email}`)

  useEffect(() => {
    if (filledFromLocalStorage?.length > 0) {
      setIncomes([
        {
          employment_status: filledFromLocalStorage[0]?.employment_status
            ? filledFromLocalStorage[0]?.employment_status
            : 'Employed',
          employer_name: filledFromLocalStorage[0]?.employer_name,
          yearly_salary: filledFromLocalStorage[0]?.yearly_salary,
          job_title: filledFromLocalStorage[0]?.job_title,
          time_with_employer: filledFromLocalStorage[0]?.time_with_employer,
        },
      ])
      setOtherIncome(filledFromLocalStorage[0]?.other_income ? true : false)
      setOther_income(otherIncomeFromLocal === 0 ? '' : otherIncomeFromLocal)
      setEmploymentStatus(
        filledFromLocalStorage[0]?.employment_status
          ? filledFromLocalStorage[0]?.employment_status
          : 'Employed',
      )
    } else if (profile?.id) {
      setIncomes([
        {
          employment_status: profile?.employment_status
            ? profile?.employment_status
            : 'Employed',
          employer_name: profile?.employer_name ? profile?.employer_name : '',
          yearly_salary: profile?.customer_income,
          job_title: profile?.occupation,
          time_with_employer: profile?.employment_duration_min_months,
        },
      ])
      setOtherIncome(profile?.other_income ? true : false)
      setOther_income(profile?.other_income === 0 ? '' : profile?.other_income)
      setEmploymentStatus(
        profile?.employment_status ? profile?.employment_status : 'Employed',
      )
    }
  }, [profile?.id, filledFromLocalStorage?.length > 0])

  const handleChange = (e, index, isSelect = false) => {
    const updatedIncomes = [...incomes]

    if (isSelect) {
      // Assuming e is the option provided by react-select
      updatedIncomes[index]['time_with_employer'] = e ? e.value : ''
    } else {
      updatedIncomes[index][e.target.name] = e.target.value
    }
    setIncomes(updatedIncomes)
    localStorage.setItem(
      `employmentFilledForm-${token?.email}`,
      JSON.stringify(updatedIncomes),
    )
  }

  const radioOptions = [
    { id: 'Employed', label: 'Employed' },
    { id: 'Self-employed', label: 'Self-employed' },
    { id: 'Retired', label: 'Retired' },
  ]
  const timewithemployeroptions = [
    // {
    //   label: "Less than 1 month",
    //   value: "Less than 1 month",
    // },
    {
      label: 'Less than 6 months',
      value: 6,
    },
    {
      label: '1 year +',
      value: 12,
    },
    {
      label: '2 years +',
      value: 24,
    },
    {
      label: '3 years +',
      value: 36,
    },
    {
      label: '5 years +',
      value: 60,
    },
  ]

  const handleRadioChange = (e, index) => {
    const firstValue = e.target.value.split(',')[0].trim()
    const updatedIncomes = [...incomes]
    updatedIncomes[index].employment_status = e.target.value
    updatedIncomes[index].employer_name = ''
    updatedIncomes[index].time_with_employer = ''
    updatedIncomes[index].job_title = ''
    updatedIncomes[index].yearly_salary = ''
    setIncomes(updatedIncomes)
    setEmploymentStatus(firstValue)
    localStorage.removeItem(`employmentFilledForm-${token?.email}`)
  }

  const handleRemoveIncome = () => {
    setOtherIncome(false)
    setOther_income('')
    // const updatedIncomes = incomes.filter((_, index) => index !== activeIndex);
    // setIncomes(updatedIncomes);
    setopenModal(false)
  }

  const handleUpdateProfile = async () => {
    const updatedIncomes = incomes.map((income) => ({
      ...income,
      status: employment_status,
      other_income: other_income,
    }))
    if (updatedIncomes[0].status === 'Retired') {
      updatedIncomes[0].employer_name = 'Retired'
      updatedIncomes[0].job_title = 'Retired'
      updatedIncomes[0].time_with_employer = 6
      updatedIncomes[0].other_income = other_income
    }
    const updated = await dispatch(editMyProfile(updatedIncomes, setErr))
    if (updated) {
      const getPro = await dispatch(getProfile())
      if (getPro) {
        // localStorage.removeItem(`payoff-${token?.email}`)
        navigate('/circumstances')
        dispatch({ type: ActionTypes.EDIT_PROFILE, payload: incomes })
      }
    }
  }

  // ==================== Checking user status  =======================//

  const testing = localStorage.getItem(`payoff-${token?.email}`)
  useEffect(() => {
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      const myID = await dispatch(getProfile())
      // if (payoff === null) {
      //   navigate('/address-detail')
      // }
      // const testing = await localStorage.getItem(`payoff-${token?.email}`)
      if (myID?.data?.employer_name) {
        console.log('inner', testing)
      } else if (!myID?.data?.employer_name && testing?.length > 0 === false) {
        navigate('/address-detail')
      }

      dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
    }
    temp()
  }, [])

  return (
    <DefaultPage>
      <RefferedRoute />
      <div className="relative container mx-auto md:w-[564px]   md:space-y-10">
        <PageHeader
          title={'Your job '}
          backButtonTitle={'Reason for loan'}
          prevRoute={'/payoff'}
          customDescription={
            <p
              style={
                isSmallScreen
                  ? {
                    ...AppStyles.Body,
                    color: color.Grey,
                  }
                  : {
                    ...AppStyles.Subheading1,
                    color: color.Grey,
                  }
              }
            >

              <span style={{ color: color.BlurpleTeal }}> {profile?.first_name}</span>
              , please tell us about your personal income.
            </p>
          }
        />
        <div
          style={{
            backgroundColor: color.White,
          }}
          className={` rounded-3xl p-6  space-y-6 `}
        >
          {incomes.map((income, index) => (
            <>
              {isSmallScreen && incomes?.length > 0 && index > 0 && (
                <div
                  style={{ margin: '24px 0' }}
                  className="border-b-[1px] m-0"
                ></div>
              )}
              <div
                style={{
                  borderColor: color.MidGrey,
                  margin: isSmallScreen && incomes.length > 0 && '0',
                  padding: isSmallScreen && incomes.length > 0 && '0',
                }}
                className={` ${index !== 0 ? 'pt-4' : ''}   ${index !== incomes.length - 1 && !isSmallScreen
                    ? 'border-b pb-10'
                    : ''
                  }  space-y-6 `}
              >
                <div className="flex justify-between items-center w-full">
                  <SubPageHeader
                    Icon={BriefcaseIcon}
                    text={
                      index === 0 ? 'Employment' : `Additional income ${index}`
                    }
                  />

                  {index !== 0 && ( // Do not show the remove button for the first set
                    <p
                      style={{ ...AppStyles.Body16Demi, color: color.SoftPink }}
                      className="cursor-pointer"
                      onClick={() => {
                        setactiveIndex(index)
                        setopenModal(true)
                      }}
                    >
                      Remove
                    </p>
                  )}
                </div>
                <div className="w-full flex flex-col space-y-6 md:space-y-6 ">
                  <div className=" rounded-xl w-full">
                    <RadioGroupInput
                      options={radioOptions}
                      name={`radioGroup-${index}`} // Ensure unique name per set
                      onChange={(e) => handleRadioChange(e, index)}
                      selectedValue={income.employment_status}
                    />
                  </div>
                  {employment_status !== 'Retired' ? (
                    <>
                      <div className="w-full flex flex-col space-y-6 ">
                        {/* <DateInput/> */}
                        <div className="flex flex-col justify-end space-y-6 w-full">
                          <FormInput
                            placeholder={
                              employment_status == 'Self-employed'
                                ? 'Business name'
                                : 'Employer name (As shown on your bank statement)'
                            }
                            type={'text'}
                            onChange={(e) => handleChange(e, index)}
                            name={'employer_name'}
                            value={income?.employer_name}
                          />
                        </div>
                        <div className="flex flex-col justify-end space-y-6 w-full">
                          <FormInput
                            placeholder={'Job title'}
                            type={'text'}
                            onChange={(e) => handleChange(e, index)}
                            name={'job_title'}
                            autoComplete={'organization-title'}
                            value={income?.job_title}
                          />
                        </div>
                      </div>
                      <div className="w-full flex flex-col space-y-6 ">
                        {/* <DateInput/> */}
                        <div className="flex flex-col justify-end space-y-6 w-full">
                          <FormSelect
                            calIcon={true}
                            options={timewithemployeroptions}
                            placeholder={'Time with employer '}
                            label={'Time with employer'}
                            type={'select'}
                            onChange={(e) => handleChange(e, index, true)}
                            name={'time_with_employer'}
                            value={timewithemployeroptions.find(
                              (opt) => opt.value === income.time_with_employer,
                            )}
                          // value={values.residence_type}
                          />
                        </div>
                        <div className="flex flex-col justify-end space-y-6 w-full">
                          <FormInput
                            leftSign={true}
                            placeholder={
                              income.employment_status == 'Self-employed'
                                ? 'How much do you pay yourself each year'
                                : 'Yearly salary'
                            }
                            type={'number'}
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(/,/g, '')
                              handleChange(e, index)
                            }}
                            name={'yearly_salary'}
                            // value={income.yearly_salary}
                            value={
                              !income.yearly_salary
                                ? ''
                                : numeral(income.yearly_salary).format('0,0') ||
                                ''
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="w-full  ">
                      <FormInput
                        leftSign={true}
                        placeholder={
                          income.employment_status == 'Self-employed'
                            ? 'How much do you pay yourself each year'
                            : 'Yearly income'
                        }
                        type={'number'}
                        // onChange={(e) => handleChange(e, index)}
                        onChange={(e) => {
                          e.target.value = e.target.value.replace(/,/g, '')
                          handleChange(e, index)
                        }}
                        name={'yearly_salary'}
                        // value={income.yearly_salary}
                        value={
                          !income.yearly_salary
                            ? ''
                            : numeral(income.yearly_salary).format('0,0') || ''
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          ))}
          <div
            style={{ marginTop: '40px' }}
            className={`${otherIncome && 'border-t pt-[40px]'}`}
          >
            {!otherIncome && (
              <div style={{ marginTop: isSmallScreen && '16px' }}>
                <OutllineButton
                  id={'additional-income'}
                  text={'Add Additional Income'}
                  onClick={() => setOtherIncome(true)}
                />
              </div>
            )}
            {incomes.map((income, index) => (
              <>
                {isSmallScreen && incomes?.length > 0 && index > 0 && (
                  <div
                    style={{ margin: '24px 0' }}
                    className="border-b-[1px] m-0"
                  ></div>
                )}
                <div
                  style={{
                    borderColor: color.MidGrey,
                    margin: isSmallScreen && incomes.length > 0 && '0',
                    padding: isSmallScreen && incomes.length > 0 && '0',
                  }}
                  className={` ${index !== 0 ? 'pt-4' : ''}   ${index !== incomes.length - 1 && !isSmallScreen
                      ? 'border-b pb-10'
                      : ''
                    }  space-y-6 `}
                >
                  {otherIncome && (
                    <>
                      <div className="flex justify-between items-center w-full">
                        <SubPageHeader
                          Icon={BriefcaseIcon}
                          text={`Additional income `}
                        />

                        <p
                          style={{
                            ...AppStyles.Body16Demi,
                            color: color.SoftPink,
                          }}
                          className="cursor-pointer"
                          onClick={() => {
                            setopenModal(true)
                          }}
                        >
                          Remove
                        </p>
                      </div>
                      <div className="w-full flex flex-col ">
                        <>
                         
                          <div className="w-full flex flex-col space-y-6">
                            {/* <DateInput/> */}
                            <div className="flex flex-col justify-end space-y-6 w-full">
                              <FormInput
                                leftSign={true}
                                placeholder={'Additional income'}
                                type={'number'}
                                // onChange={(e) => {
                                //   setOther_income(e.target.value)
                                //   localStorage.setItem(
                                //     `other-${token?.email}`,
                                //     e.target.value,
                                //   )
                                // }}
                                // value={other_income}
                                onChange={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /,/g,
                                    '',
                                  )
                                  // handleChange(e)
                                  localStorage.setItem(
                                    `other-${token?.email}`,
                                    e.target.value,
                                  )
                                  setOther_income(e.target.value)
                                }}
                                value={
                                  !other_income
                                    ? ''
                                    : numeral(other_income).format('0,0') || ''
                                }
                                name={'other_income'}
                              />
                            </div>
                            <div className="flex flex-col justify-end space-y-6 w-full"></div>
                          </div>
                        </>
                      </div>
                    </>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
        <div style={{ ...AppStyles.Caption1, color: color.SoftPink }}>
          {err}
        </div>
        <OnEnter
          actionFunction={() => {
            if (employment_status === 'Retired'
              ? !incomes[0]?.yearly_salary
              : !incomes[0]?.employer_name ||
              !incomes[0]?.job_title ||
              !incomes[0]?.time_with_employer ||
              !employment_status ||
              !incomes[0]?.yearly_salary ||
              (otherIncome && !other_income)
            ) {
              return
            }
            window.heap &&
              window.heap.track('uc_confirm_work_details', {
                action_name: 'tap',
                action_details: 'ucConfirmWorkDetails',
              })
            handleUpdateProfile()
          }}
        />
        <div
          style={{
            marginTop: isSmallScreen && '24px',
            background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
          }}
          className={
            isSmallScreen ? (
              `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex ${
                profile?.id ? 'justify-center items-center' : ''
              } flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
            ) : (
              `px-6 pb-6 md:p-0 fixed bottom-0 md:relative  w-full `
            )
          }
        >
          {isSmallScreen && (
            <>
              <div
                className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
              ></div>
              <div
                className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
              />
            </>
          )}
          <PrimaryButton
            id={'employment-btn'}
            text={loading ? <Spinner /> : 'Continue'}
            disabled={
              employment_status === 'Retired'
                ? !incomes[0]?.yearly_salary
                : !incomes[0]?.employer_name ||
                !incomes[0]?.job_title ||
                !incomes[0]?.time_with_employer ||
                !employment_status ||
                !incomes[0]?.yearly_salary ||
                (otherIncome && !other_income)
            }
            onClick={() => {
              if (loading) {
                return
              }
              window.heap &&
                window.heap.track('uc_confirm_work_details', {
                  action_name: 'tap',
                  action_details: 'ucConfirmWorkDetails',
                })
              handleUpdateProfile()
            }}
            className={' w-full md:w-[260px]'}
          />
        </div>
      </div>
      <Modal isOpen={openModal} onClose={() => setopenModal(false)}>
        <div className=" space-y-6">
          <ModalHeader
            Icon={BriefcaseIcon}
            title={'Remove additional income?'}
            description={
              'Are you sure you want to remove your additional income?'
            }
          />
          <div className=" space-y-2">
            <OutllineButton
              text={'Cancel'}
              id={'cancel-emp'}
              onClick={() => setopenModal(false)}
            />
            <PrimaryButton
              text={'Confirm'}
              id={'confirm-emp'}
              onClick={() => handleRemoveIncome()}
            />
          </div>
        </div>
      </Modal>
    </DefaultPage>
  )
}

export default Employment
