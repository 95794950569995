/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
// ThemeContext.js
import { createContext, useState, useEffect, useContext } from 'react'
import { darkmodeColors, lightmodeColors } from '../assets/styles/AppColors'

export const ThemeColorContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false)
  const [color, setColor] = useState(lightmodeColors)
  const [isSmallScreen, setSmallScreen] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true'
    setDarkMode(savedDarkMode)
  }, [])

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode)
    if (darkMode) {
      setColor(darkmodeColors)
      document.documentElement.classList.add('dark')
    } else {
      setColor(lightmodeColors)
      document.documentElement.classList.remove('dark')
    }
  }, [darkMode])

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <ThemeColorContext.Provider
      value={{ darkMode, setDarkMode, color, isSmallScreen }}
    >
      {children}
    </ThemeColorContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeColorContext)
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}
