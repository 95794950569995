import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/PageHeader'
// import SubPageHeader from '../../../components/SubPageHeader'
import DefaultPage from '../DefaultPage'
import TrueLayer from '../../../assets/images/truelayer.svg'
import BankCard from '../../../components/cards/BankCard'
import SearchInput from '../../../components/inputs/SearchInput'
import connectaccout from '../../../assets/images/connectaccout.png'
import SpinnerLarge from '../../../components/SpinnerLarge'

import { useNavigate } from 'react-router-dom'
// import { Links } from '../../../assets/AppIcons'
import { useTheme } from '../../../utils/ThemeColorContext'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { AppStyles } from '../../../assets/styles/AppStyles'
import ContentBankCard from '../../../components/cards/ContentBankCard'
import AddAccount from '../../../components/cards/AddAccount'
import {
  ConnectBank,
  getAccounts,
  getApplications,
  getAvailableBanks,
  getTruelayerTerms,
  // refreshAccounts,
} from '../../../redux/Actions/getMethods'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner'
import RefferedRoute from '../../../navigation/RefferedRoute'
import OnEnter from '../../../components/navigation/onEnter'

function VerifyIncome() {
  // ========================= Redux ===================
  const { loading } = useSelector((state) => state.authReducer)

  const dispatch = useDispatch()
  // ========================= Redux ===================

  const navigate = useNavigate()
  const { terms, dd_accounts, banks, getApplication } = useSelector(
    (state) => state.dataReducer,
  )
  const { loading2, token } = useSelector((state) => state.authReducer)

  const { color, isSmallScreen } = useTheme()
  const [step, setstep] = useState(0)
  const [search, setSeach] = useState('')
  const [selectedBanks, setSelectedBanks] = useState([])
  const [selectedBankAccount, setSelectedBankAccount] = useState(null)
  const [connect, setConnect] = useState('')
  const [anOther, setAnOther] = useState(false)

  const addOther =
    localStorage.getItem(`add_accounts-${token?.email}`) === 'true'
  const addAnotherAccountFromReferred = localStorage.getItem(`add-another-bank-account-${token?.email}`)
  // const addedOther = localStorage.getItem('added_account') === 'true'
  useEffect(() => {
    if (addOther) {
      setstep(0)
      setAnOther(true)
    } else if (addAnotherAccountFromReferred) {
      setstep(0)
    }
  }, [addOther, addAnotherAccountFromReferred])

  const [accountLoading, setAccountLoading] = useState(false)
  useEffect(() => {
    // dispatch(getApplications())
    dispatch(getAvailableBanks())
    if (!addOther) {
      dispatch(getAccounts(setstep, setAccountLoading, token?.email, addOther, navigate))
    }
  }, [dd_accounts?.length > 0])

  useEffect(() => {
    dispatch(getTruelayerTerms())
    if (selectedBanks?.truelayer_id) {
      dispatch(ConnectBank(selectedBanks?.truelayer_id, setConnect))
    }
  }, [selectedBanks?.truelayer_id])

  const separator =
    'You will connect via a secure service provided by TrueLayer, an account information service provider regulated by the Financial Conduct Authority under the Payment Services Regulations 2017 and Electronic Money Regulations 2011 (firm reference number 901096)'
  let text = terms?.body2

  if (text) {
    const parts = text.split(separator)

    text = parts.map((part, index) => (
      <div key={index}>
        {part}
        {index !== parts.length - 1 && <br />}
      </div>
    ))
  }

  const handleAddAccount = async () => {
    localStorage.setItem(
      `bankConfirm-${token?.email}`,
      JSON.stringify(selectedBankAccount),
    )
    const getApp = await dispatch(getApplications())
    if (getApp) {
      if (getApp?.data[0]?.status === 'DECLINED') {
        navigate('/failed-offer')
      } else {
        navigate('/loanreferred')
      }
      // }
    }
  }

  // ==================== Checking user status  =======================//
  useEffect(() => {
    if (!getApplication[0]?.id) {
      const temp = async () => {
        const myID = await dispatch(getApplications())
        if (myID?.data[0]?.status === 'REFERRED') {
          navigate('/verify-income')
        } else {
          navigate('/address-detail')
        }
      }
      temp()
    }
  }, [!getApplication[0]?.id])

  return (
    <DefaultPage>
      {accountLoading || loading ? (
        <SpinnerLarge />
      ) : (
        <>
          <RefferedRoute />
          <div className="relative container mx-auto md:w-[564px]   md:space-y-10  ">
            <PageHeader
              title={
                anOther
                  ? step === 1
                    ? 'Select your bank'
                    : 'Link your bank account'
                  : step === 1
                    ? 'Select your bank'
                    : step === 3
                      ? 'Income verification'
                      : 'In this last step please help us verify your income'
              }
              description={
                step === 1
                  ? ''
                  : (step === 0) ? (
                    'Just link the account into which it is paid. You’ll connect it via Open Banking, which means all of your info is safe and secure. It’s super fast to do and trusted by all the major banks, from HSBC to Monzo and plenty of others in between.'
                  ) : (
                    'Awesome, just select the account you’d like us to use to run our checks.\nIf you can’t see the account you added yet, try refreshing the page, or add another one.'
                  )
              }
              backButtonTitle={
                addOther ? 'Direct debit' : anOther ? 'Income verification' : ''
              }
              anOther={anOther}
              setAnOther={setAnOther}
              prevRoute={addOther ? '/direct-debit' : '/monthly-spend'}
              setstep={setstep}
            />
            <div
              style={{
                backgroundColor: color.White,
              }}
              className=" rounded-3xl p-6 space-y-6 "
            >
              {/* {step !== 2 && (
                <SubPageHeader Icon={Links} text={'Link an account'} />
              )} */}
              {step === 0 && (
                <div
                  style={{
                    backgroundColor: color.LightGrey,
                    borderColor: color.MidGrey,
                  }}
                  className="p-5 border overflow-scroll min:h-[400px] rounded-2xl space-y-6  items-center"
                >
                  <img src={TrueLayer} alt="" />
                  <div className=" space-y-4">
                    <p style={{ ...AppStyles.BodyBold, color: color.Plum }}>
                      {terms?.title1}
                    </p>
                    <p style={{ ...AppStyles.Body, color: color.Grey }}>
                      {terms?.body1}
                    </p>
                  </div>
                  <div className=" space-y-4">
                    <p style={{ ...AppStyles.BodyBold, color: color.Plum }}>
                      {terms?.title2}
                    </p>
                    <div
                      style={{
                        ...AppStyles.Body,
                        color: color.Grey,
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {terms?.body2}
                    </div>
                  </div>
                </div>
              )}
              {step === 1 && (
                <div className=" space-y-6 ">
                  {/* <p style={{ ...AppStyles.Body16, color: color.Grey }}>
                    Please select your bank from the list belowasd
                  </p> */}
                  <div
                    style={{
                      backgroundColor: color.LightGrey,
                      borderColor: color.MidGrey,
                    }}
                    className="  rounded-2xl px-6 pt-6 border space-y-6"
                  >
                    <SearchInput onChange={(e) => setSeach(e.target.value)} />
                    <div className="space-y-2 pb-6  max-h-[500px] overflow-y-scroll hide-scrollbar">
                      {banks
                        ?.filter((value) => {
                          if (search == '') {
                            return value
                          } else if (
                            value?.truelayer_display_name
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return value
                          }
                        })
                        ?.slice()
                        ?.sort((a, b) =>
                          a.truelayer_display_name.localeCompare(
                            b.truelayer_display_name,
                          ),
                        )
                        ?.map((bank) => (
                          <BankCard
                            key={bank.id}
                            bank={bank}
                            isSelected={selectedBanks}
                            onClick={() => setSelectedBanks(bank)}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className=" space-y-3 w-full flex items-center justify-center">
                  <img src={connectaccout} alt="" />
                </div>
              )}
              {step === 3 && (
                <div className=" space-y-6">
                  {/* <p style={{ ...AppStyles.Body16, color: color.Grey }}>
                    Your selected bank(s)
                  </p> */}
                  <div className=" grid grid-cols-1  gap-6  ">
                    {dd_accounts?.map((bank, index) => (
                      <div key={index}>
                        <ContentBankCard
                          selectedBank={bank}
                          isSelected={selectedBankAccount}
                          onClick={() => {
                            setSelectedBankAccount(bank)
                            localStorage.setItem(
                              `bank-${token?.email}`,
                              JSON.stringify(bank),
                            )
                          }}
                        />
                      </div>
                    ))}
                    <AddAccount
                      onClick={() => {
                        setstep(0)
                        setAnOther(true)
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <OnEnter
              actionFunction={() => {
                if (step === 0 ? false : !connect && !selectedBankAccount) {
                  return
                }
                step === 0
                  ? setstep(1)
                  : step === 1
                    ? (window.location.href = connect)
                    : step === 2
                      ? setstep(3)
                      : handleAddAccount()
              }}
            />
            <div
              style={{
                marginTop: isSmallScreen && '24px',
                background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
              }}
              className={
                isSmallScreen ? (
                  `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
                ) : (
                  `px-6 pb-6 md:p-0 fixed bottom-0 md:relative  w-full `
                )
              }
            >
              {isSmallScreen && (
                <>
                  <div
                    className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
                  ></div>
                  <div
                    className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
                  />
                </>
              )}
              <PrimaryButton
                id={'verify-income-btn'}
                disabled={step === 0 ? false : !connect && !selectedBankAccount}
                text={
                  step === 3 ? (
                    loading2 ? (
                      <Spinner />
                    ) : (
                      'Verify My Income'
                    )
                  ) : loading ? (
                    <Spinner />
                  ) : step === 1 ? (
                    loading2 ? (
                      <Spinner />
                    ) : (
                      'Continue'
                    )
                  ) : (
                    'Connect my account'
                  )
                }
                onClick={() => {
                  if (step === 0) {
                    setstep(1)
                    localStorage.removeItem(`add-another-bank-account-${token?.email}`)
                  } else if (step === 1) {
                    window.location.href = connect
                  } else if (step === 2) {
                    setstep(3)
                  } else {
                    handleAddAccount()
                  }
                  /* step === 0
                    ? setstep(1)
                    : step === 1
                      ? (window.location.href = connect)
                      : step === 2
                        ? setstep(3)
                        : handleAddAccount() */
                }}
                className={' md:w-[260px]'}
              />
            </div>
          </div>
        </>
      )}
    </DefaultPage>
  )
}

export default VerifyIncome
