import React, { useState } from 'react'
import { BankNoteFlyIcon } from '../../../assets/AppIcons'
import { useTheme } from '../../../utils/ThemeColorContext'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getProfile } from '../../../redux/Actions/getMethods'

import PageHeader from '../../../components/PageHeader'
import RadioGroupInput from '../../../components/inputs/RadioGroupInput'
import SubPageHeader from '../../../components/SubPageHeader'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import DefaultPage from '../DefaultPage'
import Spinner from '../../../components/Spinner'
import RefferedRoute from '../../../navigation/RefferedRoute'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import OnEnter from '../../../components/navigation/onEnter'
import { AppStyles } from '../../../assets/styles/AppStyles'

function PayoffOptions() {
  const dispatch = useDispatch()
  const { loading, token } = useSelector((state) => state.authReducer)
  const { getApplication, profile } = useSelector((state) => state.dataReducer)
  const { color, isSmallScreen } = useTheme()
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState('')

  const radioOptions = [
    { id: 'Clear my Overdraft', label: 'Clear my overdraft' },
    { id: 'Pay off my Credit Cards', label: 'Pay off my credit cards' },
    { id: 'Pay off other loans I have', label: 'Pay off other loans I have' },
    { id: 'Consolidate multiple loans', label: 'Consolidate multiple loans' },
  ]

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value)
    localStorage.setItem(`payoff-${token?.email}`, e.target.value)
  }

  // useEffect(() => {
  //   if (token?.email) {
  //     localStorage.setItem(`journeyStep-${token?.email}`, '/payoff')
  //   }
  // }, [token?.email])

  const inLocal = localStorage.getItem(`payoff-${token?.email}`)

  useEffect(() => {
    // localStorage.setItem('journeyStep', '/payoff')
    if (getApplication[0]?.reason) {
      setSelectedOption(getApplication[0]?.reason)
    } else if (inLocal) {
      setSelectedOption(inLocal)
    }
  }, [getApplication[0]?.reason, inLocal])

  const handleCreateApplication = async () => {
    localStorage.setItem('payoff', selectedOption)
    localStorage.setItem(`payoff-${token?.email}`, selectedOption)
    const getPro = await dispatch(getProfile())
    if (getPro) {
      navigate('/employment')
    }
  }

  // ==================== Checking user status  =======================//
  useEffect(() => {
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      const myID = await dispatch(getProfile())
      if (!myID?.data?.address?.id) {
        navigate('/address-detail')
      }
      dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
    }
    temp()
  }, [])

  return (
    <DefaultPage>
      {/* <JourneyRoute /> */}
      <RefferedRoute />
      <div className="relative container mx-auto md:w-[564px] md:space-y-10  ">
        <PageHeader
          title={'Reason for loan'}
          backButtonTitle={'Your address'}
          prevRoute={'/address-details'}
          customDescription={
            <p
              style={
                isSmallScreen
                  ? {
                      ...AppStyles.Body,
                      color: color.Grey,
                    }
                  : {
                      ...AppStyles.Subheading1,
                      color: color.Grey,
                    }
              }
            >
              <span style={{ color: color.BlurpleTeal }}>
                {' '}
                {profile?.first_name}
              </span>
              , please tell us what you’ll be using your loan for.
            </p>
          }
        />
        <div
          style={{ backgroundColor: color.White }}
          className="rounded-3xl p-6 space-y-6"
        >
          <SubPageHeader Icon={BankNoteFlyIcon} text={'Pay off options'} />
          <div className="w-full flex flex-col space-y-6 md:space-y-0 md:flex-row md:space-x-6">
            <div className=" rounded-xl w-full">
              <RadioGroupInput
                options={radioOptions}
                name="radioGroup"
                onChange={handleRadioChange}
                selectedValue={selectedOption}
              />
            </div>
          </div>
        </div>
        <OnEnter
          actionFunction={() => {
            if (!selectedOption) {
              return
            }
            window.heap &&
              window.heap.track('uc_why_credit', {
                action_name: 'tap',
                action_details: 'ucWhyCredit',
              })
            handleCreateApplication()
          }}
        />

        <div
          style={{
            marginTop: isSmallScreen && '24px',
            background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
          }}
          className={
            isSmallScreen ? (
              `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
            ) : (
              `px-6 pb-6 md:p-0 fixed bottom-0 md:relative  w-full `
            )
          }
        >
          {isSmallScreen && (
            <>
              <div
                className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
              ></div>
              <div
                className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
              />
            </>
          )}
          <PrimaryButton
            id={'pay-off'}
            text={loading ? <Spinner /> : 'Continue'}
            disabled={!selectedOption}
            onClick={() => {
              if (loading) {
                return
              }
              window.heap &&
                window.heap.track('uc_why_credit', {
                  action_name: 'tap',
                  action_details: 'ucWhyCredit',
                })
              handleCreateApplication()
            }}
            className={' md:w-[260px]'}
          />
        </div>
      </div>
    </DefaultPage>
  )
}

export default PayoffOptions
