/* eslint-disable react/no-unescaped-entities */
import { AppStyles } from '../../../assets/styles/AppStyles'
import { useTheme } from '../../../utils/ThemeColorContext'

import React, { useEffect } from 'react'
import DefaultPage from '../DefaultPage'
import manageLoanIcon from '../../../assets/icons/manageLoan2.png'
import manageLoanIconMobile from '../../../assets/icons/ManageLoanMobile.svg'
import CheckInformationWrapper from '../CheckInformationWrapper'
import AndroidIcon from '../../../assets/images/AndroidIcon.png'
import iosIcon from '../../../assets/images/Ios.png'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { Account_Management_List } from '../../../redux/Actions/getMethods'
import SpinnerLarge from '../../../components/SpinnerLarge'

function NoActiveLoan() {
  const { color, isSmallScreen } = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { applicationStatus, loadingWholeScreen } = useSelector(
    (state) => state.dataReducer,
  )

  useEffect(() => {
    if (applicationStatus?.status === 'APPLY') {
      navigate('/reapply')
    }
  }, [applicationStatus?.status === 'APPLY'])

  // ==================== Checking user status  =======================//
  useEffect(() => {
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      const get_loan_account_management = await dispatch(
        Account_Management_List(),
      )
      if (
        (get_loan_account_management?.status === 'CLOSED' &&
          get_loan_account_management?.settled_date === null) ||
        (get_loan_account_management?.status === 'CLOSED' &&
          get_loan_account_management?.settled_date !== null)
      ) {
        // window.location.href = `${window.location.origin}/no-active-loan`
        navigate('/no-active-loan')
      } else if (
        get_loan_account_management?.status === 'GOOD' ||
        get_loan_account_management?.status === 'OVERDUE' ||
        get_loan_account_management?.status === 'HOLIDAY' ||
        get_loan_account_management?.status === 'ARRANGEMENT' ||
        get_loan_account_management?.status === 'ARRANGEMENT_OVERDUE' ||
        get_loan_account_management?.status === null
      ) {
        navigate('/active-loan')
      } else {
        navigate('/address-detail')
      }
      dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
    }
    temp()
  }, [])

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <div className="md:mt-0 mt-6 ">
          <CheckInformationWrapper
            img={isSmallScreen ? manageLoanIconMobile : manageLoanIcon}
          >
            <div className="w-full space-y-8 md:space-y-10">
              <div className="flex flex-col items-center space-y-4 md:space-y-6">
                <h1
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Title1,
                          color: color.Plum,
                        }
                      : {
                          ...AppStyles.LargeTitle2,
                          color: color.Plum,
                        }
                  }
                  className="text-center"
                >
                  Welcome back
                </h1>
                <h1
                  style={{
                    fontSize: isSmallScreen ? '18px' : '20px',
                    fontFamily: 'GilroyBold',
                    lineHeight: '32px',
                    color: '#5D1CD3',
                    width: '376px',
                    marginTop: isSmallScreen ? '8px' : '24px',
                  }}
                  className=" text-center"
                >
                  {isSmallScreen
                    ? 'Got the Updraft app?'
                    : 'Scan the QR code above to install Updraft on your phone.'}
                </h1>
                <p
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Body,
                          color: color.Grey,
                          height: '96px',
                        }
                      : {
                          ...AppStyles.Body16,
                          color: color.Grey,
                          lineHeight: '28px',
                        }
                  }
                  className="text-center"
                >
                  As you're not able to check your eligibility right now, it
                  makes sense to head over to the Updraft app to use all of the
                  awesome features there.
                </p>
                <p
                  style={{
                    ...AppStyles.Caption4,
                    color: '#6C819C',
                    marginTop: '40px',
                  }}
                >
                  Available On
                </p>
                <div
                  style={{ marginTop: '12px' }}
                  className="flex md:justify-between md:w-[77px] m-[auto]"
                >
                  { isSmallScreen ? (
                    <>
                      <a
                        href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                        target="_blank"
                        className="cursor-pointer w-full space-y-2 "
                        rel="noreferrer"
                      >
                        <img
                          src={iosIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </a>
                      <a
                        href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                        target="_blank"
                        className="cursor-pointer w-full space-y-2 "
                        rel="noreferrer"
                      >
                        <img
                          src={AndroidIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className="w-full space-y-2 ">
                        <img
                          src={iosIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                      <div className="w-full space-y-2 ">
                        <img
                          src={AndroidIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </CheckInformationWrapper>
        </div>
      )}
    </DefaultPage>
  )
}

export default NoActiveLoan
