import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from './utils/ThemeColorContext'
import { AuthProvider } from './utils/AuthContext'
import { Provider } from 'react-redux'
import Store from './redux'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <AuthProvider>
    <ThemeProvider>
      <Provider store={Store}>
        <App />
      </Provider>
    </ThemeProvider>
  </AuthProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
