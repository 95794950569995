/* eslint-disable react/prop-types */
import React from 'react'
import { useTheme } from '../utils/ThemeColorContext'

function Modal({ isOpen, onClose, children, className }) {
  const { color, isSmallScreen } = useTheme()
  if (!isOpen) return null

  return (
    <div
      className={`fixed h-screen w-full flex inset-0`}
    >
      <div
        style={{ marginTop: isSmallScreen && '0%', position: 'fixed' }}
        className={`flex items-end md:items-center justify-center h-full w-full md:p-10 overflow-y-auto`}
      >
        {/* Background overlay */}
        <div className="fixed inset-0 transition-opacity" onClick={onClose}>
          <div
            style={{ backgroundColor: color.Overlay }}
            className="absolute inset-0"
          ></div>
        </div>

        {/* Modal content */}
        <div
          style={{
            backgroundColor: color.White,
            maxHeight: isSmallScreen && '100vh ',
          }}
          className={
            isSmallScreen ?
            `${className} h-full w-full overflow-auto transform transition-all `
            :
              `${className} h-5/6 w-5/6 px-6 py-10 overflow-auto transform transition-all md:align-middle md:p-10 `
          }
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
