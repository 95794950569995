/* eslint-disable react/prop-types */
import React from 'react'
import TopNavBar from '../../components/navigation/TopNavBar'
import { useTheme } from '../../utils/ThemeColorContext'
import { useLocation } from 'react-router-dom'

function DefaultPage({ children }) {
  const { color } = useTheme()
  const temp = useLocation()

  return (
    <div style={{ backgroundColor: color.LightGrey }} className="min-h-screen ">
      <TopNavBar />

      <div
        style={{
          minHeight: 'calc(100vh - 108px)',
        }}
        className={`mobilecta  bottom-0 w-full setBgForBigScreen md:pt-[64px] pb-[100px]    md:px-6 mt-[72px] md:mt-[0px] md:block lg:cta md:cta ${temp?.pathname !== '/customise-loan' && 'cta'} md:pb-16`}
      >
        {children}
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default DefaultPage
