/* eslint-disable react/no-unescaped-entities */
import { Auth } from 'aws-amplify'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { titleoptions } from '../../../api/dummyData'
import * as Sentry from '@sentry/react'
import {
  ArrowHeadRightIcon,
  DocumentIcon,
  HelpIcon,
  Iphone,
  UserIcon,
} from '../../../assets/AppIcons'
import { AppStyles } from '../../../assets/styles/AppStyles'
import FormInput from '../../../components/inputs/FormInput'
import { doSignUp, resetPhone } from '../../../redux/Actions/authMethod'
import {
  getIntercomHashUser,
  getProfile,
} from '../../../redux/Actions/getMethods'
import {
  conversionAPI,
  editMyProfileAfterSignUp,
} from '../../../redux/Actions/postMethods'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { useAuth } from '../../../utils/AuthContext'
import { useTheme } from '../../../utils/ThemeColorContext'

import Thumb from '../../../assets/images/Like.png'
import CustomOTPInput from '../../../components/CustomOtp'
import Modal from '../../../components/Modal'
import PageHeader from '../../../components/PageHeader'
import Spinner from '../../../components/Spinner'
import SpinnerLarge from '../../../components/SpinnerLarge'
import SubPageHeader from '../../../components/SubPageHeader'
import OutllineButton from '../../../components/buttons/OutllineButton'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import AgreeToTerms from '../../../components/cards/AgreeToTerms'
import DatePicker from '../../../components/inputs/DatePicker'
import FormSelect from '../../../components/inputs/FormSelect'
import OnEnter from '../../../components/navigation/onEnter'
import detectPlatform from '../../../utils/DetectPlatform'
import DefaultPage from '../DefaultPage'
/* import AnalyticsContext from '../../../App' */
import {
  getAnalytics,
  logEvent,
  setUserProperties,
  setUserId,
} from 'firebase/analytics'
import captureException from '../../../utils/SentryHelper'

const initialvalues = {
  title: '',
  first_name: '',
  surname: '',
  date: '',
  email: '',
  phone: '',
  phone2: '',
  phone3: '',
  password: '',
  birthday: '',
}

function AboutYou() {
  const {
    loading,
    loading2,
    token: mtoken,
  } = useSelector((state) => state.authReducer)
  const dispatch = useDispatch()
  const { profile, editProfile, loadingWholeScreen } = useSelector(
    (state) => state.dataReducer,
  )

  const { color, isSmallScreen } = useTheme()
  const { setToken } = useAuth()
  // const isLoggedIn = localStorage.getItem('login')

  const [valid, setValid] = useState(false)
  const [limitFull, setLimitFull] = useState(false)
  const navigate = useNavigate()
  const [values, setValues] = useState(initialvalues)
  const [err, setErr] = useState('')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false)

  const [otp, setOtp] = useState(new Array(6).fill(''))
  const [selectedDate, setSelectedDate] = useState(null)
  const [triedToContinue, setTriedToContinue] = useState(false)
  const [isPhoneResendModalOpen, setIsPhoneResendModalOpen] = useState(false)
  const [scrollToCalendar, setScrollToCalendar] = useState(null)

  const handleDateChange = (selectedDate) => {
    setSelectedDate(selectedDate)
    const date = new Date(selectedDate)
    const birthday = `${date.getFullYear()}-${String(
      date.getMonth() + 1,
    ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
    setValues({
      ...values,
      birthday: birthday
    })
  }
  // ================== Format number =======================//
  const [formattedNumber, setFormattedNumber] = useState('')
  const [formattedNumber2, setFormattedNumber2] = useState('')
  const [formattedNumber3, setFormattedNumber3] = useState('')
  // ================== Format number =======================//
  // ================== Format Date 2023-12-28 =======================//
  const date = new Date(selectedDate)
  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1,
  ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
  // ================== Formate Date 2023-12-28 =======================//

  const handleChange = (e) => {
    const { name, value } = e.target // e.target refers to the input element
    let trimmed = null
    if (name !== 'password') {
      trimmed = value?.trim()
    }
    setValues({
      ...values, // spread the existing values
      [name]: trimmed ? trimmed : value, // override the value for the input's name
    })
  }

  function areAllValuesEmpty(obj) {
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) &&
          (obj[key] !== '' 
            && (key === 'phone' && obj[key] !== '07')
          )
      ) {
        return false
      }
    }
    return true
  }
  useEffect(() => {
    if (!areAllValuesEmpty(values)) {
      sessionStorage.setItem(`user`, JSON.stringify(values))
      sessionStorage.setItem(`birth`, new Date(selectedDate))
    }
  }, [values, selectedDate])

  useEffect(() => {
    if (values?.phone) {
      const phoneNumber = values?.phone
      let formatted = phoneNumber.replace(' ', '').trim()
      if (formatted.length == 14 && formatted?.slice(0, 4) === "0044") {
        formatted = '44' + formatted.slice(4)
      } else if (formatted.length == 13 && formatted?.slice(0, 3) === "+44") {
        formatted = '44' + formatted.slice(3)
      } else if (formatted.length == 12 && formatted?.slice(0, 2) === "44") {
        formatted = '44' + formatted.slice(2)
      } else if (formatted.length == 11 && formatted?.slice(0, 2) === "07") {
        formatted = '44' + formatted.slice(1)
      }
      setFormattedNumber(formatted)
    }
  }, [values?.phone])

  useEffect(() => {
    if (values?.phone2) {
      const phoneNumber = values?.phone2
      let formatted = phoneNumber.replace(' ', '').trim()
      if (formatted.length == 14 && formatted?.slice(0, 4) === "0044") {
        formatted = '44' + formatted.slice(4)
      } else if (formatted.length == 13 && formatted?.slice(0, 3) === "+44") {
        formatted = '44' + formatted.slice(3)
      } else if (formatted.length == 12 && formatted?.slice(0, 2) === "44") {
        formatted = '44' + formatted.slice(2)
      } else if (formatted.length == 11 && formatted?.slice(0, 2) === "07") {
        formatted = '44' + formatted.slice(1)
      }
      setFormattedNumber2(formatted)
    }
  }, [values?.phone2])

  useEffect(() => {
    if (values?.phone3) {
      const phoneNumber = values?.phone3
      let formatted = phoneNumber.replace(' ', '').trim()
      if (formatted.length == 14 && formatted?.slice(0, 4) === "0044") {
        formatted = '44' + formatted.slice(4)
      } else if (formatted.length == 13 && formatted?.slice(0, 3) === "+44") {
        formatted = '44' + formatted.slice(3)
      } else if (formatted.length == 12 && formatted?.slice(0, 2) === "44") {
        formatted = '44' + formatted.slice(2)
      } else if (formatted.length == 11 && formatted?.slice(0, 2) === "07") {
        formatted = '44' + formatted.slice(1)
      }
      setFormattedNumber3(formatted)
    }
  }, [values?.phone3])

  const loadPresetAddress = () => {
    const myData = sessionStorage.getItem(`user`)
    const birth = sessionStorage.getItem(`birth`)
    if (myData) {
      setValues(JSON.parse(myData))
      setSelectedDate(new Date(birth))
    }
  }

  useEffect(() => {
    if (profile?.id) {
      if (profile?.title !== '') {
        setValues({
          title: {
            label: profile?.title,
            value: profile?.title,
          },
          first_name: profile?.first_name,
          surname: profile?.family_name,
        })
      }
      setSelectedDate(new Date(profile?.birthday))
    } else {
      loadPresetAddress()
    }
  }, [profile?.id, mtoken?.email])

  /* const analytics = useContext(AnalyticsContext) */
  const analytics = getAnalytics()

  // ==================== Checking user status  =======================//
  const token = localStorage.getItem('login')

  useEffect(() => {
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      const myID = await dispatch(getProfile())
      if (myID?.data?.id) {
        dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
        navigate('/address-detail')
      }
      dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
    }
    if (token) {
      temp()
    }
  }, [])

  const handleSignUp = async (noErrorMessage) => {
    if (formattedNumber !== formattedNumber2) {
      setTriedToContinue(true)
      return
    }
    window.heap &&
      window.heap.track('signup_completed_info', {
        action_name: 'tap',
        action_details: 'SignUp',
      })
    const signUpPlatform = detectPlatform(isSmallScreen) + '_web'
    const alreadySignedUp = () => {
      dispatch({ type: ActionTypes.OPEN_LOGIN_MODAL })
      dispatch({ type: ActionTypes.LOGIN_WITH_VALUES, payload: values })
    }
    const success = await dispatch(
      doSignUp({
        values,
        selectedDate,
        formattedNumber,
        setErr,
        noErrorMessage,
        signUpPlatform,
        alreadySignedUp
      }),
    )
    if (success) {
      setIsModalOpen(true)
      dispatch({ type: ActionTypes.EDIT_PROFILE, payload: values })
      window.heap &&
        window.heap.track('signup_user_created', {
          action_name: 'tap',
          action_details: 'SignUpUserCreated',
        })
    }
  }
  
  const verified = async () => {
    try {
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('birth')
      window.heap &&
        window.heap.track('signup_verified', {
          action_name: 'tap',
          action_details: 'SignUpVerified',
        })
      setUserProperties(analytics, {
        email: values.email,
        phone: formattedNumber,
        first_name: values.first_name,
        last_name: values.surname,
        title: values.title,
        birthday: formattedDate,
        platform: 'web',
      })
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          send_to: process.env.REACT_APP_REGISTRATION_VERIFIED_GTAG_CONVERSION,
          submit_registration: 'sms',
          user_data: {
            email: values.email,
            phone_number: `+${formattedNumber}`,
          },
        })
      }
      logEvent(analytics, 'registration_verified', {
        submit_registration: 'sms',
        platform: 'web',
      })
      if (window.fbq) {
        window.fbq('trackCustom', 'registration_verified', {
          submit_registration: 'sms',
          platform: 'web',
        })
      }
      // ======================= After Create account then signIn ==================
      const res = await Auth.signIn((values.email || '').toLowerCase(), values.password)
      if (res) {
        setToken(res?.signInUserSession?.idToken?.jwtToken)
        if (window.heap) {
          window.heap.identify(res.signInUserSession.idToken.payload.sub)
          window.heap.addUserProperties({
            email: res?.attributes?.email,
            ldUserId: res?.username,
          })
        }
        const getHashUserId = await dispatch(getIntercomHashUser())
        if (getHashUserId) {
          window.Intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
            name: `${res.attributes?.given_name} ${res.attributes?.family_name}`,
            email: res.attributes?.email,
            phone: res.attributes?.phone_number,
            user_id: res.signInUserSession.idToken.payload.sub,
            user_hash: getHashUserId?.data?.web,
          })
        }
        setUserId(analytics, res.signInUserSession.idToken.payload.sub)
        if (window.gtag) {
          window.gtag('set', {
            user_id: res.signInUserSession.idToken.payload.sub,
          })
        }
        if (window._sva) {
          window._sva.setVisitorTraits({
            email: res.attributes?.email,
            user_id: res.signInUserSession.idToken.payload.sub,
          })
        }
        Sentry.setUser({
          id: res.signInUserSession.idToken.payload.sub,
          username: res.signInUserSession.idToken.payload.sub,
        })
        // ======================= After signUp Edit Profile ==================
        setTimeout(async () => {
          const eventReference = 'user_registered_'
          const eventName = 'User Registered'
          if (window.fbq) {
            window.fbq('init', process.env.REACT_APP_META_ID, {
              em: values.email?.toLowerCase(),
              ph: formattedNumber,
              fn: values.first_name?.toLowerCase(),
              ln: values.surname?.toLowerCase(),
              db: formattedDate,
              external_id: res.signInUserSession.idToken.payload.sub,
            })
            window.fbq('trackCustom', eventName, {
              external_id: res.signInUserSession.idToken.payload.sub,
            },
            {eventID: `${eventReference}${res.signInUserSession.idToken.payload.sub}`}
            )
          }
          const data = {
            eventName: eventName,
            eventSourceUrl: `${process.env.REACT_APP_FRONTEND_URL}about-you`,
            phoneNumber: formattedNumber,
            email: values?.email,
            fName: values?.first_name,
            lName: values?.surname,
            cognitoUserId: res.signInUserSession.idToken.payload.sub,
            customData: { profileStatus: 'Sign Up Verified' },
            dateOfBirth: formattedDate,
            userAgent: navigator.userAgent,
            eventId: `${eventReference}${res.signInUserSession.idToken.payload.sub}`,
            eventReference: eventReference,
          }
          dispatch(conversionAPI(data))
          const profileUpdate = await dispatch(
            editMyProfileAfterSignUp({
              ...editProfile,
              birthday: formattedDate,
            }),
          )
          if (profileUpdate) {
            // setDoFinish(true)
            localStorage.setItem('login', true)
            // navigate('/marketing-permissions')
            navigate('/address-details')
          }
        }, 1000)
        // ======================= After signUp Edit Profile ==================
      }
      // ======================= After Create account then signIn ==================
    } catch (error) {
      setErr(error?.message)
      captureException(error)
      console.log({ error })
    }
  }

  const handleVerification = async (verificationCode) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await Auth.confirmSignUp(values.email?.toLowerCase(), verificationCode, {
        platform: 'web',
      })
      if (res) {
        verified()
      }
    } catch (error) {
      if (error?.code === 'UsernameExistsException') {
        verified()
        return
      } else if (error.code === 'NotAuthorizedException') {
        verified()
        return
      } else if (
        error?.code === 'CodeMismatchException' ||
        error?.code === 'ExpiredCodeException'
      ) {
        captureException(error)
        setErr(error?.message)
      }
      setValid(true)
      dispatch({ type: ActionTypes.LOADING_FAIL })
    }
  }
  // useEffect(() => {
  //   if (otp.join('')?.length === 6) {
  //     handleVerification(otp.join(''))
  //   }
  // }, [otp.join('')?.length === 6])
  const handleUpdateProfile = async () => {
    const data = {
      birthday: formattedDate,
      first_name: values?.first_name,
      surname: values.surname,
      title: values?.title,
    }
    const updated = await dispatch(editMyProfileAfterSignUp(data))
    if (updated) {
      const getPro = await dispatch(getProfile())
      if (getPro) {
        navigate('/address-details')
        dispatch({ type: ActionTypes.LOADING_FAIL2 })
      }
    }
  }

  useEffect(() => {
    if (otp.join('').length < 1) {
      setValid(false)
    } else if (otp.join('').length > 0) {
      setResendToggle(false)
    }
  }, [otp.join('').length < 1, otp.join('').length > 0])

  // ================================= Resend otp ===========================\\
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [resendToggle, setResendToggle] = useState(false)

  const resendOtp = async (userName) => {
    try {
      const res = await Auth.resendSignUp(userName?.toLowerCase())
      if (res) {
        setIsButtonDisabled(true)
        setResendToggle(true)
      }
      return res
    } catch (error) {
      captureException(error)
      console.error('Error resending OTP:', error?.message)
      setLimitFull(true)
      setResendToggle(false)
      setTimeout(() => {
        setLimitFull(false)
        setIsModalOpen(false)
      }, 10000)
    }
  }

  // ================================= Resend otp ===========================\\

  const [resignUp, setReSignUp] = useState(false)

  const handleResetPhone = async () => {
    if (formattedNumber !== formattedNumber2) {
      setTriedToContinue(true)
      return
    }
    const number = formattedNumber3 || formattedNumber
    const regex = /^\+447\d{9}$/
    if (!regex.test(`+${number}`)) {
      setErr('Invalid phone number format - 07XXXXXXXXX')
      setTimeout(() => {
        setErr('')
      }, 10000)
    } else {
      const successPhoneChange = await dispatch(
        resetPhone(values, number, setIsModalOpen, setErr),
      )
      if (successPhoneChange) {
        handleResendSignUp()
      }
    }
  }

  const handleResendSignUp = async () => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS2 })
      const done = await Auth.resendSignUp(values?.email?.toLowerCase())
      if (done) {
        setIsModalOpen(true)
        setReSignUp(false)
        dispatch({ type: ActionTypes.LOADING_FAIL2 })
      }
    } catch (error) {
      captureException(error)
      const prefix = 'CustomMessage failed with error '
      if (error?.message.startsWith(prefix)) {
        setErr(error?.message?.slice(prefix.length))
      } else {
        setErr(error?.message)
      }
      dispatch({ type: ActionTypes.LOADING_FAIL2 })
      setTimeout(() => {
        setErr('')
      }, 5000)
      console.error('Error resending confirmation code', error)
    }
  }

  const bottomRef = useRef(null)
  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const phoneNumberErrorRef = useRef(null)
  const scrollToPhoneNumberError = () => {
    if (phoneNumberErrorRef.current) {
      phoneNumberErrorRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (triedToContinue && formattedNumber !== formattedNumber2) {
      scrollToPhoneNumberError()
    } else if (formattedNumber === formattedNumber2) {
      setTriedToContinue(false)
    }
  }, [triedToContinue, (formattedNumber !== formattedNumber2)])

  useEffect(() => {
    if (err) {
      scrollToBottom()
    }
  }, [err])
  
  useEffect(() => {
    const resendSignUp = async () => {
      if (err === 'User is not confirmed.') {
        handleResendSignUp()
      }
    }
    resendSignUp()
  }, [err === 'User is not confirmed.'])

  const adultDate = new Date().setFullYear(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() + 1)

  const selectDateRef = useRef(null)
  const scrollToSelectDate = () => {
    if (selectDateRef.current) {
      selectDateRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }
  useEffect(() => {
    if (scrollToCalendar != null && isSmallScreen) {
      scrollToSelectDate()
    }
  }, [scrollToCalendar])

  const [openCalendar, setOpenCalendar] = useState(null)
  const surnameRef = useRef(null)

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <>
          <div className="relative container mx-auto md:w-[564px] md:space-y-10 ">
            <PageHeader
              title={'About you'}
              description={
                'A few simple details to get your eligibility check up and running.'
              }
            />
            <div
              style={{
                backgroundColor: color.White,
              }}
              className=" rounded-3xl p-6 space-y-10 "
            >
              {/* personal details */}
              <div className=" space-y-6">
                <div style={{ marginBottom: isSmallScreen && '24px' }}>
                  <SubPageHeader
                    // width={isSmallScreen && '131px'}
                    Icon={HelpIcon}
                    text={'Personal details'}
                  />
                </div>
                <div className="flex flex-col space-y-6 w-full">
                  {localStorage.getItem('login') ? (
                    <>
                      <div className="w-full flex flex-col space-y-6 ">
                        {/* <DateInput/> */}
                        <div className="flex flex-col justify-end space-y-6 w-full">
                          <FormSelect
                            options={titleoptions}
                            placeholder={'Title'}
                            type={'select'}
                            onChange={(val) =>
                              setValues({ ...values, title: val })
                            }
                            name={'title'}
                            value={values.title}
                          />
                        </div>
                        <div className="flex flex-col justify-end space-y-6 w-full">
                          <FormInput
                            placeholder={'First name'}
                            type={'text'}
                            onChange={(e) => handleChange(e)}
                            name={'first_name'}
                            autoComplete={'given-name'}
                            value={values.first_name}
                          />
                        </div>
                      </div>
                      <div className="w-full flex flex-col space-y-6">
                        {/* <DateInput/> */}
                        <div className="flex flex-col justify-end space-y-6 w-full" ref={selectDateRef}>
                          <FormInput
                            placeholder={'Surname'}
                            type={'text'}
                            onChange={(e) => handleChange(e)}
                            name={'surname'}
                            autoComplete={'family-name'}
                            value={values.surname}
                            enterKeyHint={'done'}
                            ref={surnameRef}
                            onKeyDown={(e) => {
                              const inputValue = e.key
                              if (inputValue === 'Enter') {
                                surnameRef.current?.blur()
                                e.preventDefault()
                                setOpenCalendar(!openCalendar)
                              }
                            }}
                          />
                        </div>
                        <div className="flex flex-col justify-end space-y-6 w-full disable-dbl-tap-zoom">
                          <div className="space-y-1">
                            <DatePicker
                              placeholder={'Date of birth'}
                              date={selectedDate}
                              onDateChange={handleDateChange}
                              onCalendarOpen={() => setScrollToCalendar(!scrollToCalendar)}
                              openCalendar={openCalendar}
                            />
                            { selectedDate && selectedDate >= adultDate && (
                              <p style={{ color: '#FF619A' }}>
                              You need to be at least 18 years old to continue
                            </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-full flex flex-col space-y-6 ">
                        <FormSelect
                          options={titleoptions}
                          placeholder={'Title'}
                          type={'select'}
                          onChange={(val) =>
                            setValues({ ...values, title: val })
                          }
                          name={'title'}
                          value={values.title}
                        />
                      </div>
                      <div className="w-full flex flex-col space-y-6 ">
                        <div className="flex flex-col justify-end space-y-6 w-full">
                          <FormInput
                            placeholder={'First name'}
                            type={'text'}
                            onChange={(e) => handleChange(e)}
                            name={'first_name'}
                            autoComplete={'given-name'}
                            value={values.first_name}
                          />
                        </div>
                        <div className="flex flex-col justify-end space-y-6 w-full" ref={selectDateRef}>
                          <FormInput
                            placeholder={'Surname'}
                            type={'text'}
                            onChange={(e) => handleChange(e)}
                            name={'surname'}
                            autoComplete={'family-name'}
                            value={values.surname}
                            enterKeyHint={'done'}
                            ref={surnameRef}
                            onKeyDown={(e) => {
                              const inputValue = e.key
                              if (inputValue === 'Enter') {
                                surnameRef.current?.blur()
                                e.preventDefault()
                                setOpenCalendar(!openCalendar)
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-full flex flex-col space-y-6">
                        <div className="relative flex flex-col justify-end space-y-6 w-full disable-dbl-tap-zoom">
                          <div className="space-y-1">
                            <DatePicker
                              placeholder={`Date of birth`}
                              date={selectedDate}
                              onDateChange={handleDateChange}
                              onCalendarOpen={() => setScrollToCalendar(!scrollToCalendar)}
                              openCalendar={openCalendar}
                            />
                            { selectedDate && selectedDate >= adultDate && (
                              <p style={{ color: '#FF619A' }}>
                                You need to be at least 18 years old to continue
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {!localStorage.getItem('login') && (
                <>
                  <div
                    style={{ backgroundColor: color.MidGrey }}
                    className=" h-px bg-MidGrey  "
                  ></div>
                  <div className=" space-y-6">
                    <div style={{ marginBottom: isSmallScreen && '24px' }}>
                      <SubPageHeader
                        // width={isSmallScreen && '131px'}
                        Icon={UserIcon}
                        text={'Create an account'}
                      />
                    </div>
                    <p
                      style={{
                        ...AppStyles.Subheading1,
                        color: color.Grey,
                      }}
                    >
                      This is to ensure your details are kept safe. You’ll
                      receive a text with a code to confirm your account.
                    </p>
                    <div>
                      <div className="w-full flex flex-col space-y-6 ">
                        {/* <DateInput/> */}
                        <div className="flex flex-col justify-end space-y-6 w-full">
                          <FormInput
                            placeholder={'Email address'}
                            type={'email'}
                            onChange={(e) => handleChange(e)}
                            name={'email'}
                            autoComplete={'email'}
                            value={values.email}
                          />
                        </div>
                        <div className="flex flex-col justify-end space-y-6 w-full">
                          <FormInput
                            placeholder={
                              'Create password (minimum 8 characters)'
                            }
                            type={'password'}
                            onChange={(e) => handleChange(e)}
                            name={'password'}
                            autoComplete={'new-password'}
                            value={values.password}
                          />
                        </div>
                        <div className="flex flex-col justify-end space-y-6 w-full">
                          <FormInput
                            placeholder={'UK mobile phone number'}
                            type={'tel'}
                            onChange={(e) => handleChange(e)}
                            name={'phone'}
                            autoComplete={'tel-national'}
                            value={values.phone}
                          />
                        </div>
                        <div 
                          className="flex flex-col justify-end space-y-6 w-full"
                          ref={phoneNumberErrorRef}
                        >
                          <FormInput
                            placeholder={'Re-enter your phone number'}
                            type={'tel'}
                            onChange={(e) => handleChange(e)}
                            name={'phone2'}
                            autoComplete={'tel-national'}
                            value={values.phone2}
                          />
                        </div>
                        {(triedToContinue && formattedNumber !== formattedNumber2) && (
                          <b style={{ 
                            ...AppStyles.Caption1,
                            color: color.SoftPink,
                            marginTop: '0px',
                            paddingTop: '0px',
                            }}
                          >
                            Phone numbers do not match
                          </b>
                        )}
                        <div className="w-full flex flex-col md:space-y-0 md:flex-row md:space-x-6">
                          <div
                            style={{
                              backgroundColor: color.White,
                              borderColor: color.MidGrey,
                              height: '64px',
                            }}
                            onClick={() =>
                              window.open('https://www.updraft.com/privacy/')
                            }
                            className="cursor-pointer px-4 border rounded-2xl flex space-x-4 w-full "
                          >
                            <div className="flex space-x-4 items-center justify-between  w-full">
                              <div className="flex space-x-4 items-center justify-between">
                                <DocumentIcon fill={color.BlurpleTeal} />
                                <p
                                  style={{
                                    ...AppStyles.BodyBold,
                                    color: color.Plum,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  View our Privacy Policy <br />{' '}
                                </p>
                              </div>
                              <ArrowHeadRightIcon fill={color.BlurpleTeal} />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginTop: '8px' }}
                          className="w-full flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-6"
                        >
                          <div
                            style={{
                              backgroundColor: color.White,
                              borderColor: color.MidGrey,
                              height: '64px',

                              // height: isSmallScreen && '112px',
                            }}
                            onClick={() => {
                              window.open(
                                'https://www.updraft.com/terms-conditions/',
                              )
                            }}
                            className="cursor-pointer px-4 py-5 border rounded-2xl w-full "
                          >
                            <div className="w-full">
                              <div className="flex space-x-4 items-start md:items-center justify-between w-full">
                                <div className="flex space-x-4 items-start md:items-center justify-between">
                                  <DocumentIcon fill={color.BlurpleTeal} />
                                  <p
                                    style={{
                                      ...AppStyles.BodyBold,
                                      color: color.Plum,
                                      display: 'flex',
                                      alignItems: !isSmallScreen && 'center',
                                      justifyContent:
                                        !isSmallScreen && 'center',
                                    }}
                                    className="  md:w-auto w-[227px] "
                                  >
                                    View our Terms of Service{' '}
                                  </p>
                                </div>
                                <div style={{ width: 12, height: 12 }}>
                                  <ArrowHeadRightIcon
                                    fill={color.BlurpleTeal}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: '8px' }}>
                          <AgreeToTerms
                            setIsOn={setAcceptedPrivacy}
                            isOn={acceptedPrivacy}
                            title={
                              'I have read and accept the Privacy Policy & Terms of Service.'
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="w-[90%] md:w-[full]"
              style={{ margin: isSmallScreen && 'auto' }}
            >
              <b style={{ ...AppStyles.Caption1, color: color.SoftPink }}>
                {err}
              </b>
              <b ref={bottomRef} />
            </div>
            <OnEnter
              actionFunction={() => {
                if (localStorage?.getItem('login')) {
                  if (!values.title ||
                    !values.first_name ||
                    !selectedDate ||
                    !values.surname
                  ) {
                    return
                  }
                  handleUpdateProfile()
                } else if (isPhoneResendModalOpen) {
                  if (values?.phone3?.length < 11 || values?.phone3?.length > 11) {
                    return
                  }
                  setOtp(new Array(6).fill(''))
                  if (values?.phone3 !== values?.phone) {
                    handleResetPhone()
                    setIsModalOpen(false)
                    setIsPhoneResendModalOpen(false)
                  } else {
                    // handleSignUp(true);
                    resendOtp(values.email)
                    setIsPhoneResendModalOpen(false)
                  }
                } else if (isModalOpen) {
                  if (!otp?.every((char) => char !== '') || valid) {
                    return
                  }
                  window.heap &&
                    window.heap.track('signup_finished', {
                      action_name: 'tap',
                      action_details: 'SignUpFinished',
                    })
                  handleVerification(otp.join(''))
                } else {
                  if (!values.title ||
                    !values.first_name ||
                    !values.surname ||
                    !values.phone ||
                    !values.phone2 ||
                    (triedToContinue && values.phone.trim() !== values.phone2.trim()) ||
                    !values.email ||
                    !selectedDate ||
                    !acceptedPrivacy ||
                    values.password?.length < 8 ||
                    !values.password
                  ) {
                    return
                  }
                  resignUp ? handleResetPhone() : handleSignUp()
                }
              }}
            />
            <div
              style={{
                marginTop: isSmallScreen && '24px',
                background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
              }}
              className={
                isSmallScreen ? (
                  `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex ${
                    profile?.id ? 'justify-center items-center' : ''
                  } flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
                ) : (
                  `mt-10 md:max-w-[588px] m-[auto] fixed md:relative w-full bottom-0  px-6 pb-6 md:p-0 flex ${
                    profile?.id && 'justify-center items-center'
                  } flex-col-reverse gap-5 md:gap-10 md:flex-row `
                )
              }
            >
              {isSmallScreen && (
                <>
                  <div
                    className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
                  ></div>
                  <div
                    className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
                  />
                </>
              )}
              {localStorage?.getItem('login') ? (
                <PrimaryButton
                  id={'login-in-about-you'}
                  text={loading || loading2 ? <Spinner /> : 'Continue'}
                  disabled={
                    !values.title ||
                    !values.first_name ||
                    !selectedDate ||
                    selectedDate >= adultDate ||
                    !values.surname
                  }
                  onClick={async () => {
                    if (loading || loading2) {
                      return
                    }
                    handleUpdateProfile()
                  }}
                  className={' w-full md:w-[260px]'}
                />
              ) : (
                <>
                  {err ===
                  `Looks like you already have an account with us - login and we'll take you to where you left your application
              ` ? (
                    <PrimaryButton
                      id={'login-in-about-you'}
                      text={loading || loading2 ? <Spinner /> : 'Login'}
                      onClick={() => {
                        if (loading || loading2) {
                          return
                        }
                        dispatch({ type: ActionTypes.OPEN_LOGIN_MODAL })
                      }}
                      className={' w-full md:w-[260px]'}
                    />
                  ) : (
                    <PrimaryButton
                      id={'sign-up'}
                      text={loading || loading2 ? <Spinner /> : 'Continue'}
                      disabled={
                        !values.title ||
                        !values.first_name ||
                        !values.surname ||
                        !values.phone ||
                        !values.phone2 ||
                        (triedToContinue && values.phone.trim() !== values.phone2.trim()) ||
                        !values.email ||
                        !selectedDate ||
                        selectedDate >= adultDate ||
                        !acceptedPrivacy ||
                        values.password?.length < 8 ||
                        !values.password
                      }
                      onClick={async () => {
                        if (loading || loading2) {
                          return
                        }
                        resignUp ? handleResetPhone() : handleSignUp()
                      }}
                      className={' w-full md:w-[260px]'}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <Modal
            isOpen={isModalOpen}
            onClose={() => {
              // setIsModalOpen(false)
              // setReSignUp(true)
            }}
          >
            <div className=" space-y-6">
              <div
                className=" w-full flex flex-col items-center justify-center space-y-6 "
                style={{}}
              >
                <div className="flex w-fit relative items-center justify-center">
                  <Iphone fill={color.BlurpleTeal} />
                  <div
                    style={{ backgroundColor: color.Teal }}
                    className=" absolute top-[0px] right-[5px] rounded-md transform rotate-45 flex items-center justify-center  w-[24px] h-[24px] "
                  >
                    <p className="transform -rotate-45  text-white text-12  font-maisonBold w-full text-center">
                      1
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <p style={{ ...AppStyles.Title1, color: color.Plum }}>
                    We've text you a code
                  </p>
                  <p style={{ ...AppStyles.Subheading1, color: color.Grey }}>
                    Please enter it to verify your phone number
                  </p>
                </div>
              </div>

              <CustomOTPInput otp={otp} setOtp={setOtp} isValidCode={valid} />
              {valid && (
                <p
                  style={{ ...AppStyles.Caption1, color: color.SoftPink }}
                  className=" text-center"
                >
                  Sorry, that code is incorrect
                </p>
              )}
              {limitFull && (
                <p
                  style={{ ...AppStyles.Caption1, color: color.SoftPink }}
                  className=" text-center "
                >
                  Limit exceeded, try some time later
                </p>
              )}
              {resendToggle && (
                <div className=" flex items-center justify-center">
                  <div
                    style={{
                      ...AppStyles.SentNewCode,
                      cursor: 'pointer',
                      width: !isSmallScreen ? 'fit-content' : '100%',
                    }}
                  >
                    <img src={Thumb} className="w-[16px] h-[16px]" />
                    <p
                      style={{
                        ...AppStyles.SentNewCodeText,
                      }}
                      className={` text-brightPurple font-maisonMedium rounded-xl btn-medium transition md:block dark:text-dark-darkTeal dark:border-dark-darkBorderGrey`}
                    >
                      We’ve sent you a new code
                    </p>
                  </div>
                </div>
              )}
              <div className=" space-y-2">
                <OutllineButton
                  id={'new-code-aboutyou'}
                  text={'Send me a new code'}
                  disabled={isButtonDisabled}
                  onClick={async () => {
                    setOtp(new Array(6).fill(''))
                    setIsModalOpen(false)
                    setIsPhoneResendModalOpen(true)
                  }}
                />
                <OutllineButton
                  text={'Change number'}
                  id={'change-number'}
                  onClick={() => {
                    setOtp(new Array(6).fill(''))
                    setIsModalOpen(false)
                    setReSignUp(true)
                    setIsPhoneResendModalOpen(true)
                  }}
                />

                <PrimaryButton
                  id={'handle-verification'}
                  disabled={!otp?.every((char) => char !== '') || valid}
                  // disabled={!otp?.every((char) => char !== '') || !doFinish}
                  className={'w-full'}
                  text={loading ? <Spinner /> : 'Finish'}
                  // text={'Finish'}
                  onClick={() => {
                    if (loading) {
                      return
                    }
                    window.heap &&
                      window.heap.track('signup_finished', {
                        action_name: 'tap',
                        action_details: 'SignUpFinished',
                      })
                    handleVerification(otp.join(''))
                    // localStorage.setItem('login', true)
                    // navigate('/marketing-permissions')
                  }}
                />
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={isPhoneResendModalOpen}
            onClose={() => {
              setIsPhoneResendModalOpen(false)
            }}
          >
            <div className=" space-y-6">
              <div
                className=" w-full flex flex-col items-center justify-center space-y-6 "
                style={{}}
              >
                <div className="flex w-fit relative items-center justify-center">
                  <Iphone fill={color.BlurpleTeal} />
                  <div
                    style={{ backgroundColor: color.Teal }}
                    className=" absolute top-[0px] right-[5px] rounded-md transform rotate-45 flex items-center justify-center  w-[24px] h-[24px] "
                  >
                    <p className="transform -rotate-45  text-white text-12  font-maisonBold w-full text-center">
                      1
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <p style={{ ...AppStyles.Title1, color: color.Plum }}>
                    {resignUp ? 'Enter your phone number' : 'Please re-enter your number' }
                  </p>
                  <p style={{ ...AppStyles.Subheading1, color: color.Grey }}>
                    {resignUp ? 'Please check carefully it\'s correct' : 'Just to make sure the number is correct' }
                  </p>
                </div>
              </div>

              <div className="flex flex-col justify-end space-y-6 w-full">
                <FormInput
                  placeholder={'UK mobile phone number starting 07'}
                  type={'tel'}
                  onChange={(e) => handleChange(e)}
                  name={'phone3'}
                  value={values.phone3}
                />
              </div>
              <div className=" space-y-2">
                <PrimaryButton
                  id={'handle-verification'}
                  disabled={values?.phone3?.length < 11 || values?.phone3?.length > 11}
                  // disabled={!otp?.every((char) => char !== '') || !doFinish}
                  className={'w-full'}
                  text={loading ? <Spinner /> : 'Confirm'}
                  // text={'Finish'}
                  onClick={() => {
                    if (loading) {
                      return
                    }
                    setOtp(new Array(6).fill(''))
                    if (values?.phone3 !== values?.phone) {
                      handleResetPhone()
                      setIsModalOpen(false)
                      setIsPhoneResendModalOpen(false)
                    } else {
                      // handleSignUp(true);
                      setIsPhoneResendModalOpen(false)
                      setIsModalOpen(true)
                      resendOtp(values.email)
                    }
                  }}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </DefaultPage>
  )
}

export default AboutYou
