/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Image2 from '../../../assets/images/Image (1).png'
import Spinner from '../../../components/Spinner'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import CheckInformationWrapper from '../CheckInformationWrapper'
import DefaultPage from '../DefaultPage'

import { getAnalytics, logEvent } from 'firebase/analytics'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ArrowHeadRightIcon,
  DocumentIcon,
  InfoIcon,
} from '../../../assets/AppIcons'
import { AppStyles } from '../../../assets/styles/AppStyles'
import SpinnerLarge from '../../../components/SpinnerLarge'
import FormInput from '../../../components/inputs/FormInput'
import OnEnter from '../../../components/navigation/onEnter'
import {
  SubmitApplication,
  getAgreementDocs,
} from '../../../redux/Actions/getMethods'
import { conversionAPI } from '../../../redux/Actions/postMethods'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { useTheme } from '../../../utils/ThemeColorContext'

const SignAgreement = () => {
  const { loading, token } = useSelector((state) => state.authReducer)
  const { getApplication, agreement, profile, loadingWholeScreen } =
    useSelector((state) => state.dataReducer)

  const dispatch = useDispatch()
  const { color, isSmallScreen } = useTheme()
  const navigate = useNavigate()
  const [err, setErr] = useState(false)
  const [networkErr, setNetworkErr] = useState(false)

  const [signature, setSignatire] = useState(null)
  const id = getApplication[0]?.id

  const getSignature = localStorage.getItem(`signature-${token?.email}`)
  useEffect(() => {
    if (getSignature) {
      setSignatire(getSignature)
    }
  }, [getSignature])

  const getSignatureData = async () => {
    if (!agreement) {
      setErr(true)
    } else {
      setErr(false)
      const success = await dispatch(
        SubmitApplication(id, signature, setNetworkErr),
      )
      if (success) {
        const eventReference = 'accepted_loan_offer_'
        const eventName = 'Accepted Loan Offer'
      if (window.fbq) {
        window.fbq('trackCustom', eventName, {
          applicationId: getApplication[0]?.id,
          loan_amount: getApplication[0]?.max_amount,
          loan_fee_percent: getApplication[0]?.fee_percentage,
        },
        {eventID: `${eventReference}${getApplication[0]?.id}`}
        )
      }
        dispatch(
          conversionAPI({
            eventName: eventName,
            eventSourceUrl: `${process.env.REACT_APP_FRONTEND_URL}signagreement`,
            phoneNumber: token?.phone_number,
            email: token?.email,
            fName: token?.given_name,
            lName: token?.family_name,
            cognitoUserId: token?.sub,
            customData: {
              applicationId: getApplication[0]?.id,
              loan_amount: getApplication[0]?.amount,
              loan_fee_percent: getApplication[0]?.fee_percentage,
            },
            dateOfBirth: token?.birthdate,
            userAgent: navigator.userAgent,
            eventId: `${eventReference}${getApplication[0]?.id}`,
            eventReference: eventReference,
          }),
        )
        if (window.gtag) {
          let user_data = {}
          if (
            profile?.first_name &&
            profile?.family_name &&
            profile?.address?.postcode
          ) {
            let address = { country: 'UK' }
            address.first_name = profile?.first_name
            address.last_name = profile?.family_name
            address.postal_code = profile?.address.postcode
            if (profile?.address?.line_1)
              address.street = profile?.address?.line_1
            if (profile?.address?.town) address.city = profile?.address.town
            if (
              profile?.address?.postal_county ||
              profile?.address?.traditional_county
            )
              address.region =
                profile?.address?.postal_county ||
                profile?.address?.traditional_county
            user_data = {
              email: token?.email,
              phone_number: token?.phone_number,
              address: address,
            }
          } else {
            user_data = {
              email: token?.email,
              phone_number: token?.phone_number,
            }
          }
          window.gtag('event', 'conversion', {
            send_to: process.env.REACT_APP_LOAN_ACCEPTED_GTAG_CONVERSION,
            loan_amount: getApplication[0]?.amount,
            loan_fee_percent: getApplication[0]?.fee_percentage,
            user_data: user_data,
          })
        }
        const analytics = getAnalytics()
        logEvent(analytics, 'accepted_loan_application_client_side', {
          loan_amount: getApplication[0]?.amount,
          loan_fee_percent: getApplication[0]?.fee_percentage,
          platform: 'web',
        })
        // navigate('/manageloan')
        navigate('/marketing-permissions')
        localStorage.removeItem(`loan-agreement-${token?.email}`)
        localStorage.removeItem(`bankConfirm-${token?.email}`)
        localStorage.removeItem(`add_accounts-${token?.email}`)
        localStorage.removeItem(`signature-${token?.email}`)
        localStorage.removeItem(`direct-debit-${token?.email}`)
        localStorage.removeItem('add_account')
        localStorage.removeItem('bank')
        localStorage.removeItem('SelectedBank')
        localStorage.removeItem(`bank-${token?.email}`)
        localStorage.removeItem(`add-another-bank-account-${token?.email}`)
      }
    }
    // Save the signature data to a database or send it to a server here.
  }

  // useEffect(() => {
  //   if (token?.email) {
  //     localStorage.setItem(`journeyStep-${token?.email}`, '/signagreement')
  //   }
  // }, [token?.email])

  useEffect(() => {
    if (id) {
      dispatch(getAgreementDocs(id, 'credit', ActionTypes.GET_AGREMENT_DOCS))
    }
  }, [id])

  async function convertHtmlToPdf() {
    navigate('/loan-agreement')
  }

  // ==================== Checking user status  =======================//
  useEffect(() => {
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      // const myID = await dispatch(getApplications())
      if (getApplication[0]?.status === 'APPROVED') {
        dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
        navigate('/signagreement')
      } else {
        dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
        navigate('/address-detail')
      }
    }
    temp()
  }, [])

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <div className="md:flex md:w-[792px] m-[auto] py-10 md:py-0">
        <CheckInformationWrapper img={Image2}>
          <div className="w-full space-y-8  md:space-y-10 ">
            <div className=" space-y-4 md:space-y-6 ">
              <div className="flexflex-col items-center ">
                <h1
                  style={
                    isSmallScreen
                      ? { ...AppStyles.Title1, color: color.Plum }
                      : { ...AppStyles.LargeTitle2, color: color.Plum }
                  }
                  className=" text-center"
                >
                  Sign your agreement
                </h1>
              </div>
              <div className="flex flex-col justify-end space-y-6 w-full">
                <FormInput
                  placeholder={'Sign here'}
                  type={'text'}
                  onChange={(e) => {
                    setSignatire(e.target.value)
                    dispatch({
                      type: ActionTypes.SIGNATURE,
                      payload: e.target.value,
                    })
                    localStorage.setItem(
                      `signature-${token?.email}`,
                      e.target.value,
                    )
                  }}
                  name={'signature'}
                  value={signature}
                />
              </div>
              <div
                style={{
                  backgroundColor: color,
                  borderColor: color.MidGrey,
                  margin: '16px 0',
                  height: isSmallScreen && '100%',
                }}
                className="border w-full rounded-[16px] flex p-4 space-x-4 "
              >
                <div className="">
                  <InfoIcon fill={color.BlurpleTeal} />
                </div>
                <div className="flex flex-col space-y-2">
                  <b
                    style={{
                      ...AppStyles.BodyBold,
                      color: color.BlurpleTeal,
                    }}
                  >
                    eSignature
                  </b>
                  <p
                    style={{
                      ...AppStyles.Body12Demi,
                      color: color.Grey,
                      width: isSmallScreen && '100%',
                      lineHeight: isSmallScreen && '20px',
                      height: isSmallScreen && '100%',
                    }}
                    className="leading-[20px]"
                  >
                    Upon signing the agreement we'll send you a signed copy via
                    email. This will also be available on demand within your
                    Account Settings.
                  </p>
                </div>
              </div>
            <div
              onClick={() => convertHtmlToPdf()}
              className="w-full space-y-6 cursor-pointer"
            >
              <div
                style={{
                  backgroundColor: color.White,
                  borderColor: color.MidGrey,
                  height: isSmallScreen && '100%',
                }}
                className="px-4 py-5 border rounded-2xl flex space-x-4  justify-between "
              >
                <div className="flex space-x-4 items-center justify-between  w-full">
                  <div className="flex space-x-4 items-center justify-between">
                    <DocumentIcon fill={color.BlurpleTeal} />
                    <p
                      style={{
                        ...AppStyles.BodyBold,
                        color: color.Plum
                      }}
                    >
                      View and Accept Your Loan Agreement <br />{' '}
                    </p>
                  </div>
                  <ArrowHeadRightIcon fill={color.BlurpleTeal} />
                </div>
              </div>
            </div>
            </div>
            {err && (
              <p
                className="text-center"
                style={{ ...AppStyles.Caption1, color: color.SoftPink }}
              >
                {'Please view and accept your loan agreement'}
              </p>
            )}
            {networkErr && (
              <p
                className="text-center"
                style={{ ...AppStyles.Caption1, color: color.SoftPink }}
              >
                {'Please try again some time later'}
              </p>
            )}
            <OnEnter
              actionFunction={() => {
                if (!signature) {
                  return
                }
                getSignatureData()
                window.heap &&
                  window.heap.track('uc_loan_agreement_signed', {
                    action_name: 'tap',
                    action_details: 'usLoanAgreementSigned',
                  })
              }}
            />
            <div className=" space-y-2">
              <PrimaryButton
                id={'sign-agreement-btn'}
                disabled={!signature}
                text={loading ? <Spinner /> : 'Sign my agreement'}
                onClick={() => {
                  if (loading) {
                    return
                  }
                  getSignatureData()
                  window.heap &&
                    window.heap.track('uc_loan_agreement_signed', {
                      action_name: 'tap',
                      action_details: 'usLoanAgreementSigned',
                    })
                }}
              />
            </div>
          </div>
        </CheckInformationWrapper>
        </div>
      )}
    </DefaultPage>
  )
}

export default SignAgreement
