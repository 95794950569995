import React, { useEffect } from 'react'
import TopNavBar from '../../../components/navigation/TopNavBar'
import { useTheme } from '../../../utils/ThemeColorContext'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

import SpinnerLarge from '../../../components/SpinnerLarge'
import JourneyRoute from '../../../navigation/JourneyRoute'
const Privacy = () => {
  const { loadingWholeScreen } = useSelector((state) => state.dataReducer)
  const navigate = useNavigate()
  const { color, isSmallScreen } = useTheme()
  const isLogin = localStorage.getItem('login')

  useEffect(() => {
    if (!isLogin) {
      navigate('/')
    }
  }, [!isLogin])
  return (
    <>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <>
          <div style={{ backgroundColor: color.LightGrey }}>
            {isLogin && <JourneyRoute />}
            <TopNavBar />
            <div
              style={{ height: isSmallScreen && `calc(100vh - 72px)` }}
              className="mobilecta bottom-0 w-full pb-8 px-6 mt-12 md:mt-16 md:block md:cta md:pb-16"
            ></div>
            {/* <Footer /> */}
          </div>
        </>
      )}
    </>
  )
}

export default Privacy
