import React from 'react'
import ConfettiSvg from '../../../assets/icons/Confetti.svg'
import AndroidIcon from '../../../assets/images/AndroidIcon.png'
import iosIcon from '../../../assets/images/Ios.png'
import confetti from '../../../assets/images/confetti.png'
import TopNavBar from '../../../components/navigation/TopNavBar'
import CheckInformationWrapper from '../CheckInformationWrapper'
import manageLoanIcon from '../../../assets/icons/manageLoan2.png'
import manageLoanIconMobile from '../../../assets/icons/ManageLoanMobile.svg'
import PrimaryButton from '../../../components/buttons/PrimaryButton'

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { removeGarbage } from '../../../api/dummyData'
import { AppStyles } from '../../../assets/styles/AppStyles'
import SpinnerLarge from '../../../components/SpinnerLarge'
import { Account_Management_List } from '../../../redux/Actions/getMethods'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { useTheme } from '../../../utils/ThemeColorContext'

function ManageLoan() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { applicationStatus, loadingWholeScreen } = useSelector(
    (state) => state.dataReducer,
  )
  const { color, isSmallScreen } = useTheme()

  useEffect(() => {
    removeGarbage()
    if (applicationStatus?.status === 'APPLY') {
      navigate('/reapply')
    }
  }, [applicationStatus?.status === 'APPLY'])

  // ==================== Checking user status  =======================//
  useEffect(() => {
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      const get_loan_account_management = await dispatch(
        Account_Management_List(),
      )
      if (!get_loan_account_management) {
        navigate('/address-detail')
      }
      dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
    }
    temp()
  }, [])

  return (
    <>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <div
          style={{
            backgroundColor: color.LightGrey,
          }}
        >
          <TopNavBar />

          <div
            style={{
              backgroundColor: color.LightGrey,
              minHeight: isSmallScreen
                ? 'calc(100vh - 0px)'
                : 'calc(100vh - 108px)',
            }}
            className="mobilecta bottom-0 w-full md:block md:cta"
          >
            <div
              style={{
                backgroundImage: `url(${isSmallScreen ? ConfettiSvg : confetti})`,
                backgroundRepeat: 'repeat-x',
                backgroundPosition: 'bottom',
                zIndex: 20,
                minHeight: isSmallScreen
                ? 'calc(100vh - 0px)'
                : 'calc(100vh - 108px)',
              }}
              className="md:min-h-[800px] min-h-[600px] py-[64px]  "
            >
              <div className="relative md:h-[500px]  h-full container mx-auto md:w-[792px] space-y-10 md:p-[100px] py-8 ">
                <div className="h-full flex flex-col md:items-center md:justify-center space-y-10 mt-16 ">
                  <CheckInformationWrapper
                    img={isSmallScreen ? manageLoanIconMobile : manageLoanIcon}
                  >
                    {/* <div
                      // color.White
                      style={{
                        backgroundColor: color.White,
                      }}
                      className=" p-6 md:p-10 rounded-2xl  md:w-[456px] flex flex-col items-center w-full  "
                    > */}
                      <div className="w-full space-y-10">
                        <div className="flex flex-col items-center space-y-6">
                          <h1
                            style={
                              isSmallScreen
                                ? {
                                    ...AppStyles.Title1,
                                    color: color.Plum,
                                    height: '32px',
                                    width: '279px',
                                  }
                                : { ...AppStyles.LargeTitle2, color: color.Plum }
                            }
                            className=" text-center"
                          >
                            Welcome to the Club!
                          </h1>
                          <h1
                            style={{
                              fontSize: isSmallScreen ? '18px' : '20px',
                              fontFamily: 'GilroyBold',
                              lineHeight: '32px',
                              color: '#5D1CD3',
                              width: '376px',
                            }}
                            className=" text-center"
                          >
                            {/* Manage your loan in our app */}
                            {isSmallScreen
                              ? 'Download Updraft today'
                              : 'Scan the QR code above to manage your loan on our app.'}
                          </h1>
                          <p
                            style={
                              isSmallScreen
                                ? {
                                    ...AppStyles.Body,
                                    color: color.Grey,
                                    margin: 0,
                                    marginTop: '16px',
                                  }
                                : {
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    lineHeight: '28px',
                                    color: '#6C819C',
                                    fontFamily: 'MaisonMedium","sans-serif',
                                  }
                            }
                            className="leading-[24px] text-center"
                          >
                            Congrats on accepting your offer - to manage your loan
                            and get all the support you need from us, download our
                            app.
                          </p>
                          {isSmallScreen && (
                          <a
                            href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                            target="_blank"
                            className=" space-y-2 w-[279px]"
                            style={{ marginTop: isSmallScreen && '40px' }}
                            rel="noreferrer"
                          >
                            <PrimaryButton
                              id={'get-updraft-now-manage-loan'}
                              text={'Get Updraft Now'}
                            />
                          </a>
                        )}
                          <div className=" space-y-4">
                            <p
                              style={{
                                ...AppStyles.Caption4,
                                color: '#6C819C',
                              }}
                            >
                              Available On
                            </p>
                            <div className="flex md:justify-between md:w-[77px] m-[auto]">
                              { isSmallScreen ? (
                                <>
                                  <a
                                    href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                                    target="_blank"
                                    className="cursor-pointer w-full space-y-2 "
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={iosIcon}
                                      style={{
                                        width: '32px',
                                        height: '25px',
                                        objectFit: 'contain',
                                      }}
                                    />
                                  </a>
                                  <a
                                    href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                                    target="_blank"
                                    className="cursor-pointer w-full space-y-2 "
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={AndroidIcon}
                                      style={{
                                        width: '32px',
                                        height: '25px',
                                        objectFit: 'contain',
                                      }}
                                    />
                                  </a>
                                </>
                              ) : (
                                <>
                                  <div className="w-full space-y-2 ">
                                    <img
                                      src={iosIcon}
                                      style={{
                                        width: '32px',
                                        height: '25px',
                                        objectFit: 'contain',
                                      }}
                                    />
                                  </div>
                                  <div className="w-full space-y-2 ">
                                    <img
                                      src={AndroidIcon}
                                      style={{
                                        width: '32px',
                                        height: '25px',
                                        objectFit: 'contain',
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                  </CheckInformationWrapper>
                </div>
              </div>
            </div>

          </div>
          
          {/* <Footer /> */}
        </div>
      )}
    </>
  )
}
export default ManageLoan
