import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Account_Management_List } from '../redux/Actions/getMethods'
import { ActionTypes } from '../redux/Constants/actionTypes'

const RefferedRoute = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.authReducer)
  const { getApplication } = useSelector((state) => state.dataReducer)

  const bank = JSON.parse(localStorage.getItem(`bankConfirm-${token?.email}`))

  useEffect(() => {
    if (token?.email) {
      if (getApplication[0]?.status === 'CREDIT_OK') {
        navigate('/monthly-spend')
      } else if (getApplication[0]?.status === 'POLICY_OK') {
        navigate('/soft-search')
      } else if (bank && getApplication[0]?.status === 'REFERRED') {
        navigate('/loanreferred')
      } else if (!bank && getApplication[0]?.status === 'REFERRED') {
        navigate('/verify-income')
      } else if (getApplication[0]?.status === 'DECLINED') {
        navigate('/failed-offer')
      } else if (
        getApplication[0]?.status === 'APPROVED' &&
        getApplication[0]?.offer_type === 'UPDRAFT_CREDIT_INTEREST_ONLY'
      ) {
        navigate('/loanapproved')
      } else if (
        getApplication[0]?.status === 'APPROVED' &&
        getApplication[0]?.offer_type !== 'UPDRAFT_CREDIT_INTEREST_ONLY'
      ) {
        navigate('/loanapprovedfee')
      } else {
        const temp = async () => {
          dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
          const get_loan_account_management = await dispatch(
            Account_Management_List(),
          )
          if (
            (get_loan_account_management?.status === 'CLOSED' &&
              get_loan_account_management?.settled_date === null) ||
            (get_loan_account_management?.status === 'CLOSED' &&
              get_loan_account_management?.settled_date !== null)
          ) {
            navigate('/no-active-loan')
          } else if (
            get_loan_account_management?.status === 'GOOD' ||
            get_loan_account_management?.status === 'OVERDUE' ||
            get_loan_account_management?.status === 'HOLIDAY' ||
            get_loan_account_management?.status === 'ARRANGEMENT' ||
            get_loan_account_management?.status === 'ARRANGEMENT_OVERDUE' ||
            get_loan_account_management?.status === null
          ) {
            navigate('/active-loan')
          }
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
        }
        temp()
      }
    }
  }, [token?.email, getApplication[0]?.id])
  return ''
}

export default RefferedRoute
