import { AppStyles } from '../../../assets/styles/AppStyles'
import { useTheme } from '../../../utils/ThemeColorContext'

import React, { useEffect } from 'react'
import DefaultPage from '../DefaultPage'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import manageLoanIcon from '../../../assets/icons/manageLoan2.png'
import manageLoanIconMobile from '../../../assets/icons/ManageLoanMobile.svg'
import CheckInformationWrapper from '../CheckInformationWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AndroidIcon from '../../../assets/images/AndroidIcon.png'
import iosIcon from '../../../assets/images/Ios.png'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { getApplications } from '../../../redux/Actions/getMethods'
import SpinnerLarge from '../../../components/SpinnerLarge'

function ExsistingLoan() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useSelector((state) => state.authReducer)
  const { reApply, loadingWholeScreen } = useSelector(
    (state) => state.dataReducer,
  )

  const { color, isSmallScreen } = useTheme()

  // Define the provided date
  const providedDate = new Date(reApply)

  // Get the current date
  const currentDate = new Date()

  // Calculate the difference in milliseconds
  const differenceInMilliseconds =
    providedDate.getTime() - currentDate.getTime()

  // Convert milliseconds to days
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24),
  )

  // Print the difference in days
  console.log('Difference in days:', differenceInDays)

  // ==================== Checking user status  =======================//
  useEffect(() => {
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      const myID = await dispatch(getApplications())
      if (myID?.data[0]?.id) {
        navigate('/address-detail')
      }
      dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
    }
    temp()
  }, [])

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <div className="md:mt-0 mt-6 ">
          <CheckInformationWrapper
            img={isSmallScreen ? manageLoanIconMobile : manageLoanIcon}
          >
            <div className="w-full space-y-8 md:space-y-10">
              <div className="flex flex-col items-center space-y-4 md:space-y-6">
                <h1
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Title1,
                          color: color.Plum,
                        }
                      : {
                          ...AppStyles.LargeTitle2,
                          color: color.Plum,
                        }
                  }
                  className="text-center"
                >
                  Welcome back
                </h1>
                {/* <h1
                style={{
                  fontSize: isSmallScreen ? '18px' : '20px',
                  fontFamily: 'GilroyBold',
                  lineHeight: '32px',
                  color: '#5D1CD3',
                  width: '376px',
                }}
                className=" text-center"
              >
                Manage your existing loan
              </h1> */}
                <p
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Body,
                          color: color.Grey,
                          height: '48px',
                        }
                      : {
                          ...AppStyles.Body16,
                          color: color.Grey,
                          lineHeight: '28px',
                        }
                  }
                  className="text-center"
                >
                  If you would like to apply for a new loan, just hit the button
                  below to get started.
                </p>
                <div
                  style={{
                    marginTop: isSmallScreen ? '40px' : '24px',
                    width: '100%',
                  }}
                >
                  <PrimaryButton
                    id={'check-eligbility'}
                    text={'Check My Eligbility'}
                    onClick={async () => {
                      localStorage.setItem(`reAaply-${token?.email}`, true)
                      navigate('/address-details')
                    }}
                    className={'w-full'}
                  />
                </div>
                <div
                  className="flex justify-between items-center w-full"
                  style={{ marginTop: '40px', marginBottom: '12px' }}
                >
                  <span className="border-[1px] w-full"></span>
                  <span
                    style={{
                      ...AppStyles.BodyBold,
                      color: color.Plum,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    className="h-[32px] w-[48px] ml-3 mr-3"
                  >
                    Or
                  </span>
                  <span className="border-[1px] w-full"></span>
                </div>
                <p
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Body,
                          color: color.Grey,
                          height: '48px',
                          margin: 0,
                        }
                      : {
                          ...AppStyles.Body16,
                          color: color.Grey,
                          lineHeight: '28px',
                          margin: 0,
                        }
                  }
                  className="text-center"
                >
                  {isSmallScreen
                    ? 'Download Updraft on your phone and check your eligibility from there.'
                    : `
                  Scan the QR code above to install Updraft on your phone and
                  check your eligibility from there.
                  `}
                </p>
                <p
                  style={{
                    ...AppStyles.Caption4,
                    color: '#6C819C',
                    marginTop: '40px',
                  }}
                >
                  Available On
                </p>
                <div
                  style={{ marginTop: '12px' }}
                  className="flex md:justify-between md:w-[77px]"
                >
                  { isSmallScreen ? (
                    <>
                      <a
                        href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                        target="_blank"
                        className="cursor-pointer w-full space-y-2 "
                        rel="noreferrer"
                      >
                        <img
                          src={iosIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </a>
                      <a
                        href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                        target="_blank"
                        className="cursor-pointer w-full space-y-2 "
                        rel="noreferrer"
                      >
                        <img
                          src={AndroidIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className="w-full space-y-2 ">
                        <img
                          src={iosIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                      <div className="w-full space-y-2 ">
                        <img
                          src={AndroidIcon}
                          style={{
                            width: '32px',
                            height: '25px',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </CheckInformationWrapper>
        </div>
      )}
    </DefaultPage>
  )
}

export default ExsistingLoan
