import { AppStyles } from '../../../assets/styles/AppStyles'
import { useTheme } from '../../../utils/ThemeColorContext'

import React from 'react'
import DefaultPage from '../DefaultPage'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import manageLoanIcon from '../../../assets/icons/manageLoan2.png'
import manageLoanIconMobile from '../../../assets/icons/ManageLoanMobile.svg'
import AppStore from '../../../assets/images/App Store.png'
import CheckInformationWrapper from '../CheckInformationWrapper'
import PlayStore from '../../../assets/images/googleplay.png'
import { useSelector } from 'react-redux'

function ReApply() {
  const { reApply } = useSelector((state) => state.dataReducer)
  const { token } = useSelector((state) => state.authReducer)

  const { color, isSmallScreen } = useTheme()

  // Define the provided date
  const providedDate = new Date(reApply)

  // Get the current date
  const currentDate = new Date()

  // Calculate the difference in milliseconds
  const differenceInMilliseconds =
    providedDate.getTime() - currentDate.getTime()

  // Convert milliseconds to days
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24),
  )

  // Print the difference in days
  console.log('Difference in days:', differenceInDays > 1)

  return (
    <DefaultPage>
      <div className="md:mt-0 mt-6">
        <CheckInformationWrapper img={isSmallScreen ? manageLoanIconMobile : manageLoanIcon}>
          <div className="w-full space-y-8 md:space-y-10">
            <div className="flex flex-col items-center space-y-4 md:space-y-6">
              <h1
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Title1,
                        color: color.Plum,
                      }
                    : {
                        ...AppStyles.LargeTitle2,
                        color: color.Plum,
                      }
                }
                className="text-center"
              >
                After a new loan with Updraft?
              </h1>
              <p
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Body,
                        color: color.Grey,
                        height: '96px',
                      }
                    : {
                        ...AppStyles.Body16,
                        color: color.Grey,
                      }
                }
                className="text-center"
              >
                If you would like to apply for a new Updraft loan, please
                continue by hitting the button below.
              </p>
              <PrimaryButton
                id={'new-updraft-loan-btn'}
                text={'Apply for a new Updraft loan'}
                onClick={() => {
                  localStorage.setItem(`reAaply-${token?.email}`, true)
                }}
                className={' w-full '}
              />
              <div className="flex justify-between items-center w-full">
                <span className="border-[2px] w-full"></span>
                <span
                  style={{
                    ...AppStyles.BodyBold,
                    color: color.Plum,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  className="h-[32px] w-[48px] ml-3 mr-3"
                >
                  Or
                </span>
                <span className="border-[2px] w-full"></span>
              </div>
              <p
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Body,
                        color: color.Grey,
                        height: '96px',
                      }
                    : {
                        ...AppStyles.Body16,
                        color: color.Grey,
                      }
                }
                className="text-center"
              >
                If you would like to see what other benefits Updraft have to
                offer, such as our Updraft Payce plan, please view this in the
                app.
              </p>
            </div>
            <div className="flex md:justify-between  w-full md:w-[330px] m-[auto]">
              <a
                style={{ width: isSmallScreen && '155px' }}
                href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                target="_blank"
                className="cursor-pointer w-full space-y-2 w-[144px]"
                rel="noreferrer"
              >
                <img src={AppStore} />
              </a>
              <a
                style={{ width: isSmallScreen && '170px' }}
                href={isSmallScreen ? "https://updraft.onelink.me/ZLOh/oorc3pwo" : "https://updraft.onelink.me/ZLOh/moxsc062" }
                target="_blank"
                className="cursor-pointer w-full space-y-2 w-[162px]"
                rel="noreferrer"
              >
                <img src={PlayStore} />
              </a>
            </div>
          </div>
        </CheckInformationWrapper>
      </div>
    </DefaultPage>
  )
}

export default ReApply
