/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState } from 'react'
import { useTheme } from '../../utils/ThemeColorContext'
import { AppStyles } from '../../assets/styles/AppStyles'
import { forwardRef } from 'react'
import { CalenderIcon } from '../../assets/AppIcons'

const FormInput = forwardRef(({
  placeholder,
  type,
  onChange,
  name,
  onKeyDown,
  onKeyUp,
  value,
  leftSign,
  rightSign = false,
  rightElement = false,
  error = false,
  disabled = false,
  autoComplete = "off",
  enterKeyHint = null,
  onClick,
}, ref) => {
  const [isFocused, setIsFocused] = useState(false)
  const { color, isSmallScreen } = useTheme()

  const inputStyle =
    name === 'signature'
      ? {
        ...AppStyles.BodyBold3,
        color: color.BlurpleTeal,
        backgroundColor: color.LightGrey,
        borderColor: color.Tint3,
        width: '100%',
        fontSize: '24px',
        border: 'none',
        padding: '0px',
      }
      : isSmallScreen
        ? {
          ...AppStyles.BodyBoldForZoomIssue,
          color: color.BlurpleTeal,
          backgroundColor: color.LightGrey,
          borderColor:  isFocused ? color.BlurpleTeal : color.Tint3,
          width: '100%',
          border: 'none',
          padding: '0px',
        }
        : {
          ...AppStyles.BodyBold,
          color: color.BlurpleTeal,
          backgroundColor: color.LightGrey,
          borderColor: isFocused ? color.BlurpleTeal : color.Tint3,
          width: '100%',
          border: 'none',
          padding: '0px',
        }

  return (
    <div
      style={{
        backgroundColor: error ? color.Negative15 : color.LightGrey,
        borderColor: error ? color.SoftPink : isFocused ? color.BlurpleTeal : color.MidGrey,
        opacity: disabled && '40%'
      }}
      className=" flex items-center  border rounded-2xl  px-3  "
    >
      <div
        onClick={onClick}
        className="py-4 h-16 w-full   flex flex-col justify-center relative items-start"
      >
        {(isFocused || value || value === 0) && (
          <p
            style={{
              ...AppStyles.Caption3,
              color: error ? color.SoftPink : color.Grey,
            }}
          >
            {placeholder}
          </p>
        )}
        <div className="flex relative justify-start items-center w-[100%]">
          {leftSign && value && (
            <div
              style={{
                ...AppStyles.BodyBold,
                color: color.BlurpleTeal,
                backgroundColor: color.LightGrey,
                borderColor: color.Tint3,
                width: '10px',
                height: '24px',
                display: 'flex',
                fontSize: '14px',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
                marginTop: '2px',
              }}
            >
              {value && '£'}
            </div>
          )}
          <input
            id={name}
            style={{
              ...inputStyle,
              backgroundColor: 'transparent',
            }}
            autoComplete={autoComplete}
            className={` ${type === 'date' && `example-custom-input`
              } px-0 focus:ring-transparent 
            bg-transparent hover:bg-transparent focus:bg-transparent placeholder:font-MaisonMedium placeholder:text-14 placeholder:font-medium placeholder:text-TextGrey`}
            type={leftSign ? 'text' : type}
            {...(leftSign && { inputMode: 'numeric' })}
            min={0}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onChange={(e) => onChange(e)}
            name={name}
            // onWheel={(e) => e.preventDefault()}
            // onWheel={(e) => e.preventDefault({ passive: false })}
            // onTouchStart={(e) => e.preventDefault()}
            // onTouchMove={(e) => e.preventDefault()}
            // onPointerDown={(e) => e.preventDefault()}
            // onPointerMove={(e) => e.preventDefault()}
            onWheel={(e) => e.target.blur()}
            value={value}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={disabled}
            placeholder={!isFocused && placeholder}
            ref={ref}
            enterKeyHint={enterKeyHint}
          />
          {rightSign && (
            <div className="cursor-pointer">
              <CalenderIcon fill={color.BlurpleTeal} className={'w-4 h-4'} />
            </div>
          )}
        </div>
      </div>
      {rightElement && rightElement}
    </div>
  )
})

export default FormInput