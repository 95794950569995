/* eslint-disable react/no-unescaped-entities */
import * as Sentry from "@sentry/react"
import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import {
  CloseIcon,
  Iphone,
  KeyIcon,
  MenuIcon,
  UserIcon,
} from '../../assets/AppIcons'
import { useTheme } from '../../utils/ThemeColorContext'
import { AppStyles } from '../../assets/styles/AppStyles'
import { useAuth } from '../../utils/AuthContext'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { ActionTypes } from '../../redux/Constants/actionTypes'
import { useEffect } from 'react'
import { doSignIn } from '../../redux/Actions/authMethod'
import { jwtDecode } from 'jwt-decode'
import { SendOtpForgot, editMyProfileAfterSignUp, createNewPass } from '../../redux/Actions/postMethods'
import { useLocation } from 'react-router-dom'
import {
  getApplications,
  getIntercomHashUser,
  getProfile,
} from '../../redux/Actions/getMethods'
import Modal from '../Modal'
import Logo from '../../assets/images/updrift.svg'
import LogoWhite from '../../assets/images/updriftwhite.svg'
import FormInput from '../inputs/FormInput'
import OutllineButton from '../buttons/OutllineButton'
import PrimaryButton from '../buttons/PrimaryButton'
import ModalHeader from '../ModalHeader'
import CustomOTPInput from '../CustomOtp'
import MobileDrawer from './Drawer'
import ScrollToTop from './ScrollTop'
import Spinner from '../Spinner'
import Thumb from '../../assets/images/Like.png'
import OnEnter from './onEnter'
import captureException from '../../utils/SentryHelper'
import {
  getAnalytics,
  logEvent,
  setUserProperties,
  setUserId,
} from 'firebase/analytics'

const initialvalues = {
  email: '',
  password: '',
}

function TopNavBar() {
  const location = useLocation()
  const analytics = getAnalytics()

  // ======================== redux ======================//
  const dispatch = useDispatch()
  const { loading, loading2, isLoginModalOpen, signUpValues } = useSelector(
    (state) => state.authReducer,
  )
  // ======================== redux ======================//

  const navigate = useNavigate()
  // const { token: myToken } = useSelector((state) => state.authReducer)
  const { darkMode, color, isSmallScreen } = useTheme()
  const [otp, setOtp] = useState(new Array(6).fill(''))
  const { token, setToken } = useAuth()
  const [toggleMenu] = useState(false)
  const [isModalOpenOtp, setIsModalOpenOtp] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [openCodeModal, setopenCodeModal] = useState(false)
  const [openForgetPassModal, setopenForgetPassModal] = useState(false)
  const [openNewPassModal, setopenNewPassModal] = useState(false)
  const [values, setValues] = useState(initialvalues)
  const [loginErr, setLoginErr] = useState('')

  useEffect(() => {
    const resendSignUp = async () => {
      if (loginErr === 'User is not confirmed.') {
        try {
          const res = await Auth.resendSignUp(values?.email?.toLowerCase())
          if (res) {
            setIsModalOpen(false)
            setIsModalOpenOtp(true)
            dispatch({ type: ActionTypes.OPEN_LOGIN_CLOSE })
          }
        } catch (error) {
          console.log({ error })
          if (error?.message !== 'User is not confirmed.') {
            const prefix = 'CustomMessage failed with error '
            if (error?.message.startsWith(prefix)) {
              setLoginErr(error?.message?.slice(prefix.length))
            } else {
              setLoginErr(error?.message)
            }
          }
        }
      }
    }
    resendSignUp()
  }, [loginErr === 'User is not confirmed.'])

  const handleChange = (e) => {
    const { name, value } = e.target // e.target refers to the input element
    let trimmed = null
    if (name !== 'password') {
      trimmed = value?.trim()
    }
    setValues({
      ...values, // spread the existing values
      [name]: trimmed ? trimmed : value, // override the value for the input's name
    })
  }

  const handleLogin = async (emailOverride, passwordOverride) => {
    try {
      let payloadValues = values
      if (emailOverride && passwordOverride) {
        payloadValues = {
          ...values,
          email: emailOverride,
          password: passwordOverride,
        }
      }
      const res = await dispatch(doSignIn(payloadValues, setLoginErr))
      if (res) {
        if (window.heap) {
          window.heap.identify(res.signInUserSession.idToken.payload.sub)
          window.heap.addUserProperties({
            email: res?.attributes?.email,
            ldUserId: res?.username,
          })
        }
        const getHashUserId = await dispatch(getIntercomHashUser())
        if (getHashUserId) {
          window.Intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
            name: `${res?.attributes?.given_name} ${res?.attributes?.family_name}`,
            email: res?.attributes?.email,
            phone: res?.attributes?.phone_number,
            user_id: res.signInUserSession.idToken.payload.sub,
            user_hash: getHashUserId?.data?.web,
          })
        }
        setToken(res?.signInUserSession?.idToken?.jwtToken)
        localStorage.setItem('login', true)
        setUserId(analytics, res.signInUserSession.idToken.payload.sub)
        if (window.gtag) {
          window.gtag('set', {'user_id': res.signInUserSession.idToken.payload.sub })
        }
        if (window._sva) {
          window._sva.setVisitorTraits({
            email: res?.attributes?.email,
            user_id: res?.signInUserSession.idToken.payload.sub,
          })
        }
        Sentry.setUser({ 
          id: res.signInUserSession.idToken.payload.sub,
          username: res.signInUserSession.idToken.payload.sub,
        })
        if (window.fbq) {
          window.fbq('init', process.env.REACT_APP_META_ID, {
            em: res?.attributes?.email?.toLowerCase(),
            ph: res?.attributes?.phone_number?.slice(1),
            external_id: res.signInUserSession.idToken.payload.sub,
          })
        }
        // if we have been sent to login via an attempt to sign up AND profile
        // doesn't exist yet, POST it before navigating
        try {
          const profile = dispatch(getProfile())
          if (profile == 404 || !profile?.first_name || !profile?.family_name || !profile?.title || !profile?.birthday) {
            const profileData = {
              first_name: signUpValues?.first_name,
              surname: signUpValues?.surname,
              birthday: signUpValues?.birthday,
              phone: res?.attributes?.phone_number,
              title: signUpValues?.title,
            }
            dispatch(editMyProfileAfterSignUp(profileData))
          }
        } catch (error) {
          console.log(error)
        }
        if (res?.attributes?.email) {
          navigate('/address-detail')
        }
        setIsModalOpen(false)
        dispatch({ type: ActionTypes.OPEN_LOGIN_CLOSE })
        dispatch({ type: ActionTypes.LOGIN_WITH_VALUES, payload: {} })
      }
    } catch (error) {
      const prefix = 'CustomMessage failed with error '
      if (error?.message.startsWith(prefix)) {
        setLoginErr(error?.message?.slice(prefix.length))
      } else {
        setLoginErr(error?.message)
      }
      console.log({ error })
    }
  }

  useEffect(() => {
    const showIncorrectPasswordError = async () => {
      setIsModalOpenOtp(false)
      if (isLoginModalOpen
        && signUpValues?.email // aim was to sign up not log in
        && signUpValues?.password
      ) {
        setLoginErr(
          'It seems you’ve already got an account with us. Please enter the correct password or reset your password below.'
        )
      } else {
        setLoginErr(
          'Please enter the correct password or reset your password below.'
        )
      }
    }
    if (isLoginModalOpen // we are logging in via external request
      && signUpValues?.email // aim was to sign up not log in
      && signUpValues?.password
      && loginErr === 'Incorrect username or password.'
    ) {
      showIncorrectPasswordError()
    }
  }, [loginErr === 'Incorrect username or password.'])

  const verified = async () => {
    try {
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('birth')
      window.heap &&
        window.heap.track('signup_verified', {
          action_name: 'tap',
          action_details: 'SignUpVerified',
        })
      setUserProperties(analytics, {
        email: values.email,
        phone: values.phone,
        first_name: values.first_name,
        last_name: values.surname,
        title: values.title,
        platform: 'web',
      })
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          send_to: process.env.REACT_APP_REGISTRATION_VERIFIED_GTAG_CONVERSION,
          submit_registration: 'sms',
          user_data: {
            email: values.email,
            phone_number: values.phone,
          },
        })
      }
      logEvent(analytics, 'registration_verified', {
        submit_registration: 'sms',
        platform: 'web',
      })
      if (window.fbq) {
        window.fbq('trackCustom', 'registration_verified', {
          submit_registration: 'sms',
          platform: 'web',
        })
      }
      dispatch({ type: ActionTypes.LOADING_FAIL })
      dispatch({ type: ActionTypes.OPEN_LOGIN_MODAL })
      handleLogin()
    } catch (error) {
      console.log({ error })
      captureException(error)
    }
  }

  const handleVerification = async (verificationCode) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await Auth.confirmSignUp(values.email?.toLowerCase(), verificationCode, {
        platform: 'web',
      })
      if (res) {
        verified()
      }
    } catch (error) {
      console.log({ error })
      if (error?.code === 'UsernameExistsException') {
        verified()
        return
      } else if (error.code === 'NotAuthorizedException') {
        verified()
        return
      } else if (
        error?.code === 'CodeMismatchException' ||
        error?.code === 'ExpiredCodeException'
      ) {
        setLoginErr(error?.message)
      } else {
        captureException(error)
      }
      setValid(true)
      dispatch({ type: ActionTypes.LOADING_FAIL })
    }
  }

  const [inValidEmailForForgot, setInvalidEmailForForgot] = useState('')
  const sendEmail = async () => {
    try {
      const res = await dispatch(
        SendOtpForgot(
          values.email?.toLowerCase(),
          setInvalidEmailForForgot,
          setopenForgetPassModal,
        ),
      )
      if (res) {
        setIsModalOpen(false)
        setopenForgetPassModal(false)
        setopenCodeModal(true)
        dispatch({ type: ActionTypes.OPEN_LOGIN_CLOSE })
      }
    } catch (error) {
      console.log({ error })
      captureException(error)
    }
  }

  // const [resendToggleNumberVarification] = useState(false)
  const [resendToggle, setResendToggle] = useState(false)
  const sendEmailAgain = async () => {
    try {
      const res = await Auth.forgotPassword(values.email?.toLowerCase(), {
        platform: 'web',
      })
      if (res) {
        setopenCodeModal(true)
        setResendToggle(true)
      }
    } catch (error) {
      console.log({ error })
      if (
        error?.message === 'Attempt limit exceeded, please try after some time.'
      ) {
        setForgotErr(error?.message)
        setTimeout(() => {
          setForgotErr('')
          setopenCodeModal(false)
        }, 5000)
      } else {
        captureException(error)
      }
    }
  }

  useEffect(() => {
    if (otp.join('').length < 1) {
      setValid(false)
    } else if (otp.join('').length > 0) {
      setResendToggle(false)
    }
  }, [otp.join('').length < 1, otp.join('').length > 0])

  const [forgotErr, setForgotErr] = useState('')
  const submitVerificationCode = async () => {
    try {
      await Auth.forgotPasswordSubmit(values.email?.toLowerCase(), otp.join(''), '1111')
    } catch (error) {
      if (
        error?.message ===
        'Invalid verification code provided, please try again.'
      ) {
        // Ask valid code again
        setValid(true)
      } else if (
        error?.message ===
        'Password does not conform to policy: Password not long enough'
      ) {
        // If the error message is not Invalid verification code error
        // then move to the next step: get a new password from the user
        setValid(false)
        setopenCodeModal(false)
        setopenForgetPassModal(false)
        setopenNewPassModal(true)
      } else {
        setOtp(new Array(6).fill(''))
        //need to show error message something went wrong
        setForgotErr(`Something went wrong. Try again later. ${error?.message}`)
        setTimeout(() => {
          setForgotErr('')  
          setopenCodeModal(false)
        }, 5000)
        captureException(error)
      }
    }
  }

  useEffect(() => {
    if (isLoginModalOpen) {
      setIsModalOpen(true)
    }
    if (token) {
      const decoded = jwtDecode(token)
      dispatch({ type: ActionTypes.USER_TOKEN_DATA, payload: decoded })
    }
  }, [isLoginModalOpen, token])

  useEffect(() => {
    if (isLoginModalOpen === true
      && signUpValues?.email
      && signUpValues?.password
    ) {
      // we have been instructed to login via another page
      setValues({
        ...values,
        email: signUpValues?.email,
        password: signUpValues?.password,
      })
      handleLogin(signUpValues?.email, signUpValues?.password)
    }
  }, [isLoginModalOpen === true,
    signUpValues?.email,
    signUpValues?.password,
  ])

  const isLogin = localStorage.getItem('login')
  useEffect(() => {
    if (isLogin) {
      dispatch(getApplications())
    }
  }, [isLogin])

  const [createNewPassErr, setCreateNewPassErr] = useState('')
  const createNewPassword = async () => {
    try {
      const data = {
        email: values.email,
        otp: otp?.join(''),
        password: values?.password,
      }
      const success = await dispatch(
        createNewPass(data, setCreateNewPassErr, setopenNewPassModal),
      )

      if (success) {
        setopenNewPassModal(false)
      }
      // Redirect or show a confirmation message
    } catch (error) {
      console.error('Error confirming new password:', error.message)
      captureException(error)
    }
  }

  const [valid, setValid] = useState(false)

  const resendOtp = async (userName) => {
    try {
      const res = await Auth.resendSignUp(userName?.toLowerCase())
      if (res) {
        setResendToggle(true)
      }
      return res
    } catch (error) {
      const prefix = 'CustomMessage failed with error '
      if (error?.message.startsWith(prefix)) {
        setForgotErr(error?.message?.slice(prefix.length))
      } else {
        setForgotErr(error?.message)
      }
      console.error('Error resending OTP:', error?.message)
      setResendToggle(false)
      setTimeout(() => {
        setForgotErr('')
        setIsModalOpen(false)
        dispatch({ type: ActionTypes.OPEN_LOGIN_CLOSE })
      }, 10000)
      captureException(error)
    }
  }

  return (
    <>
      <ScrollToTop />
      <div
        style={{ backgroundColor: color.White }}
        className={` ${toggleMenu && `rounded-b-3xl`}  md:rounded-none fixed md:relative top-0 w-full z-50`}
      >
        <nav className=" md:py-[30px] md:px-[60px] lg:px-[120px] md:space-x-10">
          <div
            style={{ width: !isSmallScreen && '87.84773060029282vw' }}
            className={`p-6 md:p-0 flex items-center justify-between md:h-auto h-[72px]`}
          >
            <div
              onClick={() => navigate('/')}
              className="md:w-[142px] w-[120px] md:h-[24px] h-[28px] cursor-pointer"
            >
              <img src={darkMode ? LogoWhite : Logo} alt="" />
            </div>
            {/* {!localStorage.getItem('login') && (
              <>
                {location?.pathname !== '/about-you' && (
                  <div
                    className={`hidden ml-[15px] lg:space-x-[28px] md:grid md:grid-cols-2  lg:flex `}
                  >
                    {[
                      {
                        navItem: 'Updraft Credit',
                        path: 'https://www.updraft.com/updraftcredit/',
                      },
                      {
                        navItem: 'Credit Report',
                        path: 'https://www.updraft.com/credit-report/',
                      },
                      {
                        navItem: 'Money Talks',
                        path: 'https://www.updraft.com/money-talks/',
                      },
                    ].map((item, index) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        key={index}
                        href={item.path}
                        style={{
                          ...AppStyles.Body16Demi,
                          color: color.BlurpleWhite,
                        }}
                        className=" hover:bg-T_T_7 rounded-md py-2 px-3 "
                      >
                        {item.navItem}
                      </a>
                    ))}
                  </div>
                )}
              </>
            )} */}
            <div className="flex">
              {localStorage.getItem('login') ? (
                <div className="hidden space-x-2 md:flex">
                  <OutllineButton
                    text={'Logout'}
                    id={'logout-outline'}
                    onClick={async () => {
                      await window.Intercom('shutdown')
                      await window.Intercom('boot', {
                        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                      })
                      await Auth.signOut()
                      setUserId(analytics, null)
                      Sentry.setUser(null)
                      if (window.gtag) {
                        window.gtag('set', { user_id: null })
                      }
                      window.Intercom('boot', {
                        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                      })
                      localStorage.removeItem('login')
                      window.location.href = '/'
                      localStorage.removeItem(
                        `journeyStep-${token?.email}`,
                        '/about-you',
                      )
                    }}
                  />
                </div>
              ) : (
                <div className="hidden space-x-2 md:flex">
                  {location?.pathname !== '/about-you' && (
                    <>
                      <OutllineButton
                        id={'login-top'}
                        text={'Login'}
                        onClick={() => {
                          localStorage.clear()
                          setIsModalOpen(true)
                        }}
                        className={' md:w-[73px]'}
                      />
                      <PrimaryButton
                        id={'eligibility-check-top'}
                        text={'Check Eligibility'}
                        onClick={() => {
                          navigate('/about-you')
                        }}
                        className={' w-full md:w-[149px] '}
                      />
                    </>
                  )}
                </div>
              )}
              <div>
                <button
                  id="menu-btn"
                  className="block hamburger md:hidden focus:outline-none"
                  onClick={() => setIsDrawerOpen(true)}
                >
                  {toggleMenu ? (
                    <CloseIcon fill={color.Tint1} />
                  ) : (
                    <MenuIcon fill={color.BlurpleTeal} />
                  )}
                </button>
              </div>
            </div>
          </div>
          {toggleMenu && (
            <div className="md:hidden">
              {/* {location?.pathname !== '/about-you'} */}
              <div className="p-6 md:p-0  space-y-6 flex flex-col w-full md:hidden">
                {[
                  {
                    navItem: 'Updraft Credit',
                    path: 'https://www.updraft.com/updraftcredit/',
                  },
                  {
                    navItem: 'Credit Report',
                    path: 'https://www.updraft.com/credit-report/',
                  },
                  {
                    navItem: 'Money Talks',
                    path: 'https://www.updraft.com/money-talks/',
                  },
                ].map((item, index) => (
                  <a
                    key={index}
                    href={item.path}
                    style={{
                      ...AppStyles.Body16Demi,
                      color: color.BlurpleWhite,
                    }}
                    className=" hover:bg-T_T_7 rounded-md py-2 px-3 "
                  >
                    {item.navItem}
                  </a>
                ))}
              </div>
              <div
                style={{ borderColor: color.MidGrey }}
                className=" flex items-center space-x-3 border-t p-6 "
              >
                <OutllineButton
                  text={'Login'}
                  id={'login-toggle'}
                  onClick={() => {
                    localStorage.clear()
                    setIsModalOpen(true)
                  }}
                  className={'w-full'}
                />
                <PrimaryButton
                  text={'Check Eligibility'}
                  id={'eligibility-check'}
                  className={'w-full'}
                />
              </div>
            </div>
          )}
        </nav>
        {isSmallScreen && (
          <MobileDrawer
            isOpen={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          >
            <nav className=" md:py-[30px] md:px-[60px] lg:px-[120px] md:space-x-10">
              <div className="p-6 md:p-0 flex w-full  items-center justify-between h-[72px] border-b-[1px]">
                <div className=" md:w-[142px] w-[120px] md:h-[24px] h-[28px]">
                  <img src={darkMode ? LogoWhite : Logo} alt="" />
                </div>

                <div
                  className={`hidden lg:space-x-[28px] md:grid md:grid-cols-2  lg:flex `}
                >
                  {[
                    {
                      navItem: 'Updraft Credit',
                      path: 'https://www.updraft.com/updraftcredit/',
                    },
                    {
                      navItem: 'Credit Report',
                      path: 'https://www.updraft.com/credit-report/',
                    },
                    {
                      navItem: 'Money Talks',
                      path: 'https://www.updraft.com/money-talks/',
                    },
                  ].map((item, index) => (
                    <a
                      key={index}
                      href={item.path}
                      style={{
                        ...AppStyles.Body16Demi,
                        color: color.BlurpleWhite,
                      }}
                      className=" border-t hover:bg-T_T_7 rounded-md py-2 px-3 "
                    >
                      {item.navItem}
                    </a>
                  ))}
                </div>

                <div className="flex">
                  <div className="hidden space-x-2 md:flex">
                    <OutllineButton
                      id={'logout-navbar-top'}
                      text={'Logout'}
                      onClick={async () => {
                        await window.Intercom('shutdown')
                        await window.Intercom('boot', {
                          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                        })
                        await Auth.signOut()
                        setUserId(analytics, null)
                        Sentry.setUser(null)
                        if (window.gtag) {
                          window.gtag('set', { user_id: null })
                        }
                        window.Intercom('boot', {
                          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                        })
                        localStorage.removeItem('login')
                        window.location.href = '/'
                        localStorage.removeItem(
                          `journeyStep-${token?.email}`,
                          '/about-you',
                        )
                      }}
                    />
                  </div>
                  <div className="hidden space-x-2 md:flex">
                    <OutllineButton
                      text={'Login'}
                      id={'login'}
                      onClick={() => {
                        localStorage.clear()
                        setIsModalOpen(true)
                      }}
                      className={' md:w-[73px]'}
                    />
                    <PrimaryButton
                      text={'Check Eligibility'}
                      id={'eligibility-check'}
                      className={' w-full md:w-[149px] '}
                    />
                  </div>
                  <div>
                    <button
                      id="menu-btn"
                      className="block hamburger md:hidden focus:outline-none"
                      onClick={() => setIsDrawerOpen(false)}
                    >
                      <CloseIcon fill={color.Tint1} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="md:hidden">
                <div className="p-6 md:p-0  space-y-6 flex flex-col w-full md:hidden">
                  {[
                    {
                      navItem: 'Updraft Credit',
                      path: 'https://www.updraft.com/updraftcredit/',
                    },
                    {
                      navItem: 'Credit Report',
                      path: 'https://www.updraft.com/credit-report/',
                    },
                    {
                      navItem: 'Money Talks',
                      path: 'https://www.updraft.com/money-talks/',
                    },
                  ].map((item, index) => (
                    <a
                      key={index}
                      href={item.path}
                      style={{
                        ...AppStyles.Body16Demi,
                        color: color.BlurpleWhite,
                      }}
                      className=" hover:bg-T_T_7 rounded-md py-2 px-3 "
                    >
                      {item.navItem}
                    </a>
                  ))}
                </div>
                {localStorage.getItem('login') ? (
                  <div
                    style={{ borderColor: color.MidGrey }}
                    className=" flex items-center space-x-3 border-t p-6 h-[96px]"
                  >
                    <OutllineButton
                      id={'logout-navbar'}
                      text={'Logout'}
                      onClick={async () => {
                        await window.Intercom('shutdown')
                        await window.Intercom('boot', {
                          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                        })
                        await Auth.signOut()
                        setUserId(analytics, null)
                        Sentry.setUser(null)
                        if (window.gtag) {
                          window.gtag('set', { user_id: null })
                        }
                        window.Intercom('boot', {
                          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                        })
                        localStorage.removeItem('login')
                        window.location.href = '/'
                        localStorage.removeItem(
                          `journeyStep-${token?.email}`,
                          '/about-you',
                        )
                      }}
                      className={' md:w-[80px]'}
                    />
                  </div>
                ) : (
                  <div
                    style={{ borderColor: color.MidGrey }}
                    className=" flex items-center space-x-3 border-t p-6 h-[96px]"
                  >
                    <OutllineButton
                      text={'Login'}
                      id={'login'}
                      onClick={() => {
                        setIsModalOpen(true)
                        setIsDrawerOpen(false)
                        localStorage.clear()
                      }}
                      className={'w-full'}
                    />
                    <PrimaryButton
                      text={'Check Eligibility'}
                      id={'eligibility-check-primary'}
                      className={'w-full'}
                      onClick={() => {
                        navigate('/about-you')
                        setIsDrawerOpen(false)
                      }}
                    />
                  </div>
                )}
              </div>
            </nav>
          </MobileDrawer>
        )}
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
            dispatch({ type: ActionTypes.OPEN_LOGIN_CLOSE })
          }}
        >
          <div className=" space-y-6">
            <ModalHeader Icon={UserIcon} title={'Login to Your Account'} />
            <div className="space-y-6">
              <FormInput
                placeholder={'Email Address'}
                type={'email'}
                onChange={(e) => handleChange(e)}
                name={'email'}
                autoComplete={'email'}
                value={values.email}
              />
              <FormInput
                placeholder={'Password'}
                type={'password'}
                onChange={(e) => handleChange(e)}
                name={'password'}
                autoComplete={'current-password'}
                value={values.password}
              />
            </div>
            <p className="text-center" style={{ color: '#FF619A' }}>
              {loginErr}
            </p>
            <div className=" space-y-2">
              <OutllineButton
                id={'forgot-password-modal'}
                text={'Forgot Password?'}
                onClick={() => {
                  setIsModalOpen(false)
                  dispatch({ type: ActionTypes.OPEN_LOGIN_CLOSE })
                  setopenForgetPassModal(true)
                  setValues({
                    ...values,
                    password: '',
                  })
                }}
              />
              <OnEnter
                actionFunction={() => {
                  if (!values.email || !values.password) {
                    return
                  }
                  if (openForgetPassModal) {
                    sendEmail()
                  } else if (openNewPassModal) {
                    createNewPassword()
                  } else {
                    handleLogin()
                    window.heap &&
                      window.heap.track('signup_password_set', {
                        action_name: 'tap',
                        action_details: 'SignIn',
                      })
                  }
                }}
              />
              <PrimaryButton
                text={
                  loading ? (
                    <>
                      <Spinner />
                    </>
                  ) : (
                    'Continue'
                  )
                }
                id={'login-modal'}
                className={' w-full'}
                disabled={!values.email || !values.password}
                onClick={() => {
                  // localStorage.clear()
                  if (loading) {
                    return
                  }
                  handleLogin()
                  window.heap &&
                    window.heap.track('signup_password_set', {
                      action_name: 'tap',
                      action_details: 'SignIn',
                    })
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={isModalOpenOtp}
          onClose={() => {
            // setIsModalOpen(false)
            // setReSignUp(true)
            setIsModalOpenOtp(false)
          }}
        >
          <div className=" space-y-6">
            <div
              className=" w-full flex flex-col items-center justify-center space-y-6 "
              style={{}}
            >
              <div className="flex w-fit relative items-center justify-center">
                <Iphone fill={color.BlurpleTeal} />
                <div
                  style={{ backgroundColor: color.Teal }}
                  className=" absolute top-[0px] right-[5px] rounded-md transform rotate-45 flex items-center justify-center  w-[24px] h-[24px] "
                >
                  <p className="transform -rotate-45  text-white text-12 font-bold font-maisonBold w-full text-center">
                    1
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <p style={{ ...AppStyles.Title1, color: color.Plum }}>
                  We've text you a code
                </p>
                <p style={{ ...AppStyles.Subheading1, color: color.Grey }}>
                  Please enter it to verify your phone number
                </p>
              </div>
            </div>
            <CustomOTPInput otp={otp} setOtp={setOtp} isValidCode={valid} />
            {valid && (
              <p
                style={{ ...AppStyles.Caption1, color: color.SoftPink }}
                className=" text-center "
              >
                Sorry, that code is incorrect
              </p>
            )}
            {forgotErr && (
              <p
                style={{ ...AppStyles.Caption1, color: color.SoftPink }}
                className=" text-center "
              >
                {forgotErr}
              </p>
            )}
            {resendToggle && (
              <div
                style={{
                  ...AppStyles.SentNewCode,
                  margin: '24px 0',
                  cursor: 'pointer',
                  width: !isSmallScreen ? '360px' : '279px',
                }}
              >
                <img src={Thumb} className="w-[16px] h-[16px]" />
                <p
                  style={{
                    ...AppStyles.SentNewCodeText,
                  }}
                  className={` text-brightPurple font-maisonMedium rounded-xl btn-medium transition md:block dark:text-dark-darkTeal dark:border-dark-darkBorderGrey`}
                >
                  We’ve sent you a new code
                </p>
              </div>
            )}
            <div className=" space-y-2">
              <OutllineButton
                id={'navbar-send-me-code'}
                text={'Send me a new code'}
                onClick={async () => {
                  setOtp(new Array(6).fill(''))
                  // handleSignUp(true);
                  resendOtp(values.email)
                }}
              />

              <PrimaryButton
                id={'navbar-submit'}
                disabled={!otp?.every((char) => char !== '') || valid}
                className={'w-full'}
                text={loading ? <Spinner /> : 'Finish'}
                onClick={() => {
                  if (loading) {
                    return
                  }
                  window.heap &&
                    window.heap.track('signup_finished', {
                      action_name: 'tap',
                      action_details: 'SignUpFinished',
                    })
                  handleVerification(otp.join(''))
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={openForgetPassModal}
          onClose={() => setopenForgetPassModal(false)}
        >
          <div className=" space-y-6">
            <ModalHeader
              Icon={UserIcon}
              title={'Forgot Password'}
              description={
                'Enter your email address and we’ll send you a code to reset your password.'
              }
            />
            <FormInput
              placeholder={'Email Address'}
              type={'email'}
              onChange={(e) => handleChange(e)}
              name={'email'}
              autoComplete={'email'}
              value={values.email}
            />
            <div style={{ color: '#FF619A', textAlign: 'center' }}>
              {inValidEmailForForgot}
            </div>
            <OnEnter
              actionFunction={() => {
                if (values.email === '') {
                  return
                }
                sendEmail()
              }}
            />
            <div className=" ">
              <PrimaryButton
                id={'submit-primarybtn'}
                disabled={values.email === ''}
                text={loading2 ? <Spinner /> : 'Submit'}
                className={' w-full'}
                onClick={() => {
                  if (loading2) {
                    return
                  }
                  sendEmail()
                }}
              />
            </div>
          </div>
        </Modal>

        <Modal isOpen={openCodeModal} onClose={() => setopenCodeModal(false)}>
          <div className=" space-y-6  ">
            <div className=" w-full flex flex-col items-center justify-center space-y-6 ">
              <div className="flex w-fit relative items-center justify-center">
                <Iphone fill={color.BlurpleTeal} />
                <div
                  style={{ backgroundColor: color.Teal }}
                  className=" absolute top-[0px] right-[5px] rounded-md transform rotate-45 flex items-center justify-center w-[24px] h-[24px] "
                >
                  <p className="transform -rotate-45  text-white text-12 font-bold font-maisonBold w-full text-center">
                    1
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <p style={{ ...AppStyles.Title1, color: color.Plum }}>
                  Please enter your code
                </p>
                <p
                  style={{
                    ...AppStyles.Subheading1,
                    color: color.Grey,
                    textAlign: 'center',
                  }}
                >
                  Depending on how you created your Updraft account you’ll
                  receive a text or an email containing a verification code
                </p>
              </div>
            </div>
            <CustomOTPInput
              type={'number'}
              otp={otp}
              setOtp={setOtp}
              isValidCode={valid}
            />
            {resendToggle && (
              <div className=" flex items-center justify-center">
                <div
                  style={{
                    ...AppStyles.SentNewCode,
                    cursor: 'pointer',
                    width: !isSmallScreen ? 'fit-content' : '100%',
                  }}
                >
                  <img src={Thumb} className="w-[16px] h-[16px]" />
                  <p
                    style={{
                      ...AppStyles.SentNewCodeText,
                    }}
                    className={` text-brightPurple font-maisonMedium rounded-xl btn-medium transition md:block dark:text-dark-darkTeal dark:border-dark-darkBorderGrey`}
                  >
                    We’ve sent you a new code
                  </p>
                </div>
              </div>
            )}

            <div
              style={{
                ...AppStyles.Caption1,
                color: color.SoftPink,
                textAlign: 'center',
              }}
            >
              {forgotErr}
            </div>
            {valid && (
              <p
                style={{ ...AppStyles.Caption1, color: color.SoftPink }}
                className="text-center"
              >
                We couldn’t validate your code
              </p>
            )}
            <div className="space-y-2">
              <OutllineButton
                id={'new-code-navbar'}
                text={'Send me a new code'}
                onClick={() => {
                  setOtp(new Array(6).fill(''))
                  sendEmailAgain()
                }}
              />

              <OnEnter
                actionFunction={() => {
                  if (!otp?.every((element) => element !== '') || valid) {
                    return
                  }
                  submitVerificationCode()
                }}
              />
              <PrimaryButton
                id={'submit-code-navbar'}
                disabled={!otp?.every((element) => element !== '') || valid}
                className={'w-full'}
                text={loading ? <Spinner /> : 'Submit'}
                onClick={() => {
                  if (loading) {
                    return
                  }
                  submitVerificationCode()
                }}
              />
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={openNewPassModal}
          onClose={() => setopenNewPassModal(false)}
        >
          <div className=" space-y-6">
            <ModalHeader
              Icon={KeyIcon}
              title={'Let’s create your new password'}
            />

            <FormInput
              placeholder={'Password'}
              type={'password'}
              onChange={(e) => handleChange(e)}
              name={'password'}
              autoComplete={'new-password'}
              value={values.password}
            />
            <div style={{ color: '#FF619A', textAlign: 'center' }}>
              {createNewPassErr}
            </div>
            <PrimaryButton
              id={'submit-passwordbtn'}
              disabled={values.password === ''}
              text={loading2 ? <Spinner /> : 'Submit'}
              className={' w-full'}
              onClick={() => {
                if (loading2) {
                  return
                }
                createNewPassword()
              }}
            />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default TopNavBar
