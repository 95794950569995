import React from 'react'
import { AppStyles } from '../../../assets/styles/AppStyles'
import {
  ArrowHeadDownIcon,
  ArrowHeadRightIcon,
  BankNoteFlyIcon,
  CheckIcon,
  ClockIcon,
  DocumentIcon,
  InfoIcon,
  UpdraftLogoIcon,
} from '../../../assets/AppIcons'
import { useLocation } from 'react-router'
import DefaultPage from '../DefaultPage'
import { useTheme } from '../../../utils/ThemeColorContext'
import DocumentsCol from '../../../components/lists/DocumentsCol'
import PrimaryButton from '../../../components/buttons/PrimaryButton'

function LoanDetails() {
  const location = useLocation()
  const loan = location.state.loan
  const { color, isSmallScreen } = useTheme()

  function currencyToNumber(currencyString) {
    return parseFloat(currencyString.replace(/[^0-9.-]+/g, ''))
  }
  const paid = currencyToNumber(loan?.amountPaid)
  const unpaid = currencyToNumber(loan?.amountUnPaid)
  const taskProgress = (paid / (paid + unpaid)) * 100

  const items = [
    {
      id: 1,
      title: 'Schedule & Payments',
      Icon: ClockIcon,
    },
    {
      id: 2,
      title: 'Payoff Summary',
      Icon: BankNoteFlyIcon,
    },
    {
      id: 1,
      title: 'My Agreements',
      label: '3 Documents',
      Icon: DocumentIcon,
    },
  ]

  return (
    <DefaultPage>
      <div className="relative container mx-auto md:w-[564px]   space-y-10  px-6 md:px-0 ">
        <div
          style={{ backgroundColor: color.White, borderColor: color.MidGrey }}
          className=" border p-4 rounded-2xl  space-y-4"
        >
          <div
            style={{
              height: isSmallScreen && '128px',
              padding: isSmallScreen && 0,
            }}
            className="  flex md:justify-between justify-center items-center py-4"
          >
            <div
              className="flex md:space-x-4 space-x-0 items-center"
              style={{
                flexDirection: isSmallScreen && 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{ backgroundColor: color.Blurple }}
                className="w-14 h-14 rounded-full flex items-center justify-center md:mb-[0px] mb-[8px]"
              >
                <UpdraftLogoIcon fill={color.White} />
              </div>
              <p style={{ ...AppStyles.BodyBold, color: color.GreyBlue }}>
                Updraft Credit
              </p>
              {isSmallScreen && (
                <p
                  style={{
                    fontFamily: 'MaisonMedium","sans-serif',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#6C819C',
                  }}
                >
                  UD00001
                </p>
              )}
            </div>
            {!isSmallScreen && (
              <div
                style={{
                  backgroundColor: color.LightGrey,
                  borderColor: color.Tint3,
                }}
                className="flex items-center space-x-4  border rounded-2xl p-4 "
              >
                <div className="flex items-center ">
                  <p style={{ ...AppStyles.BodyBold, color: color.Grey }}>
                    Loan reference{' '}
                  </p>
                  <p
                    style={{ ...AppStyles.BodyBold, color: color.BlurpleTeal }}
                    className="pl-10"
                  >
                    {loan?.referenceID}
                  </p>
                </div>
                <ArrowHeadDownIcon fill={color.BlurpleTeal} />
              </div>
            )}
          </div>
          {!isSmallScreen && (
            <div
              style={{
                backgroundColor: color.Positive15,
              }}
              className=" rounded-2xl p-4 flex items-center space-x-4"
            >
              <div
                style={{ backgroundColor: color.Teal }}
                className=" w-5 h-5 flex items-center justify-center rounded-full  "
              >
                <CheckIcon fill={color.White} className={' w-3 h-3'} />
              </div>
              <p style={{ ...AppStyles.BodyBold, color: color.GreyBlue }}>
                Account up to date
              </p>
            </div>
          )}

          <div
            style={{
              backgroundColor: color.White,
              borderColor: color.MidGrey,
              // margin: 0,
            }}
            className=" md:border border-t md:rounded-2xl rounded-[0px] "
          >
            <div className="rounded-t-2xl p-4 space-y-2">
              <div className="rounded-b-2xl flex justify-between items-center">
                <div className="">
                  <p style={{ ...AppStyles.BodyBold, color: color.GreyBlue }}>
                    Amount Repaid
                  </p>
                  <p
                    style={{ ...AppStyles.BodyBold, color: color.BlurpleTeal }}
                  >
                    {loan?.amountPaid}
                  </p>
                </div>
                <div className=" space-y-1">
                  <div className="flex items-center space-x-1 ">
                    <p style={{ ...AppStyles.BodyBold, color: color.GreyBlue }}>
                      Left to Pay{' '}
                    </p>
                    <InfoIcon
                      fill={color.BlurpleTeal}
                      className={' w-4 h-4 '}
                    />
                  </div>
                  <p
                    className=" text-end"
                    style={{ ...AppStyles.BodyBold, color: color.BlurpleTeal }}
                  >
                    {loan?.amountUnPaid}
                  </p>
                </div>
              </div>
              <div className="relative">
                <div
                  style={{ backgroundColor: color.MidGrey }}
                  className="overflow-hidden h-2 text-xs flex rounded"
                >
                  <div
                    style={{
                      width: `${taskProgress}%`,
                      backgroundColor: color.Teal,
                    }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                  ></div>
                </div>
              </div>
            </div>
            {isSmallScreen && (
              <div
                style={{
                  backgroundColor: color.White,
                  marginBottom: '24px',
                  marginTop: '8x',
                }}
                className=" rounded-2xl p-4 flex items-center border-[1px] space-x-4"
              >
                <div
                  style={{ backgroundColor: color.Teal }}
                  className=" w-5 h-5 flex items-center justify-center rounded-full  "
                >
                  <CheckIcon fill={color.White} className={' w-3 h-3'} />
                </div>
                <p style={{ ...AppStyles.BodyBold, color: color.GreyBlue }}>
                  Account up to date
                </p>
              </div>
            )}
            <div
              style={{ backgroundColor: color.LightGrey }}
              className={`${isSmallScreen ? 'rounded-2xl' : 'rounded-b-2xl'
                } grid grid-cols-1 md:grid-cols-4`}
            >
              <div
                style={{
                  borderColor: color.MidGrey,
                  flexDirection: isSmallScreen && 'row-reverse',
                  borderBottom: isSmallScreen && '1px solid #fff',
                }}
                className="p-3 md:border-r min-h-[72px] flex w-full flex-row md:flex-col items-center md:justify-center justify-between"
              >
                <p style={{ ...AppStyles.Title4, color: color.BlurpleTeal }}>
                  {' '}
                  25/03/2023
                </p>
                <p style={{ ...AppStyles.Caption2, color: color.Grey }}>
                  {' '}
                  Next payment date
                </p>
              </div>
              <div
                style={{
                  borderColor: color.MidGrey,
                  flexDirection: isSmallScreen && 'row-reverse',
                  borderBottom: isSmallScreen && '1px solid #fff',
                }}
                className="p-3 md:border-r min-h-[72px] flex w-full flex-row md:flex-col items-center md:justify-center justify-between"
              >
                <p style={{ ...AppStyles.Title4, color: color.BlurpleTeal }}>
                  {' '}
                  17.9%
                </p>
                <p style={{ ...AppStyles.Caption2, color: color.Grey }}>
                  {' '}
                  Your interest rate
                </p>
              </div>
              <div
                style={{
                  borderColor: color.MidGrey,
                  flexDirection: isSmallScreen && 'row-reverse',
                  borderBottom: isSmallScreen && '1px solid #fff',
                }}
                className="p-3 md:border-r min-h-[72px] flex w-full flex-row md:flex-col items-center md:justify-center justify-between"
              >
                <p style={{ ...AppStyles.Title4, color: color.BlurpleTeal }}>
                  {' '}
                  £120.00
                </p>
                <p style={{ ...AppStyles.Caption2, color: color.Grey }}>
                  {' '}
                  Your loan fee
                </p>
              </div>
              <div
                style={{
                  borderColor: color.MidGrey,
                  flexDirection: isSmallScreen && 'row-reverse',
                  borderBottom: isSmallScreen && '1px solid #fff',
                }}
                className="p-3 min-h-[72px] flex w-full flex-row md:flex-col items-center md:justify-center justify-between"
              >
                <p style={{ ...AppStyles.Title4, color: color.BlurpleTeal }}>
                  {' '}
                  £5,000.00
                </p>
                <p style={{ ...AppStyles.Caption2, color: color.Grey }}>
                  {' '}
                  Amount borrowed
                </p>
              </div>
            </div>
          </div>
          {isSmallScreen ? (
            items.map((i, index) => (
              <div key={index} className="w-full space-y-6 ">
                <div
                  style={{
                    backgroundColor: color.White,
                    borderColor: color.MidGrey,
                    height: isSmallScreen && '88px',
                  }}
                  className="px-4 py-5 border rounded-2xl flex space-x-4  justify-between "
                >
                  <div className="flex space-x-4 items-center justify-between w-full">
                    <div className="flex space-x-4 items-center justify-between">
                      <i.Icon fill={color.BlurpleTeal} className={'w-6 h-6'} />
                      <p
                        style={{
                          ...AppStyles.BodyBold,
                          color: color.Plum,
                        }}
                      
                      >
                        {i.title} <br />{' '}
                        <span
                          style={{
                            fontWeight: '400',
                            fontSize: '12px',
                            color: '#6C819C',
                          }}
                        >
                          {i?.label}
                        </span>
                      </p>
                    </div>
                    <ArrowHeadRightIcon
                      fill={color.BlurpleTeal}
                      className={'w-4 h-4'}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4">
              <DocumentsCol docTitle={'Schedule & Payments'} Icon={ClockIcon} />
              <DocumentsCol
                docTitle={'Payoff Summary'}
                Icon={BankNoteFlyIcon}
              />
              <DocumentsCol
                docTitle={'My Agreements'}
                Icon={DocumentIcon}
                description="3 Documents"
              />
            </div>
          )}
        </div>
        <div className="md:w-[260px]">
          <PrimaryButton id={'make-a-payment-btn'} text={'Make a Payment'} className={'w-full'} />
        </div>
      </div>
    </DefaultPage>
  )
}

export default LoanDetails
