/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react'

function SwitchInput({ id, onChange, label, isChecked }) {
  return (
    <label className="flex cursor-pointer select-none items-center">
      <div className="relative">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
          className="sr-only"
        />
        <div
          className={`box block h-6 w-[42px] rounded-full ${
            isChecked ? 'bg-dark-darkTeal' : 'bg-TextGreyTint2'
          }`}
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.06)', // Apply shadow when checked
          }}
        ></div>
        <div
          style={{
            filter:
              'drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.15))',
          }}
          className={`absolute ${
            !isChecked ? 'left-[4px]' : 'right-[4px]'
          } top-1 flex h-4 w-4 items-center justify-center rounded-full bg-white transition `}
        ></div>
      </div>
    </label>
  )
}

export default SwitchInput
