export const lightmodeColors = {
  Blurple: '#5D1CD3',
  Plum: '#340D7A',
  DarkPurple: '#220A4D',
  White: '#FFFFFF',
  LightGrey: '#F5F7FC',
  MidGrey: '#ECEFF9',
  Grey: '#6C819C',
  GreyBlue: '#1F3550',
  Tint1: 'rgba(108, 129, 156, 0.4)',
  Tint2: 'rgba(108, 129, 156, 0.2)',
  Tint3: 'rgba(108, 129, 156, 0.05)',
  Tint4: 'rgba(256, 256, 256, 0.4)',
  Tint5: 'rgba(52, 13, 122, 0.3)',
  Tint6: 'rgba(93, 28, 211, 0.1)',
  Tint7: 'rgba(93, 28, 211, 0.05)',
  SoftPink: '#FF619A',
  HotPink: '#FF2B90',
  Yellow: '#FFAD00',
  Orange: '#FF8F00',
  Teal: '#50E3C2',
  Cyan: '#40AADE',
  // colors changing
  BlurpleBlurple: '#5D1CD3',
  BlurpleWhite: '#5D1CD3',
  WhiteBlurple: '#FFFFFF',
  BlurpleTeal: '#5D1CD3',
  WhiteBlack: '#FFFFFF',
  BlackWhite: '#000000',
  Negative15: 'rgba(255, 97, 154, 0.15)',
  Positive15: 'rgba(80, 227, 194, 0.15) ',
  Neutral15: 'rgba(255, 173, 0, 0.2)',

  //   Misc colors
  Truelayer: '#083454',
  Trustpilot: '#00B67A',
  WebFooter: '#23272A',
  Overlay: 'rgba(93, 28, 211, 0.25)',
}
export const darkmodeColors = {
  Blurple: '#0C0B1C',
  Plum: '#FFFFFF',
  DarkPurple: '#000000',
  White: '#17152C',
  LightGrey: '#131028',
  MidGrey: '#29243F',
  Grey: '#E7ECF1',
  GreyBlue: '#E7ECF1',
  Tint1: 'rgba(231, 236, 241, 0.4)',
  Tint2: 'rgba(231, 236, 241, 0.2)',
  Tint3: 'rgba(231, 236, 241, 0.2)',
  Tint4: 'rgba(256, 256, 256, 0.4)',
  Tint5: 'rgba(256, 256, 256, 0.05)',
  Tint6: 'rgba(256, 256, 256, 0.1)',
  Tint7: 'rgba(256, 256, 256, 0.05)',
  SoftPink: '#FF619A',
  HotPink: '#FF2B90',
  Yellow: '#FFAD00',
  Orange: '#FF8F00',
  Teal: '#50E3C2',
  Cyan: '#40AADE',
  // colors changing
  BlurpleBlurple: '#5D1CD3',
  BlurpleWhite: '#FFFFFF',
  WhiteBlurple: '#5D1CD3',
  BlurpleTeal: '#50E3C2',
  WhiteBlack: '#000000',
  BlackWhite: '#FFFFFF',

  //   Misc colors
  Truelayer: '#FFFFFF',
  Trustpilot: '#00B67A',
  WebFooter: '#17152C',
  Overlay: 'rgba(5, 3, 30, 0.75)',
}
