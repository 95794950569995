import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const OnEnter = ({ actionFunction }) => {
  const { buttonAnables } = useSelector((state) => state.dataReducer)
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && buttonAnables) {
        // Call the function to handle the action
        actionFunction()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [buttonAnables, actionFunction]) // Add props to dependency array to trigger effect when they change

  return null // Return null as this component doesn't render anything
}

OnEnter.propTypes = {
  actionFunction: PropTypes.func.isRequired,
}

export default OnEnter
