/* eslint-disable react/jsx-key */
import React from 'react'
import LoanDetailsCard from '../../../components/cards/LoanDetailsCard'
import DefaultPage from '../DefaultPage'
import { useTheme } from '../../../utils/ThemeColorContext'
import { AppStyles } from '../../../assets/styles/AppStyles'
import { loans } from '../../../api/dummyData'
import { useNavigate } from 'react-router'

function ManageLoans() {
  const { isSmallScreen, color } = useTheme()
  const navigate = useNavigate()
  return (
    <DefaultPage>
      <div className="relative container mx-auto md:w-[564px]   space-y-10   px-6 md:px-0 ">
        <div className=" space-y-2">
          <h1
            style={
              isSmallScreen
                ? { ...AppStyles.Title1, color: color.Plum }
                : { ...AppStyles.LargeTitle2, color: color.Plum }
            }
          >
            Your Loans
          </h1>
          <p
            style={
              isSmallScreen
                ? { ...AppStyles.Body, color: color.Grey }
                : { ...AppStyles.Subheading1, color: color.Grey }
            }
          >
            Lorem ipsum dolor sit amet consectetur.
          </p>
        </div>
        <div className=" space-y-4">
          {loans.map((loan) => (
            <LoanDetailsCard
              amountPaid={loan.amountPaid}
              amountUnPaid={loan.amountUnPaid}
              referenceID={loan.referenceID}
              onClick={() => {
                navigate('/loan-details', { state: { loan } })
              }}
            />
          ))}
        </div>
      </div>
    </DefaultPage>
  )
}

export default ManageLoans
