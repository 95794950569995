import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Notification from '../../../assets/images/Notification.png'
import offer from '../../../assets/images/offer.png'
import promotion from '../../../assets/images/promotion.png'
import { AppStyles } from '../../../assets/styles/AppStyles'
import Spinner from '../../../components/Spinner'
import SpinnerLarge from '../../../components/SpinnerLarge'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import AgreeToTerms from '../../../components/cards/AgreeToTerms'
import { getMarketingData } from '../../../redux/Actions/getMethods'
import { updateMarketingData } from '../../../redux/Actions/postMethods'
import { useTheme } from '../../../utils/ThemeColorContext'
import CheckInformationWrapper from '../CheckInformationWrapper'
import DefaultPage from '../DefaultPage'

function Marketing() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { marketingData, loadingWholeScreen } = useSelector(
    (state) => state.dataReducer,
  )
  const { loading, loading2 } = useSelector((state) => state.authReducer)
  const { color, isSmallScreen } = useTheme()
  const [promotions, setPromotion] = useState(true)
  const [partnerOffer, setPartnerOffer] = useState(false)

  useEffect(() => {
    dispatch(getMarketingData())
    if (marketingData?.status === 200) {
      setPartnerOffer(marketingData?.data[1]?.granted)
    } else if (!promotions) {
      setPromotion(marketingData?.data[0]?.granted)
    }
  }, [marketingData?.status === 200, promotions])

  const addPermissions = async () => {
    const success = await dispatch(
      updateMarketingData(marketingData?.data, partnerOffer, promotions),
    )
    if (success) {
      navigate('/manageloan')
    }
  }

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <div className="md:min-h-[800px] min-h-[600px] flex flex-col items-center justify-center ">
          <div className="relative container mx-auto md:w-6/12 space-y-10 p-6 ">
            <div className="h-full flex flex-col items-center justify-center space-y-10 ">
              <CheckInformationWrapper>
                <div className="w-full space-y-10">
                  <div className="flex flex-col items-center space-y-6">
                    <img src={Notification} className="w-[56px] h-[56px]" />
                    <h1
                      style={
                        isSmallScreen
                          ? {
                              ...AppStyles.Title1,
                              color: color.Plum,
                              width: '279px',
                            }
                          : {
                              ...AppStyles.Title1,
                              color: color.Plum,
                            }
                      }
                      className=" text-center"
                    >
                      {/* Get the best from Updraft */}
                      Your signed agreement is on its way to your email - enable the options below to get the best from Updraft.
                    </h1>
                    <p
                      style={
                        isSmallScreen
                          ? {
                              ...AppStyles.Body,
                              color: color.Grey,
                              margin: 0,
                              marginTop: '16px',
                            }
                          : {
                              fontSize: '16px',
                              fontWeight: '500',
                              lineHeight: '28px',
                              marginTop: '24px',
                              color: '#6C819C',
                              fontFamily: 'MaisonMedium","sans-serif',
                            }
                      }
                      className="leading-[24px] text-center md:w-[360px] w-full"
                    >
                      We’ll keep you updated on new features, services and
                      promotions. We’ll only ever nudge you when you need it and
                      you can opt out of these whenever you like.
                    </p>
                    {!loading && !loading2 ? (
                      <>
                        <div className="space-y-2 md:w-[360px] w-full">
                          <AgreeToTerms
                            setIsOn={setPromotion}
                            isOn={promotions}
                            title={
                              marketingData?.data &&
                              marketingData?.data[0]?.permission?.description
                            }
                            customHeight={'80px'}
                            icon={promotion}
                          />
                          <AgreeToTerms
                            setIsOn={setPartnerOffer}
                            isOn={partnerOffer}
                            title={
                              marketingData?.data &&
                              marketingData?.data[1]?.permission?.description
                            }
                            doAuto={true}
                            icon={offer}
                            customHeight={'56px'}
                          />
                        </div>
                        <PrimaryButton
                          id={'marketing-confirm-btn'}
                          text={loading ? <Spinner /> : 'Confirm'}
                          className={' w-full'}
                          onClick={() => {
                            if (loading) {
                              return
                            }
                            addPermissions()
                          }}
                        />
                      </>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              </CheckInformationWrapper>
            </div>
          </div>
        </div>
      )}
      {/* <Footer /> */}
    </DefaultPage>
  )
}
export default Marketing
