import { ActionTypes } from '../Constants/actionTypes'
import { Auth } from 'aws-amplify'
import api from '../../api/interceptor'
import captureException from '../../utils/SentryHelper'

function convertToYYYYMMDD(dateStr) {
  // Create a new Date object from the date string
  const date = new Date(dateStr)

  // Extract the year, month, and day
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are 0-indexed
  const day = date.getDate().toString().padStart(2, '0')

  // Return in YYYY-MM-DD format
  return `${year}-${month}-${day}`
}

export const doSignUp = ({
  values,
  selectedDate,
  formattedNumber,
  setErr,
  noErrorMessage,
  signUpPlatform,
  alreadySignedUp,
}) => {
  return async (dispatch) => {
    let errorTimeout
    const setErrorTimeout = (time) => {
      // Clear the previous timeout and start a new one
      clearTimeout(errorTimeout)

      errorTimeout = setTimeout(
        () => {
          setErr('')
        },
        time,
      )
    }
    
    const regex = /^\+447\d{9}$/
    if (!regex.test(`+${formattedNumber}`)) {
      setErr('Invalid phone number format - 07XXXXXXXXX')
      setErrorTimeout(10000)
    } else {
      try {
        dispatch({ type: ActionTypes.LOADING_SUCCESS })
        const date = new Date()
        const isoDate = date.toISOString()
        const res = await Auth.signUp({
          username: values?.email?.toLowerCase(),
          password: values?.password,
          attributes: {
            email: values?.email?.toLowerCase(), // optional
            given_name: values?.first_name,
            family_name: values?.surname,
            birthdate: convertToYYYYMMDD(selectedDate),
            phone_number: `+${formattedNumber}`,
            'custom:date_terms_agreed': isoDate,
            'custom:sign_up_platform': signUpPlatform,
          },
          autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: true,
          },
          validationData: [], // Add this line to indicate you want to use verification code.
          autoConfirm: false, // Set auto confirmation to false
        })
        dispatch({ type: ActionTypes.LOADING_FAIL })
        return res
      } catch (error) {
        dispatch({ type: ActionTypes.LOADING_FAIL })
        if (noErrorMessage) {
          captureException(error)
          setErr('')
        } else {
          if (
            error?.message === 'An account with the given email already exists.'
          ) {
            alreadySignedUp()
          } else if (error?.message === 'Invalid email address format.') {
            setErr('Please enter a valid email address to continue.')
            setErrorTimeout(10000)
          } else if (
            error?.message ===
            'Password did not conform with policy: Password not long enough'
          ) {
            setErr('Minimum 8 characters')
            setErrorTimeout(10000)
          } else {
            captureException(error)
            setErr(error.message)
            setErrorTimeout(10000)
          }
        }
      }
    }
  }
}

export const resetPhone = (values, formattedNumber) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.LOADING_SUCCESS2 })
    const data = {
      phone: `+${formattedNumber}`,
    }
    const sendOtp = await api.post(`authentication/user/resetphone/${values.email?.toLowerCase()}/`, data)
    if (sendOtp?.data?.success) {
      return sendOtp
    }
    return sendOtp
  }
}

export const doSignIn = (values, setLoginErr) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_SUCCESS })
      const res = await Auth.signIn(values.email?.toLowerCase(), values.password)
      dispatch({ type: ActionTypes.LOADING_FAIL })
        return res
    } catch (error) {
      dispatch({ type: ActionTypes.LOADING_FAIL })

      setLoginErr(error.message)
      setTimeout(() => {
        setLoginErr('')
      }, 15000)
      console.log(error.message)
      captureException(error)
    }
  }
}
