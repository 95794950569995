/* eslint-disable react/prop-types */
import React from 'react'
import { AppStyles } from '../../assets/styles/AppStyles'
import { useTheme } from '../../utils/ThemeColorContext'

function RadioGroupInput({
  options,
  name,
  onChange,
  selectedValue,
  onTextChange,
  compName,
}) {
  const { color } = useTheme()

  function titleCase(input) {
    const words = input.split(' ')
    const capitalizedWords = words.map((word) => {
      if (isNaN(parseInt(word))) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase()
      } else {
        return word
      }
    })
    return capitalizedWords.join(' ')
  }
  if (compName === 'findAddress') {
    return (
      <div>
        <div
          style={{ backgroundColor: color.LightGrey }}
          className="flex flex-col justify-between w-full rounded-2xl "
        >
          {Array.isArray(options) &&
            options.map((option, i) => {
              if (
                selectedValue ===
                (option?.label2
                  ? `${option?.label && titleCase(option?.label)}, ${
                      option?.label2 && titleCase(option?.label2)
                    }, ${option?.town && titleCase(option?.town)} ${
                      option?.postcode && option?.postcode
                    }`
                  : `${option?.label && titleCase(option?.label)}, ${
                      option?.town && titleCase(option?.town)
                    } ${option?.postcode && option?.postcode}`)
              ) {
                const label = option?.label
                const label2 = option?.label2
                const line_3 = option?.line_3
                const town = option?.town
                const postcode = option?.postcode
                const udprn = option?.udprn
                const dps = option?.dps
                const department_name = option?.department_name
                const organisation_name = option?.organisation_name
                const traditional_county = option?.traditional_county
                const postal_county = option?.postal_county
                const data = {
                  label,
                  label2,
                  line_3,
                  town,
                  postcode,
                  udprn,
                  dps,
                  department_name,
                  organisation_name,
                  traditional_county,
                  postal_county,
                }
                localStorage.setItem('findAddress', JSON.stringify(data))
              }
              return (
                <div
                  key={option.id}
                  style={{
                    backgroundColor: color.LightGrey,
                    borderColor: color.MidGrey,
                  }}
                  className="cursor-pointer w-full flex justify-between items-center  p-4 border-b-[1px]  last:border-b-0 first:rounded-t-3xl last:rounded-b-3xl"
                >
                  <label
                    htmlFor={option.id + i}
                    style={{
                      ...AppStyles.BodyBold,
                      color: color.Plum,
                      textTransform: 'capitalize',
                    }}
                    className="cursor-pointer "
                    onClick={() => {
                      onTextChange(
                        option?.label2
                          ? `${option?.label && titleCase(option?.label)}, ${
                              option?.label2 && titleCase(option?.label2)
                            }, ${option?.town && titleCase(option?.town)} ${
                              option?.postcode && option?.postcode
                            }`
                          : `${option?.label && titleCase(option?.label)}, ${
                              option?.town && titleCase(option?.town)
                            } ${option?.postcode && option?.postcode}`,
                      )
                    }}
                  >
                    {/* {option?.town
                ? `${
                    (option.label,
                    option?.town && option?.town,
                    option?.postcode && option?.postcode)
                  }`
                : option.label} */}
                    {option?.town
                      ? option?.label2
                        ? `${option?.label && titleCase(option?.label)}, ${
                            option?.label2 && titleCase(option?.label2)
                          }, ${option?.town && titleCase(option?.town)} ${
                            option?.postcode && option?.postcode
                          }`
                        : `${option?.label && titleCase(option?.label)}, ${
                            option?.town && titleCase(option?.town)
                          } ${option?.postcode && option?.postcode}`
                      : option?.label}
                  </label>
                  <input
                    id={option.id}
                    name={name}
                    type="radio"
                    style={{ marginLeft: 16 }}
                    value={
                      option?.label2
                        ? `${option?.label && titleCase(option?.label)}, ${
                            option?.label2 && titleCase(option?.label2)
                          }, ${option?.town && titleCase(option?.town)} ${
                            option?.postcode && option?.postcode
                          }`
                        : `${option?.label && titleCase(option?.label)}, ${
                            option?.town && titleCase(option?.town)
                          } ${option?.postcode && option?.postcode}`
                    }
                    checked={
                      selectedValue ===
                      (option?.label2
                        ? `${option?.label && titleCase(option?.label)}, ${
                            option?.label2 && titleCase(option?.label2)
                          }, ${option?.town && titleCase(option?.town)} ${
                            option?.postcode && option?.postcode
                          }`
                        : `${option?.label && titleCase(option?.label)}, ${
                            option?.town && titleCase(option?.town)
                          } ${option?.postcode && option?.postcode}`)
                    }
                    onChange={onChange}
                    className="relative w-6 h-6 form-radio cursor-pointer radio-white-dot bg-TextGreyTint2 text-TextGreyTint2 border-GreyTint focus:ring-transparent  dark:text-dark-darkTeal mr-2"
                  />
                </div>
              )
            })}
        </div>
      </div>
    )
  } else if (compName === 'circumstances') {
    return (
      <div>
        <div
          style={{ backgroundColor: color.LightGrey }}
          className="flex flex-col justify-between w-full rounded-2xl "
        >
          {options.map((option) => (
            <div
              key={option.id}
              style={{
                backgroundColor: color.LightGrey,
                borderColor: color.MidGrey,
              }}
              className=" w-full flex justify-between items-center  p-4 border-b-[1px]  last:border-b-0 first:rounded-t-3xl last:rounded-b-3xl"
              onClick={() => {
                onChange({ target: { value: option.id } })
              }}
            >
              <label
                htmlFor={option.id}
                style={{ ...AppStyles.BodyBold, color: color.Plum }}
                className="cursor-pointer "
              >
                {option.label}
              </label>
              <input
                id={option.id}
                name={name}
                type="radio"
                value={option.id}
                checked={selectedValue === option.id}
                onChange={onChange}
                className="relative w-6 h-6 form-radio cursor-pointer radio-white-dot bg-TextGreyTint2 text-TextGreyTint2 border-GreyTint focus:ring-transparent  dark:text-dark-darkTeal mr-2"
              />
            </div>
          ))}
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div
          style={{ backgroundColor: color.LightGrey }}
          className="flex flex-col justify-between w-full rounded-2xl "
        >
          {options.map((option) => (
            <div
              key={option.id}
              style={{
                backgroundColor: color.LightGrey,
                borderColor: color.MidGrey,
              }}
              onClick={() => {
                onChange({ target: { value: option.id } })
              }}
              className=" w-full flex justify-between items-center  p-4 border-b-[1px]  last:border-b-0 first:rounded-t-3xl last:rounded-b-3xl"
            >
              <label
                htmlFor={option.id}
                style={{ ...AppStyles.BodyBold, color: color.Plum }}
                className="cursor-pointer "
              >
                {option.label}
              </label>
              <input
                id={option.id}
                name={name}
                type="radio"
                value={option.id}
                checked={selectedValue === option.id}
                onChange={onChange}
                className="relative w-6 h-6 form-radio cursor-pointer radio-white-dot bg-TextGreyTint2 text-TextGreyTint2 border-GreyTint focus:ring-transparent  dark:text-dark-darkTeal mr-2"
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default RadioGroupInput
