/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import { useTheme } from '../../../utils/ThemeColorContext'
import { AppStyles } from '../../../assets/styles/AppStyles'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import { conversionAPI } from '../../../redux/Actions/postMethods'

import CheckInformationWrapper from '../CheckInformationWrapper'
import DefaultPage from '../DefaultPage'
import Image1 from '../../../assets/images/Image.png'
import { getApplications } from '../../../redux/Actions/getMethods'
import SpinnerLarge from '../../../components/SpinnerLarge'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import AddAccount from '../../../components/cards/AddAccount'
import { useState } from 'react'

const LoanReferred = () => {
  const dispatch = useDispatch()
  const { getApplication, loadingWholeScreen } = useSelector(
    (state) => state.dataReducer,
  )
  const { token } = useSelector((state) => state.authReducer)
  const { color, isSmallScreen } = useTheme()
  const navigate = useNavigate()

  // useEffect(() => {
  //   if (token?.email) {
  //     localStorage.setItem(`journeyStep-${token?.email}`, '/loanreferred')
  //   }
  // }, [token?.email])

  useEffect(() => {
    const intervalId = setInterval(() => {
      detectStatus()
    }, 60000) // 60000 milliseconds is one minute

    return () => clearInterval(intervalId) // Clean up the interval when the component unmounts
  }, [getApplication[0]?.id])

  const detectStatus = () => {
    dispatch(getApplications())
  }

  useEffect(() => {
    if (getApplication[0]?.status === 'DECLINED') {
      navigate('/failed-offer')
    } else if (getApplication[0]?.status === 'REFERRED') {
      // do nothing
    } else if (
      getApplication[0]?.status === 'APPROVED' &&
      getApplication[0]?.offer_type === 'UPDRAFT_CREDIT_INTEREST_ONLY'
    ) {
      navigate('/loanapproved')
    } else if (
      getApplication[0]?.status === 'APPROVED' &&
      getApplication[0]?.offer_type !== 'UPDRAFT_CREDIT_INTEREST_ONLY'
    ) {
      navigate('/loanapprovedfee')
    }
  }, [getApplication[0]?.id, getApplication[0]?.status])

  // ==================== Checking user status  =======================//
  useEffect(() => {
    if (!getApplication[0]?.id) {
      const temp = async () => {
        dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
        const myID = await dispatch(getApplications())
        if (
          myID?.data[0]?.status === 'REFERRED' &&
          JSON.parse(localStorage.getItem(`bankConfirm-${token?.email}`))
        ) {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          // do nothing
        } else {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/address-detail')
        }
      }
      temp()
    }
  }, [!getApplication[0]?.id])

  const goToVerifyIncome = () => {
    localStorage.removeItem(`bankConfirm-${token?.email}`)
    localStorage.removeItem(`add_accounts-${token?.email}`)
    localStorage.setItem(`add-another-bank-account-${token?.email}`, true)
    navigate('/verify-income/')
  }

  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (loadingWholeScreen) {
      return
    }
    const timer = setTimeout(() => {
      setVisible(true)
    }, 30000)
    return () => clearTimeout(timer)
  }, [loadingWholeScreen])

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <div className="md:flex-col md:w-[1015px] md:m-[auto] py-8 md:py-0 px-8 space-y-4 items-center">
          <CheckInformationWrapper img={Image1}>
            <div className="w-full space-y-8 md:space-y-10">
              <div className="flex flex-col items-center space-y-4 md:space-y-6 md:pb-[36px]">
                <h1
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Title1,
                          color: color.Plum,
                        }
                      : {
                          ...AppStyles.LargeTitle2,
                          color: color.Plum,
                        }
                  }
                  className="text-center"
                >
                  We’re{' '}
                  <span
                    style={
                      isSmallScreen
                        ? { ...AppStyles.Title1, color: color.Yellow }
                        : { ...AppStyles.LargeTitle2, color: color.Yellow }
                    }
                  >
                    Checking
                  </span>{' '}
                  Your Information Now!
                </h1>
                <p
                  style={
                    isSmallScreen
                      ? {
                          ...AppStyles.Body,
                          color: color.Grey,
                        }
                      : {
                          ...AppStyles.Body16,
                          color: color.Grey,
                        }
                  }
                  className="text-center"
                >
                  Our underwriting team are looking at your application now,
                  they'll reach out to you with anything they require or a
                  decision via email.
                </p>
                
              </div>
            </div>
          </CheckInformationWrapper>
          <div className="flex items-center justify-center w-full  ">
            <div className={`fade-in ${visible ? 'visible' : ''} md:w-[456px]`}>
              { visible && (
                <AddAccount
                  onClick={() => {
                    if (visible) {
                      goToVerifyIncome()
                    }
                  }}
                  title="If you’ve been asked by our underwriters to add another account, you can do that here"
                  switched={true}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </DefaultPage>
  )
}
export default LoanReferred
