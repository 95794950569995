/* eslint-disable react/prop-types */
import React from 'react'
import { useTheme } from '../utils/ThemeColorContext'
import { AppStyles } from '../assets/styles/AppStyles'

function ModalHeader({ title, description, Icon }) {
  const { color } = useTheme()
  return (
    <div className=" w-full flex flex-col space-y-6 items-center justify-center ">
      <Icon className={'w-14 h-14 '} fill={color.BlurpleTeal} />
      <div className=" w-full items-center px-4">
        <h2
          style={{ ...AppStyles.Title1, color: color.Plum }}
          className="text-center "
        >
          {title}
        </h2>
        {description && (
          <h3
            style={{ ...AppStyles.Subheading1, color: color.Grey }}
            className=" text-center "
          >
            {description}
          </h3>
        )}
      </div>
    </div>
  )
}

export default ModalHeader
