import * as Sentry from "@sentry/react"

const captureException = (error) => {
  if (error != null) {
    let contextData = {}
    contextData.url = error.config?.url ? error.config.url : 'unknown'
    contextData.code = error.code ? error.code : 'unknown'
    contextData.data = error.response?.data ? error.response?.data : 'unknown'
    contextData.message = error.message ? error.message : 'unknown'
    
    Sentry.captureException(error, {
      level: 'error',
      extra: contextData
    })
  }
}

export default captureException