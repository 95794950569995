/* eslint-disable react/prop-types */
import React from 'react'
import { useTheme } from '../utils/ThemeColorContext'
import { AppStyles } from '../assets/styles/AppStyles'

function SubPageHeader({ Icon, text, width }) {
  const { color, isSmallScreen } = useTheme()
  return (
    <div
      style={{ backgroundColor: color.Tint6, width: width }}
      className=" flex items-center rounded-full  px-4 h-[44px]  w-fit space-x-[10px]"
    >
      <div style={{ marginTop: '-2px' }}>
        <Icon
          fill={color.BlurpleTeal}
          className={isSmallScreen ? `w-4 h-4` : 'max-w-4 max-h-4'}
        />
      </div>
      <p
        style={
          isSmallScreen
            ? { ...AppStyles.Body16Demi, color: color.Plum }
            : { ...AppStyles.Body16Demi, color: color.Plum, marginLeft: '8px' }
        }
      >
        {text}
      </p>
    </div>
  )
}

export default SubPageHeader
