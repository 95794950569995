/* eslint-disable no-undef */
const configuration = { 
  awsExports: {
    Auth: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_CLIENTID,
    },
  },
  envVars: {
    loansApiUrl: process.env.REACT_APP_LOANS_API_URL,
    transactionsApiUrl: process.env.REACT_APP_TRANSACTION_API_URL,
    intercomAppId: process.env.REACT_APP_INTERCOM_APP_ID,
    heapEnvId: process.env.REACT_APP_HEAP_ENV,
  }
}

export default configuration