/* eslint-disable react/prop-types */
import React from 'react'
import { PlusIcon } from '../../assets/AppIcons'
import { useTheme } from '../../utils/ThemeColorContext'
import { AppStyles } from '../../assets/styles/AppStyles'

function AddAccount({ onClick, title = "Add another account", switched = false }) {
  const { color } = useTheme()
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: color.White,
        borderColor: color.MidGrey,
      }}
      className="cursor-pointer min-h-[152px] flex flex-col items-center justify-center border rounded-2xl p-5 space-y-4"
    >
      { !switched && (
        <div
          style={{ backgroundColor: color.BlurpleTeal }}
          className=" w-8 h-8 flex items-center justify-center rounded-full "
        >
          <PlusIcon fill={color.White} className={'w-4 h-4'} />
        </div>
      )}
      <p style={{ ...AppStyles.Body, color: color.Grey, textAlign: 'center' }}>
        {title}
      </p>
      { switched && (
        <div
          style={{ backgroundColor: color.BlurpleTeal }}
          className=" w-8 h-8 flex items-center justify-center rounded-full "
        >
          <PlusIcon fill={color.White} className={'w-4 h-4'} />
        </div>
      )}
    </div>
  )
}

export default AddAccount
