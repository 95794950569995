import * as Sentry from "@sentry/react"
/* eslint-disable react/react-in-jsx-scope */
import './assets/AppTheme.css'
import { Amplify, Auth } from 'aws-amplify'
import AppStack from './navigation/AppStack'
import configuration from './Configuration'
import { useEffect } from 'react'
import {
  // getApplications,
  getProfile,
  getReApply,
  getTruelayerTerms,
} from './redux/Actions/getMethods'
import { useLocation, useNavigationType } from 'react-router-dom'
import { createRoutesFromChildren, matchRoutes } from 'react-router'
import { useDispatch } from 'react-redux'
import { initializeApp } from "firebase/app"
/* import { getAnalytics } from "firebase/analytics" */
import React from 'react'
import { getAnalytics, setUserId } from "firebase/analytics"

/* const AnalyticsContext = React.createContext(); */
Amplify.configure(configuration.awsExports)

function App() {
  Sentry.init({
    dsn: "https://f24ba1f9e19efe51efb6733d2a24535a@o273284.ingest.us.sentry.io/4507377817681920",
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      // Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
  
  const token = localStorage.getItem('login')

  const firebaseConfig = {
    apiKey: "AIzaSyDpejr0Yx-72hil9TO0nJMbZhcxoV75o8k",
    authDomain: "updraft-282b6.firebaseapp.com",
    projectId: "updraft-282b6",
    storageBucket: "updraft-282b6.appspot.com",
    messagingSenderId: "866229470694",
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  }
  // Initialize Firebase
  //const app = initializeApp(firebaseConfig)
  initializeApp(firebaseConfig)

  const dispatch = useDispatch()
  useEffect(() => {
    if (token) {
      dispatch(getTruelayerTerms())
      // dispatch(getApplications())
      dispatch(getProfile())
      dispatch(getReApply())
    }
  }, [token])

  const handlLogoutChat = async () => {
    await window.Intercom('shutdown')
    await window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    })
  }

  const analytics = getAnalytics()

  useEffect(() => {
    let inactivityTimeout

    const resetInactivityTimeout = () => {
      // Clear the previous timeout and start a new one
      clearTimeout(inactivityTimeout)

      inactivityTimeout = setTimeout(
        () => {
          // Perform logout or any other action on inactivity timeout
          handleLogout()
        },
        5 * 60 * 1000,
      ) // 5 minutes in milliseconds
    }

    const handleActivity = () => {
      resetInactivityTimeout()
    }

    const handleLogout = async () => {
      // Perform logout logic
      if (localStorage.getItem('login')) {
        Auth.signOut()
        setUserId(analytics, null)
        Sentry.setUser(null)
        if (window.gtag) {
          window.gtag('set', {'user_id': null })
        }
        // navigate("/");
        localStorage.removeItem('login')
        window.location.href = '/'
      }
      handlLogoutChat()
      // setIsLoggedIn(false)
    }
    // Attach event listeners for user activity
    window.addEventListener('mousemove', handleActivity)
    window.addEventListener('keydown', handleActivity)
    window.addEventListener('touchstart', handleActivity)

    // Set up initial inactivity timeout
    resetInactivityTimeout()

    // Detach event listeners and clear timeout on component unmount
    return () => {
      window.removeEventListener('mousemove', handleActivity)
      window.removeEventListener('keydown', handleActivity)
      window.removeEventListener('touchstart', handleActivity)
      clearTimeout(inactivityTimeout)
    }
  }, [])

  useEffect(() => {
    if (token === null) {
      handlLogoutChat()
    }
  }, [token === null])

  // TODO: put the following below wrapping the div I think:
  {/* <AnalyticsContext.Provider value={analytics}>*/}
  {/*</AnalyticsContext.Provider> */}
  return (
    <div>
      <AppStack />
    </div>
  )
}

export default App