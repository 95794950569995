/* eslint-disable no-unused-vars */
import React, { useState } from 'react'

import PageHeader from '../../../components/PageHeader'
import { useTheme } from '../../../utils/ThemeColorContext'
import DefaultPage from '../DefaultPage'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import AgreeToTerms from '../../../components/cards/AgreeToTerms'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner'
import { useEffect } from 'react'
import { myHtml } from '../../../api/dummyData'
import { getAgreementDocs } from '../../../redux/Actions/getMethods'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { isSafari } from '../../../utils/browser'
//import { getSoftsearchDocs } from "../../../redux/Actions/getMethods";

function LoanAgreement() {
  const { loading, token } = useSelector((state) => state.authReducer)
  const dispatch = useDispatch()
  const { getApplication, agreementDoc } = useSelector(
    (state) => state.dataReducer,
  )
  const [docHtml, setDocHtml] = useState('')
  const { color, isSmallScreen } = useTheme()
  const navigate = useNavigate()
  const [isOn, setIsOn] = useState(false)

  const parser = new DOMParser()
  const doc = parser.parseFromString(docHtml, 'text/html')

  useEffect(() => {
    dispatch({ type: ActionTypes.AGREEMENT, payload: isOn })

    if (getApplication[0]?.id) {
      const getCrditDocs = async () => {
        const success = await dispatch(
          getAgreementDocs(
            getApplication[0]?.id,
            'credit',
            ActionTypes.GET_AGREMENT_DOCS,
          ),
        )
        success && setDocHtml(success)
      }
      getCrditDocs()
    }
  }, [getApplication[0]?.id, isOn])

  // const alreadyChecked = JSON.parse(
  //   localStorage.getItem(`loan-agreement-${token?.email}`),
  // )
  return (
    <DefaultPage>
      <div className="relative container mx-auto  md:w-[564px]  md:space-y-10  ">
        <PageHeader
          // arrow={true}
          title={'Your loan agreement'}
          description={`Updraft Credit\nScroll to the bottom to accept the terms of the loan`}
          backButtonTitle={'Sign your agreement'}
          prevRoute={'/signagreement'}
        />
        <div
          style={{
            backgroundColor: color.White,
            margin: isSmallScreen && 'auto',
          }}
          className="rounded-3xl p-6 space-y-6 "
        >
          {/* <div
            style={{
              backgroundColor: color.White,
              borderColor: color.MidGrey,
            }}
            className="key-facts space-y-6 overflow-y-scroll h-[500px] "
          > */}
            <p>Key Facts</p>
            <div
              className={isSafari ? 'key-facts-safari' : ''}
              style={{ margin: 0 }}
              dangerouslySetInnerHTML={{ __html: agreementDoc }}
            />
          {/* </div> */}
          <AgreeToTerms
            setIsOn={setIsOn}
            isOn={isOn}
            title={'I accept the terms of the loan'}
          // name={`loan-agreement-${token?.email}`}
          // alreadyChecked={alreadyChecked}
          />
        </div>
        <div
          style={{
            marginTop: /* isSmallScreen &&  */'24px',
            /* background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)', */
          }}
          /* className={
            isSmallScreen ? (
              `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
            ) : (
              `px-6 pb-6 md:p-0 fixed bottom-0 md:relative  w-full `
            )
          } */
          className='px-6 pb-6 md:p-0 bottom-0 md:relative  w-full'
        >
          {/* {isSmallScreen && (
            <>
              <div
                className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
              ></div>
              <div
                className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
              />
            </>
          )} */}
          <PrimaryButton
            id={'signagreement-primary'}
            disabled={!isOn}
            onClick={() => {
              if (loading) {
                return
              }
              navigate('/signagreement')
              window.heap &&
                window.heap.track('uc_loan_terms_agreed', {
                  action_name: 'tap',
                  action_details: 'ucLoanTermsAgreed',
                })
            }}
            text={loading ? <Spinner /> : 'Continue'}
            className={'md:w-[260px]'}
          />
        </div>
      </div>
    </DefaultPage>
  )
}

export default LoanAgreement
