/* eslint-disable react/prop-types */
import React from 'react'
import { SearchIcon } from '../../assets/AppIcons'
import { useTheme } from '../../utils/ThemeColorContext'
import { AppStyles } from '../../assets/styles/AppStyles'

const SearchInput = ({ onChange, value }) => {
  const { color } = useTheme()
  return (
    <div
      style={{ backgroundColor: color.White, borderColor: color.MidGrey }}
      className="flex items-center border rounded-2xl"
    >
      <div className="pl-4 py-3">
        <SearchIcon fill={color.BlurpleTeal} />
      </div>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder="Search"
        style={{
          ...AppStyles.BodyBold,
          color: color.Blurple,
          backgroundColor: color.White,
          borderColor: color.Tint3,
        }}
        className="px-4 py-3 rounded-2xl outline-none border-none w-full  focus:outline-none  focus:ring-transparent   "
      />
    </div>
  )
}

export default SearchInput
