/* eslint-disable react/prop-types */
import React from 'react'
import { useTheme } from '../utils/ThemeColorContext'
import { ArrowLeft, ArrowLeft16 } from '../assets/AppIcons'
import { useNavigate, useLocation } from 'react-router'
import { AppStyles } from '../assets/styles/AppStyles'
import TrustBox from './TustBox/.TrustBox'
import { useSelector } from 'react-redux'

function PageHeader({
  title,
  description,
  customDescription,
  minHeight,
  arrow,
  backButtonTitle,
  anOther,
  setAnOther,
  prevRoute,
  setstep,
  marginTop,
  showTrust,
}) {
  const { color, isSmallScreen } = useTheme()
  const { token } = useSelector((state) => state.authReducer)
  const navigate = useNavigate()
  const temp = useLocation()

  return (
    <div
      className={`space-y-6 ${
        anOther ? 'md:space-y-0' : 'md:space-y-10'
      } md:space-x-0 px-6 md:px-0 md:space-y-10 py-8 md:py-0` }
    >
      {arrow
        ? ''
        : backButtonTitle && (
            <div
              onClick={() => {
                if (anOther) {
                  setAnOther(false)
                  setstep(3)
                  if (
                    localStorage.getItem(`add_accounts-${token?.email}`) ===
                    'true'
                  ) {
                    navigate('/direct-debit')
                  }
                } else if (!anOther) {
                  navigate(prevRoute)
                }
              }}
              className="flex items-center space-x-2 md:space-x-4 cursor-pointer"
              style={{ width: '300px' }}
            >
              <button>
                {isSmallScreen ? (
                  <div className="-mt-[3px]">
                    <ArrowLeft16 fill={color.BlurpleTeal} />
                  </div>
                ) : (
                  <ArrowLeft fill={color.BlurpleTeal} />
                )}
              </button>
              <h2
                style={
                  isSmallScreen
                    ? { ...AppStyles.BodyBold, color: color.BlurpleWhite }
                    : { ...AppStyles.Title2, color: color.BlurpleWhite }
                }
              >
                {/* {Supported ? 'Updraft Credit' : 'Your loan application'} */}
                {backButtonTitle}
              </h2>
            </div>
          )}
      <div className="flex flex-col items-start justify-between space-y-4 md:flex-row md:space-x-4 ">
        <div
          style={{
            paddingBottom: '0px',
          }}
          className=' space-y-2'
        >
          <h1
            style={
              isSmallScreen
                ? {
                    ...AppStyles.Title1,
                    color: color.Plum,
                  }
                : { ...AppStyles.LargeTitle2, color: color.Plum }
            }
          >
            {title}
          </h1>
          {customDescription && customDescription}
          {description && (
            <>
              {description.split('\n').map((line, index) => {
                return (
                  <p
                    
                    key={index}
                    style={
                      isSmallScreen
                        ? {
                            ...AppStyles.Body,
                            color: color.Grey,
                            minHeight: minHeight
                              ? isSmallScreen && '72px'
                              : temp?.pathname === '/customise-loan'
                              ? '0px'
                              : '0px',
                          }
                        : {
                            ...AppStyles.Subheading1,
                            color: color.Grey,
                          }
                    }
                  >
                    {line}
                    <br />
                  </p>
                )
              })}
            </>
          )}
        </div>
        {showTrust && (
          <div style={{ width: '180px', marginTop: marginTop }} >
            <TrustBox />
          </div>
        )}
      </div>
    </div>
  )
}

export default PageHeader
