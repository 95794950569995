import React from 'react'
import { useTheme } from '../../utils/ThemeColorContext'

const TrustBox = () => {
  const { isSmallScreen } = useTheme()

  // Create a reference to the <div> element that will represent the TrustBox
  const trustBoxRef = React.useRef(null)

  // Load the TrustBox widget when the script is loaded
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true)
    }
  }, [])

  return (
    <div
      style={{ margin: 0, marginTop: isSmallScreen && '-10px' }}
      ref={trustBoxRef} // Reference to the TrustBox element
      className="trustpilot-widget" // Renamed to className
      data-locale="en-GB" // Set the locale
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5e15be0bde96c700011673e3"
      data-style-height="100px" // Set the height
      data-style-width="162px" // Set the width
      data-theme="light" // Set the theme
      data-stars="1,2,3,4,5" // Set the stars
      data-group="on" // Enable group reviews
      data-schema-type="Organization" // Set the schema type
      data-no-reviews="show" // Show the widget even if there are no reviews
      data-scroll-to-list="true" // Scroll to the review list when clicked
      data-allow-robots="true" // Allow robots to index the widget
    >
      <a
        href="https://uk.trustpilot.com/review/updraft.com"
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  )
}

export default TrustBox
