/* eslint-disable no-unused-vars */
import DOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ArrowHeadRightIcon,
  CloseIcon,
  DocumentIcon,
  InfoIcon
} from '../../../assets/AppIcons'
import { AppStyles } from '../../../assets/styles/AppStyles'
import DocumentModal from '../../../components/DocumentModal'
import PageHeader from '../../../components/PageHeader'
import Spinner from '../../../components/Spinner'
import SpinnerLarge from '../../../components/SpinnerLarge'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import AgreeToTerms from '../../../components/cards/AgreeToTerms'
import { directDebutMehod } from '../../../redux/Actions/postMethods'
import {
  getApplications,
  getDirectDebitGDocs,
} from '../../../redux/Actions/getMethods'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { useTheme } from '../../../utils/ThemeColorContext'
import DefaultPage from '../DefaultPage'

function DirectDebit() {
  const [err, setErr] = useState('')
  const { loading2, token } = useSelector((state) => state.authReducer)
  const [isDirectDebitGuaranteeModalOpen, setIsDirectDebitGuaranteeModalOpen] =
    useState(false)
  const { getApplication, directDebitG, loadingWholeScreen } = useSelector(
    (state) => state.dataReducer,
  )

  // useEffect(() => {
  //   if (token?.email) {
  //     localStorage.setItem(`journeyStep-${token?.email}`, '/direct-debit')
  //   }
  // }, [token?.email])

  useEffect(() => {
    dispatch(getDirectDebitGDocs())
  }, [])
  const id = getApplication[0]?.id
  const bank = JSON.parse(localStorage.getItem('SelectedBank'))

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { color, isSmallScreen } = useTheme()
  const [isOn, setIsOn] = useState(false)

  const addAccountDetails = async () => {
    const success = await dispatch(directDebutMehod(id, bank, setErr))
    if (success) {
      if (token?.email_verified === true) {
        localStorage.removeItem(`direct-debit-${token?.email}`)
        navigate('/signagreement')
      } else {
        navigate('/confirm-email')
      }
      dispatch(getApplications())
    }
  }

  // ==================== Checking user status  =======================//
  useEffect(() => {
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      // const myID = await dispatch(getApplications())
      if (getApplication[0]?.status === 'APPROVED') {
        dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
        navigate('/direct-debit-guarantee')
      } else {
        dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })

        navigate('/address-detail')
      }
    }
    temp()
  }, [])

  /* const alreadyChecked = JSON.parse(
    localStorage.getItem(`direct-debit-${token?.email}`),
  ) */
  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <div className="relative container mx-auto md:w-[564px]   md:space-y-10  ">
          <PageHeader
            title={'Direct Debit Guarantee'}
            description={`Direct Debit Instruction to your Bank or Building Society.`}
            backButtonTitle={'Direct Debit setup'}
            prevRoute={'/direct-debit'}
          />
          <div
            style={{ backgroundColor: color.White }}
            className=" rounded-3xl p-6 space-y-6"
          >
            {/* <SubPageHeader
              width={'144px'}
              Icon={BankIcon}
              text={'Direct Debit'}
            /> */}
            <div className="w-full space-y-6 cursor-pointer">
              <div className="w-full space-y-6 ">
                <div
                  onClick={() => setIsDirectDebitGuaranteeModalOpen(true)}
                  style={{
                    backgroundColor: color.White,
                    borderColor: color.MidGrey,
                    height: isSmallScreen && '88px',
                  }}
                  className="px-4 py-5 border rounded-2xl flex space-x-4  justify-between "
                >
                  <div className="flex space-x-4 items-center justify-between w-full">
                    <div className="flex space-x-4 items-center justify-between">
                      <DocumentIcon fill={color.BlurpleTeal} />
                      <p
                        style={{
                          ...AppStyles.BodyBold,
                          color: color.Plum,
                        }}
                      >
                        Direct Debit Guarantee
                      </p>
                    </div>
                    <ArrowHeadRightIcon fill={color.BlurpleTeal} />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: color.Tint7,
                height: isSmallScreen && '288px',
              }}
              className="p-6  rounded-2xl space-x-4 flex"
            >
              <div>
                <InfoIcon fill={color.BlurpleTeal} />
              </div>
              <div className=" flex flex-col">
                <p
                  style={{
                    ...AppStyles.BodyBold,
                    color: color.BlurpleTeal,
                    width: '243px',
                    height: '24px',
                  }}
                >
                  Your Direct Debit
                </p>
                <p
                  style={{
                    ...AppStyles.Body,
                    color: color.Grey,
                    width: isSmallScreen && '243px',
                    height: isSmallScreen && '216px',
                    lineHeight: isSmallScreen && '24px',
                  }}
                >
                  Please pay L&Z re Updraft Credit Direct Debits from the
                  account detailed on this instruction subject to the safeguards
                  assured by the Direct Debit Guarantee. I understand that this
                  instruction may remain with L&Z re Updraft Credit and if so,
                  details will be passed electronically to my Bank/Building
                  Society.
                </p>
              </div>
            </div>
            <AgreeToTerms
              setIsOn={setIsOn}
              isOn={isOn}
              title={'Agree to Direct Debit Terms'}
              name={`direct-debit-${token?.email}`}
              // alreadyChecked={alreadyChecked}
            />
          </div>
          <b style={{ ...AppStyles.Caption1, color: color.SoftPink }}>{err}</b>
          <div
            style={{
              marginTop: isSmallScreen && '24px',
              background: !isSmallScreen && 'linear-gradient(0deg, #F5F7FC 0%, rgba(245, 247, 252, 0) 100%)',
            }}
            className={
              isSmallScreen ? (
                `mt-10 md:max-w-[588px] m-[auto] fixed w-full bottom-0 px-6 pb-6 md:p-0 flex flex-col-reverse gap-5 md:gap-10 md:flex-row bg-[#F5F7FC]`
              ) : (
                `px-6 pb-6 md:p-0 fixed bottom-0 md:relative  w-full `
              )
            }
          >
            {isSmallScreen && (
              <>
                <div
                  className="absolute inset-x-0 top-[-32px] h-[24px] bg-gradient-to-t from-[#F5F7FC] to-[#F5F7FC00] pointer-events-none"
                ></div>
                <div
                  className="absolute inset-x-0 top-[-8px] h-[8px] bg-[#F5F7FC] pointer-events-none"
                />
              </>
            )}
            <PrimaryButton
              id={'confirm-direct-debit'}
              text={loading2 ? <Spinner /> : 'Confirm Direct Debit'}
              disabled={!isOn}
              onClick={() => {
                if (loading2) {
                  return
                }
                addAccountDetails()
                window.heap &&
                  window.heap.track('uc_direct_debit_confirm', {
                    action_name: 'tap',
                    action_details: 'ucDirectDebitConfirm',
                  })
              }}
              className={' md:w-[260px]'}
            />
          </div>
        </div>
      )}
      <DocumentModal
        isOpen={isDirectDebitGuaranteeModalOpen}
        onClose={() => setIsDirectDebitGuaranteeModalOpen(false)}
      >
        {/* <div
          //className="p-4 h-full w-full items-center "
          className="p-4 h-full mt-16"
        >
          <div
            //className="flex flex-col space-y-4 pb-10 scale-50"
            className="flex flex-col space-y-4 pb-10 " */}
        <div className="p-4 keyfact md:h-full md:w-full items-center ">
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          />
          <div className="flex">
            <div
              className="flex flex-col space-y-4 pb-10"
              dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(directDebitG),
              }}
            />
            <div
              onClick={() => setIsDirectDebitGuaranteeModalOpen(false)}
              className="cursor-pointer pcci-close"
            >
              <CloseIcon className="overflow-auto transform fixed mt-16" fill={color.Tint1} />
            </div>
          </div>
        </div>
      </DocumentModal>
      {/* {isDirectDebitGuaranteeModalOpen && (
        <div
          onClick={() => setIsDirectDebitGuaranteeModalOpen(false)}
          className="cursor-pointer fixed top-6 right-6"
        >
          <CloseIcon className="overflow-auto transform" fill={color.Tint1} />
        </div>
      )} */}
    </DefaultPage>
  )
}

export default DirectDebit
