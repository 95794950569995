/* eslint-disable react/prop-types */
import React from 'react'
import { useTheme } from '../utils/ThemeColorContext'

function Modal({ isOpen, onClose, children, className }) {
  const { color, isSmallScreen } = useTheme()
  if (!isOpen) return null

  return (
    <div
      style={{ overflow: 'hidden' }}
      className={`fixed md:min-h-screen h-screen w-full flex z-50 inset-0 overflow-y-auto transition-opacity duration-300`}
    >
      <div
        style={{ marginTop: isSmallScreen && '0%', position: 'fixed' }}
        className={`flex items-end md:items-center justify-center h-full w-full md:p-10 overflow-y-auto`}
      >
        {/* Background overlay */}
        <div className="fixed inset-0 transition-opacity" onClick={onClose}>
          <div
            style={{ backgroundColor: color.Overlay }}
            className="absolute inset-0"
          ></div>
        </div>

        {/* Modal content */}
        <div
          style={{
            backgroundColor: color.White,
            maxHeight: isSmallScreen && '85vh',
          }}
          className={`${className} w-full md:w-[440px] px-6 py-10 rounded-t-3xl overflow-auto transform transition-all md:align-middle md:p-10 md:rounded-3xl`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
