/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Select from 'react-select'
import { useTheme } from '../../utils/ThemeColorContext'
import { AppStyles } from '../../assets/styles/AppStyles'
import { ArrowHeadDownIcon, CalenderIconForMobile } from '../../assets/AppIcons'
function FormSelect({
  onChange,
  value,
  calIcon,
  placeholder,
  options,
  defaultValue,
  label,
  error = false,
  disabled = false
}) {
  const { color, isSmallScreen } = useTheme()
  const [isFocused, setIsFocused] = useState(false)

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: error ? color.Negative15 : color.LightGrey,
      height: '64px',
      borderRadius: '16px',
      fontFamily: 'MaisonMedium, sans-serif',
      color: color.BlurpleTeal,
      borderColor: error ? color.SoftPink : state.isFocused ? color.BlurpleTeal : color.Tint3,
      opacity: state.isDisabled && '40%',
      borderWidth: '1px',
      cursor: state.isDisabled ? 'disabled': 'pointer',
      paddingLeft: '2px',
      paddingTop:
        value || isFocused
          ? '25px'
          : isSmallScreen && label && isFocused
            ? '25px'
            : '10px',
      paddingBottom: '10px',
      margin: '0px',
      marginBottom: '0px',
      boxShadow: state.isFocused
        ? `0 0 0 0px ${color.BlurpleTeal}`
        : `0 0 0 1px ${color.Tint3}`,
      '&:hover': {
        borderColor: color.BlurpleTeal,
        borderWidth: '1px',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: color.BlurpleTeal,
      ...AppStyles.BodyBold,
      fontSize: isSmallScreen ? '16px' : '14px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      ...AppStyles.Body,
      color: state.isFocused ? color.BlurpleTeal : color.Grey,
      fontWeight: state.isFocused ? '700' : '400',
      padding: '0px',
      margin: '0px',
    }),

    option: (provided, state) => {
      const isLastOption =
        state.data === state.options[state.options.length - 1]
      const isFirstOption = state.data === state.options[0]
      return {
        ...provided,
        borderBottom: isLastOption ? 'none' : `1px solid ${color.MidGrey}`,
        borderBottomRightRadius: isLastOption ? '24px' : '0px',
        borderBottomLeftRadius: isLastOption ? '24px' : '0px',
        borderTopRightRadius: isFirstOption ? '24px' : '0px', // Added for the first option
        borderTopLeftRadius: isFirstOption ? '24px' : '0px',
        backgroundColor: state.isFocused ? color.Tint7 : color.White, // This color will be applied when the option is hovered over
        color: color.BlurpleTeal, // Adjust font color if necessary
        height: '56px',
        ...AppStyles.BodyBold,
        display: 'flex', // Added this line to use flexbox
        alignItems: 'center', // This will vertically center the content
        padding: '16px',
        marginTop: '0px',
      }
    },

    menu: (provided) => ({
      ...provided,
      boxShadow: ' 0px 4px 16px 0px rgba(0, 0, 0, 0.25);',
      // boxShadow: "0px 4px 16px 0px  rgba(0,0,0,0.75);",
      // padding: "0px",
      backgroundColor: color.White,
      zIndex: 100,
      marginTop: '0px',
      height: 'fit-content',
      borderRadius: '24px',
      paddingTop: '0px',
      margin: 0,
    }),
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      // backgroundColor: "red",
      maxHeight: '336px',
      zIndex: 100,
    }),
  }

  return (
    <div style={{ position: 'relative' }} className="relative w-full text-14">
      <div className="relative">
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            paddingLeft: 13,
            marginTop: 12,
          }}
        >
          {(isFocused || value) && (
            <p
              style={{
                ...AppStyles.Caption3,
                color: error ? color.SoftPink : color.Grey,
              }}
            >
              {placeholder}
            </p>
          )}
        </div>

        <Select
          placeholder={!isFocused && placeholder}
          options={options}
          onChange={onChange}
          styles={customStyles}
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          isSearchable={false}
          isDisabled={disabled}
          defaultValue={defaultValue}
          menuPlacement="bottom" // or "top" depending on your preference
          menuPosition="absolute"
          components={{
            // ValueContainer: CustomValueContainer,
            // Placeholder: CustomPlaceholder,
            DropdownIndicator: () => {
              return (
                <>
                  {calIcon ? (
                    <div
                      style={{
                        marginRight: 15,
                        marginTop:
                          label && isFocused && isSmallScreen && '-15px',
                      }}
                    >
                      {isSmallScreen ? (
                        <CalenderIconForMobile
                          fill={color.BlurpleTeal}
                          alt=""
                        />
                      ) : (
                        <div
                          style={{
                            marginTop: '-15px',
                          }}
                        >
                          <CalenderIconForMobile
                            fill={color.BlurpleTeal}
                            className=" mx-0"
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <ArrowHeadDownIcon
                        fill={color.BlurpleTeal}
                        className={`w-4 mx-4 ${value && 'm-[-14px]'}`}
                        alt=""
                      />
                    </div>
                  )}
                </>
              )
            },
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </div>
  )
}

export default FormSelect

// Let's check the frontend
