import { applyMiddleware, createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { authReducer } from './Reducers/authReducer'
import { dataReducer } from './Reducers/dataReducer'

const rootReducer = combineReducers({
  dataReducer,
  authReducer,
})

const middleware = [thunk]

const Store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware)),
)

export default Store
