import '../AppTheme.css'

const AppStyles = {
  BigTitle: {
    fontFamily: 'GilroyBold',
    fontSize: '52px',
    // fontWeight: "700",
    fontStyle: 'normal',
    lineHeight: '66px',
  },
  LargeTitle1: {
    fontFamily: 'GilroyBold',
    fontSize: '32px',
    // fontWeight: "700",
    fontStyle: 'normal',
    lineHeight: '40px',
  },
  LargeTitle2: {
    fontSize: '30px',
    fontFamily: 'GilroyBold',
    fontStyle: 'normal',
    lineHeight: '40px',
  },
  LargeTitle3: {
    fontSize: '28px',
    // fontWeight: "700",
    fontFamily: 'GilroyBold',
    fontStyle: 'normal',
    lineHeight: '40px',
  },
  LargeTitle4: {
    fontSize: '24px',
    // fontWeight: "700",
    fontFamily: 'GilroyBold',
    fontStyle: 'normal',
    lineHeight: '32px',
  },
  Title1: {
    fontSize: '20px',
    // fontWeight: "700",
    fontFamily: 'GilroyBold',
    fontStyle: 'normal',
    lineHeight: '32px',
  },
  Title2: {
    fontSize: '18px',
    // fontWeight: "700",
    fontFamily: 'GilroyBold',
    fontStyle: 'normal',
    lineHeight: '32px',
  },
  Title3: {
    fontSize: '16px',
    // fontWeight: "700",
    fontFamily: 'GilroyBold',
    fontStyle: 'normal',
    lineHeight: '28px',
  },
  Title4: {
    fontSize: '14px',
    // fontWeight: "700",
    fontFamily: 'GilroyBold',
    fontStyle: 'normal',
    lineHeight: '24px',
  },
  Subtitle: {
    fontSize: '18px',
    // fontWeight: '500',
    fontFamily: '"MaisonMedium","sans-serif"',
    fontStyle: 'normal',
    lineHeight: '32px',
  },
  // BODY
  Subheading1: {
    fontSize: '16px',
    // fontWeight: '500',
    fontFamily: '"MaisonMedium","sans-serif"',
    fontStyle: 'normal',
    lineHeight: '28px',
  },
  Subheading2: {
    fontSize: '18px',
    // fontWeight: '500',
    fontFamily: '"MaisonMedium","sans-serif"',
    fontStyle: 'normal',
    lineHeight: '32px',
  },
  Subheading3: {
    fontSize: '12px',
    // fontWeight: '500',
    fontFamily: '"MaisonMedium","sans-serif"',
    fontStyle: 'normal',
    lineHeight: '20px',
  },
  Body: {
    fontSize: '14px',
    fontFamily: '"MaisonMedium","sans-serif"',
    fontStyle: 'normal',
    lineHeight: '28px',
  },
  BodyBold: {
    fontSize: '14px',
    // fontWeight: "700",
    fontFamily: 'MaisonBold',
    fontStyle: 'normal',
    lineHeight: '24px',
  },
  Body12Demi: {
    fontSize: '12px',
    // fontWeight: "600",
    fontFamily: 'MaisonDemiBold',
    fontStyle: 'normal',
    lineHeight: '20px',
  },
  Body16: {
    fontSize: '16px',
    // fontWeight: '500',
    fontFamily: 'MaisonMedium',
    fontStyle: 'normal',
    lineHeight: '28px',
  },
  Body16Demi: {
    fontSize: '16px',
    // fontWeight: "600",
    fontFamily: 'MaisonDemiBold',
    fontStyle: 'normal',
    lineHeight: '28px',
  },
  BodyBoldForZoomIssue: {
    fontSize: '16px',
    // fontWeight: "700",
    fontFamily: 'MaisonBold',
    fontStyle: 'normal',
  },
  representativeExample: {
    fontSize: '12px',
    // fontWeight: "700",
    fontFamily: 'MaisonBold',
    fontStyle: 'normal',
    lineHeight: '20px',
  },
  BodyLink: {
    fontSize: '14px',
    // fontWeight: '400',
    fontFamily: 'MaisonBook',
    fontStyle: 'normal',
    lineHeight: '20px',
  },
  BodyLinkBold: {
    fontSize: '14px',
    // fontWeight: "700",
    fontFamily: 'MaisonBold',
    fontStyle: 'normal',
    lineHeight: '20px',
  },
  NavLink: {
    fontSize: '16px',
    // fontWeight: "600",
    fontFamily: 'MaisonDemiBold',
    fontStyle: 'normal',
  },
  Caption1: {
    fontSize: '12px',
    // fontWeight: '400',
    fontFamily: 'MaisonBook',
    fontStyle: 'normal',
    lineHeight: '24px',
  },
  Caption2: {
    fontSize: '12px',
    // fontWeight: "700",
    fontFamily: 'MaisonBold',
    fontStyle: 'normal',
    lineHeight: '24px',
  },
  Caption3: {
    fontSize: '10px',
    // fontWeight: "700",
    fontFamily: 'MaisonBook',
    fontStyle: 'normal',
    lineHeight: '16px',
  },
  Caption4: {
    fontSize: '10px',
    // fontWeight: '400',
    fontFamily: 'GilroyExtraBold',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    lineHeight: '16px',
  },
  Caption5: {
    fontSize: '8px',
    fontFamily: 'GilroyExtraBold',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    lineHeight: '16px',
  },
  ButtonLarge: {
    fontSize: '16px',
    fontFamily: 'MaisonBold',
    fontStyle: 'normal',
    lineHeight: '24px',
  },

  ButtonMedium: {
    fontSize: '16px',
    // fontWeight: '700',
    fontFamily: 'MaisonBold',
    fontStyle: 'normal',
    lineHeight: '24px',
    borderRadius: '12px',
  },
  ButtonSmall: {
    fontSize: '12px',
    // fontWeight: "700",
    fontFamily: 'MaisonBold',
    fontStyle: 'normal',
  },

  TabbarActive: {
    fontSize: '12px',
    // fontWeight: '500',
    fontFamily: 'MaisonMedium',
    fontStyle: 'normal',
  },
  TabbarInActive: {
    fontSize: '12px',
    // fontWeight: '400',
    fontFamily: 'MaisonBook',
    fontStyle: 'normal',
    lineHeight: '18px',
  },
  SegmentedActive: {
    fontSize: '12px',
    // fontWeight: '700',
    fontFamily: 'MaisonBold',
    fontStyle: 'normal',
  },
  SegmentedInActive: {
    fontSize: '12px',
    // fontWeight: '500',
    fontFamily: 'MaisonMedium',
    fontStyle: 'normal',
  },
  BodyBold3: {
    fontSize: '30px !important',
    fontWeight: '700',
    fontFamily: 'Bellarina',
    fontStyle: 'normal',
  },
  SentNewCode: {
    fontFamily: 'Bellarina',
    display: 'flex',
    padding: '8px 16px 8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '16px',
    background: '#5D1CD30D',
  },
  SentNewCodeText: {
    fontFamily: 'MaisonBold',
    // fontWeight: '700',
    fontSize: '12px',
    lineHeight: '24px',
  },
  MonthlySpendText: {
    fontSize: '14px',
    color: '#1F3550',
    // fontWeight: '700',
    lineHeight: '24px',
    fontFamily: 'MaisonBold',
    fontStyle: 'normal',
    margin: '0 4px',
  },
}

export { AppStyles }
