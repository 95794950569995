import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { CloseIconSmall } from '../../../assets/AppIcons'
import { AppStyles } from '../../../assets/styles/AppStyles'
import {
  getAgreementDocs,
  getApplications,
  getMonthsLimit,
  upateApplications,
} from '../../../redux/Actions/getMethods'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { useTheme } from '../../../utils/ThemeColorContext'

import numeral from 'numeral'
import ReactSlider from 'react-slider'
import Edit from '../../../assets/images/Edit.svg'
import PageHeader from '../../../components/PageHeader'
import Spinner from '../../../components/Spinner'
import SpinnerLarge from '../../../components/SpinnerLarge'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import DefaultPage from '../DefaultPage'

function LoanAmount() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getApplication, loadingWholeScreen } = useSelector(
    (state) => state.dataReducer,
  )

  const { token, loading } = useSelector((state) => state.authReducer)
  const { profile } = useSelector((state) => state.dataReducer)
  const [months, setMonths] = useState('')
  const [monthsData, setMonthsData] = useState('')
  const { color, isSmallScreen } = useTheme()
  const [LoanAmount, setLoanAmount] = useState(0)
  const [Over, setOver] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [calculatedValue, setCalculatedValue] = useState('')
  const [editValue, setEditValue] = useState()
  const [minAmountErr, setMinAmountErr] = useState(false)
  const [MaxAmountErr, setMaxAmountErr] = useState(false)
  const [only100AmountErr, setOnly100AmountErr] = useState(false)
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [error, setError] = useState('')

  useEffect(() => {
    const result = temp(Over)
    setMonthlyPayment(result)
    setError('')
  }, [LoanAmount, Over])

  // useEffect(() => {
  //   if (parseInt(monthlyPayment) >= 1000) {
  //     if (getApplication[0]?.max_emi !== null) {
  //       setOver(calculatedEmi())
  //     } else {
  //       setOver(getApplication[0]?.min_duration)
  //     }
  //   }
  // }, [monthlyPayment])
  useEffect(() => {
    if (
      getApplication[0]?.max_emi !== null &&
      parseInt(monthlyPayment) >= getApplication[0]?.max_emi
    ) {
      if (getApplication[0]?.max_emi !== null) {
        setOver(calculatedEmi())
      }
    }
  }, [monthlyPayment])

  useEffect(() => {
    if (token?.email) {
      // localStorage.setItem(`journeyStep-${token?.email}`, '/customise-loan')
      window.heap &&
        window.heap.track('uc_offer_customise_view', {
          action_name: 'tap',
          action_details: 'ucOfferCustomiseView',
        })
    }
  }, [token?.email])

  useEffect(() => {
    // ==================== Max Amount exsist  =======================//
    if (getApplication[0]?.max_amount) {
      setLoanAmount(getApplication[0]?.max_amount)
    }
  }, [getApplication[0]?.id])

  // ==================== Checking user status  =======================//
  useEffect(() => {
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      // const myID = await dispatch(getApplications())
      if (getApplication[0]?.status === 'APPROVED') {
        dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
        navigate('/loan-amount')
      } else {
        dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
        navigate('/address-detail')
      }
    }
    temp()
  }, [])

  useEffect(() => {
    // =====================> When user edit manually Click on Pencil Icon put data inside setLoanAmount
    if (editValue) {
      setLoanAmount(editValue)
    } else if (LoanAmount) {
      setEditValue('')
    }
  }, [LoanAmount])
  // =============================>> Select Date for Loan <<===================================

  useEffect(() => {
    // =====================> When user edit manually Click on Pencil Icon put data inside setLoanAmount
    if (LoanAmount > getApplication[0]?.min_amount) {
      setMinAmountErr(true)
    } else if (LoanAmount === getApplication[0]?.min_amount) {
      setMinAmountErr(false)
    } else if (getApplication[0]?.min_amount > LoanAmount) {
      setMinAmountErr(false)
    } else if (getApplication[0]?.max_amount < LoanAmount) {
      setMaxAmountErr(true)
    } else if (getApplication[0]?.max_amount > LoanAmount) {
      setMaxAmountErr(false)
    }
  }, [getApplication[0]?.min_amount, getApplication[0]?.max_amount])
  // =============================>> Select Date for Loan <<===================================

  const getMonth = (array, amount) => {
    for (const obj of array) {
      if (amount <= obj.max_amount && amount >= 0 && Number.isInteger(amount)) {
        // setOver(getApplication[0]?.duration && getApplication[0]?.duration)
        // setOver(obj.max_months_duration)
        return obj.max_months_duration
      }
    }
    return null
  }

  const temp = (over) => {
    if (LoanAmount > getApplication[0]?.max_amount) {
      return '-'
    } else {
      const rate = getApplication[0]?.interestRate / 12
      let valueTemp = (1 + rate) ** over
      if (getApplication[0]?.offer_type === 'UPDRAFT_CREDIT_INTEREST_AND_FEE') {
        const amountWithFee =
          LoanAmount + getApplication[0]?.fee_percentage * LoanAmount //
        return Math.round((amountWithFee * valueTemp * rate) / (valueTemp - 1))
      } else {
        return Math.round((LoanAmount * valueTemp * rate) / (valueTemp - 1))
      }
    }
  }

  // const payable = 300
  const payable = getApplication[0]?.max_emi
  const calculatedEmi = () => {
    const isWithinRange = (months) => {
      if (temp(months) <= payable) {
        return true
      } else {
        return false
      }
    }

    const getRangeValue = (months) => {
      if (isWithinRange(months)) {
        return months
      } else {
        let count = 0
        let value = months
        while (!isWithinRange(value)) {
          value++
          count++
          if (count == 100) {
            value = months
            break
          }
        }
        return value
      }
    }

    if (!getApplication[0]?.fee_percentage) {
      const sum1 = (LoanAmount * getApplication[0]?.interestRate * 100) / 100
      const sum2 = LoanAmount + sum1
      const sum3 = sum2 / payable
      const sum4 = Math.ceil(sum3)
      const result = getRangeValue(sum4)
      return result
    } else {
      const amountWithFee =
        LoanAmount + getApplication[0]?.fee_percentage * LoanAmount
      const sum1 = (amountWithFee * getApplication[0]?.interestRate * 100) / 100
      const sum2 = amountWithFee + sum1
      const sum3 = sum2 / payable
      const sum4 = Math.ceil(sum3)
      const result = getRangeValue(sum4)
      return result
    }
  }

  useEffect(() => {
    ;(async () => {
      const res = await dispatch(getMonthsLimit())
      res && setMonthsData(res)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const months = getMonth(monthsData, LoanAmount)
      setMonths(months)
    })()
  }, [LoanAmount, monthsData])

  const handleAcceptLoan = async () => {
    const id = getApplication[0]?.id
    const data = {
      disbursement_date: getApplication[0]?.disbursement_by_date,
      duration: Over,
      amount: LoanAmount,
      dd_account_name: 'Mock',
    }
    const success = await dispatch(upateApplications(id, data, setError))
    if (success) {
      dispatch(getApplications())
      const agreeDoc = await dispatch(
        getAgreementDocs(
          getApplication[0]?.id,
          'keyfacts',
          ActionTypes.GET_FACTS_DOCS,
        ),
      )
      const agreDoc2 = await dispatch(
        getAgreementDocs(
          getApplication[0]?.id,
          'secci',
          ActionTypes.GET_PCCI_DOCS,
        ),
      )
      if (agreDoc2 && agreeDoc) {
        navigate('/customise-loan')
      }
    }
  }

  function determineMaxLoanDuration(
    loanAppMaxDuration,
    termLimitMaxDuration,
    maxEmi,
  ) {
    // Assuming maxEmiDuration is calculated elsewhere and directly provided
    let maxEmiDuration = maxEmi // This should be the duration calculated based on maxEmi

    let finalMaxDuration
    if (
      maxEmiDuration &&
      maxEmiDuration < loanAppMaxDuration &&
      maxEmiDuration < termLimitMaxDuration
    ) {
      finalMaxDuration = maxEmiDuration // Prioritize Max EMI Duration
    } else if (loanAppMaxDuration < termLimitMaxDuration) {
      finalMaxDuration = loanAppMaxDuration // Prioritize Loan Application Max Duration
    } else {
      finalMaxDuration = termLimitMaxDuration // Prioritize Term Limit Max Duration
    }

    return finalMaxDuration
  }

  // Example usage:
  let finalMaxDuration = determineMaxLoanDuration(
    getApplication[0]?.max_duration,
    // loanApplicationMaxDuration,
    months,
    getApplication[0]?.max_emi,
  )

  useEffect(() => {
    if (finalMaxDuration) {
      setOver(finalMaxDuration)
    }
  }, [finalMaxDuration])

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <div className="relative container mx-auto md:w-[564px]   md:space-y-10  ">
          <PageHeader
            title={'Customise your loan'}
            customDescription={
              <p
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Body,
                        color: color.Grey,
                      }
                    : {
                        ...AppStyles.Subheading1,
                        color: color.Grey,
                      }
                }
              >
                OK
                <span style={{ color: color.BlurpleTeal }}>
                  {' '}
                  {profile?.first_name}
                </span>
                , tailor Updraft Credit to meet your needs.
              </p>
            }
            anOther
          />

          <div
            style={{
              backgroundColor: color.White,
            }}
            className={` rounded-3xl p-6 md:p-10  space-y-6 `}
          >
            {/* <div className=" space-y-2">
              <h1
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Title1,
                        color: color.Plum,
                      }
                    : { ...AppStyles.LargeTitle2, color: color.Plum }
                }
              >
                Let’s customise your loan
              </h1>
              <p
                style={
                  isSmallScreen
                    ? {
                        ...AppStyles.Body,
                        color: color.Grey,
                      }
                    : { ...AppStyles.Subheading1, color: color.Grey }
                }
              >
                Now you’ve been approved, tailor Updraft Credit to meet your
                needs
              </p>
            </div> */}
            <div className=" space-y-8  items-center ">
              {/* <SubPageHeader Icon={BankNoteIcon} text={'Customise Your Loan'} /> */}
              {/* sliders */}
              <div className=" space-y-8">
                <div className=" space-y-4">
                  <div className="flex justify-between items-center h-[32px]">
                    <p
                      style={{
                        ...AppStyles.BodyBold,
                        color: color.Plum,
                      }}
                    >
                      Loan Amount
                    </p>
                    <div className="flex justify-center items-center">
                      {!isEdit ? (
                        <h2
                          onClick={() => {
                            setIsEdit(!isEdit)
                            setEditValue(LoanAmount)
                          }}
                          style={{
                            ...AppStyles.LargeTitle4,
                            color: color.BlurpleWhite,
                          }}
                        >
                          £{LoanAmount ? numeral(LoanAmount).format('0,0') : 0}
                        </h2>
                      ) : (
                        <div className="flex align-center items-center relative">
                          <h2
                            style={{
                              ...AppStyles.LargeTitle4,
                              color: color.BlurpleWhite,
                              position: 'absolute',
                              left: '-2px',
                            }}
                          >
                            £
                          </h2>
                          <input
                            type="number"
                            style={{
                              ...AppStyles.LargeTitle4,
                              color: color.BlurpleWhite,
                              border: 'none',
                              outline: 'none',
                            }}
                            onBlur={() => {
                              setIsEdit(!isEdit)
                              setEditValue(calculatedValue)
                              setOnly100AmountErr(false)
                              // setMinAmountErr(false)
                              // setMaxAmountErr(false)
                              setTimeout(() => {
                                if (
                                  editValue < getApplication[0]?.min_amount ||
                                  !editValue
                                ) {
                                  setLoanAmount(getApplication[0]?.min_amount)
                                  setMinAmountErr(false)
                                } else {
                                  setLoanAmount(editValue)
                                  setMinAmountErr(false)
                                  setMaxAmountErr(false)
                                }
                              }, 10)
                            }}
                            className="w-[90px]"
                            value={editValue}
                            onChange={(e) => {
                              if (
                                e.target.value < getApplication[0]?.min_amount
                              ) {
                                setMinAmountErr(true)
                                setOnly100AmountErr(false)
                              } else if (
                                e.target.value >= getApplication[0]?.min_amount
                              ) {
                                setMinAmountErr(false)
                                setOnly100AmountErr(false)
                              }
                              if (
                                e.target.value > getApplication[0]?.max_amount
                              ) {
                                setMaxAmountErr(true)
                              } else if (
                                e.target.value <= getApplication[0]?.max_amount
                              ) {
                                setMaxAmountErr(false)
                              }
                              const orignalVal = parseInt(e.target.value, 10)
                              let roundedValue
                              if (orignalVal >= 1 && orignalVal <= 49) {
                                roundedValue = 300
                              } else if (orignalVal >= 51 && orignalVal <= 99) {
                                roundedValue = 400
                              } else {
                                // Round the value to the nearest hundred for other cases
                                roundedValue =
                                  Math.round(orignalVal / 100) * 100
                              }

                              const only100 = e.target.value % 100 > 0

                              if (only100) {
                                setOnly100AmountErr(true)
                              } else {
                                setOnly100AmountErr(false)
                              }
                              setCalculatedValue(
                                isNaN(roundedValue) ? 0 : roundedValue,
                              )
                              setEditValue(parseInt(e.target.value))
                            }}
                          />
                        </div>
                      )}
                      {!isEdit ? (
                        <img
                          onClick={() => {
                            setIsEdit(!isEdit)
                            setEditValue(LoanAmount)
                          }}
                          src={Edit}
                          className="ml-1 cursor-pointer"
                          alt="Edit"
                        />
                      ) : (
                        <div
                          onClick={() => setIsEdit(false)}
                          className="cursor-pointer"
                        >
                          <CloseIconSmall fill={color.Tint1} />
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className=""
                    style={{
                      margin: isSmallScreen && '16px',
                      height: '20px',
                    }}
                  >
                    <ReactSlider
                      className="customSlider"
                      thumbClassName="customSlider-thumb"
                      trackClassName="customSlider-track"
                      marks={LoanAmount}
                      step={100} // Set the step to 100
                      min={getApplication[0]?.min_amount}
                      max={getApplication[0]?.max_amount}
                      value={LoanAmount}
                      onChange={(value) => {
                        setMinAmountErr(false)
                        setMaxAmountErr(false)
                        setOnly100AmountErr(false)
                        setEditValue('')
                        setLoanAmount(value)
                        setIsEdit(false)
                      }}
                    />
                  </div>
                  <div
                    className={`${isSmallScreen ? '' : 'pt-0'}`}
                    style={{ textAlign: 'center' }}
                  >
                    <div className="flex items-center justify-between w-[250px]">
                      <p
                        style={{
                          ...AppStyles.Caption2,
                          color:
                            editValue &&
                            editValue < getApplication[0]?.min_amount
                              ? '#FF619A'
                              : color.Grey,
                        }}
                      >
                        {`Min amount £${
                          getApplication[0]?.min_amount
                            ? numeral(getApplication[0]?.min_amount).format(
                                '0,0',
                              )
                            : 0
                        }`}
                      </p>
                      <p
                        style={{
                          ...AppStyles.Caption2,
                          color: color.Grey,
                        }}
                      >
                        {`|`}
                      </p>
                      <p
                        style={{
                          ...AppStyles.Caption2,
                          color: MaxAmountErr ? '#FF619A' : color.Grey,
                        }}
                      >
                        {`Max amount £${
                          getApplication[0]?.max_amount
                            ? numeral(getApplication[0]?.max_amount).format(
                                '0,0',
                              )
                            : 0
                        }`}
                      </p>
                    </div>
                    {only100AmountErr && (
                      <p
                        className="w-[220px]"
                        style={{
                          ...AppStyles.Caption2,
                          color: only100AmountErr ? '#FF619A' : color.Grey,
                        }}
                      >
                        Please only enter multiples of £100
                      </p>
                    )}
                  </div>
                </div>
                {/* second slider */}
              </div>
              <div className={`${!isSmallScreen && 'pb-10'}`}>
                <div
                  className="flex justify-between items-center h-[32px]"
                  style={{
                    marginBottom: isSmallScreen && '16px',
                  }}
                >
                  <p
                    style={{
                      ...AppStyles.BodyBold,
                      color: color.Plum,
                    }}
                  >
                    Over
                  </p>
                  <h2
                    style={{
                      fontSize: '24px',
                      fontFamily: 'MaisonDemiBold',
                      fontStyle: 'normal',
                      lineHeight: '32px',
                      color: color.Plum,
                    }}
                  >
                    {Over}{' '}
                    <span
                      style={{
                        ...AppStyles.Body16Demi,
                        color: color.Plum,
                      }}
                    >
                      Months
                    </span>
                  </h2>
                </div>

                <div
                  className=""
                  style={{
                    marginTop: isSmallScreen && '16px',
                    marginBottom: isSmallScreen && '32px',
                    margin: isSmallScreen && '16px',

                    height: '20px',
                  }}
                >
                  <ReactSlider
                    className="customSlider"
                    thumbClassName="customSlider-thumb"
                    trackClassName="customSlider-track"
                    marks={10}
                    markClassName="customSlider-mark "
                    // min={calculatedEmi()}
                    // min={calculatedEmi()}
                    min={
                      getApplication[0]?.max_emi !== null
                        ? getApplication[0]?.min_duration >= calculatedEmi()
                          ? getApplication[0]?.min_duration
                          : calculatedEmi()
                        : getApplication[0]?.min_duration
                    }
                    max={
                      months < getApplication[0]?.max_duration
                        ? months
                        : getApplication[0]?.max_duration
                    }
                    value={Over}
                    onChange={(value) => {
                      setOver(value)
                    }}
                  />
                </div>
              </div>
            </div>
            {/* grey card */}
            <div
              style={{ backgroundColor: color.LightGrey }}
              className="w-full rounded-2xl "
            >
              <div
                style={{ borderColor: color.MidGrey }}
                className="cardBody  border-b h-[80px]"
              >
                <div>
                  <p
                    style={{
                      ...AppStyles.BodyBold,
                      color: color.Plum,
                      lineHeight: '24px',
                    }}
                  >
                    Monthly payments
                  </p>
                  <p
                    style={{
                      ...AppStyles.Caption1,
                      color: color.Grey,
                      // lineHeight: '24px',
                    }}
                  >
                    Includes your monthly loan fee payment
                  </p>
                </div>
                <p style={{ ...AppStyles.BodyBold, color: color.BlurpleTeal }}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    `£${monthlyPayment ? monthlyPayment : 0}`
                  )}
                </p>
              </div>
              <div
                style={{ borderColor: color.MidGrey }}
                className="cardBody h-[56px]"
              >
                <p
                  style={{
                    ...AppStyles.BodyBold,
                    color: color.Plum,
                    lineHeight: '24px',
                  }}
                >
                  {getApplication[0]?.offer_type ===
                  'UPDRAFT_CREDIT_INTEREST_AND_FEE'
                    ? 'Total loan fee'
                    : 'Annual Interest Rate'}
                </p>
                <p style={{ ...AppStyles.BodyBold, color: color.BlurpleTeal }}>
                  {!getApplication[0]?.interestRate
                    ? 0
                    : getApplication[0]?.offer_type ===
                        'UPDRAFT_CREDIT_INTEREST_AND_FEE'
                      ? `£${Math.ceil(
                          getApplication[0]?.fee_percentage * LoanAmount,
                        )}`
                      : `${(getApplication[0]?.interestRate * 100).toFixed(2)}%`}
                </p>
              </div>
            </div>

            <div
              style={{
                ...AppStyles.Caption1,
                color: color.SoftPink,
                textAlign: 'center',
              }}
            >
              {error}
            </div>

            <PrimaryButton
              id={'confirm-amount'}
              onClick={() => {
                // setTimeout(() => {
                if (loading) {
                  return
                }
                handleAcceptLoan()
                // }, 500)
              }}
              disabled={
                minAmountErr ||
                MaxAmountErr ||
                loading ||
                only100AmountErr ||
                getApplication[0]?.min_amount > LoanAmount ||
                getApplication[0]?.max_amount < LoanAmount
              }
              text={loading ? <Spinner /> : 'Confirm Amount & Term'}
              className={' '}
            />
          </div>
        </div>
      )}
    </DefaultPage>
  )
}

export default LoanAmount
