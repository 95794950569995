/* eslint-disable react/react-in-jsx-scope */
import { Outlet, Navigate } from 'react-router-dom'

const ProtectedRoutes = () => {
  const token = localStorage.getItem('login')

  return token ? <Outlet /> : <Navigate to="/" />
}

export default ProtectedRoutes
