/* eslint-disable react/prop-types */
import React from 'react';
import { AppStyles } from '../../assets/styles/AppStyles'

function OutllineButton({ className, text, onClick, id, isDisabled }) {
  return (
    <button
      disabled={isDisabled}
      id={id}
      style={{
        ...AppStyles.ButtonMedium,
        textAlign: 'center',
      }}
      onClick={onClick}
      type="button"
      className={`${className} w-full border-2 border-borderGrey text-brightPurple font-maisonMedium px-4 py-3 h-[48px] rounded-xl  hover:bg-T_T_7  transition md:block dark:text-dark-darkTeal dark:border-dark-darkBorderGrey`}
    >
      {text}
    </button>
  )
}

export default OutllineButton
