/* eslint-disable react/prop-types */
import React from 'react'
import { AppStyles } from '../../assets/styles/AppStyles'
import { useTheme } from '../../utils/ThemeColorContext'
import SwitchInput from '../inputs/SwitchInput'

function AgreeToTerms({
  setIsOn,
  isOn,
  title,
  name,
  alreadyChecked,
  icon,
  customHeight,
}) {
  const { color, isSmallScreen } = useTheme()

  return (
    <div
      style={{
        backgroundColor: color.LightGrey,
        borderColor: color.MidGrey,
        height: isSmallScreen ? 'auto' : customHeight ? customHeight : '56px',
      }}
      className={`p-4 border rounded-2xl flex justify-between ${
        icon ? 'items-start' : isSmallScreen ? 'items-start' : 'items-center'
      } `}
    >
      <div className="flex">
        {icon && <img src={icon} className="w-[22px] h-[22px]" />}
        <p
          style={{
            ...AppStyles.BodyBold,
            color: color.Plum,
            marginLeft: icon && '16px',
            marginRight: icon && '16px',
            height: isSmallScreen ? 'auto' : '24px',
            lineHeight: '24px',
          }}
        >
          {title}
        </p>
      </div>

      <div style={{ marginLeft: isSmallScreen ? 16 : 0 }}>
        <SwitchInput
          id="mySwitch"
          isChecked={alreadyChecked ? alreadyChecked : isOn}
          onChange={() => {
            setIsOn(!isOn)
            localStorage.setItem(name, !isOn)
          }}
          label="Dark Mode"
        />
      </div>
    </div>
  )
}

export default AgreeToTerms
