import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Account_Management_List,
  getApplicationsErrorHandling,
  getProfile,
  getReApply,
} from '../redux/Actions/getMethods'
import { ActionTypes } from '../redux/Constants/actionTypes'

const JourneyRoute = () => {
  const dispatch = useDispatch()
  const isLoggedIn = localStorage.getItem('login')
  const navigate = useNavigate()
  const { token } = useSelector((state) => state.authReducer)
  const { profile, getApplication } = useSelector((state) => state.dataReducer)
  const bank = JSON.parse(localStorage.getItem(`bankConfirm-${token?.email}`))

  useEffect(() => {
    dispatch(getApplicationsErrorHandling())
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      const Temp = async () => {
        const getPro = await dispatch(getProfile())
        if (getPro === 404) {
          navigate('/about-you')
        } else if (getPro === 500) {
          // window.location.href = `${window.location.origin}/error`
          navigate('/error')
        } else if (getPro === 403) {
          // window.location.href = `${window.location.origin}/error`
          localStorage.removeItem('login')
          navigate('/')
        }
        const getApplicationStatus = await dispatch(getReApply())
        // const getDD = await dispatch(getDdAccounts())
        const get_loan_account_management = await dispatch(
          Account_Management_List(),
        )

        if (getPro && getApplicationStatus) {
          if (
            // getApplication[0]?.dd_account_number &&
            getApplication[0]?.status === 'DECLINED'
          ) {
            navigate('/failed-offer')
          } else if (getApplication[0]?.status === 'CREDIT_OK') {
            navigate('/monthly-spend')
          } else if (getApplication[0]?.status === 'POLICY_OK') {
            navigate('/soft-search')
          } else if (get_loan_account_management) {
            if (
              (get_loan_account_management?.status === 'CLOSED' &&
                get_loan_account_management?.settled_date === null) ||
              (get_loan_account_management?.status === 'CLOSED' &&
                get_loan_account_management?.settled_date !== null)
            ) {
              // window.location.href = `${window.location.origin}/no-active-loan`
              navigate('/no-active-loan')
            } else if (
              get_loan_account_management?.status === 'GOOD' ||
              get_loan_account_management?.status === 'OVERDUE' ||
              get_loan_account_management?.status === 'HOLIDAY' ||
              get_loan_account_management?.status === 'ARRANGEMENT' ||
              get_loan_account_management?.status === 'ARRANGEMENT_OVERDUE' ||
              get_loan_account_management?.status === null
            ) {
              navigate('/active-loan')
            }
          } else if (
            getApplicationStatus?.status === 'APPLY' &&
            profile?.employment_status &&
            profile?.expect_expenses_increase !== null
          ) {
            // navigate('/about-you')
            navigate('/reapply')
          } else if (
            getApplication[0]?.status === 'APPROVED' &&
            getApplication[0]?.offer_type === 'UPDRAFT_CREDIT_INTEREST_ONLY'
          ) {
            navigate('/loanapproved')
          } else if (
            getApplication[0]?.status === 'APPROVED' &&
            getApplication[0]?.offer_type !== 'UPDRAFT_CREDIT_INTEREST_ONLY'
          ) {
            navigate('/loanapprovedfee')
          } else if (bank && getApplication[0]?.status === 'REFERRED') {
            navigate('/loanreferred')
          } else if (getApplication[0]?.status === 'REFERRED') {
            navigate('/verify-income')
          } else if (profile?.title) {
            navigate('/address-details')
          }
        }
        dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
      }
      Temp()
    }
  }, [isLoggedIn, profile?.id, getApplication[0]?.id])
  return ''
}

export default JourneyRoute
