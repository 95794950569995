import { ActionTypes } from '../Constants/actionTypes'
const initState = {
  softSearch: '',
  directDebitG: '',
  agreementDoc: '',
  keyFacts: '',
  PCCI: '',
  Usersignature: '',
  postCodeError: '',

  agreement: false,
  loadingWholeScreen: false,
  addAnOtherAccount: false,

  getApplication: [],
  editProfile: [],
  affordibility: [],
  dd_accounts: [],
  profile: [],
  limits: [],
  banks: [],
  terms: [],
  rapidAddresses: [],
  findAddress: [],
  payoutSummary: [],
  aboutYou: [],
  reApply: [],
  applicationStatus: [],
  marketingData: [],
  buttonAnables: 0,
}

export const dataReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BANKS:
      return {
        ...state,
        banks: action.payload,
      }
    case ActionTypes.FILTERS_BANKS: {
      const { searchQuery } = action.payload
      const filteredBanks = state.banks.filter((bank) =>
        bank.truelayer_display_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
      )
      return {
        ...state,
        banks: filteredBanks,
      }
    }
    case ActionTypes.GET_TERMS:
      return {
        ...state,
        terms: action.payload,
      }
    case ActionTypes.GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      }
    case ActionTypes.GET_AFFORDIBILITY:
      return {
        ...state,
        affordibility: action.payload,
      }
    case ActionTypes.GET_DD_ACCOUNTS:
      return {
        ...state,
        dd_accounts: action.payload,
      }
    case ActionTypes.ADD_AND_GET_APPLICATOION:
      return {
        ...state,
        getApplication: action.payload,
      }
    case ActionTypes.GET_SOFT_SEARCH:
      return {
        ...state,
        softSearch: action.payload,
      }
    case ActionTypes.EDIT_PROFILE:
      return {
        ...state,
        editProfile: { ...state.editProfile, ...action.payload },
      }
    case ActionTypes.GET_DIRECT_DEBIT_G:
      return {
        ...state,
        directDebitG: action.payload,
      }
    case ActionTypes.GET_AGREMENT_DOCS:
      return {
        ...state,
        agreementDoc: action.payload,
      }
    case ActionTypes.GET_FACTS_DOCS:
      return {
        ...state,
        keyFacts: action.payload,
      }
    case ActionTypes.GET_PCCI_DOCS:
      return {
        ...state,
        PCCI: action.payload,
      }
    case ActionTypes.GET_RAPID_ADDRESSES:
      return {
        ...state,
        rapidAddresses: action.payload,
      }
    case ActionTypes.FINDADDRESS:
      return {
        ...state,
        findAddress: action.payload,
      }
    case ActionTypes.AGREEMENT:
      return {
        ...state,
        agreement: action.payload,
      }
    case ActionTypes.SIGNATURE:
      return {
        ...state,
        Usersignature: action.payload,
      }
    case ActionTypes.PAYOUT_SUMMARY:
      return {
        ...state,
        payoutSummary: action.payload,
      }
    case ActionTypes.LOADING_FAIL_WHOLE_SCREEN:
      return {
        ...state,
        loadingWholeScreen: false,
      }
    case ActionTypes.ADD_OTHER_ACCOUNT:
      return {
        ...state,
        addAnOtherAccount: true,
      }
    case ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN:
      return {
        ...state,
        loadingWholeScreen: true,
      }
    case ActionTypes.ABOUT_YOU:
      return {
        ...state,
        aboutYou: action.payload,
      }
    case ActionTypes.RE_APPLY:
      return {
        ...state,
        reApply: action.payload,
      }
    case ActionTypes.GET_APPLICATION_STATUS:
      return {
        ...state,
        applicationStatus: action.payload,
      }
    case ActionTypes.GET_MAKETING_DATA:
      return {
        ...state,
        marketingData: action.payload,
      }
    case ActionTypes.POSTCODE_ERROR:
      return {
        ...state,
        postCodeError: action.payload,
      }
    case ActionTypes.PRESS_ENTER_ON:
      return {
        ...state,
        buttonAnables: 1,
      }
    case ActionTypes.PRESS_ENTER_OFF:
      return {
        ...state,
        buttonAnables: 0,
      }
    default:
      return state
  }
}
