/* eslint-disable react/prop-types */
import React from 'react'
import { useTheme } from '../../utils/ThemeColorContext'

function MobileDrawer({ isOpen, onClose, children, className }) {
  const { color } = useTheme()
  if (!isOpen) return null

  return (
    <div
      className={`fixed md:min-h-screen h-screen w-full flex z-50 inset-0 overflow-y-auto  transition-opacity duration-300 `}
    >
      <div
        className={`flex items-start md:items-center justify-center h-full  w-full  md:p-10 overflow-y-auto`}
      >
        {/* Background overlay */}
        <div className="fixed inset-0 transition-opacity" onClick={onClose}>
          <div
            style={{ backgroundColor: color.Overlay }}
            className="absolute inset-0"
          ></div>
        </div>

        {/* MobileDrawer content */}
        <div
          style={{ backgroundColor: color.White }}
          className={`${className} w-full md:w-[440px] px-0 py-0 rounded-b-3xl overflow-hidden transform transition-all 
        md:align-middle md:p-10 md:rounded-3xl`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default MobileDrawer
