import axios from 'axios'
import checkIsUserLoggedIn from '../navigation/checkIsUserLoggedIn'

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_LOANS_API_URL,
  // Add any additional configuration as needed
})

// Add an interceptor to handle token expiration
api.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error)
  },
)

// Add an interceptor to include the JWT Bearer token in the headers
api.interceptors.request.use(
  async (config) => {
    const token = await checkIsUserLoggedIn()
    if (token) {
      config.headers['Authorization'] =
        `Bearer ${token?.signInUserSession?.idToken?.jwtToken}`
      config.headers['Content-Type'] = 'application/json'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default api
