import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ArrowHeadRightIcon,
  CloseIcon,
  DocumentIcon,
  DocumentSmallIcon,
  InfoIcon,
} from '../../../assets/AppIcons'
import { lightmodeColors } from '../../../assets/styles/AppColors'
import { AppStyles } from '../../../assets/styles/AppStyles'
import {
  addRegularDate,
  getAgreementDocs,
  getApplications,
} from '../../../redux/Actions/getMethods'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
import { useTheme } from '../../../utils/ThemeColorContext'

import DOMPurify from 'dompurify'
import numeral from 'numeral'

import confetti from '../../../assets/images/Misc.svg'
import confettiMobile from '../../../assets/images/MiscMobile.svg'
import DocumentModal from '../../../components/DocumentModal'
import Modal from '../../../components/Modal'
import PageHeader from '../../../components/PageHeader'
import Spinner from '../../../components/Spinner'
import SpinnerLarge from '../../../components/SpinnerLarge'
import SubPageHeader from '../../../components/SubPageHeader'
import OutllineButton from '../../../components/buttons/OutllineButton'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import FormInput from '../../../components/inputs/FormInput'
import DefaultPage from '../DefaultPage'

const CustomizeLoanNew = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getApplication, keyFacts, PCCI, loadingWholeScreen, profile } =
    useSelector((state) => state.dataReducer)

  const { loading, loading2, token } = useSelector((state) => state.authReducer)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isKeyFactsModalOpen, setIsKeyFactsModalOpen] = useState(false)
  const [isPcciModalOpen, setIsPcciModalOpen] = useState(false)
  const { color, isSmallScreen } = useTheme()
  const [LoanAmount, setLoanAmount] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [editValue, setEditValue] = useState()
  const [selectedOption, setSelectedOption] = useState(null)

  useEffect(() => {
    if (token?.email) {
      window.heap &&
        window.heap.track('uc_offer_customise_view', {
          action_name: 'tap',
          action_details: 'ucOfferCustomiseView',
        })
    }
  }, [token?.email])

  // ==================== Checking user status  =======================//
  useEffect(() => {
    if (!getApplication[0]?.id) {
      const temp = async () => {
        dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
        // const myID = await dispatch(getApplications())
        if (getApplication[0]?.status === 'APPROVED') {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
          navigate('/customise-loan')
        } else {
          dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })

          navigate('/address-detail')
        }
      }
      temp()
    }
  }, [!getApplication[0]?.id])

  useEffect(() => {
    if (selectedOption?.value) {
      addRegulateDate()
    }
  }, [selectedOption?.value])

  useEffect(() => {
    // ==================== Max Amount exsist  =======================//
    if (getApplication[0]?.max_amount) {
      setLoanAmount(
        // getApplication[0]?.amount
        //   ? getApplication[0]?.amount
        //   :
        getApplication[0]?.max_amount,
      )
      // ==================== Months exsist  =======================//
      // setOver(
      //   getApplication[0]?.duration
      //     ? getApplication[0]?.duration
      //     : getApplication[0]?.max_duration,
      // )
    }
    // ==================== Get Application document with type =======================//
    if (getApplication[0]?.id) {
      dispatch(
        getAgreementDocs(
          getApplication[0]?.id,
          'keyfacts',
          ActionTypes.GET_FACTS_DOCS,
        ),
      )
      dispatch(
        getAgreementDocs(
          getApplication[0]?.id,
          'secci',
          ActionTypes.GET_PCCI_DOCS,
        ),
      )
    }
  }, [getApplication[0]?.id])

  useEffect(() => {
    // =====================> When user edit manually Click on Pencil Icon put data inside setLoanAmount
    if (editValue) {
      setLoanAmount(editValue)
    } else if (LoanAmount) {
      setEditValue('')
    }
  }, [editValue, LoanAmount])

  // =============================>> Select Date for Loan <<===================================
  const handleSelectDate = (option) => {
    setSelectedOption(option)

    setIsOpen(false)
    // =============================>> Hit Heap Api When Select Date for Loan <<===================================
    window.heap &&
      window.heap.track('uc_offer_customise_accepted', {
        action_name: 'tap',
        action_details: 'ucOfferCustomiseAccepted',
        new_amount: LoanAmount,
        new_duration: getApplication[0]?.duration,
        did_amount_change: LoanAmount,
      })
  }

  const dateoptions = Array.from({ length: 28 }, (_, index) => ({
    value: String(index + 1), // Convert to a string if necessary
    label: String(index + 1), // Convert to a string if necessary
  }))

  const formatOptionLabel = (selectedOption) => {
    if (selectedOption === null) {
      return
    }

    let dayLabel
    switch (selectedOption.label) {
      case '1':
        dayLabel = 'st'
        break
      case '2':
        dayLabel = 'nd'
        break
      case '3':
        dayLabel = 'rd'
        break
      case '23':
        dayLabel = 'rd'
        break
      case '22':
        dayLabel = 'nd'
        break
      case '21':
        dayLabel = 'st'
        break
      default:
        dayLabel = 'th'
        break
    }
    return `${selectedOption.label}${dayLabel} of every month`
  }

  const getDateOfNextMonth = (item) => {
    const today = new Date()
    const nextMonth = new Date(today)
    nextMonth.setMonth(today.getMonth() + 1)
    nextMonth.setDate(item)

    const year = nextMonth.getFullYear()
    const month = String(nextMonth.getMonth() + 1).padStart(2, '0')
    const day = String(nextMonth.getDate()).padStart(2, '0')

    return `${day}/${month}/${year}`
  }

  const addRegulateDate = async () => {
    const id = getApplication[0]?.id
    const regularDay = await dispatch(addRegularDate(id, selectedOption?.label))
    if (regularDay) {
      dispatch(getApplications())
      dispatch(
        getAgreementDocs(
          getApplication[0]?.id,
          'keyfacts',
          ActionTypes.GET_FACTS_DOCS,
        ),
      )
      dispatch(
        getAgreementDocs(
          getApplication[0]?.id,
          'secci',
          ActionTypes.GET_PCCI_DOCS,
        ),
      )
    }
  }

  // ==================== Calculated from BE for getting Interest Rate ===================//
  const calculatedInterestRateFromUpdaft =
    getApplication[0]?.repayment_schedules?.totalRepaymentExpected -
    getApplication[0]?.amount -
    getApplication[0]?.fee_amount
  // ==================== Calculated from BE for getting Interest Rate ===================//

  const totalPayment =
    getApplication[0]?.repayment_schedules?.totalRepaymentExpected
  const fees = parseFloat(getApplication[0]?.fee_amount)
  const APR = getApplication[0]?.apr
  const totalWithFee = totalPayment

  const selectDateRef = useRef(null)
  const scrollToSelectDate = () => {
    if (selectDateRef.current) {
      selectDateRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <DefaultPage>
      {loadingWholeScreen ? (
        <SpinnerLarge />
      ) : (
        <div className="">
          {loading2 ? (
            <SpinnerLarge />
          ) : (
            <>
              <>
                <div className="relative container mx-auto md:w-[564px]    md:space-y-10  ">
                  <PageHeader
                    title={'Your Loan'}
                    backButtonTitle={'Customise your loan'}
                    prevRoute={'/loan-amount'}
                    customDescription={
                      <p
                        style={
                          isSmallScreen
                            ? {
                                ...AppStyles.Body,
                                color: color.Grey,
                                minHeight: '72px',
                              }
                            : {
                                ...AppStyles.Subheading1,
                                color: color.Grey,
                              }
                        }
                      >
                        <span style={{ color: color.BlurpleTeal }}>
                          {' '}
                          {profile?.first_name}
                        </span>
                        , please select your payment date and view your loan
                        documents before moving on to the next step.
                      </p>
                    }
                  />
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-1 gap-4  ">
                      {/* column 2 */}
                      <div
                        style={{ backgroundColor: color.White }}
                        className="p-4 rounded-2xl items-center  md:block"
                      >
                        <div
                          style={{ backgroundColor: color.Tint7 }}
                          className="rounded-[12px] mb-4"
                        >
                          <div
                            style={{
                              backgroundImage: `url(${confetti})`,
                              backgroundRepeat: 'repeat-x',
                              backgroundPosition: 'bottom',
                              zIndex: 20,
                            }}
                            className=" flex  flex-col youwillreceive  justify-center items-center h-[176px] w-[312px] m-auto space-y-2"
                          >
                            <h3
                              style={{
                                ...AppStyles.Subheading1,
                                color: color.Plum,
                              }}
                            >
                              You will receive
                            </h3>
                            <h1
                              style={{
                                ...AppStyles.LargeTitle2,
                                color: color.BlurpleTeal,
                              }}
                            >
                              £
                              {getApplication[0]?.amount
                                ? numeral(getApplication[0]?.amount).format(
                                    '0,0',
                                  )
                                : 0}
                            </h1>
                          </div>
                          <div
                            className="flex justify-center items-center h-[60px] rounded-b-[12px]"
                            style={{ backgroundColor: color.Tint7 }}
                          >
                            <p
                              style={{
                                ...AppStyles.BodyBold,
                                color: color.GreyBlue,
                              }}
                            >
                              You’ll pay:&nbsp;
                              <span
                                style={{
                                  ...AppStyles.Title3,
                                  color: color.BlurpleTeal,
                                }}
                              >
                                £
                                {!totalWithFee
                                  ? 0
                                  : totalWithFee
                                      ?.toFixed(2)
                                      ?.toLocaleString('en-US', {
                                        maximumFractionDigits: 2, // No decimal places
                                      })
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </span>
                              <>
                                {getApplication[0]?.offer_type !==
                                  'UPDRAFT_CREDIT_INTEREST_ONLY' && (
                                  <span
                                    style={{
                                      ...AppStyles.Title3,
                                      color: color.BlurpleTeal,
                                    }}
                                  >
                                    {' over'}&nbsp;
                                    <span style={{ color: color.BlurpleTeal }}>
                                      {getApplication[0]?.duration}
                                      &nbsp;
                                    </span>
                                    months
                                  </span>
                                )}
                              </>
                            </p>
                          </div>
                        </div>

                        <div className=" space-y-3 " ref={selectDateRef}>
                          <div className="space-y-0 ">
                            <p
                              style={{
                                ...AppStyles.Caption2,
                                color: color.GreyBlue,
                                backgroundColor: color.White,
                              }}
                              className="h-[48px]  flex justify-center items-center rounded-t-[12px] border-[1px] border-TextGrey-500 "
                            >
                              Which is made up of:
                            </p>
                            <div className="flex justify-between items-center bg-[#ffff] rounded-b-[12px]">
                              <div className="flex-col h-[72px] flex justify-center items-center w-[100%] border-r-[1px] border-l-[1px] border-b-[1px] rounded-bl-[12px]">
                                <p
                                  style={{
                                    ...AppStyles.Title4,
                                    color: color.BlurpleTeal,
                                  }}
                                >
                                  £
                                  {getApplication[0]?.amount
                                    ? numeral(getApplication[0]?.amount).format(
                                        '0,0',
                                      )
                                    : 0}
                                </p>
                                <p
                                  style={{
                                    ...AppStyles.Caption2,
                                    color: color.Grey,
                                  }}
                                >
                                  Loan amount
                                </p>
                              </div>
                              {getApplication[0]?.offer_type !==
                                'UPDRAFT_CREDIT_INTEREST_ONLY' && (
                                <div className="flex-col h-[72px] flex justify-center items-center w-[100%]  border-r-[1px] border-b-[1px]">
                                  <p
                                    style={{
                                      ...AppStyles.Title4,
                                      color: color.BlurpleTeal,
                                    }}
                                  >
                                    £
                                    {parseFloat(getApplication[0]?.fee_amount)
                                      ? parseFloat(
                                          getApplication[0]?.fee_amount,
                                        )
                                      : 0}
                                  </p>
                                  <p
                                    style={{
                                      ...AppStyles.Caption2,
                                      color: color.Grey,
                                    }}
                                  >
                                    Fees
                                  </p>
                                </div>
                              )}
                              <div className="flex-col h-[72px] flex justify-center items-center w-[100%] rounded-br-[12px] border-r-[1px] border-b-[1px]">
                                <p
                                  style={{
                                    ...AppStyles.Title4,
                                    color: color.BlurpleTeal,
                                  }}
                                >
                                  £
                                  {getApplication[0]?.repayment_schedules
                                    ?.totalRepaymentExpected
                                    ? calculatedInterestRateFromUpdaft.toFixed(
                                        2,
                                      )
                                    : 0}
                                </p>
                                <p
                                  style={{
                                    ...AppStyles.Caption2,
                                    color: color.Grey,
                                  }}
                                >
                                  Interest
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="relative inline-block w-full  space-y-4 ">
                            <FormInput
                              placeholder={'Select your monthly payment date'}
                              type={'text'}
                              id="MonthlyPayment"
                              name={'MonthlyPayment'}
                              value={
                                selectedOption
                                  ? formatOptionLabel(selectedOption)
                                  : ''
                              }
                              onClick={() => {
                                setIsOpen(!isOpen)
                              }}
                              rightSign={true}
                            />

                            {isOpen && (
                              <div
                                style={{
                                  margin: 0,
                                  backgroundColor: color.White,
                                  borderColor: color.WhiteBlack,
                                  boxShadow:
                                    '0px 8px 16px 0px rgba(51, 51, 51, 0.20), 0px 4px 16px 0px rgba(0, 0, 0, 0.25)',
                                }}
                                className="border rounded-2xl absolute z-10  "
                              >
                                <div className="p-6">
                                  <p
                                    style={{
                                      ...AppStyles.Title2,
                                      color: color.GreyBlue,
                                    }}
                                  >
                                    Select your payment date
                                  </p>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: color.LightGrey,
                                  }}
                                  className=" grid grid-cols-7 gap-3 py-5 px-4"
                                >
                                  {dateoptions.map((option, index) => (
                                    <div
                                      key={index}
                                      onClick={() => handleSelectDate(option)}
                                      style={{
                                        backgroundColor:
                                          selectedOption &&
                                          selectedOption.value === option.value
                                            ? color.BlurpleTeal
                                            : color.LightGrey,
                                      }}
                                      className={`py-3 px-4 w-10 h-10 rounded-lg flex items-center justify-center cursor-pointer `}
                                    >
                                      <p
                                        style={{
                                          ...AppStyles.Body,
                                          color:
                                            selectedOption &&
                                            selectedOption.value ===
                                              option.value
                                              ? color.White
                                              : color.GreyBlue,
                                        }}
                                      >
                                        {' '}
                                        {option.label}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                                <div className="p-2 flex justify-end space-x-2">
                                  <OutllineButton
                                    id={'cancel-customize'}
                                    text={'Cancel'}
                                    className={'md:w-fit'}
                                    onClick={() => setIsOpen(!isOpen)}
                                  />
                                  <OutllineButton
                                    id={'ok-customize'}
                                    text={'OK'}
                                    className={'md:w-fit'}
                                    onClick={() => setIsOpen(!isOpen)}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              borderColor: color.MidGrey,
                              marginTop: '16px',
                            }}
                            className="border rounded-[12px] mt-4"
                          >
                            <div
                              style={{
                                backgroundColor: color.White,
                                borderColor: color.MidGrey,
                              }}
                              className=" px-4  py-3 rounded-t-[12px] border-b"
                            >
                              <p
                                style={{
                                  ...AppStyles.Caption3,
                                  color: color.Grey,
                                }}
                              >
                                First payment on
                              </p>
                              <p
                                style={{
                                  ...AppStyles.BodyBold,
                                  color: color.BlurpleTeal,
                                }}
                              >
                                {!selectedOption
                                  ? '-'
                                  : getDateOfNextMonth(selectedOption?.label)}
                              </p>
                            </div>
                            <div
                              style={{ backgroundColor: color.White }}
                              className=" px-4 py-3 rounded-b-[12px]"
                            >
                              <p
                                style={{
                                  ...AppStyles.Caption3,
                                  color: color.Grey,
                                }}
                              >
                                Funds deposited in
                              </p>
                              <p
                                style={{
                                  ...AppStyles.BodyBold,
                                  color: color.BlurpleTeal,
                                }}
                              >
                                3 – 5 working days
                              </p>
                            </div>
                          </div>
                          <div className="space-y-0 ">
                            <div
                              style={{
                                backgroundColor: color.White,
                                borderColor: color.MidGrey,
                              }}
                              className="border rounded-[12px]"
                            >
                              <div
                                style={{
                                  borderColor: color.MidGrey,
                                }}
                                className=" px-4 py-3 rounded-t-[12px] border-b"
                              >
                                <p
                                  style={{
                                    ...AppStyles.Caption3,
                                    color: color.Grey,
                                  }}
                                >
                                  Monthly Instalments
                                </p>
                                <p
                                  style={{
                                    ...AppStyles.BodyBold,
                                    color: color.BlurpleTeal,
                                  }}
                                >
                                  {getApplication[0]?.duration ? (
                                    <div
                                      style={{
                                        ...AppStyles.BodyBold,
                                        color: color.BlurpleTeal,
                                      }}
                                    >
                                      {getApplication[0]?.duration - 1} x £
                                      {
                                        getApplication[0]?.repayment_schedules
                                          ?.periods[1]?.amount
                                      }
                                    </div>
                                  ) : (
                                    '-'
                                  )}
                                </p>
                              </div>
                              <div
                                style={{ borderColor: color.MidGrey }}
                                className=" px-4 py-3  border-b"
                              >
                                <p
                                  style={{
                                    ...AppStyles.Caption3,
                                    color: color.Grey,
                                  }}
                                >
                                  Final Instalment of
                                </p>
                                <p
                                  style={{
                                    ...AppStyles.BodyBold,
                                    color: color.BlurpleTeal,
                                  }}
                                >
                                  <>
                                    {loading ? (
                                      <Spinner width={'5'} height={'5'} />
                                    ) : getApplication[0]?.repayment_schedules
                                        ?.periods[1]?.amount ? (
                                      '£' +
                                      getApplication[0]?.repayment_schedules?.periods.slice(
                                        -1,
                                      )[0]?.amount
                                    ) : (
                                      '-'
                                    )}
                                  </>
                                </p>
                              </div>
                              <div
                                style={{ backgroundColor: color.White }}
                                className="  px-4 py-3   rounded-b-[12px]"
                              >
                                <p
                                  style={{
                                    ...AppStyles.Caption3,
                                    color: color.Grey,
                                  }}
                                >
                                  Your Interest rate
                                </p>
                                <p
                                  style={{
                                    ...AppStyles.BodyBold,
                                    color: color.BlurpleTeal,
                                  }}
                                >
                                  {getApplication[0]?.interestRate ? (
                                    <div
                                      style={{
                                        ...AppStyles.BodyBold,
                                        color: color.BlurpleTeal,
                                      }}
                                    >
                                      {(
                                        getApplication[0]?.interestRate * 100
                                      ).toFixed(2)}
                                      %
                                    </div>
                                  ) : (
                                    '-'
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="pt-3 pr-5 pb-2 pl-4 rounded-b-lg">
                              <p
                                style={{
                                  ...AppStyles.Caption3,
                                  color: color.Grey,
                                }}
                              >
                                APR
                              </p>
                              <p
                                style={{
                                  ...AppStyles.BodyBold,
                                  color: color.BlurpleTeal,
                                }}
                              >
                                {/* {APR?.toFixed(2) * 100}% */}
                                {APR
                                  ? `${parseFloat((APR * 100).toFixed(2))}%`
                                  : '-'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* column 1 */}
                      <div className=" space-y-4  items-center">
                        <div
                          style={{
                            background: lightmodeColors.White,
                          }}
                          className="  rounded-3xl  p-6 space-y-6"
                        >
                          <SubPageHeader
                            width={isSmallScreen && 'fit-content'}
                            Icon={DocumentSmallIcon}
                            text={'Loan documents'}
                          />
                          <p
                            style={{
                              ...AppStyles.Body16,
                              color: color.Grey,
                              lineHeight: '24px',
                            }}
                          >
                            {`It's important you read the following loan documents. If you have any questions please open a chat.`}
                          </p>
                          <div className=" space-y-2">
                            <div
                              onClick={() => {
                                setIsOpen(false)
                                setIsKeyFactsModalOpen(true)
                              }}
                              className="w-full space-y-6 cursor-pointer"
                            >
                              <div className="w-full space-y-6 ">
                                <div
                                  style={{
                                    backgroundColor: color.White,
                                    borderColor: color.MidGrey,
                                    height: isSmallScreen && '88px',
                                  }}
                                  className="px-4 py-5 border rounded-2xl flex space-x-4  justify-between "
                                >
                                  <div className="flex space-x-4 items-center justify-between w-full">
                                    <div className="flex space-x-4 items-center justify-between">
                                      <DocumentIcon fill={color.BlurpleTeal} />
                                      <p
                                        style={{
                                          ...AppStyles.BodyBold,
                                          color: color.Plum,
                                        }}
                                        className=" text-Purple text-14 font-semibold font-maisonBold  dark:text-dark-darkTextGrey"
                                      >
                                        Key Facts
                                      </p>
                                    </div>
                                    <ArrowHeadRightIcon
                                      fill={color.BlurpleTeal}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                setIsOpen(false)
                                setIsPcciModalOpen(true)
                              }}
                              className="w-full space-y-6 cursor-pointer"
                            >
                              <div className="w-full space-y-6 ">
                                <div
                                  style={{
                                    backgroundColor: color.White,
                                    borderColor: color.MidGrey,
                                    height: isSmallScreen && '88px',
                                  }}
                                  className="px-4 py-5 border rounded-2xl flex space-x-4  justify-between "
                                >
                                  <div className="flex space-x-4 items-center justify-between w-full">
                                    <div className="flex space-x-4 items-center justify-between">
                                      <DocumentIcon fill={color.BlurpleTeal} />
                                      <p
                                        style={{
                                          ...AppStyles.BodyBold,
                                          color: color.Plum,
                                        }}
                                        className=" text-Purple text-14 font-semibold font-maisonBold  dark:text-dark-darkTextGrey"
                                      >
                                        PCCI
                                      </p>
                                    </div>
                                    <ArrowHeadRightIcon
                                      fill={color.BlurpleTeal}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-6 md:px-0 mt-4">
                      {getApplication[0]?.offer_type !==
                        'UPDRAFT_CREDIT_INTEREST_ONLY' && (
                        <div
                          style={{ backgroundColor: color.Tint7 }}
                          className=" w-full rounded-[12px] p-6 flex  space-x-4"
                        >
                          <div className="">
                            <InfoIcon fill={color.BlurpleTeal} />
                          </div>
                          <div className="flex flex-col">
                            <p
                              style={{
                                ...AppStyles.BodyBold,
                                color: color.BlurpleTeal,
                              }}
                            >
                              Your APR explained:
                            </p>
                            <p style={{ ...AppStyles.Body, color: color.Grey }}>
                              Your APR includes a loan fee of £
                              {fees ? fees : '0'} which is paid in equal
                              instalments over the term of your loan. If you
                              settle early, any outstanding amount of the loan
                              fee will be payable in full.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center  p-6 md:p-0  md:block">
                    <PrimaryButton
                      id={'customise-loan-direct-debit-btn'}
                      onClick={() => {
                        if (loading) {
                          return
                        }
                        if (!selectedOption?.value) {
                          scrollToSelectDate()
                          setIsOpen(true)
                          return
                        }
                        navigate('/direct-debit')
                        window.heap &&
                          window.heap.track('uc_offer_details_accepted', {
                            action_name: 'tap',
                            action_details: 'ucOfferDetailsAccepted',
                            new_amount: LoanAmount,
                            new_duration: getApplication[0]?.duration,
                            did_amount_change: LoanAmount,
                          })
                      }}
                      disabled={loading}
                      text={loading ? <Spinner /> : selectedOption?.value ? 'Confirm payment date' : 'Select payment date'}
                      className={`md:w-[260px] w-full ${isKeyFactsModalOpen || (isPcciModalOpen && 'hidebutton')}`}
                    />
                  </div>
                </div>

                {isSmallScreen && (
                  <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                  >
                    <>
                      <div
                        style={{ backgroundColor: color.Tint7 }}
                        className="p-4 rounded-2xl items-center "
                      >
                        <div
                          style={{
                            backgroundImage: `url(${confettiMobile})`,
                            backgroundRepeat: 'repeat-x',
                            backgroundPosition: 'bottom',
                            zIndex: 20,
                          }}
                          className=" flex flex-col youwillreceive  justify-center items-center h-[126px]  w-[295px]  m-auto space-y-2"
                        >
                          <h3
                            style={{
                              ...AppStyles.Subheading1,
                              color: color.Plum,
                            }}
                          >
                            You will receive
                          </h3>
                          <h1
                            style={{
                              ...AppStyles.LargeTitle2,
                              color: color.BlurpleTeal,
                            }}
                          >
                            £
                            {getApplication[0]?.amount
                              ? numeral(getApplication[0]?.amount).format('0,0')
                              : 0}
                          </h1>
                        </div>

                        <div className=" space-y-3">
                          <div
                            className="flex justify-center items-center h-[60px] rounded-b-[12px] "
                            style={{ backgroundColor: color.Tint7 }}
                          >
                            <p
                              style={{
                                ...AppStyles.BodyBold,
                                color: color.GreyBlue,
                              }}
                            >
                              You’ll pay:&nbsp;
                              <span style={{ color: color.BlurpleTeal }}>
                                £
                                {!totalWithFee
                                  ? 0
                                  : totalWithFee
                                      ?.toFixed(2)
                                      ?.toLocaleString('en-US', {
                                        maximumFractionDigits: 2, // No decimal places
                                      })
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </span>
                              <>
                                {getApplication[0]?.offer_type !==
                                  'UPDRAFT_CREDIT_INTEREST_ONLY' && (
                                  <span>
                                    {' over'}&nbsp;
                                    <span style={{ color: color.BlurpleTeal }}>
                                      {getApplication[0]?.duration}
                                      &nbsp;
                                    </span>
                                    months
                                  </span>
                                )}
                              </>
                            </p>
                          </div>
                          <div className="space-y-0">
                            <p
                              style={{
                                ...AppStyles.Caption2,
                                color: color.GreyBlue,
                                backgroundColor: color.White,
                              }}
                              className="h-[48px]  flex justify-center items-center rounded-t-[12px] border-[1px] border-TextGrey-500"
                            >
                              Which is made up of:
                            </p>
                            <div className="flex  justify-between items-center bg-[#ffff] rounded-b-[12px]">
                              <div className="flex-col h-[72px] flex justify-center items-center w-[100%] border-r-[1px] border-l-[1px] border-b-[1px] rounded-bl-[12px]">
                                <p
                                  style={{
                                    ...AppStyles.Title4,
                                    color: color.BlurpleTeal,
                                  }}
                                >
                                  £
                                  {LoanAmount
                                    ? numeral(LoanAmount).format('0,0')
                                    : 0}
                                </p>
                                <p
                                  style={{
                                    ...AppStyles.Caption2,
                                    color: color.Grey,
                                  }}
                                >
                                  Loan amount
                                </p>
                              </div>
                              {getApplication[0]?.offer_type !==
                                'UPDRAFT_CREDIT_INTEREST_ONLY' && (
                                <div className="flex-col h-[72px] flex justify-center items-center w-[100%]  border-r-[1px] border-b-[1px]">
                                  <p
                                    style={{
                                      ...AppStyles.Title4,
                                      color: color.BlurpleTeal,
                                    }}
                                  >
                                    £
                                    {parseFloat(getApplication[0]?.fee_amount)
                                      ? parseFloat(
                                          getApplication[0]?.fee_amount,
                                        )
                                      : 0}
                                  </p>
                                  <p
                                    style={{
                                      ...AppStyles.Caption2,
                                      color: color.Grey,
                                    }}
                                  >
                                    Fees
                                  </p>
                                </div>
                              )}
                              <div className="flex-col h-[72px] flex justify-center items-center w-[100%] rounded-br-[12px] border-r-[1px] border-b-[1px]">
                                <p
                                  style={{
                                    ...AppStyles.Title4,
                                    color: color.BlurpleTeal,
                                  }}
                                >
                                  £
                                  {getApplication[0]?.repayment_schedules
                                    ?.totalRepaymentExpected
                                    ? calculatedInterestRateFromUpdaft?.toFixed(
                                        2,
                                      )
                                    : 0}
                                </p>
                                <p
                                  style={{
                                    ...AppStyles.Caption2,
                                    color: color.Grey,
                                  }}
                                >
                                  Interest
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ borderColor: color.MidGrey }}
                            className="border rounded-[12px] "
                          >
                            <div
                              style={{
                                backgroundColor: color.White,
                                borderColor: color.MidGrey,
                              }}
                              className="px-4 h-[64px] md:w-[295px] py-3 rounded-t-[12px] border-b border-r"
                            >
                              <p
                                style={{
                                  ...AppStyles.Caption3,
                                  color: color.Grey,
                                }}
                              >
                                First payment on
                              </p>
                              <p
                                style={{
                                  ...AppStyles.BodyBold,
                                  color: color.BlurpleTeal,
                                }}
                              >
                                {!selectedOption
                                  ? '-'
                                  : getDateOfNextMonth(selectedOption?.label)}
                              </p>
                            </div>
                            <div
                              style={{ backgroundColor: color.White }}
                              className=" px-4 py-3 rounded-b-[12px] h-[64px] md:w-[295px] border-r"
                            >
                              <p
                                style={{
                                  ...AppStyles.Caption3,
                                  color: color.Grey,
                                }}
                              >
                                Funds deposited in
                              </p>
                              <p
                                style={{
                                  ...AppStyles.BodyBold,
                                  color: color.BlurpleTeal,
                                }}
                              >
                                3 – 5 working days
                              </p>
                            </div>
                          </div>
                          <div className="space-y-0 ">
                            <div
                              style={{
                                backgroundColor: color.White,
                                borderColor: color.MidGrey,
                              }}
                              className="border rounded-[12px]"
                            >
                              <div
                                style={{
                                  borderColor: color.MidGrey,
                                }}
                                className=" h-[64px] w-[295px] px-4 py-3 rounded-t-[12px] border-b"
                              >
                                <p
                                  style={{
                                    ...AppStyles.Caption3,
                                    color: color.Grey,
                                  }}
                                >
                                  Monthly Instalments
                                </p>
                                <p
                                  style={{
                                    ...AppStyles.BodyBold,
                                    color: color.BlurpleTeal,
                                  }}
                                >
                                  {getApplication[0]?.duration ? (
                                    <div
                                      style={{
                                        ...AppStyles.BodyBold,
                                        color: color.BlurpleTeal,
                                      }}
                                    >
                                      {getApplication[0]?.duration - 1} x £
                                      {
                                        getApplication[0]?.repayment_schedules
                                          ?.periods[1]?.amount
                                      }
                                    </div>
                                  ) : (
                                    '-'
                                  )}
                                </p>
                              </div>
                              <div
                                style={{ borderColor: color.MidGrey }}
                                className=" px-4 py-3  border-b h-[64px] w-[295px] "
                              >
                                <p
                                  style={{
                                    ...AppStyles.Caption3,
                                    color: color.Grey,
                                  }}
                                >
                                  Final Instalment of
                                </p>
                                <p
                                  style={{
                                    ...AppStyles.BodyBold,
                                    color: color.BlurpleTeal,
                                  }}
                                >
                                  <>
                                    {loading ? (
                                      <Spinner />
                                    ) : getApplication[0]?.repayment_schedules
                                        ?.periods[1]?.amount ? (
                                      '£' +
                                      getApplication[0]?.repayment_schedules?.periods.slice(
                                        -1,
                                      )[0]?.amount
                                    ) : (
                                      '-'
                                    )}
                                  </>
                                </p>
                              </div>
                              <div
                                style={{ backgroundColor: color.White }}
                                className="  px-4 py-3 border-r-[1px]  rounded-b-[12px] h-[64px] w-[295px]"
                              >
                                <p
                                  style={{
                                    ...AppStyles.Caption3,
                                    color: color.Grey,
                                  }}
                                >
                                  Your Interest rate
                                </p>
                                <p
                                  style={{
                                    ...AppStyles.BodyBold,
                                    color: color.BlurpleTeal,
                                  }}
                                >
                                  {getApplication[0]?.interestRate ? (
                                    <div
                                      style={{
                                        ...AppStyles.BodyBold,
                                        color: color.BlurpleTeal,
                                      }}
                                    >
                                      {getApplication[0]?.interestRate * 100}%
                                    </div>
                                  ) : (
                                    '-'
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="pt-3 pr-5 pb-2 pl-4 rounded-b-lg h-[64px] w-[295px]">
                              <p
                                style={{
                                  ...AppStyles.Caption3,
                                  color: color.Grey,
                                }}
                              >
                                APR
                              </p>
                              <p
                                style={{
                                  ...AppStyles.BodyBold,
                                  color: color.BlurpleTeal,
                                }}
                              >
                                {APR
                                  ? `${parseFloat((APR * 100).toFixed(2))}%`
                                  : '-'}
                              </p>
                            </div>
                            {getApplication[0]?.offer_type !==
                              'UPDRAFT_CREDIT_INTEREST_ONLY' && (
                              <div
                                style={{ backgroundColor: color.Tint7 }}
                                className=" w-full rounded-[12px] p-6 flex  space-x-4 h-[216px] w-[295px]"
                              >
                                <div className="">
                                  <InfoIcon fill={color.BlurpleTeal} />
                                </div>
                                <div className="flex flex-col">
                                  <p
                                    className="h-[24px] w-[211px]"
                                    style={{
                                      ...AppStyles.BodyBold,
                                      color: color.BlurpleTeal,
                                    }}
                                  >
                                    Your APR explained:
                                  </p>
                                  <p
                                    className="h-[144px] "
                                    style={{
                                      ...AppStyles.Body,
                                      color: color.Grey,
                                      lineHeight: '24px',
                                    }}
                                  >
                                    Your APR includes a loan fee of £
                                    {fees ? fees : '0'} which is paid in equal
                                    instalments over the term of your loan. If
                                    you settle early, any outstanding amount of
                                    the loan fee will be payable in full.
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-[24px]">
                        <OutllineButton
                          id={'back-customise'}
                          onClick={() => setIsModalOpen(false)}
                          text={'Back to Customise Loan'}
                          className={' w-full mb-[8px]'}
                        />
                        <PrimaryButton
                          id={'go-direct-debit'}
                          onClick={() => {
                            if (loading) {
                              return
                            }
                            navigate('/direct-debit')
                          }}
                          text={loading ? <Spinner /> : 'Continue'}
                          className={'w-[full]'}
                        />
                      </div>
                    </>
                  </Modal>
                )}

                <DocumentModal
                  isOpen={isKeyFactsModalOpen}
                  onClose={() => setIsKeyFactsModalOpen(false)}
                >
                  <div className="p-4 keyfact md:h-full md:w-full items-center ">
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1"
                    />
                    <div className="flex">
                      <div
                        className="flex flex-col space-y-4 pb-10"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(keyFacts),
                        }}
                      />
                      <div
                        className="cursor-pointer"
                        onClick={() => setIsKeyFactsModalOpen(false)}
                      >
                        <CloseIcon fill={color.Tint1} />
                      </div>
                    </div>
                  </div>
                </DocumentModal>

                <DocumentModal
                  isOpen={isPcciModalOpen}
                  onClose={() => setIsPcciModalOpen(false)}
                >
                  <div className="p-2 h-full pcci w-full items-center ">
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1"
                    />
                    <div className="flex relative">
                      <div
                        className="flex flex-col space-y-4 pb-10 document-table"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(PCCI),
                        }}
                      />
                      <div
                        className="cursor-pointer pcci-close"
                        onClick={() => setIsPcciModalOpen(false)}
                      >
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                </DocumentModal>
              </>
            </>
          )}
        </div>
      )}
    </DefaultPage>
  )
}

export default CustomizeLoanNew
