import React from 'react'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import TopNavBar from '../../../components/navigation/TopNavBar'
import confetti from '../../../assets/images/confetti.png'
import ConfettiSvg from '../../../assets/icons/Confetti.svg'
import { useNavigate } from 'react-router'
import { AppStyles } from '../../../assets/styles/AppStyles'
import { useTheme } from '../../../utils/ThemeColorContext'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionTypes } from '../../../redux/Constants/actionTypes'
// import { getApplications } from '../../../redux/Actions/getMethods'
import SpinnerLarge from '../../../components/SpinnerLarge'
import { removeAllGarbage } from '../../../api/dummyData'

function EmailVerified() {
  const dispatch = useDispatch()
  const { loadingWholeScreen, getApplication, profile } = useSelector(
    (state) => state.dataReducer,
  )
  const navigate = useNavigate()
  const { color, isSmallScreen } = useTheme()

  // ==================== Checking user status  =======================//
  useEffect(() => {
    const temp = async () => {
      dispatch({ type: ActionTypes.LOADING_SUCCESS_WHOLE_SCREEN })
      // const myID = await dispatch(getApplications())
      if (getApplication[0]?.status === 'APPROVED') {
        dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
        navigate('/email-verified')
        removeAllGarbage()
      } else {
        dispatch({ type: ActionTypes.LOADING_FAIL_WHOLE_SCREEN })
        navigate('/address-detail')
      }
    }
    temp()
  }, [])

  return (
    <>
    {loadingWholeScreen ? (
      <SpinnerLarge />
    ) : (
      <div
        style={{
          backgroundColor: color.LightGrey,
        }}
      >
        <TopNavBar />
        <div
          style={{
            backgroundColor: color.LightGrey,
            minHeight: isSmallScreen
              ? 'calc(100vh - 0px)'
              : 'calc(100vh - 108px)',
          }}
          className="mobilecta bottom-0 w-full md:block cta "
        >
          <div
            style={{
              backgroundImage: `url(${isSmallScreen ? ConfettiSvg : confetti})`,
              backgroundRepeat: 'repeat-x',
              backgroundPosition: 'bottom',
              zIndex: 20,
              minHeight: isSmallScreen
              ? 'calc(100vh - 0px)'
              : 'calc(100vh - 108px)',
            }}
            className="flex flex-col pt-[164px]"
          >
            <div className="relative container mx-auto md:w-[564px] space-y-10 p-6  ">
              <div className="h-full flex flex-col items-center justify-center space-y-10 ">
                <div className="flex flex-col items-center space-y-2">
                  <h1
                    style={
                      isSmallScreen
                        ? {
                            ...AppStyles.Title1,
                            color: color.Plum,
                          }
                        : { ...AppStyles.LargeTitle2, color: color.Plum }
                    }
                    className="text-center"
                  >
                    {profile?.first_name}, your email is verified
                  </h1>
                  <p
                    style={{
                      ...AppStyles.Body,
                      color: color.Grey,
                      height: isSmallScreen && '48px',
                      textAlign: 'center',
                      lineHeight: isSmallScreen && '24px',
                    }}
                  >
                    All that’s left is to sign your agreement and then we’ll
                    transfer you the funds.
                  </p>
                </div>

                <div className="flex flex-col items-center space-y-2 w-full">
                  <PrimaryButton
                    id={'email-verified-btn'}
                    text={'Sweet!'}
                    className={'w-full md:w-[260px]'}
                    onClick={() => navigate('/signagreement')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    )}
  </>
  )
}
export default EmailVerified