/* eslint-disable react/prop-types */
import React from 'react'
import { CheckIcon } from '../../assets/AppIcons'
import { useTheme } from '../../utils/ThemeColorContext'
import { AppStyles } from '../../assets/styles/AppStyles'

function BankCard({ bank, isSelected, onClick }) {
  const { darkMode, color } = useTheme()
  return (
    <div
      style={{
        backgroundColor: color.White,
        borderColor: isSelected === bank ? color.Teal : color.MidGrey,
      }}
      onClick={onClick}
      className={`p-4 cursor-pointer h-[64px] w-full rounded-xl flex items-center justify-between border space-x-3  
      } `}
    >
      <div className="flex items-center space-x-3">
        <img
          src={bank.truelayer_logo_url}
          alt=""
          className="rounded-full w-[32px] h-[32px]"
        />
        <p style={{ ...AppStyles.BodyBold, color: color.Plum }}>
          {bank.truelayer_display_name}
        </p>
      </div>
      {isSelected === bank && (
        <div
          style={{ backgroundColor: color.Teal }}
          className=" w-5 h-5 flex items-center justify-center rounded-full  "
        >
          <CheckIcon
            fill={
              darkMode ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 1)'
            }
            className={' w-3 h-3'}
          />
        </div>
      )}
    </div>
  )
}

export default BankCard
